import { scrollToElement } from '@maternity/mun-cantrips';
import classNames from 'classnames';
import * as React from 'react';

import { ComboboxCtx } from './useComboboxCtx';
import { getDropdownOptions, getFocusedOption } from './utils';

interface ComboboxContainerProps {
  /** Combobox context object */
  ctx: ComboboxCtx<any>;
  /** Extra CSS classes to add to the `.dropdown` element */
  className?: string;
  children: React.ReactNode;
}

/** Renders the container element for a combobox. */
export const ComboboxContainer = ({
  ctx,
  className,
  children,
}: ComboboxContainerProps) => {
  const { disabled, isOpen, setIsOpen, menuRef } = ctx;

  const handleContainerKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (disabled || !menuRef.current) return;
      switch (e.key) {
        case 'Escape': {
          setIsOpen(false);
          return;
        }
        case 'ArrowLeft':
        case 'ArrowUp': {
          // Focus previous option
          const options = getDropdownOptions(menuRef.current);
          const currentEl = getFocusedOption(menuRef.current);
          let currentIdx = -1;
          if (currentEl) {
            currentEl.classList.remove('focused');
            currentIdx = options.indexOf(currentEl);
          }
          const newIdx = (currentIdx - 1 + options.length) % options.length;
          const newFocused = options[newIdx];
          if (newFocused) {
            newFocused.classList.add('focused');
            scrollToElement(newFocused);
          }
          return;
        }
        case 'ArrowRight':
        case 'ArrowDown': {
          // Focus next option
          const options = getDropdownOptions(menuRef.current);
          const currentEl = getFocusedOption(menuRef.current);
          let currentIdx = -1;
          if (currentEl) {
            currentEl.classList.remove('focused');
            currentIdx = options.indexOf(currentEl);
          }
          const newIdx = (currentIdx + 1) % options.length;
          const newFocused = options[newIdx];
          if (newFocused) {
            newFocused.classList.add('focused');
            scrollToElement(newFocused);
          }
          return;
        }
        case 'Home':
        case 'PageUp': {
          // Focus first option
          const options = getDropdownOptions(menuRef.current);
          getFocusedOption(menuRef.current)?.classList.remove('focused');
          const newFocused = options[0];
          if (newFocused) {
            newFocused.classList.add('focused');
            scrollToElement(newFocused);
          }
          return;
        }
        case 'End':
        case 'PageDown': {
          // Focus last option
          const options = getDropdownOptions(menuRef.current);
          getFocusedOption(menuRef.current)?.classList.remove('focused');
          const newFocused = options[options.length - 1];
          if (newFocused) {
            newFocused.classList.add('focused');
            scrollToElement(newFocused);
          }
          return;
        }
        case 'Enter':
        case ' ': {
          // Trigger click handler of focused option to select it
          const currentEl = getFocusedOption(menuRef.current);
          currentEl?.dispatchEvent(new MouseEvent('click', { bubbles: true }));
          return;
        }
      }
    },
    [disabled, setIsOpen, menuRef],
  );
  const handleBlur = React.useCallback(
    (e: React.FocusEvent) => {
      // If focus is leaving the subtree, close the menu
      if (!e.currentTarget.contains(e.relatedTarget as any)) {
        setIsOpen(false);
      }
    },
    [setIsOpen],
  );

  return (
    <div
      className={classNames('dropdown', isOpen && 'open', className)}
      onKeyDown={handleContainerKeyDown}
      onBlur={handleBlur}
    >
      {children}
    </div>
  );
};
