import angular from 'angular';

import { injector } from './injector';

export default angular
  .module('mun-linear', [])

  /**
   * Wraps a function and performs a digest when called.
   *
   * Use this to wrap callbacks passed from angular to react.
   */
  // eslint-disable-next-line prefer-arrow-callback
  .factory('withApply', function ($rootScope: angular.IScope) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    return (fn: Function, { scope = $rootScope, sync = false } = {}) =>
      function (this: any) {
        try {
          // Call the wrapped function
          // eslint-disable-next-line prefer-rest-params
          return fn.apply(this, arguments);
        } finally {
          // Perform or schedule a digest
          if (sync) {
            scope.$apply();
          } else {
            scope.$applyAsync();
          }
        }
      };
  })

  // Capture a reference to the injector when the app boots
  .run(($injector: angular.auto.IInjectorService) =>
    injector._set_injector($injector),
  );
