import { react2angular } from 'react2angular';

import ngModule from '../kerbin-core-module';
import { PhoneDisplay } from '../../phones/PhoneDisplay';

ngModule

  .value('phoneTypes', [
    'Home',
    'Office',
    'Mobile',
    'Fax',
    'Other',
  ])

  .component('phoneListEditor', {
    bindings: {
      showNote: '<?',
      showVoicemail: '<?',
    },
    require: {
      model: 'ngModel',
    },
    controller: function(munDocSchemas) {
      const Phone = munDocSchemas['kerbin.Phone'];

      this.$onInit = onInit;
      this.add = add;
      this.remove = remove;

      function onInit() {
        this.model.$isEmpty = isEmpty;
      }

      function add() {
        const newPhone = new Phone();
        if (this.showVoicemail) {
          // Default voicemail field to false if it is displayed
          newPhone.voicemail_ok = false;
        }

        const vv = this.model.$viewValue || [];
        this.model.$setViewValue(vv.concat(newPhone));
      }

      function remove(item) {
        const vv = this.model.$viewValue || [];
        this.model.$setViewValue(vv.filter(value => value !== item));
      }

      function isEmpty(value) {
        return !(value?.length);
      }
    },
    template: `
      <div class="well well-sm" ng-repeat='phone in $ctrl.model.$viewValue'>
        <phone-editor
          phone="phone"
          remove="$ctrl.remove(item)"
          show-note="$ctrl.showNote"
          show-voicemail="$ctrl.showVoicemail"></phone-editor>
      </div>
      <button class="btn btn-link margin-bottom-15 no-padding-left"
        type='button'
        ng-click='$ctrl.add()'>
        <i class="fa fa-plus-circle"></i> Add phone
      </button>
    `,
  })

  .component('phoneEditor', {
    bindings: {
      phone: '<',
      remove: '&',
      showNote: '<?',
      showVoicemail: '<?',
    },
    controller: function(phoneTypes) {
      this.$onInit = onInit;

      function onInit() {
        this.types = phoneTypes;
      }

    },
    template: require('../../../partials/common/phone-editor.jade'),
  })

  .component('phoneDisplay', react2angular(PhoneDisplay, ['phone']))

  ;
