import * as React from 'react';

import { ErrorMessage } from '../ErrorMessage';
import { generateId } from '../id';
import { FormApi } from '../types';
import { ErrorMessageMap } from '../validation';
import { Field } from './Field';

// Props that must be supported by the wrapped component
interface RequiredComponentProps {
  /** Form API handle */
  form: FormApi<any>;
  /** Model path */
  model: string;
  /** If true, enable required validation */
  required?: boolean;
  /** Optional id for the input component */
  id?: string;
}
// Extra props of the resulting field component
type ExtraProps = Pick<
  React.ComponentProps<typeof Field>,
  'label' | 'labelProps' | 'divProps'
> & {
  /** Map of error messages. */
  errorMessages?: ErrorMessageMap;
};

// Use constant for defaults to avoid unnecessary renders of child components
const EMPTY_OBJECT = {};

/**
 * A higher-order component that wraps an input component to produce a "field"
 * component with a label and error messages.
 *
 * A HOC allows us to take advantage of Typescript's higher-order type
 * inference from generic functions in order to preserve the type parameters of
 * the wrapped input component to the resulting field component.
 */
export const asField =
  <P extends RequiredComponentProps>(
    // Need to use a function type definition here because
    // React.ComponentType<P> seems to break higher-order type inference. This
    // type only allows functional components, but we almost never use class
    // components anyway.
    Component: (props: P) => React.ReactElement<any, any> | null,
    generateDefaultId = generateId,
  ) =>
  ({
    form,
    model,
    required,
    label,
    labelProps,
    divProps,
    errorMessages = EMPTY_OBJECT,
    id = generateDefaultId(model),
    ...rest
  }: P & ExtraProps) =>
    (
      <Field
        required={required}
        htmlFor={id}
        label={label}
        labelProps={labelProps}
        divProps={divProps}
      >
        <Component
          form={form}
          model={model}
          required={required}
          id={id}
          {...(rest as any)}
        />
        <ErrorMessage form={form} model={model} errorMessages={errorMessages} />
      </Field>
    );
