import { assertNever, Path } from '@maternity/mun-types';
import * as moment from 'moment';
import * as React from 'react';

import {
  BaseFieldProps,
  EnforceModelType,
  EnforceRequired,
  FieldValues,
} from '../types';
import { useField } from '../useField';
import { setDefaultErrorMessage } from '../validation';
import { DayView } from './DayView';
import { MonthView } from './MonthView';
import { Action, ModelValue, State } from './types';
import { YearView } from './YearView';

/**
 * TODO: This date picker was created to match the behavior and markup/styles
 * of the angular-strap date picker for consistency throughout the product.
 * Once we are no longer using angular-strap, we should clean up the
 * markup/styles and improve the accessibility/UX.
 */

const views = {
  day: DayView,
  month: MonthView,
  year: YearView,
};

setDefaultErrorMessage('date', 'Please enter a valid date.');
// TODO: Reference the min/max values in the default messages?
setDefaultErrorMessage('dateMax', 'Please enter an earlier date.');
setDefaultErrorMessage('dateMin', 'Please enter a later date.');

interface ReducerCtx {
  modelType: 'string' | 'date';
  modelDateFormat: string;
  dateFormats: [string, ...string[]];
  dateInRange(d: moment.Moment): boolean;
}
const createReducer =
  ({
    modelType,
    modelDateFormat,
    dateFormats,
    dateInRange,
  }: ReducerCtx): React.Reducer<State, Action> =>
  (state, action) => {
    const toModel = (value: moment.Moment | undefined): ModelValue => {
      if (!value) return;
      if (modelType === 'string') return value.format(modelDateFormat);
      // When model is a Date, update it to preserve the time
      if (state.lastModelValue instanceof Date) {
        value = moment(state.lastModelValue).set({
          year: value.year(),
          month: value.month(),
          date: value.date(),
        });
      }
      return value.toDate();
    };
    const toInputValue = (value: moment.Moment) => value.format(dateFormats[0]);
    switch (action.type) {
      case 'close':
        return { ...state, open: false };
      case 'open':
        return { ...state, open: true };
      case 'setInputValue': {
        const inputValue = action.value;
        const parsed = moment(inputValue, dateFormats, true);
        if (parsed.isValid()) {
          return {
            ...state,
            inputValue,
            nextModelValue: toModel(parsed),
            selectedDate: parsed,
            viewDate: parsed,
          };
        }
        // Clear the model when the input is empty and the model type is string
        // (models of date type are generally shared with a time picker)
        const nextModelValue =
          !inputValue && modelType === 'string'
            ? undefined
            : state.nextModelValue;
        return {
          ...state,
          inputValue,
          nextModelValue,
          selectedDate: undefined,
        };
      }
      case 'selectDate': {
        const { value } = action;
        return {
          ...state,
          nextModelValue: toModel(value),
          selectedDate: value,
          inputValue: toInputValue(value),
          viewDate: value,
          open: false,
        };
      }
      case 'shiftView': {
        const { amount, unit } = action;
        const viewDate = state.viewDate.clone().add(amount, unit);
        return { ...state, viewDate };
      }
      case 'shiftSelection': {
        const { selectedDate } = state;
        if (!selectedDate) return state;
        const { amount, unit } = action;
        const newDate = selectedDate.clone().add(amount, unit);
        if (!dateInRange(newDate)) return state;
        return {
          ...state,
          nextModelValue: toModel(newDate),
          selectedDate: newDate,
          inputValue: toInputValue(newDate),
          viewDate: newDate,
        };
      }
      case 'setView': {
        const { viewMode, viewDate } = action;
        return { ...state, viewMode, viewDate: viewDate || state.viewDate };
      }
      case 'modelChanged': {
        const { value } = action;
        // moment ignores the format string if given a Date object
        const parsed = moment(value, modelDateFormat, true);
        const selectedDate = parsed.isValid() ? parsed : undefined;
        return {
          ...state,
          lastModelValue: value,
          nextModelValue: value,
          selectedDate,
          inputValue: selectedDate ? toInputValue(selectedDate) : '',
          viewDate: selectedDate || state.viewDate,
        };
      }
      default:
        return assertNever(action);
    }
  };

const initState = (): State => ({
  lastModelValue: undefined,
  nextModelValue: undefined,
  selectedDate: undefined,
  inputValue: '',
  viewMode: 'day',
  viewDate: moment(),
  open: false,
});

type InputProps = Omit<
  React.ComponentProps<'input'>,
  'type' | 'className' | 'value' | 'onChange' | 'onClick' | 'onFocus' | 'onBlur'
>;
interface RenderInputArgs {
  value: string;
  open: boolean;
  dispatch: React.Dispatch<Action>;
  onBlur: React.FocusEventHandler;
  onKeyDown: React.KeyboardEventHandler;
  inputProps?: InputProps;
}

const defaultRenderInput = ({
  value,
  open,
  dispatch,
  onBlur,
  onKeyDown,
  inputProps,
}: RenderInputArgs) => (
  <input
    {...inputProps}
    type="text"
    className="form-control"
    value={value}
    onChange={(e) => dispatch({ type: 'setInputValue', value: e.target.value })}
    onClick={(e) => {
      const isFocused = e.target === document.activeElement;
      if (isFocused && !open) dispatch({ type: 'open' });
    }}
    onFocus={() => dispatch({ type: 'open' })}
    onBlur={onBlur}
    onKeyDown={onKeyDown}
  />
);

type DatePickerProps<V extends FieldValues, M extends Path<V>> = (
  | ({
      /** The model can be a string or a `Date` object. Defaults to string. */
      // A discriminated union is used for props that vary with `modelType`
      modelType?: 'string';
      onChange?: (v: string | undefined) => void;
    } & EnforceModelType<V, M, string>)
  | ({
      modelType: 'date';
      onChange?: (v: Date | undefined) => void;
    } & EnforceModelType<V, M, Date>)
) & {
  /** The format for the model when it is a string (see the moment docs).
   * Defaults to ISO 8601, which is expected by travesty. */
  modelDateFormat?: string;
  /** An array of formats for parsing values typed in the input field. The
   * first format is used for displaying valid dates in the input field. */
  dateFormats?: [string, ...string[]];
  // TODO: support ISO 8601 strings?
  /** Maximum allowed date */
  maxDate?: moment.Moment | Date | 'today';
  /** Minimum allowed date */
  minDate?: moment.Moment | Date | 'today';
  /** Function that renders the input field (or other toggle for the picker) */
  renderInput?: (args: RenderInputArgs) => React.ReactNode;
  /** Extra props to pass through to the input element */
  inputProps?: InputProps;
  onBlur?: () => void;
} & BaseFieldProps<V, M> &
  EnforceRequired<V, M>;

// Use constant for default value to avoid breaking memoization
const NO_VALIDATORS: any[] = [];

/**
 * Renders an input field (by default) that displays a date picker when
 * focused/clicked.
 *
 * It currently operates on dates in the local time zone.
 */
// TODO: add special handling for mobile/touch like angular-strap?
export const DatePicker = <V extends FieldValues, M extends Path<V>>({
  form,
  model,
  modelType = 'string',
  modelDateFormat = 'YYYY-MM-DD',
  dateFormats = ['MM/DD/YYYY', 'M/D/YYYY', 'M-D-YYYY', 'MMDDYYYY', 'YYYY-M-D'],
  validators = NO_VALIDATORS,
  required,
  maxDate,
  minDate,
  renderInput = defaultRenderInput,
  inputProps,
  onChange,
  onBlur,
}: DatePickerProps<V, M>) => {
  const checkMax = React.useMemo(() => {
    if (!maxDate) return () => true;
    const refDate = maxDate === 'today' ? moment().endOf('day') : maxDate;
    return (d: moment.Moment) => d.isSameOrBefore(refDate);
  }, [maxDate]);
  const checkMin = React.useMemo(() => {
    if (!minDate) return () => true;
    const refDate = minDate === 'today' ? moment().startOf('day') : minDate;
    return (d: moment.Moment) => d.isSameOrAfter(refDate);
  }, [minDate]);
  const dateInRange = React.useCallback(
    (d: moment.Moment) => checkMax(d) && checkMin(d),
    [checkMax, checkMin],
  );

  const [state, dispatch] = React.useReducer(
    createReducer({
      modelType,
      modelDateFormat,
      dateFormats,
      dateInRange,
    }),
    null,
    initState,
  );

  // Capture reference to last committed state to make callbacks stable
  // (this should not be read during render, only in event handlers)
  const latestState = React.useRef(state);
  React.useEffect(() => {
    latestState.current = state;
  });

  validators = React.useMemo(
    () => [
      (v: any) => {
        const { inputValue, selectedDate } = latestState.current;
        if (!inputValue) {
          if (required) return 'required';
          // Consider it an error when the input is empty, but the model isn't
          if (v != null) return 'date';
          return;
        }
        if (!selectedDate) return 'date';
        // TODO: Include min/max values in returned context?
        if (!checkMax(selectedDate)) return 'dateMax';
        if (!checkMin(selectedDate)) return 'dateMin';
      },
      ...validators,
    ],
    [validators, checkMax, checkMin, required],
  );
  const [modelValue, api] = useField<ModelValue>({
    form,
    model,
    validators,
  });

  // When the model value changes, update internal state
  if (modelValue !== state.lastModelValue) {
    dispatch({ type: 'modelChanged', value: modelValue });
  }

  React.useEffect(() => {
    // Push internal state changes back to the model when necessary
    if (modelValue !== state.nextModelValue) {
      api.setValue(state.nextModelValue);
      if (onChange) onChange(state.nextModelValue as any);
    }
  });

  const handleKeyDown = React.useCallback((e: React.KeyboardEvent) => {
    if (e.altKey || e.shiftKey) return;
    const { viewMode } = latestState.current;
    switch (e.key) {
      case 'Escape':
        dispatch({ type: 'close' });
        break;
      case 'Enter':
        if (viewMode === 'day') {
          dispatch({ type: 'close' });
        } else {
          dispatch({
            type: 'setView',
            viewMode: viewMode === 'month' ? 'day' : 'month',
          });
        }
        break;
      case 'ArrowDown':
        dispatch({
          type: 'shiftSelection',
          amount: viewMode === 'day' ? 7 : 4,
          unit: viewMode,
        });
        break;
      case 'ArrowLeft':
        dispatch({ type: 'shiftSelection', amount: -1, unit: viewMode });
        break;
      case 'ArrowRight':
        dispatch({ type: 'shiftSelection', amount: 1, unit: viewMode });
        break;
      case 'ArrowUp':
        dispatch({
          type: 'shiftSelection',
          amount: viewMode === 'day' ? -7 : -4,
          unit: viewMode,
        });
        break;
      default:
        // Ignore all other keys
        return;
    }
    // Don't also move the cursor around the input
    e.preventDefault();
  }, []);
  const handleInputBlur = React.useCallback(() => {
    dispatch({ type: 'close' });
    api.setTouched();
    if (onBlur) onBlur();
  }, [api, onBlur]);
  const handleDropdownMouseDown = React.useCallback((e) => {
    // Prevent the input from blurring when clicking on the dropdown
    e.preventDefault();
  }, []);

  const ViewComponent = views[state.viewMode];
  return (
    <React.Fragment>
      {renderInput({
        value: state.inputValue,
        open: state.open,
        dispatch,
        onBlur: handleInputBlur,
        onKeyDown: handleKeyDown,
        inputProps,
      })}
      {state.open && (
        <div
          className="dropdown open"
          onKeyDown={handleKeyDown}
          onMouseDown={handleDropdownMouseDown}
        >
          <div className="dropdown-menu datepicker">
            <ViewComponent
              dispatch={dispatch}
              selectedDate={state.selectedDate}
              viewDate={state.viewDate}
              dateInRange={dateInRange}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
