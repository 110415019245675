import Modernizr from '@maternity/vendor-modernizr';

import ngModule from '../kerbin-core-module';

ngModule

  .run(function($rootScope) {
      // For small-ish screens, we need to ensure that viewport scaling is reset everytime state is updated or changed.
      // Otherwise, the user stays zoomed in as a result of an field filling.
      if (Modernizr.mq('only screen and (max-width: 1024px) and (max-height: 1024px)')) {
        $rootScope.$on('$stateChangeSuccess', resetScale);
        $rootScope.$on('$stateUpdate', resetScale);
        $rootScope.$on('modal.show', resetScale);
        $rootScope.$on('modal.hide', resetScale);
      }

      function resetScale() {
        var viewportmeta = document.querySelector('meta[name="viewport"]');
        if (viewportmeta) {
          var viewport = viewportmeta.getAttribute('content');
          viewportmeta.setAttribute('content',
            viewport.split(/,\s+/)
              .filter(function(s) { return !/^maximum-scale=/.test(s); })
              .concat('maximum-scale=1.0')
              .join(', '));
          viewportmeta.setAttribute('content', viewport);
        }
      }

    });
