import { Anchor } from '@maternity/mun-router';
import Raven from 'raven-js';
import * as React from 'react';

interface Props {
  // What to render when there is an error
  fallback?: React.ReactNode;
}
interface State {
  hasError: boolean;
}

/**
 * `ErrorBoundary` displays an error message and logs the error to Sentry if a
 * child component throws while rendering.
 */
export class ErrorBoundary extends React.Component<Props, State> {
  static defaultProps = {
    fallback: (
      <React.Fragment>
        <p>
          We're sorry, there was a problem with this feature. Please try
          reloading the page.
        </p>
        <p>
          We log errors like these and regularly investigate them, but if the
          problem persists, please{' '}
          <Anchor href="https://maternityneighborhood.com/help" target="_blank">
            contact support
          </Anchor>
          .
        </p>
      </React.Fragment>
    ),
  };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  override state = { hasError: false };

  override componentDidCatch(error: Error, info: React.ErrorInfo) {
    if ('skipSentry' in error && error.skipSentry) return;
    // Send the error to Sentry. In development, react also logs the error, so
    // errors will be reported twice to Sentry. `info.componentStack` isn't
    // very useful in production due to minification, so don't bother with it.
    Raven.captureException(error, { logger: 'ErrorBoundary' });
  }

  override render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}
