// Load dependencies
import 'angular-strap/dist/modules/date-formatter';
import 'angular-strap/dist/modules/date-parser';
import './tooltip';

// Load module
import 'angular-strap/dist/modules/datepicker';

// Load template
import datepickerTpl from 'angular-strap/src/datepicker/datepicker.tpl.html';

// Expose the module name
const moduleName = 'mgcrea.ngStrap.datepicker';
export default moduleName;

// Override template defaults
angular.module(moduleName)
  .config(function($datepickerProvider) {
    $datepickerProvider.defaults.template = datepickerTpl;
    $datepickerProvider.defaults.templateUrl = null;
  });
