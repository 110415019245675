/*
DocCache - a DocSet with stats and smarter caching.

A DocCache is a DocSet and also an Emitter, with several additional methods:

doc_cache.created(type, uid)
doc_cache.updated(type, uid)
doc_cache.deleted(type, uid)

These methods are called to indicate that you've learned something about
documents stored somewhere else (for example, on a server). All of these emit
events indicating what has happened, and update doc_cache.stats accordingly; in
addition, .updated and .deleted will "unload" the mentioned document if it
exists in this docset (emitting events to let you know it's done it).

For example, if you get a message from your server indicating that the Person
with uid "fake_uid" has been updated, you would call doc_cache.updated(Person,
"fake_uid") to indicate to the doc_cache that if it did have this Person stored,
it's no longer valid. Any services using that Person should be monitoring this
object for the relevant update/delete events, and should reload data from the
server as needed.

The (key, value) event pairs emitted by this object are:
(['created', <document type>], <uid>)
(['updated', <document type>], <uid>)
(['updated', <document type>, <uid>], null)
(['deleted', <document type>], <uid>)
(['deleted', <document type>, <uid>], null)
(['unload', <document type>], <uid>)
(['unload', <document type>, <uid>], null)

You will also see load events:
(['load'], (<document type>, <uid>))
(['load', <document type>], <uid>)
(['load', <document type>, <uid>])

These do not originate in this file, but can be emitted by the doccache via code
in travesty.js.

The duplicate events allow you to listen for any update/deletion of a type, OR
for updates/deletes to a specific document.

doc_cache.stats is a specialized Counters instance where we track the number of
load/unload events and also the total number of loaded and unloaded documents.
This is useful when tracking down memory leaks.

DocCache.create() behaves just like DocSet.create(), except it also updates the
stats.
*/

import Emitter from 'component-emitter';
import { extend } from '@maternity/mun-extend';
import * as iter from '@maternity/mun-itertools';
import tv from '@maternity/travesty';

import { Counters } from './counters';


// Cache Blasting
// - updates
//   - unload item
//   - emit event
// - deletes
//   - unload item
//   - emit event
//   - discard item

export default function DocCache(options) {
  tv.DocSet.apply(this, arguments);
  this.stats = new DocCache.Counters('stats');
}

DocCache.prototype = extend(
  tv.DocSet.prototype,
  Emitter.prototype, {
    constructor: DocCache,

    create: doccache_create,

    // Make a document unloaded, if it was loaded
    _unload: _unload,
    created: doccache_created,
    updated: doccache_updated,
    deleted: doccache_deleted,
  });

DocCache.Counters = Counters.define({
    subCounters: {
      docs: new (Counters.define({
        counts: {
          loaded: 0,
          unloaded: 0,
          get total() {
            return this.loaded+this.unloaded;
          },
        },
        getTheseStats: function() {
          return ''+this.counts.loaded+'/'+this.counts.total+' loaded';
        },
      }))(),
      events: new (Counters.define({
        counts: {
          load: 0,
          unload: 0,
        },
      }))(),
    },
  });

function doccache_created(type, uid) {
  this.emit(['created',type], uid);
}


function doccache_updated(type, uid) {
  this.emit(['updated',type], uid);
  this.emit(['updated',type,uid]);
  this._unload(type, uid);
}


function doccache_deleted(type, uid) {
  var key = this._key(type, uid),
      stats = this.stats.get(type, 'docs');

  this.emit(['deleted',type], uid);
  this.emit(['deleted',type,uid]);
  if (this._unload(type, uid))
    stats.counts.unloaded--;
  delete this.map[key];
}


function doccache_create(type, uid) {
  try {
    return tv.DocSet.prototype.create.apply(this, arguments);
  } finally {
    var stats = this.stats.get(type, 'docs');
    stats.counts.unloaded++;
  }
}


function _unload(type, uid) {
  var key = this._key(type, uid),
      doc = this.map[key];

  if (doc == null || !doc.$loaded)
      return false;

  iter.forEach(type.typegraph.key_iter(), function(key) {
      if (key !== 'uid')
        delete doc[key];
    });

  doc.$loaded = false;

  this.emit(['unload',type], uid);
  this.emit(['unload',type,uid]);

  var stats = this.stats.get(type),
      eventsStats = stats.get('events'),
      docsStats = stats.get('docs');

  eventsStats.counts.unload++;
  docsStats.counts.loaded--;
  docsStats.counts.unloaded++;

  return true;
}
