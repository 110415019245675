import { kerbin_ProfileSession } from '@maternity/kerbin/app/js/types';
import { useForceUpdate } from '@maternity/mun-cantrips';
import { injector } from '@maternity/ng-mun-linear';
import * as React from 'react';

type MunSession = Partial<kerbin_ProfileSession> & {
  $whenReady: Promise<undefined>;
  $ready: boolean;
  $whenAuthenticated: Promise<undefined>;
  $authenticated: boolean;
  $refresh(): Promise<undefined>;
  $login(username: string, password: string): Promise<undefined>;
  $logout(): Promise<undefined>;
};

/** Returns the current session and forces a re-render if it changes. */
export const useMunSession = (): MunSession => {
  const $rootScope = injector.get('$rootScope') as angular.IScope;
  const munSession = injector.get('munSession') as MunSession;
  const forceUpdate = useForceUpdate();

  // Rerender on session refresh events
  React.useEffect(
    () => $rootScope.$on('munSession.refresh', () => forceUpdate),
    [$rootScope, forceUpdate],
  );

  return munSession;
};
