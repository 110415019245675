import classNames from 'classnames';
import * as React from 'react';

import { overflowStyle, useFitHeight } from './useFitHeight';

interface ToggleProps {
  className: string;
  onClick: React.MouseEventHandler;
  'aria-haspopup': boolean;
  'aria-expanded': boolean;
}
interface Props {
  // render function for the dropdown toggle
  renderToggle: (props: ToggleProps) => React.ReactNode;
  // className to apply to the `.dropdown` element (outer wrapper)
  className?: string;
  // props to pass to the `ul.dropdown-menu` element
  menuProps?: React.ComponentProps<'ul'>;
  // when true, max-height will be set on the menu to prevent overflow
  fitHeight?: boolean;
  // tag to use for the `.dropdown` element (defaults to `div`)
  tag?: 'div' | 'li';
  children: React.ReactNode;

  // TODO: Support append-to-body option from ui-bootstrap? (currently only
  // used in one place, but does seem necessary there due to overflow issues)
}

/**
 * Renders a dropdown menu. The menu contents should be passed via `children`,
 * and must be `<li>` elements.
 */
export const Dropdown = ({
  className,
  renderToggle,
  menuProps = {},
  fitHeight = false,
  tag = 'div',
  children,
}: Props) => {
  const [open, setOpen] = React.useState(false);

  const containerRef = React.useRef<HTMLElement>(null);
  const menuRef = React.useRef<HTMLUListElement>(null);

  useFitHeight(fitHeight ? menuRef : undefined);

  // The toggle element isn't necessarily a button, but the button type allows
  // us to check for properties (e.g. `disabled`) without TS complaining
  const getToggleEl = React.useCallback((): HTMLButtonElement | null => {
    const container = containerRef.current;
    if (container) return container.querySelector('.dropdown-toggle');
    return null;
  }, []);

  const onClick: React.MouseEventHandler = () => {
    const toggleEl = getToggleEl();
    if (
      toggleEl &&
      (toggleEl.disabled || toggleEl.classList.contains('disabled'))
    ) {
      return;
    }
    setOpen((prev) => !prev);
  };

  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    if (e.key === 'Escape') {
      const toggleEl = getToggleEl();
      if (toggleEl?.focus) toggleEl.focus();
      setOpen(false);
    }
  };

  React.useEffect(() => {
    const handler = (event: MouseEvent) => {
      const toggleEl = getToggleEl();
      const clickedToggle = toggleEl && toggleEl.contains(event.target as any);
      // If clicked the toggle element or right clicked, don't close
      if (clickedToggle || event.button === 2) return;
      setOpen(false);
    };
    document.addEventListener('click', handler);
    return () => document.removeEventListener('click', handler);
  }, [getToggleEl]);

  const Container = tag;
  return (
    <Container
      className={classNames('dropdown', open && 'open', className)}
      onKeyDown={handleKeyDown}
      ref={containerRef as any}
    >
      {renderToggle({
        className: 'dropdown-toggle',
        onClick,
        'aria-haspopup': true,
        'aria-expanded': open,
      })}
      {open && (
        <ul
          {...menuProps}
          className={classNames('dropdown-menu', menuProps.className)}
          style={fitHeight ? overflowStyle : undefined}
          ref={menuRef}
        >
          {children}
        </ul>
      )}
    </Container>
  );
};
