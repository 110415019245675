import classNames from 'classnames';
import * as React from 'react';

import { TableView } from './TableView';
import { ViewProps } from './types';

export const YearView = ({
  dispatch,
  selectedDate,
  viewDate,
  dateInRange,
}: ViewProps) => {
  const decadeStart = Math.floor(viewDate.year() / 10) * 10;
  const rows: number[][] = [];
  let row: number[];
  // Include one year before and after the current decade
  let cursor = decadeStart - 1;
  for (let i = 0; i < 12; i++) {
    if (i % 4 === 0) {
      row = [];
      rows.push(row);
    }
    row!.push(cursor);
    cursor += 1;
  }
  return (
    <TableView
      onClickPrev={() =>
        dispatch({ type: 'shiftView', amount: -12, unit: 'year' })
      }
      onClickTitle={() => dispatch({ type: 'setView', viewMode: 'day' })}
      title={`${decadeStart}-${decadeStart + 9}`}
      onClickNext={() =>
        dispatch({ type: 'shiftView', amount: 12, unit: 'year' })
      }
      rows={rows}
      renderCell={(y) => {
        const firstDay = viewDate.clone().year(y).startOf('year');
        const lastDay = firstDay.clone().endOf('year');
        const disabled = !dateInRange(firstDay) && !dateInRange(lastDay);
        const selected = selectedDate && firstDay.isSame(selectedDate, 'year');

        return (
          <td className="text-center" key={y}>
            <button
              type="button"
              className={classNames('btn btn-default', {
                // selected
                'btn-primary': selected,
              })}
              disabled={disabled}
              onClick={() => {
                if (disabled) return;
                dispatch({
                  type: 'setView',
                  viewMode: 'month',
                  viewDate: firstDay,
                });
              }}
              tabIndex={-1}
            >
              <span>{y}</span>
            </button>
          </td>
        );
      }}
    />
  );
};
