import { kerbin_Pagination } from '@maternity/kerbin/app/js/types';
import { Link, useParams } from '@maternity/mun-router';
import * as React from 'react';

interface Props {
  // TODO: split `pager` into separate props once not calling this from angular
  /** A kerbin Pagination object. */
  pager?: kerbin_Pagination;
  /** When true (default), show item indexes for current page and total. */
  showItemCount?: boolean;
}

export const calculatePages = ({
  current,
  per_page,
  total,
}: {
  current: number;
  per_page: number;
  total: number;
}) => {
  const first = 1;
  const last = Math.max(first, Math.ceil(total / per_page));

  const indexStart = Math.min(total, (current - 1) * per_page + 1);
  const indexEnd = Math.min(total, current * per_page);

  return {
    prev: current > first ? current - 1 : null,
    first,
    last,
    next: current < last ? current + 1 : null,
    indexStart,
    indexEnd,
  };
};

const PagerButton = ({
  ariaLabel,
  children,
  disabled,
  params,
}: {
  ariaLabel: string;
  children: string;
  disabled?: boolean;
  params: Record<string, string>;
}) => {
  if (disabled) {
    return (
      <li className="disabled">
        <span aria-label={ariaLabel}>
          <span aria-hidden="true">{children}</span>
        </span>
      </li>
    );
  }
  return (
    <li>
      <Link sref="." params={params} aria-label={ariaLabel}>
        <span aria-hidden="true">{children}</span>
      </Link>
    </li>
  );
};

/**
 * Renders pagination links.
 *
 * Assumes the selected page is controlled by the `page` state parameter (path
 * or query).
 */
export const Paginator = ({ pager, showItemCount = true }: Props) => {
  const search = useParams().$search;
  if (!pager) return null;
  const { current, total } = pager;
  const { first, last, prev, next, indexStart, indexEnd } =
    calculatePages(pager);

  if (last === 1 && !showItemCount) return null;

  // Clearfix prevents the bottom margin from collapsing.
  return (
    <nav className="text-center clearfix" aria-label="page navigation">
      {last > 1 && (
        <ul className="pagination">
          <PagerButton
            ariaLabel="First"
            disabled={current === first}
            params={{ ...search, page: `${first}` }}
          >
            &laquo;
          </PagerButton>
          <PagerButton
            ariaLabel="Previous"
            disabled={prev == null}
            params={{ ...search, page: `${prev}` }}
          >
            &lsaquo;
          </PagerButton>
          <li className="disabled">
            <span>
              Page {current} of {last}
            </span>
          </li>
          <PagerButton
            ariaLabel="Next"
            disabled={next == null}
            params={{ ...search, page: `${next}` }}
          >
            &rsaquo;
          </PagerButton>
          <PagerButton
            ariaLabel="Previous"
            disabled={current === last}
            params={{ ...search, page: `${last}` }}
          >
            &raquo;
          </PagerButton>
        </ul>
      )}
      {showItemCount && (
        <p className="pagination-item-count">
          Showing {indexStart}-{indexEnd} of {total}
        </p>
      )}
    </nav>
  );
};
