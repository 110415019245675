import {
  gestationalAge,
  gestationalAgeDisplay,
  postpartumAge,
} from '@maternity/mun-gestationalage';

const ngModule = angular.module('mun-gestational-age', [])

  .filter('gestationalAge', function() {
    return gestationalAge;
  })

  .filter('postpartumAge', function() {
    return postpartumAge;
  })

  .filter('gestationalAgeDisplay', function() {
    return (value) => {
      if (angular.isString(value))
        return gestationalAgeDisplay(value);
    };
  })

  ;

export default ngModule.name;
