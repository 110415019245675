/** mun-form-server-errors v0.0.1
 *
 * This angular.js module provides an easy way to display server-generated
 * validation errors. For displaying angular validation errors, see the
 * mun-form-errors module.
 *
 * This module extends the ngModel and form/ngForm directives, and adds one new
 * directive. `ngModel` is extended to shallow watch the $scope variable named
 * after the `ng-model` attribute with "$error" appended. This variable should
 * contain an array of objects representing errors, and these objects must have
 * a "err_id" property. It is recommended that a single code (e.g. "custom") be
 * used for any server generated messages that should be displayed without
 * further processing. The `form` and `ngForm` directives are extended to
 * aggregate errors from all `ngModel`s they contain, and the errors are
 * exposed on the form's "$serverError" property. If the form's models have
 * associated server errors, the "server" key will be added to the form's
 * "$error" object. Forms also get a "$clearServerValidity" method that removes
 * the server errors from the form, so that it will validate (if there are no
 * other angular validation issues). The added `serverErrorsHere` directive
 * searches up the DOM tree for the nearest form, and injects the form
 * controller into a new scope as "formCtrl". The form controller's
 * $serverError object has the errors' "err_id"s as keys, and vaues are either
 * false, if valid, or an array of error objects with the ngModelController
 * attached as "$ctrl".  Thus, if the value is truthy it indicates an error. It
 * is usually helpful to include extra ngForm directives to limit the number of
 * ngModels that are aggregated.
 *
 *
 * Example:
 * $scope.person = {
 *  first: '',
 *  first$error: [
 *    {err_id: 'required'},
 *    {err_id: 'custom', message: 'Special case message from server.'}
 *  ],
 *  last: 'Doe',
 *  last$error: []
 * };
 *
 * <form>
 *  <div server-errors-here>
 *    <!-- $serverError has all errors in the top level form -->
 *    <p ng-if="$serverError.required">Missing {{$serverError.required.length}}
 *        required fields</p>
 *  </div>
 *  <div ng-form>
 *    <label>First</label>
 *    <input type="text" required ng-maxlength="20" ng-model="person.first">
 *    <div server-errors-here>
 *      <!-- $serverError limited to `person.first` due to ng-form -->
 *      <!-- in-place declaration of error display -->
 *      <p ng-if="$serverError.required">First name is required</p>
 *      <p ng-if="$serverError.maxlength">Must be 20 characters or less</p>
 *      <p ng-if="$serverError.custom"
 *          ng-repeat="error in $serverError.custom">{{error.message}}</p>
 *    </div>
 *  </div>
 *  <div ng-form>
 *    <label>Last</label>
 *    <input type="text" required ng-maxlength="20" ng-model="person.last">
 *    <div server-errors-here ng-include="'/template.html'">
 *    </div>
 * </form>
 * <script type="text/ng-template" id="/template.html">
 *  <p ng-if="$serverError.required">This field is required</p>
 *  <p ng-if="$serverError.maxlength">Input too long</p>
 *  <p ng-if="$serverError.custom"
 *      ng-repeat="error in $serverError.custom">{{error.message}}</p>
 * </script>
 *
 */
import _ from 'lodash';

const ngModule = angular.module('mun-form-server-errors', [])
  .directive('form', errorFormDirectiveFactory())
  .directive('ngForm', errorFormDirectiveFactory(true))
  .directive('ngModel', function() {
    return {
      require: ['ngModel', '^?form'],
      link: function(scope, element, attr, ctrls) {
        var modelCtrl = ctrls[0],
            formCtrl = ctrls[1];
        if (!formCtrl)
          return;
        var watchExpression = attr.ngModel+'$error || '+attr.ngModel+'.$error';
        if (/\[.*\]$/.test(attr.ngModel)) {
          var keyWithError = attr.ngModel.replace(/\]$/, '+"$error"]');
          watchExpression = keyWithError+' || '+attr.ngModel+'.$error';
        }
        scope.$watch(watchExpression, function(n, o) {
          formCtrl.$setServerValidity(n, modelCtrl);
        });
      }
    };
  })
  .directive('serverErrorsHere', function() {
    return {
      require: '^form',
      scope: true,
      priority: 500,
      link: function(scope, element, attr, formCtrl) {
        if (angular.isUndefined(formCtrl.$serverError)) {
          formCtrl.$serverError = {};
        }
        scope.formCtrl = formCtrl;
      }
    };
  })
  ;

export default ngModule.name;

// TODO? allow "serverErrorsHere" directly on ng-model element for complex controls
// TODO? add "server-error-for" directive to replace "ng-if='$serverError.key'"

function errorFormDirectiveFactory(isNgForm) {
  return function() {
    return {
      name: 'form',
      restrict: isNgForm ? 'EAC' : 'E',
      require: 'form',
      link: function(scope, element, attr, ctrl) {
        var parentCtrl = element.parent().controller('form');
        if (angular.isUndefined(ctrl.$serverError)) {
          ctrl.$serverError = {};
        }
        ctrl.$setServerValidity = function(modelServerError, modelCtrl) {
          var $error = ctrl.$serverError;
          // remove any errors already associated with the modelCtrl
          // TODO determine if errors are orphaned when updating with multiple elements with same model
          angular.forEach($error, function(errorList, code) {
            _.remove(errorList, function(error) {
              return error.$ctrl === modelCtrl;
            });
            if (errorList.length === 0) {
              $error[code] = false;
            }
          });

          // add errors
          angular.forEach(modelServerError, function(error) {
            // first modelCtrl owns the error
            if (angular.isUndefined(error.$ctrl) || error.$ctrl === modelCtrl) {
              error.$ctrl = modelCtrl;
              errIdPrefixes(error.err_id)
                .forEach(function(err_id) {
                  if (!$error[err_id])
                    $error[err_id] = [];

                  $error[err_id].push(error);
                });
            }
          });

          // set form validity
          ctrl.$setValidity('server', !_.some($error), modelCtrl);
          // propagate to parent form
          if (parentCtrl) {
            //parentCtrl.$setServerValidity(modelServerError, modelCtrl);
            parentCtrl.$setServerValidity(modelServerError, ctrl);
          }

          function errIdPrefixes(err_id) {
            var pfxs = [],
                pfx;

            err_id.split('/').forEach(function(name) {
                pfx = pfx ? pfx+'/'+name : name;
                pfxs.push(pfx);
              });

            return pfxs;
          }
        };
        ctrl.$clearServerValidity = function() {
          angular.forEach(ctrl.$serverError, function(errorList, code) {
            angular.forEach(errorList, function(error) {
              ctrl.$setValidity('server', true, error.$ctrl);
            });
            //delete ctrl.$serverError[code];
          });
          if (parentCtrl) {
            parentCtrl.$clearServerValidity();
          }
          // TODO? broadcast clear to children
        };
        // TODO wrap $addControl and $removeControl
      }
    };
  };
}
