import { Anchor } from '@maternity/mun-router';
import classNames from 'classnames';
import * as React from 'react';

import { ComboboxCtx } from './useComboboxCtx';
import { getFocusedOption } from './utils';

interface ComboboxOptionProps<T> {
  /** Combobox context object */
  ctx: ComboboxCtx<T>;
  /** The option value */
  value: T;
  /** Extra CSS classes to add to the `.dropdown-option` element */
  className?: string;
  children: React.ReactNode;
}

/** Renders an option in the combobox menu. */
export const ComboboxOption = <T extends unknown>({
  ctx,
  value,
  className,
  children,
}: ComboboxOptionProps<T>) => {
  const { setSelection, setTouched, setIsOpen, menuRef } = ctx;
  const elRef = React.useRef<HTMLLIElement>(null);

  return (
    <li
      ref={elRef}
      className={classNames('dropdown-option', className)}
      onClick={(e) => {
        // Prevent navigation when clicking on the anchor
        e.preventDefault();
        setSelection(value);
        setTouched(true);
        setIsOpen(false);
      }}
      // Using MouseEnter could unexpectedly fire the handler when scrolling
      // due to a keyboard event, but MouseMove only fires on user movement.
      onMouseMove={() => {
        const focusedEl = getFocusedOption(menuRef.current!);
        if (focusedEl === elRef.current) return;
        focusedEl?.classList.remove('focused');
        elRef.current?.classList.add('focused');
      }}
      // Safari needs this to avoid the container losing focus and closing the
      // menu when an option is clicked.
      tabIndex={-1}
      role="option"
    >
      <Anchor href="#">{children}</Anchor>
    </li>
  );
};
