import * as React from 'react';

type ConsumerType<T> = (props: {
  children: (value: T) => React.ReactNode;
}) => React.ReactElement;

// This type is intentionally structurally incompatible with `React.Context`
// (due to `ConsumerType`) to disallow passing it to `React.useContext`, which
// expects a real context object.
interface RequiredContext<T> {
  Provider: React.Provider<T>;
  Consumer: ConsumerType<T>;
  useContext: () => T;
}

/**
 * `createRequiredContext` is useful when there isn't a reasonable default
 * value for `React.createContext`. It wraps the `Consumer` component and
 * `useContext` hook so they throw an error (with a customizable message) if a
 * `Provider` is not present higher in the tree.
 */
export const createRequiredContext = <T extends any>(
  message = 'Context value not set.',
): RequiredContext<T> => {
  const sentinel: any = Symbol();
  const context = React.createContext<T>(sentinel);

  const useContext = () => {
    const value = React.useContext(context);
    if (value === sentinel) {
      throw new Error(message);
    }
    return value;
  };
  const Consumer: ConsumerType<T> = ({ children }) => (
    <context.Consumer>
      {(value) => {
        if (value === sentinel) {
          throw new Error(message);
        }
        return children(value);
      }}
    </context.Consumer>
  );

  return {
    Provider: context.Provider,
    Consumer,
    useContext,
  };
};
