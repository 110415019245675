/*globals Raven, _ravenExtra */

import { extend } from '@maternity/mun-extend';

import ngModule from '../kerbin-core-module';

ngModule

  .config(function($provide) {
    /* This config block provides the same functionality as raven's angular adapter, but without
     * handling configuration of raven itself.
     */

    if (!Raven.isSetup()) return;
    $provide.decorator('$exceptionHandler', function($delegate) {
        return exceptionHandler;

        function exceptionHandler(ex, cause) {
          $delegate(ex, cause);
          Raven.captureException(ex, {
              logger: '$exceptionHandler',
              extra: {cause: cause},
            });
        }
      });
  })

  .run(function(munSession) {
    var extra;

    if (typeof _ravenExtra === 'undefined')
      return;

    extra = extend(_ravenExtra);

    Raven.setExtraContext(extra);
    Raven.setUserContext(null);

    munSession.$whenAuthenticated
      .then(function() {
        extra.practice = munSession.practice.uid;
        extra.features = String(munSession.practice.effective_features);
        extra.mode = munSession.mode;

        Raven.setUserContext({
            person: munSession.person.uid,
          });
      });
  })

  ;
