import classNames from 'classnames';
import * as moment from 'moment';
import * as React from 'react';

import { TableView } from './TableView';
import { ViewProps } from './types';

const getMonths = () => {
  const rows: string[][] = [];
  let row: string[];
  moment.monthsShort().forEach((m, i) => {
    if (i % 4 === 0) {
      row = [];
      rows.push(row);
    }
    row.push(m);
  });
  return rows;
};

export const MonthView = ({
  dispatch,
  selectedDate,
  viewDate,
  dateInRange,
}: ViewProps) => (
  <TableView
    onClickPrev={() =>
      dispatch({ type: 'shiftView', amount: -1, unit: 'year' })
    }
    onClickTitle={() => dispatch({ type: 'setView', viewMode: 'year' })}
    title={viewDate.format('YYYY')}
    onClickNext={() => dispatch({ type: 'shiftView', amount: 1, unit: 'year' })}
    rows={getMonths()}
    renderCell={(m) => {
      const firstDay = viewDate.clone().month(m).startOf('month');
      const lastDay = firstDay.clone().endOf('month');
      const disabled = !dateInRange(firstDay) && !dateInRange(lastDay);
      const selected = selectedDate && firstDay.isSame(selectedDate, 'month');

      return (
        <td className="text-center" key={m}>
          <button
            type="button"
            className={classNames('btn btn-default', {
              // selected
              'btn-primary': selected,
            })}
            disabled={disabled}
            onClick={() => {
              if (disabled) return;
              dispatch({
                type: 'setView',
                viewMode: 'day',
                viewDate: firstDay,
              });
            }}
            tabIndex={-1}
          >
            <span>{m}</span>
          </button>
        </td>
      );
    }}
  />
);
