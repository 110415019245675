// Load dependencies
import './modal';

// Load module
import 'angular-strap/dist/modules/alert';

// Load template
import alertTpl from 'angular-strap/src/alert/alert.tpl.html';

// Expose the module name
const moduleName = 'mgcrea.ngStrap.alert';
export default moduleName;

// Override template defaults
angular.module(moduleName)
  .config(function($alertProvider) {
    $alertProvider.defaults.template = alertTpl;
    $alertProvider.defaults.templateUrl = null;
  });
