var { extend, mixin } = require('@maternity/mun-extend'),
    vg = require('@maternity/vertigo'),
    DispatchSuper = require('./dispatcher').DispatchSuper;


function DispatchGraph() { }

mixin(DispatchGraph.prototype, {
    call: function() {
      //console.groupCollapsed.bind(console, this.target+'').apply(null, [].slice.call(arguments));
      //try {
      var fn = this.disp_target.dispatch(this.target),
          args;

      if (fn == null)
        throw new Error('No handler defined for '+this.marker);

      args = [].slice.call(arguments);
      //Was passing this.target, I don't know why.  Maybe it was for a reason.
      //args.unshift(this.target);
      args.unshift(this);
      return fn.apply(null, args);
      //} finally { console.groupEnd() }
    },
    get target() { return this.value[0]; },
    get disp_target() { return this.value[1]; },
    get disp() {
      if (this.disp_target instanceof DispatchSuper)
        return this.dispatch_target.disp;
      return this.disp_target;
    },
    get value() { return [this.target, this.disp_target]; },
    get marker() {
      if (this.target.superOf != null)
        return this.target.superOf;
      return this.target;
    },
    // Why is marker_graph() a method and not a property?
    marker_graph: 'function() { [not implemented] }',
    for_marker: function(marker) {
      return new DispatchOverlay(this, marker);
    },
    super: function(ctor) {
      return this.for_marker(super_(ctor.prototype, this.marker));

      function super_(proto, obj) {
        // TODO: move this somewhere, since it's not really an internal function once other code
        // relies on the superOf property
        // Simplistic super() functionality, returns an object prototyped above proto in the
        // prototype chain with methods bound to obj.
        proto = Object.getPrototypeOf(typeof proto === 'function' ? proto.prototype : proto);

        var superObj = Object.create(proto);

        for (var k in proto) {
          if (typeof proto[k] === 'function')
            superObj[k] = proto[k].bind(obj);
        }

        // aka super.__self__
        superObj.superOf = obj;

        return superObj;
      }
    },
    parent: function(disp) {
      return new DispatchOverlay(this, null, new DispatchSuper(disp, this.disp));
    },
    restrict: function(edge_names) {
      return new DispatchRestriction(this, edge_names);
    },
  });


exports.DynamicDispatchGraph = DynamicDispatchGraph;
function DynamicDispatchGraph(graph, disp_target) {
  this.graph = graph;
  this.disp_target = disp_target;
}

DynamicDispatchGraph.prototype = extend(
    // py has these reversed?
    vg.wrapper.GraphWrapper.prototype,
    DispatchGraph.prototype, {
      constructor: DynamicDispatchGraph,

      // occlude GraphNode descriptor
      disp_target: undefined,

      get target() { return this.graph.value; },
      marker_graph: function() { return this.graph; },

      get_child: function(key) {
        //console.log('DDG > %s', key);
        var graph = this.graph.get_child(key),
            wrapper = new this.constructor(graph, this.disp_target);

        return wrapper;
      },
    });


const { ValueOverlay, EdgeRestriction } = vg;
exports.ValueOverlay = ValueOverlay;
exports.EdgeRestriction = EdgeRestriction;


exports.DispatchOverlay = DispatchOverlay;
function DispatchOverlay(graph, target, disp_target) {
  if (target == null)
    target = graph.target;
  if (disp_target == null)
    disp_target = graph.disp_target;

  return ValueOverlay.call(this, graph, [target, disp_target]);
}

DispatchOverlay.prototype = extend(
    ValueOverlay.prototype,
    DispatchGraph.prototype, {
      constructor: DispatchOverlay,

      // occlude GraphNode descriptor
      value: undefined,

      marker_graph: function() {
        return new ValueOverlay(this.graph.marker_graph(), this.target);
      },
    });


function DispatchRestriction(graph, edge_names) {
  return EdgeRestriction.call(this, graph, edge_names);
}

DispatchRestriction.prototype = extend(
    EdgeRestriction.prototype,
    DispatchGraph.prototype, {
      constructor: DispatchRestriction,

      get value() { return this.graph.value; },

      marker_graph: function() {
        return new EdgeRestriction(this.graph.marker_graph(), this.edge_names);
      },
    });
