import * as React from 'react';

/** This should be rendered in the combobox menu when there are no options
 * available. The content defaults to "No results". */
export const ComboboxNoOptionsMessage = ({
  children = 'No results',
}: {
  children?: React.ReactNode;
}) => (
  <li>
    <div className="dropdown-text">
      <strong>{children}</strong>
    </div>
  </li>
);
