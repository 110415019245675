import * as React from 'react';

import { useThrottleCallback } from './useThrottleCallback';

/**
 * Returns a throttled version of the input value- the return value will
 * change at most once every `wait` milliseconds.
 */
export const useThrottleValue = <T>(value: T, wait: number): T => {
  const [throttledValue, setThrottledValue] = React.useState(value);
  const throttledSetValue = useThrottleCallback(setThrottledValue, wait);
  React.useEffect(() => throttledSetValue(value), [throttledSetValue, value]);
  return throttledValue;
};
