import classNames from 'classnames';
import * as React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

/**
 * Renders a modal body.
 */
export const ModalBody = ({ className, children }: Props) => (
  <div className={classNames('modal-body', className)}>{children}</div>
);
