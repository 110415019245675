import { Path } from '@maternity/mun-types';
import classNames from 'classnames';
import * as React from 'react';

import { BaseFieldProps, EnforceModelType, FieldValues } from './types';
import { useField } from './useField';
import { requiredStringValidator } from './validators';

type TextAreaProps<V extends FieldValues, M extends Path<V>> = Omit<
  React.ComponentProps<'textarea'>,
  'form' | 'name' | 'value' | 'defaultValue' | 'children'
> &
  // Don't use EnforceRequired type since required=true also checks length > 0
  BaseFieldProps<V, M> &
  EnforceModelType<V, M, string>;

// Use constant for default value to avoid breaking memoization
const NO_VALIDATORS: any[] = [];

/**
 * A `<textarea>` element connected to the containing form.
 */
export const TextArea = <V extends FieldValues, M extends Path<V>>({
  form,
  model,
  validators = NO_VALIDATORS,
  required,
  className,
  onChange,
  onBlur,
  ...props
}: TextAreaProps<V, M>) => {
  validators = React.useMemo(
    () => (required ? [requiredStringValidator, ...validators] : validators),
    [validators, required],
  );
  const [value, api] = useField<string>({ form, model, validators });

  return (
    <textarea
      className={classNames('form-control', className)}
      name={model}
      // Coerce null/undefined to the empty string
      value={value == null ? '' : value}
      onChange={(e) => {
        api.setValue(e.target.value);
        if (onChange) onChange(e);
      }}
      onBlur={(e) => {
        api.setTouched();
        if (onBlur) onBlur(e);
      }}
      {...props}
    />
  );
};
