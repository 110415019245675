var search = exports.search = require('./search');
exports.BaseIndexDef = search.BaseIndexDef;
exports.StringIndexDef = search.StringIndexDef;
exports.WordIndexDef = search.WordIndexDef;

var sort = exports.sort = require('./sort');
exports.SortKeyDef = sort.SortKeyDef;
exports.TemplateSortKeyDef = sort.TemplateSortKeyDef;
exports.NumericSortKeyDef = sort.NumericSortKeyDef;

var query = exports.query = require('./query');
exports.Query = query.Query;
