import classNames from 'classnames';
import * as React from 'react';

type Props<C extends React.ElementType> = React.ComponentProps<C> extends {
  className?: string;
}
  ? React.ComponentProps<C> & {
      component?: C;
    }
  : never;

/**
 * Renders a modal title. Defaults to an `h4` element.
 */
export const ModalTitle = <C extends React.ElementType = 'h4'>({
  component = 'h4',
  className,
  ...props
}: Props<C>) => {
  const Component = component;
  return (
    <Component className={classNames('modal-title', className)} {...props} />
  );
};
