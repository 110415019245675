import classNames from 'classnames';
import * as React from 'react';

interface Props {
  /** Text to display (usually a string, but allows JSX) */
  label: React.ReactNode;
  /** Optional icon JSX. Higher priority than `iconClass` */
  icon?: React.ReactNode;
  /** Optional icon class */
  iconClass?: string;
  /** Optional menu class */
  menuClass?: string;
  children?: React.ReactNode;
}

/**
 * Standard header for the main page content.
 *
 * Use `children` to pass menu contents.
 *
 * Corresponds to the `mainHeader` angular component.
 */
export const MainHeader = ({
  label,
  icon,
  iconClass,
  menuClass,
  children,
}: Props) => (
  <header className="main-screen-header">
    <h1 className="clearfix">
      <div className="main-screen-header-crumbs">
        {icon ? (
          <span className="main-screen-header-icon">{icon}</span>
        ) : iconClass ? (
          <i className={classNames('main-screen-header-icon', iconClass)} />
        ) : null}
        <span className="main-screen-header-label">{label}</span>
      </div>
      <div className={classNames('main-screen-header-menu', menuClass)}>
        {children}
      </div>
    </h1>
  </header>
);
