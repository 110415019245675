var { extend } = require('@maternity/mun-extend'),
    iter = require('@maternity/mun-itertools'),
    vg = require('@maternity/vertigo');


exports.SingleInvalid = SingleInvalid;
function SingleInvalid(err_id) {
  if (!(this instanceof SingleInvalid)) {
    var self = Object.create(SingleInvalid.prototype);
    SingleInvalid.apply(self, arguments);
    return self;
  }

  this.name = 'Invalid';
  this.err_id = err_id;
  this.message = this.err_id;
  if (typeof Error.captureStackTrace === 'function')
    Error.captureStackTrace(this, SingleInvalid);
  else
    this.stack = Error().stack;
}

SingleInvalid.prototype = extend(
  Error.prototype, {
    constructor: SingleInvalid,

    id_string: function id_string() { return this.err_id; },
  });


exports.Invalid = Invalid;
function Invalid(err_id) {
  if (!(this instanceof Invalid)) {
    var self = Object.create(Invalid.prototype);
    Invalid.apply(self, arguments);
    return self;
  }

  this.name = 'Invalid';
  this.own_errors = [];
  this.sub_errors = {};
  // In Chrome, an Error prototype message getter is called with the prototype as context.  Dynamic
  // getters without context aren't very useful.
  // Using a bound {toString} object works around this.
  this.message = {toString: this.id_string.bind(this)};

  if (err_id)
    this.add_own(SingleInvalid.apply(null, arguments));

  if (typeof Error.captureStackTrace === 'function')
    Error.captureStackTrace(this, Invalid);
  else
    this.stack = Error().stack;
}

Invalid.prototype = extend(
  Error.prototype, {
    constructor: Invalid,

    add_own: nested_add_own,
    add_sub: nested_add_sub,
    has_errors: nested_has_errors,
    id_string: nested_id_string,
    as_graph: nested_as_graph,
    _refreshStack: refreshStack,
  });

function nested_add_own(err) {
  if (err instanceof Invalid) {
    // merge errors from a nother nested
    err.own_errors.forEach(this.add_own.bind(this));
    for (var key in err.sub_errors)
      this.add_sub(key, err.sub_errors[key]);
    return;
  }

  this.own_errors.push(err);
}

function nested_add_sub(key, err) {
  if (this.sub_errors[key] == null)
    this.sub_errors[key] = new this.constructor();

  this.sub_errors[key].add_own(err);
}

function nested_has_errors() {
  return this.own_errors.length > 0 || Object.keys(this.sub_errors).length > 0;
}

function nested_id_string() {
  var own_strings = this.own_errors.map(function(err) { return err.id_string(); }),
      sub_strings = [];

  for (var key in this.sub_errors)
    sub_strings.push(key+': ['+this.sub_errors[key].id_string()+']');

  if (own_strings.length && sub_strings.length)
    return own_strings.join(', ')+'; '+sub_strings.join(', ');
  if (own_strings.length)
    return own_strings.join(', ');
  if (sub_strings.length)
    return sub_strings.join(', ');

  return '...';
}

function nested_as_graph() {
  return new InvalidGraphNode(this);
}


exports.InvalidAggregator = InvalidAggregator;
function InvalidAggregator() {
  this.error = new this.error_type();
}

InvalidAggregator.prototype.error_type = Invalid;
InvalidAggregator.prototype.catch_type = Invalid;

InvalidAggregator.prototype.own_error = function own_error(err) { this.error.add_own(err); };
InvalidAggregator.prototype.sub_error = function sub_error(key, err) { this.error.add_sub(key, err); };

InvalidAggregator.prototype.checking = aggregator_checking;
InvalidAggregator.prototype.checking_sub = aggregator_checking_sub;

InvalidAggregator.prototype.has_errors = function() { return this.error.has_errors(); };
InvalidAggregator.prototype.raise_if_any = aggregator_raise_if_any;

function aggregator_checking(fn) {
  try {
    return fn();

  } catch (e) {
    if (e instanceof this.catch_type)
      this.own_error(e);
    else
      throw e;
  }
}

function aggregator_checking_sub(key, fn) {
  try {
    return fn();

  } catch (e) {
    if (e instanceof this.catch_type)
      this.sub_error(key, e);
    else
      throw e;
  }
}

function aggregator_raise_if_any() {
  if (this.error.has_errors())
    // Recapture stack trace at the point of throw
    throw this.error._refreshStack(aggregator_raise_if_any);
}

function refreshStack(callSite) {
  if (typeof Error.captureStackTrace === 'function')
    Error.captureStackTrace(this, callSite||refreshStack);
  else
    this.stack = Error().stack;

  return this;
}


function InvalidGraphNode(invalid) {
  this._invalid = invalid;
}

InvalidGraphNode.prototype = extend(
  vg.GraphNode.prototype, {
    constructor: InvalidGraphNode,

    get value() {
      if (this._invalid.own_errors.length)
        return this._invalid.own_errors;
    },
    key_iter: invalid_key_iter,
    get_child: invalid_get_child,
  });


function invalid_key_iter() {
  return iter.fromArray(Object.keys(this._invalid.sub_errors));
}

function invalid_get_child(key) {
  var invalid = this._invalid.sub_errors[key];
  if (invalid == null)
    throw new vg.KeyError([key]);

  return new this.constructor(invalid);
}
