import { info, roles_by_feature } from './sterling-roles.json';
import { kerbin_Person, kerbin_Practice } from './types';

type InfoKeys = keyof typeof info;
type FeatureKeys = keyof typeof roles_by_feature;

// Effectively `(typeof info)[keyof typeof info]` with the union collapsed
interface RoleInfo {
  auto_for_role: string[];
  auto_for_type: string[];
  definition: string;
  features: string[];
  id: string;
  label: string;
  option_for: string[];
}

export { info };

export const roles_for_feature = (feature: string) =>
  (roles_by_feature[feature as FeatureKeys] || []) as InfoKeys[];

export const roles_for_features = (features: string[]) =>
  ([] as InfoKeys[]).concat(...features.map(roles_for_feature));

const unique = <T>(iterable: Iterable<T>) => Array.from(new Set(iterable));

export const role_infos_for_features = (features: string[]): RoleInfo[] =>
  unique(roles_for_features(features)).map((role) => info[role]);

export const compute_effective_roles = (
  person: kerbin_Person,
  practice = person.practice,
) => {
  const features = practice.effective_features;
  const infos = role_infos_for_features(features);
  // Auto roles by type
  const auto_roles = infos
    .filter((r) => (r.auto_for_type as string[]).includes(person.type))
    .map((r) => r.id);
  if (practiceOwner(person, practice)) {
    auto_roles.push('kerbin:practice_manager');
  }
  // Auto roles by role
  auto_roles.push(...autosForRoles(person, practice));
  // Explicit optional roles for type
  const optional_roles = infos
    .filter((r) => r.option_for.includes(person.type))
    .map((r) => r.id)
    .filter((id) => person.roles.includes(id));
  return unique([...auto_roles, ...optional_roles]);
};

export const has_role = (
  role: string,
  person: kerbin_Person,
  practice = person.practice,
) => {
  if (!person.$loaded || !practice.$loaded) {
    // The person or practice isn't loaded
    return false;
  }
  const r = info[role as InfoKeys];
  if (!r) {
    // No such role exists
    return false;
  }
  const features = practice.effective_features;
  if (
    !r.features.includes('kerbin') &&
    r.features.every((a) => !(features as string[]).includes(a))
  ) {
    // The role isn't part of any feature this practice has
    return false;
  }
  if ((r.auto_for_type as string[]).includes(person.type)) {
    // The role is automatically granted for this person type
    return true;
  }
  if (autosForRoles(person, practice).includes(role)) {
    // The role is automatically granted because of another role
    return true;
  }
  if (!r.option_for.includes(person.type)) {
    // The role isn't allowed for this person type
    return false;
  }
  if (role === 'kerbin:practice_manager' && practiceOwner(person, practice)) {
    return true;
  }
  // If we get here, then the role is optional for this person, so they have it
  // iff it's in their .roles.
  return person.roles.includes(role);
};

export const auto_for_role = (role: string, features: string[]) =>
  role_infos_for_features(features)
    .filter((r) => (r.auto_for_role as string[]).includes(role))
    .map((r) => r.id);

const practiceOwner = (person: kerbin_Person, practice: kerbin_Practice) =>
  person && practice && person === practice.owner;

const autosForRoles = (person: kerbin_Person, practice: kerbin_Practice) => {
  const roles = person.roles.slice();
  if (practiceOwner(person, practice)) roles.push('kerbin:practice_manager');
  const features = practice.effective_features;
  // Iterate over roles, including any that get added inside the loop
  for (const role of roles) {
    auto_for_role(role, features).forEach((auto) => {
      if (!roles.includes(auto)) roles.push(auto);
    });
  }
  return roles;
};

// TODO add other helper functions to the registry
