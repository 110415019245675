import { useRafLoop } from '@maternity/mun-cantrips';
import * as React from 'react';

// Check if the given element or an ancestor has fixed positioning
const isFixed = (el: HTMLElement): boolean => {
  while (el) {
    if (window.getComputedStyle(el).getPropertyValue('position') === 'fixed') {
      return true;
    }
    el = el.offsetParent as HTMLElement;
  }
  return false;
};

// Set the max height of the given element so it doesn't extend off screen
const resizeEl = (el: HTMLElement | null) => {
  // Bail if no element or it is hidden
  if (!el || el.offsetHeight === 0) return;

  const bounds = el.getBoundingClientRect();
  // When `position: fixed`, base on viewport height, otherwise use body height
  const bottom = isFixed(el)
    ? document.documentElement.clientHeight
    : document.body.getBoundingClientRect().bottom;
  el.style.maxHeight = `${bottom - bounds.top}px`;
};

/** Apply this style to the element to show a scrollbar as necessary when
 * constraining height. */
export const overflowStyle = {
  overflowY: 'auto',
} as const;

/**
 * When given a ref to an element, sets the element's `max-height` to prevent
 * it from overflowing the bottom of the viewport/body.
 */
export const useFitHeight = (ref?: React.RefObject<HTMLElement>) => {
  const resize = React.useCallback(() => {
    if (!ref) return;
    resizeEl(ref.current);
  }, [ref]);

  // Resize (synchronously) when element first rendered
  React.useLayoutEffect(resize, [resize]);
  // Also resize every animation frame (in case of window resize or other
  // elements changing layout)
  useRafLoop(resize, !!ref);
};
