// TODO: revisit this if/when implementing localization
/**
 * Helper for displaying appropriate singular/plural text.
 *
 * This currently implements a subset of the functionallity of Angular's
 * ngPluralize directive.
 */
export const pluralize = (
  /** The number of items */
  count: number,
  /**
   * A mapping of numbers to the strings to display. If there is no explict
   * rule for the given count, then the `other` function is called with the
   * count.
   */
  mapping: Record<number, string> & { other: (n: number) => string },
): string => {
  if (mapping[count] != null) {
    return mapping[count];
  }
  return mapping.other(count);
};
