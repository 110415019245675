import * as React from 'react';

import { ComboboxCtx } from './useComboboxCtx';

interface ComboboxInputProps {
  /** Combobox context object */
  ctx: ComboboxCtx<any>;
  /** Optional placeholder text */
  placeholder?: string;
  /** If true (default), open the menu when focused */
  openOnFocus?: boolean;
}

/** Renders the input field for a combobox. */
export const ComboboxInput = ({
  ctx,
  placeholder,
  openOnFocus = true,
}: ComboboxInputProps) => {
  const {
    inputValue,
    setInputValue,
    isOpen,
    setIsOpen,
    onInputChange,
    disabled,
    inputRef,
    menuId,
  } = ctx;
  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setInputValue(value);
      setIsOpen(true);
      onInputChange?.(value);
    },
    [setInputValue, setIsOpen, onInputChange],
  );
  const handleInputKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        // Open menu (if not already)
        setIsOpen(true);
        // Don't submit the enclosing form if enter is pressed
        e.preventDefault();
      }
      if (
        // Don't select "focused" option when space is pressed
        e.key === ' ' ||
        // Don't "focus" previous/next option on left/right, just move the cursor
        e.key === 'ArrowLeft' ||
        e.key === 'ArrowRight'
      ) {
        e.stopPropagation();
      }
    },
    [setIsOpen],
  );
  const handleFocus = React.useCallback(() => {
    if (openOnFocus) setIsOpen(true);
  }, [openOnFocus, setIsOpen]);

  // TODO: Select any existing text when focused?
  return (
    <input
      className="form-control"
      value={inputValue}
      onChange={handleInputChange}
      onKeyDown={handleInputKeyDown}
      onFocus={handleFocus}
      placeholder={placeholder}
      disabled={disabled}
      ref={inputRef}
      aria-autocomplete="list"
      aria-controls={menuId}
      aria-expanded={isOpen}
      aria-haspopup="listbox"
      role="combobox"
      autoComplete="off"
    />
  );
};
