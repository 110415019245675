import { useIsMounted } from '@maternity/mun-cantrips';
import * as React from 'react';

import { FieldValues, FormApi, FormState } from './types';

/**
 * Returns the selected slice of form state, and triggers a rerender when that
 * value changes.
 *
 * Inspired by React's useSubscription and Redux's useSelector.
 */
export const useFormState = <V extends FieldValues, T>(
  /** The form API handle */
  form: FormApi<V>,
  /** A (memoized) selector function that returns a slice of form state. */
  selector: (state: FormState<V>) => T,
  /** Optional equality comparison function. Defaults to `Object.is`. */
  equalityFn: (a: T, b: T) => boolean = Object.is,
): T => {
  const isMounted = useIsMounted();
  const [state, setState] = React.useState(() => ({
    // Store selector so we can check for staleness
    selector,
    // Calculate initial value
    value: selector(form.getFormState()),
  }));

  let valueToReturn = state.value;

  // If selector has changed, schedule an update
  if (state.selector !== selector) {
    // The `setState` call will trigger another render, but we can avoid
    // returning a stale value here
    valueToReturn = selector(form.getFormState());

    setState({
      selector,
      value: valueToReturn,
    });
  }

  React.useEffect(() => {
    const checkForUpdates = (formState: FormState<V>) => {
      if (!isMounted()) return;
      const newValue = selector(formState);
      setState((prevState) => {
        // When the selector is stale, don't update the state
        if (prevState.selector !== selector) {
          return prevState;
        }
        // When the value hasn't changed, don't update the state (allows
        // react to skip rerender in cases where object identity did change)
        if (equalityFn(newValue, prevState.value)) {
          return prevState;
        }
        return { ...prevState, value: newValue };
      });
    };

    // Check for any updates between render and effect handler
    checkForUpdates(form.getFormState());

    return form.subscribe(checkForUpdates);
  }, [isMounted, form, selector, equalityFn]);

  return valueToReturn;
};
