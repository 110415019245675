var base = require('./base'),
    Marker = base.Marker,
    dictify = base.dictify,
    undictify = base.undictify,

    Passthrough = exports.Passthrough = Marker.sub('tv.Passthrough');


dictify.register(passthrough, Passthrough);
undictify.register(passthrough, Passthrough);

function passthrough(disp, value, options) {
  // this is the tricky part
  return value;
}
