var { mixin } = require('@maternity/mun-extend'),
    Invalid = require('./invalid').Invalid,
    base = require('./base');


var BasicLeaf = exports.BasicLeaf = base.Leaf.sub('tv.BasicLeaf');

BasicLeaf.sub = function(name, ctor, value_ctor, allowed_types, primitive) {
  var sub = base.Leaf.sub.apply(this, arguments);

  if (allowed_types == null)
    allowed_types = [];

  else if (!Array.isArray(allowed_types))
    allowed_types = [allowed_types];

  mixin(sub.prototype, {
      ctor: value_ctor,
      allowed_types: allowed_types,
      primitive: primitive,
    });

  return sub;
};


base.dictify.register(dictify_basicleaf, BasicLeaf);
base.undictify.register(dictify_basicleaf, BasicLeaf);
function dictify_basicleaf(disp, value) {
  var marker = disp.marker;

  if (!marker.allowed_types.some(function(type) { return value instanceof type; }) &&
      !(marker.primitive && typeof value === marker.primitive))
    throw new Invalid('type_error');

  return value;
}


exports.Boolean = BasicLeaf.sub('boolean', null, Boolean, null, 'boolean');
exports.Number = BasicLeaf.sub('number', null, Number, null, 'number');
var Int = exports.Int = BasicLeaf.sub('tv.Int', null, Number, null, 'number');
exports.String = BasicLeaf.sub('string', null, String, null, 'string');


base.dictify.register(dictify_int, Int);
base.undictify.register(dictify_int, Int);
function dictify_int(disp, value, options) {
  value = disp.super(Int).call(value, options);

  if (value !== (value|0))
    throw new Invalid('type_error');

  return value;
}
