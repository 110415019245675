import classNames from 'classnames';
import * as React from 'react';

import { Variant } from '../types';

type Props<C extends React.ElementType> = React.ComponentProps<C> extends {
  className?: string;
}
  ? React.ComponentProps<C> & { component?: C; variant?: Variant }
  : never;

/**
 * Renders a panel container.
 *
 * The `variant` prop indicates which style variant to use (defaults to
 * `default`). The `component` prop controls what element or component is
 * rendered (defaults to a `div` element). Any other props are passed through.
 */
export const Panel = <C extends React.ElementType = 'div'>({
  component = 'div',
  variant = 'default',
  className,
  ...props
}: Props<C>) => {
  const Component = component;

  return (
    <Component
      className={classNames('panel', `panel-${variant}`, className)}
      {...props}
    />
  );
};
