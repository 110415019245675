import Emitter from 'component-emitter';
import { extend, mixin } from '@maternity/mun-extend';
import * as vg from '@maternity/vertigo';
import tv from '@maternity/travesty';

const GraphWrapper = vg.wrapper.GraphWrapper;
const EdgeRestriction = tv.dispatchgraph.EdgeRestriction;


function SchemaMessageNode(g, refs) {
  return GraphWrapper.apply(this, arguments);
}

SchemaMessageNode.sloots = ['traverse_docs', 'path'];
SchemaMessageNode.prototype = extend(
  GraphWrapper.prototype, {
    constructor: SchemaMessageNode,

    path: '',
    get_child: function(key) {
      var child = this.graph.get_child(key),
          marker = tv.unwrap.call(child.value),
          path = this.path
            ? this.path+'/'+key
            : key;

      if (marker instanceof tv.Document.marker && this.traverse_docs.indexOf(path) === -1)
        return new EdgeRestriction(child, []);

      return new this.constructor(child, this.traverse_docs, path);
    },
  });


// SchemaMessage
export const SchemaMessage = tv.SchemaObj.extend(
      'tv_chasm.SchemaMessage', {
        field_types: {},
      });

mixin(
  SchemaMessage.prototype,
  Emitter.prototype, {
    $invalidate: function schemamessage_invalidate() {
      if (this.$invalid)
        return;
      Object.defineProperty(this, '$invalid', {enumerable: false, value: true});
      this.emit('invalid');
    },
  });

SchemaMessage.extend = (function wrap_extend(orig_extend) {
    return schemamessage_extend;

    function schemamessage_extend(name, ctor, field_types) {
      var extended = orig_extend.apply(this, arguments);
      extended.typegraph = new SchemaMessageNode(extended.typegraph, extended.traverse_docs);
      return extended;
    }
  })(SchemaMessage.extend);


export function install_importer(importer) {
  importer.register(import_message, 'tv_chasm.SchemaMessage');
}


function import_message(disp, d, options) {
  var import_schemaobj = disp.dispatch({type: 'tv.SchemaObj'}),
      typedef = import_schemaobj(disp, d, options);

  return typedef;
}

export default {
  SchemaMessage,
  install_importer,
};
