import * as React from 'react';

import { Modal, ModalContextType } from './Modal';
import { ModalBody } from './ModalBody';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
import { ModalHideButton } from './ModalHideButton';
import { ModalTitle } from './ModalTitle';

type Props = {
  title?: React.ReactNode;
  acceptBtnType?: React.ComponentProps<typeof ModalHideButton>['variant'];
  acceptText?: string;
  rejectText?: string;
  onAccept: () => void;
  onReject?: () => void;
  /** If true, the modal will start visible (defaults to true) */
  initialOpen?: boolean;
  // Need explicit `children` type because of `forwardRef`
  children?: React.ReactNode;
} & Pick<React.ComponentProps<typeof Modal>, 'keyboard' | 'backdrop'>;

export const ConfirmModal = React.forwardRef<ModalContextType, Props>(
  (
    {
      title = 'Confirmation needed',
      children,
      acceptBtnType = 'primary',
      acceptText = 'OK',
      rejectText = 'Cancel',
      onAccept,
      onReject,
      initialOpen = true,
      keyboard,
      backdrop,
    },
    ref,
  ) => {
    const hasAcceptedRef = React.useRef(false);

    return (
      <Modal
        ref={ref}
        onShow={() => {
          hasAcceptedRef.current = false;
        }}
        onHide={() => {
          if (hasAcceptedRef.current) {
            onAccept();
          } else {
            if (onReject) onReject();
          }
        }}
        initialOpen={initialOpen}
        keyboard={keyboard}
        backdrop={backdrop}
      >
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p className="text-pre-wrap">{children}</p>
        </ModalBody>
        <ModalFooter>
          <ModalHideButton>{rejectText}</ModalHideButton>
          <ModalHideButton
            onClick={() => {
              hasAcceptedRef.current = true;
            }}
            variant={acceptBtnType}
          >
            {acceptText}
          </ModalHideButton>
        </ModalFooter>
      </Modal>
    );
  },
);
