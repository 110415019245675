/*
endpoints - a place to register endpoints.

This exposes a single service, munDocEndpoints, which is an object for
registering endpoints.

Generally you'll have a config block somewhere that calls

munDocEndpointsProvider.register(endpointDefs);

where endpointDefs[ep_name][method] is a descriptor of the input types, output
type, routing rules, etc. for an endpoint. All munDocEndpoints does is store
these, so you can refer to them later.

*/

import ngModule from './mun-doc-module';

ngModule

  .provider('munDocEndpoints', function() {
    var endpoints = {};

    this.register = register;
    this.$get = get;

    function register(items) {
      Object.keys(items).forEach(function(ep) {
        endpoints[ep] = items[ep];
      });
      return this;
    }

    function get() {
      return endpoints;
    }
  })

  ;
