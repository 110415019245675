import tsort from '@maternity/vendor-tsort';
import tgi from '@maternity/travesty/src/typegraphimporter';

import schemamessage from './schemamessage';

import ngModule from './mun-doc-module';

ngModule

  .provider('munDocSchemas', function() {
    var importer = this.importer = tgi.importer.sub(),
        schemas = {
          'tv_chasm.SchemaMessage': schemamessage.SchemaMessage,
        },
        schemaDatas = [];

    schemamessage.install_importer(importer);

    this.$get = get;
    this.load = load;

    get.$inject = ['$q'];
    function get($q) {
      var types = {},
          sortGraph = tsort();

      schemaDatas.forEach(function(schemaData) {
          types[schemaData.typedef] = schemaData;
          sortGraph.add([schemaData.type, schemaData.typedef]);
        });

      sortGraph.sort().forEach(function(type) {
          // base types can be missing (SchemaObj, etc)
          if (types[type])
            importer.call(types[type], {schemas: schemas});
        });

      return schemas;
    }

    function load(schemaData) {
      // Collect schema data to load at create time.  This allows further configuration fo the
      // import to happen without the risk of it happening too late to be useful.
      schemaDatas.push(schemaData);
      return this;
    }
  })

  ;
