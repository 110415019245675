import * as iter from '@maternity/mun-itertools';

type EdgeList<T> = Array<{ key: string; node: GraphNode<T> }>;
interface NodeConstructor<T> {
  // eslint-disable-next-line @typescript-eslint/prefer-function-type
  new (value: T, edges?: EdgeList<T>): PlainGraphNode<T>;
}

export function from_dict<T>(
  d: any,
  Node: NodeConstructor<T> = PlainGraphNode,
): PlainGraphNode<T> {
  if (d == null || typeof d !== 'object') {
    return new Node(d);
  }

  const edges = Object.keys(d).reduce((acc, k) => {
    if (k !== '_self') {
      acc.push({ key: k, node: from_dict(d[k], Node) });
    }
    return acc;
  }, [] as EdgeList<T>);

  return new Node(d._self, edges);
}

export abstract class GraphNode<T> {
  abstract value: T;

  constructor() {
    /* abstract */
  }

  abstract key_iter(): IterableIterator<string>;
  abstract get_child(key: string): GraphNode<T>;

  *child_iter(): IterableIterator<GraphNode<T>> {
    for (const key of this.key_iter()) {
      yield this.get_child(key);
    }
  }

  *edge_iter(): IterableIterator<{ key: string; node: GraphNode<T> }> {
    for (const key of this.key_iter()) {
      yield { key, node: this.get_child(key) };
    }
  }

  contains(key: string): boolean {
    try {
      this.get_child(key);
    } catch (e) {
      if (e instanceof KeyError) {
        return false;
      }

      throw e;
    }

    return true;
  }

  get_path<D = never>(path: string[], default_?: D): GraphNode<T> | D {
    let sub;

    if (path.length === 0) {
      return this;
    }

    try {
      sub = this.get_child(path[0]);
    } catch (e) {
      if (e instanceof KeyError && typeof default_ !== 'undefined') {
        return default_;
      }

      throw e;
    }

    try {
      return sub.get_path(path.slice(1), default_);
    } catch (e) {
      if (e instanceof KeyError) {
        e.keys.unshift(path[0]);
      }

      throw e;
    }
  }
}

export class PlainGraphNode<T> extends GraphNode<T> {
  value: T;
  edges: Map<string, GraphNode<T>>;

  constructor(value: T, edges: EdgeList<T> = []) {
    super();

    if (!Array.isArray(edges)) {
      throw new Error('edges should be an array or nully');
    }

    this.value = value;
    this.edges = new Map(
      edges.map(({ key, node }) => [key, node] as [string, GraphNode<T>]),
    );
  }

  key_iter(): IterableIterator<string> {
    return this.edges.keys();
  }

  get_child(key: string): GraphNode<T> {
    if (this.edges.has(key)) {
      return this.edges.get(key)!;
    }

    throw new KeyError([key]);
  }

  override child_iter(): IterableIterator<GraphNode<T>> {
    return super.child_iter() as any;
  }

  override edge_iter(): IterableIterator<{ key: string; node: GraphNode<T> }> {
    return super.edge_iter() as any;
  }

  set_edge(key: string, child: GraphNode<T>): void {
    this.edges.set(key, child);
  }

  add_edge(key: string, child: GraphNode<T>): void {
    try {
      this.get_child(key);
    } catch (e) {
      if (!(e instanceof KeyError)) {
        throw e;
      }

      this.set_edge(key, child);
      return;
    }

    throw new Error('Duplicate key: ' + key);
  }

  set_path(path: string[], value: GraphNode<T>): void {
    if (!Array.isArray(path) || path.length === 0) {
      throw new Error('Bad path: ' + path);
    }

    if (path.length === 1) {
      this.set_edge(path[0], value);
    } else {
      const child = this.get_child(path[0]);
      if (!(child instanceof PlainGraphNode)) {
        throw new Error('Can only set paths on PlainGraphNodes');
      }
      child.set_path(path.slice(1), value);
    }
  }

  pop_edge<D = never>(key: string, default_?: D): GraphNode<T> | D {
    if (this.edges.has(key)) {
      const child = this.edges.get(key)!;
      this.edges.delete(key);
      return child;
    }

    if (typeof default_ !== 'undefined') {
      return default_;
    }

    throw new KeyError([key]);
  }
}

export class PathGraphNode extends GraphNode<string[]> {
  value: string[];

  constructor(path: string[] = []) {
    super();

    if (!Array.isArray(path)) {
      throw new Error('That is NOT a path: ' + path);
    }

    this.value = path;
  }

  key_iter(): IterableIterator<any> {
    return iter.empty();
  }

  get_child(key: string): PathGraphNode {
    return new PathGraphNode(this.value.concat(key));
  }
}

// LazyGraphNode resolves to a concrete GraphNode the first time its value or
// edges are accessed. This can be helpful when defining recursive graphs.
export class LazyGraphNode<T> extends GraphNode<T> {
  _graph?: GraphNode<T>;

  constructor(readonly init: () => GraphNode<T>) {
    super();
  }

  get graph(): GraphNode<T> {
    if (!this._graph) {
      this._graph = this.init();
    }
    return this._graph;
  }

  get value(): T {
    return this.graph.value;
  }

  get_child(key: string): GraphNode<T> {
    return this.graph.get_child(key);
  }

  key_iter(): IterableIterator<string> {
    return this.graph.key_iter();
  }
}

export function ascii_tree(node: GraphNode<any>): string {
  const stack: any = [];

  return render({ key: 'root', node })
    .map((s) => `${s}\n`)
    .join('');

  function render(
    edge: { key: string; node: GraphNode<any> },
    prefix?: string,
  ): string[] {
    const edges = flaglast(edge.node.edge_iter());

    stack.push(edges);
    const rows = iter
      .iter(edges.iter)
      .map((e) => render(e, prefix || '  +--'))
      .reduce((acc, r) => acc.concat(r), [] as string[]);
    stack.pop();

    const indent = stack
      .slice(0, -1)
      .map((edgeIter: ReturnType<typeof flaglast>) =>
        edgeIter.last ? '   ' : '  |',
      )
      .join('');
    rows.unshift(`${indent}${prefix || ''}${edge.key}: ${edge.node.value}`);

    return rows;
  }
}

export class KeyError extends Error {
  keys: string[];
  override message: string;
  override name = 'KeyError';
  override stack: any;

  constructor(keys: string[]) {
    super();

    this.keys = keys;
    this.message = String(keys);
    if (Error.captureStackTrace != null) {
      Error.captureStackTrace(this, KeyError);
    } else {
      this.stack = Error().stack;
    }
  }
}

function flaglast<T>(i: IterableIterator<T>): {
  iter: IterableIterator<T>;
  last: boolean;
} {
  // FIXME: This could use some better terminology
  const self = { iter: it(), last: false };

  return self;

  function* it() {
    const first = i.next();
    if (first.done) return;
    let result = first.value;

    for (const next of i) {
      yield result;
      result = next;
    }
    self.last = true;
    yield result;
  }
}
