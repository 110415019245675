import * as iter from '@maternity/mun-itertools';

import { GraphNode, KeyError } from './graph';

type Graphs<T> = Array<GraphNode<T> | undefined>;
type KeyFn<T> = (graphs: Graphs<T>) => IterableIterator<string>;
type ValueFn<T, U> = (graphs: Graphs<T>) => U;

const assertGraph = (g: GraphNode<any> | undefined): GraphNode<any> => {
  if (!g) throw Error('Graph missing');
  return g;
};

export class MergeGraphNode<T, U> extends GraphNode<U> {
  graphs: Graphs<T>;
  _key_fn: KeyFn<T>;
  _value_fn: ValueFn<T, U>;

  constructor(
    graphs: Graphs<T>,
    key_fn: KeyFn<T> = (gs) => assertGraph(gs[0]).key_iter(),
    value_fn: ValueFn<T, U> = (gs) => assertGraph(gs[0]).value as any as U,
  ) {
    super();

    this.graphs = graphs;
    this._key_fn = key_fn;
    this._value_fn = value_fn;
  }

  get value(): U {
    return this._value_fn(this.graphs);
  }

  key_iter(): IterableIterator<string> {
    return this._key_fn(this.graphs);
  }

  get_child(key: string): MergeGraphNode<T, U> {
    const graphs = this.graphs.map(
      (g) => (g && g.get_path([key], null)) || undefined,
    );
    if (graphs.every((g) => !g)) {
      throw new KeyError([key]);
    }
    return this._build_child(graphs);
  }

  _build_child(graphs: Graphs<T>): MergeGraphNode<T, U> {
    return new MergeGraphNode(graphs, this._key_fn, this._value_fn);
  }
}

export function map<T, U>(
  graph: GraphNode<T>,
  fn: (value: T) => U,
): MergeGraphNode<T, U> {
  return new MergeGraphNode([graph], undefined, (graphs) =>
    fn(assertGraph(graphs[0]).value),
  );
}

export function filter<T>(
  graphs: Graphs<T>,
  fn: (value: T) => boolean,
): MergeGraphNode<T, T> {
  return new MergeGraphNode(graphs, filter_keys);

  function filter_keys(
    this: MergeGraphNode<T, T>,
    gs: Graphs<T>,
  ): IterableIterator<string> {
    return iter.filter(assertGraph(gs[0]).key_iter(), (key) => {
      const child = this.get_child(key);
      return fn(child.value);
    });
  }
}

type MergeFn<T> = (graphs: Graphs<T>) => IterableIterator<string>;
type DefaultMergeFns = keyof typeof zip_fns;

export function zip<T, U>(
  graphs: Graphs<T>,
  merge_fn: DefaultMergeFns | MergeFn<T> = 'first',
): MergeGraphNode<T, Array<T | undefined>> {
  const key_fn = typeof merge_fn === 'string' ? zip_fns[merge_fn] : merge_fn;
  const value_fn = (gs: Graphs<T>) => gs.map((g) => g?.value);

  return new MergeGraphNode(graphs, key_fn, value_fn);
}

const zip_fns = {
  first<T>(graphs: Graphs<T>): IterableIterator<string> {
    return assertGraph(graphs[0]).key_iter();
  },

  union<T>(graphs: Graphs<T>): IterableIterator<string> {
    graphs = graphs.filter((g) => g);
    const seen: { [k: string]: boolean } = {};
    const key_iters = graphs.map((g) => (g ? g.key_iter() : iter.empty()));

    return iter.filter(iter.concat(...key_iters), (k) => {
      if (seen[k]) {
        return false;
      } else {
        seen[k] = true;
        return true;
      }
    });
  },

  // TODO: intersection
  // TODO: strict
};
