/**
 * This module intercepts error responses and notifies the user of the error.
 * It includes the following handlers:
 *
 *    401: Logout and redirect to login screen. States can opt-out by setting
 *    `allowUnauth: true`.
 *
 *    5xx: Display a modal. GET/HEAD requests will be retried,
 *    resolving/rejecting the original http promise once the request no longer
 *    returns a 5xx.
 *
 *    timeout/unreachable: Display a modal. GET/HEAD requests will be retried,
 *    resolving/rejecting the original http promise once the request no longer
 *    times out.
 *
 */
import ngModule from '../kerbin-core-module';

const MAX_RETRY_SECONDS = 10*60; // 10 minutes

ngModule
  .config(function($httpProvider) {
    var retryMethods = {GET: true, HEAD: true};

    // Use same default headers for DELETE as POST
    const defaultHeaders = $httpProvider.defaults.headers;
    defaultHeaders.delete = defaultHeaders.post;

    // TODO: Configure default timeout
    // TODO: Provide mechanism for bypassing these interceptors
    // TODO: Consolidate request failures into a single modal?
    // TODO: Add delay when countdown reaches 0 to prevent flicker

    // handle 401 errors
    $httpProvider.interceptors.push(unauthorizedInterceptor);

    // handle 5xx errors, automatically retrying GET and HEAD requests
    $httpProvider.interceptors.push(errorInterceptorFactory({
      responseTest: function(response) {
        // Treat teapots as server errors.
        // Load balancers can have assumptions about 500 errors. Linode's NodeBalancer does.
        return response.status >= 500 && response.status <= 599;
      },
      template: require('../../../partials/common/loading-500-error.jade'),
    }));

    // handle timeouts, automatically retrying GET and HEAD requests
    $httpProvider.interceptors.push(errorInterceptorFactory({
      responseTest: function(response) {
        // status code < 1 indicates timeout/xhr fail
        return response.status < 1;
      },
      template: require('../../../partials/common/loading-timeout-error.jade'),
    }));


    unauthorizedInterceptor.$inject = ['globalBypassFormSafety', '$injector', '$q'];
    function unauthorizedInterceptor(globalBypassFormSafety, $injector, $q) {
      // Load $state late via injector to prevent circular dependency.
      var $state;
      return {
        responseError: function(response) {
          // TODO: Improve handling of 401 on POST to preserve unsaved data
          //       until reauth? (Need to be careful with PHI.)
          if (!$state)
            $state = $injector.get('$state');
          // Only handle 401 for "top" state and its descendants
          if (response.status === 401 && $state.isActive('top')) {
            // Prevent form-safety from interfering with redirect
            globalBypassFormSafety.bypass();
            // Perform logout
            $state.goto('logout');
          }
          return $q.reject(response);
        }
      };
    }

    function errorInterceptorFactory(opts) {
      errorInterceptor.$inject = ['$injector', '$q'];
      function errorInterceptor($injector, $q) {
        // Load munModal late via injector to prevent circular dependency.
        var munModal,
            modalOpen = false;
        return {
          request: function(config) {
            if (config.timeout?.then)
              // Note: This assumes timeout is only a promise if the user might
              // cancel the request.
              config.timeout = config.timeout.then(function() {
                  config.userCancelled = true;
                });
            return config;
          },
          responseError: function(response) {
            if (!munModal)
              munModal = $injector.get('munModal');
            if (opts.responseTest(response) && !response.config.userCancelled) {
              if (response.config.method in retryMethods) {
                if (!response.config.modal) {
                  response.config.modal = munModal({
                    template: opts.template,
                    controller: RetryController,
                  });
                }
                return response.config.modal.$promise.then(function() {
                  response.config.modal.handleResponse(response);
                  return response.config.modal.responsePromise;
                });
              } else {
                // Ensure error modal is not already open
                // TODO: Handle errors that need to be retried
                var modal;
                if (modalOpen) {
                  return $q.reject(response);
                }
                modalOpen = true;

                modal = munModal({
                  template: opts.template,
                });

                modal.result.finally(function(){ modalOpen = false;});
              }
            }
            return $q.reject(response);
          },
        };
      }

      return errorInterceptor;
    }

    RetryController.$inject = ['$http', '$interval', '$modalInstance', 'munSession', '$q', '$scope', '$state'];
    function RetryController($http, $interval, $modalInstance, munSession, $q, $scope, $state) {
      var deferred = $q.defer(),
          countdown,
          lastResponse,
          retryCountdown = {},
          retryInterval = 1;

      $scope.showRetry = true;
      $scope.person = munSession.person;
      $scope.logout = function () {
        abortRetry();
        $state.goto('logout');
      };
      $scope.retryNow = retryNow;
      $scope.retryCountdown = retryCountdown;

      // Expose promise of final result
      $modalInstance.responsePromise = deferred.promise;
      // Handle an intercepted response
      $modalInstance.handleResponse = function(response) {
        lastResponse = response;
        retryCountdown.value = retryInterval;
        // Get notifications every second for UI feedback, and retry request
        // after retryInterval seconds.
        countdown = $interval(function() {}, 1000, retryInterval);
        countdown.then(
          retryRequest,
          null,
          function(count) {
            // count does not include the current iteration, so add one
            retryCountdown.value = retryInterval - (count + 1);
          }
        );
      };
      // Handle modal close/dismiss
      $modalInstance.result
        .finally(function() {
          abortRetry();
          deferred.reject(lastResponse);
        });
      // Insure modal is closed when done
      deferred.promise.finally(function() {
        $modalInstance.hide();
      });

      function abortRetry() {
        $interval.cancel(countdown);
      }
      function retryNow() {
        abortRetry();
        retryRequest();
      }
      function retryRequest() {
        var config = lastResponse.config;
        // Increase retryInterval unless at max
        retryInterval *= 2;
        if (retryInterval > MAX_RETRY_SECONDS)
          retryInterval = MAX_RETRY_SECONDS;
        // Replay request
        $http(config)
          .then(function(response) {
            // Resolve if retry succeeds.
            deferred.resolve(response);
          })
          .catch(function(response) {
            // If error isn't intercepted, reject.
            deferred.reject(response);
          });
      }
    }
  })

  .filter('prettySeconds', function() {
    return function(seconds) {
      if (seconds === 1) {
        return "1 second";
      } else if (seconds < 60) {
        return seconds + " seconds";
      } else if (seconds < 120) {
        return "a minute";
      } else {
        return Math.floor(seconds / 60) + " minutes";
      }
    };
  })
  ;
