import { createRequiredContext } from '@maternity/mun-required-context';
import { Path, PathValue } from '@maternity/mun-types';
import * as React from 'react';

import { BaseCheckbox } from './BaseCheckbox';
import { BaseFieldProps, FieldValues } from './types';
import { useField } from './useField';

// TODO: Add typechecking of options and <Checkbox> values? Would probably
// require passing around a handle via props similar to useForm...

interface CheckboxGroupContextType {
  model: string;
  modelValue: unknown[] | undefined;
  isChecked: (value: unknown) => boolean;
  setValue: (e: React.ChangeEvent<any>, value: unknown) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
  inline: boolean;
  register: (value: unknown) => (() => void) | undefined;
}

const CheckboxGroupContext = createRequiredContext<CheckboxGroupContextType>();

interface CheckboxProps {
  /** The value associated with the checkbox. */
  value: unknown;
  /** If the checkbox should be disabled. */
  disabled?: boolean;
  /** The label for the checkbox. */
  children: React.ReactNode;
}

/**
 * A single checkbox input, which must be used inside a `<CheckboxGroup>`.
 *
 * A label should be provided via `children`.
 */
export const Checkbox = ({ value, disabled, children }: CheckboxProps) => {
  const { model, isChecked, setValue, onBlur, inline, register } =
    CheckboxGroupContext.useContext();

  React.useEffect(() => {
    if (!disabled) return register(value);
  }, [disabled, value, register]);

  return (
    <BaseCheckbox
      name={model}
      onBlur={onBlur}
      onChange={(e) => setValue(e, value)}
      checked={isChecked(value)}
      disabled={disabled}
      inline={inline}
    >
      {children}
    </BaseCheckbox>
  );
};

interface ToggleAllProps {
  /** If the checkbox should be disabled. */
  disabled?: boolean;
  /** The label for the checkbox. Defaults to "Select All". */
  children?: React.ReactNode;
}

const TOGGLE_ALL = Symbol();

/**
 * A checkbox for toggling all other checkboxes in the group at once.
 *
 * The label text defaults to "Select All".
 */
export const ToggleAllCheckbox = ({ children, ...props }: ToggleAllProps) => (
  <Checkbox value={TOGGLE_ALL} {...props}>
    {React.Children.count(children) ? children : 'Select All'}
  </Checkbox>
);

interface OptionConfig {
  label: React.ReactNode;
  value: unknown;
  disabled?: boolean;
}
type OptionsType = Array<string | OptionConfig>;

const normalizeOptions = (options: OptionsType): OptionConfig[] =>
  options.map((opt) => {
    if (typeof opt === 'string') {
      return { label: opt, value: opt };
    }
    return opt;
  });

type CheckboxGroupProps<V extends FieldValues, M extends Path<V>> = {
  /** If true, at least one option must be selected. */
  // Don't use EnforceRequired type since required=true also checks length > 0
  required?: boolean;
  /** A list of options. When the value type includes strings, strings are
   * permitted, which will be used as both the label and value. Otherwise,
   * options may be specified as objects with the label, value, and optional
   * disabled state. */
  options?: OptionsType;
  /** If true, the checkboxes will be inline. */
  inline?: boolean;
  /** If true or a string (to use as the label), prepend a checkbox for
   * toggling all other checkboxes. */
  toggleAll?: boolean | string;
  /** Called when the value changes. The new value is passed as the second
   * argument.  */
  onChange?: (e: React.ChangeEvent<any>, value: PathValue<V, M>) => void;
  /** Called when a checkbox is blurred. */
  onBlur?: (e: React.FocusEvent<any>) => void;
  /** May contain additional `<Checkbox>` options. */
  children?: React.ReactNode;
} & BaseFieldProps<V, M>;

// Use constant for default values to avoid breaking memoization
const EMPTY_ARRAY: any[] = [];

/**
 * A group of checkboxes (the model value is an array of the checked items).
 *
 * Options may be given via the `options` prop or rendering `<Checkbox>`
 * components as children (depth doesn't matter). If both are given, the
 * options from the `options` prop appear first.
 *
 * Setting the `toggleAll` prop to `true` (to use the default label) or a label
 * string will prepend a checkbox for toggling all other checkboxes.
 */
export const CheckboxGroup = <V extends FieldValues, M extends Path<V>>({
  form,
  model,
  validators = EMPTY_ARRAY,
  required,
  options = EMPTY_ARRAY,
  inline = false,
  toggleAll = false,
  onChange,
  onBlur,
  children,
}: CheckboxGroupProps<V, M>) => {
  validators = React.useMemo(
    () =>
      required
        ? [
            (v: any) => {
              if (!v || !v.length) return 'required';
            },
            ...validators,
          ]
        : validators,
    [validators, required],
  );
  const [modelValue, api] = useField<unknown[]>({ form, model, validators });
  const [registered, setRegistered] = React.useState(new Set());
  const register = React.useCallback((value) => {
    // Toggles can be ignored
    if (value === TOGGLE_ALL) return;

    setRegistered((r) => {
      // TODO: warn on or support duplicate registration?
      // Don't update state if value already present
      if (r.has(value)) return r;
      const clone = new Set(r);
      clone.add(value);
      return clone;
    });

    return () =>
      setRegistered((r) => {
        const clone = new Set(r);
        clone.delete(value);
        return clone;
      });
  }, []);
  const context: CheckboxGroupContextType = React.useMemo(
    () => ({
      model,
      // Including the model value in the context should force children using the
      // context to re-render when it changes.
      modelValue,
      isChecked(value) {
        if (value === TOGGLE_ALL) {
          return [...registered].every(
            (v) => modelValue && modelValue.includes(v),
          );
        }
        // Always return a boolean so the `checked` prop doesn't get dropped
        return modelValue ? modelValue.includes(value) : false;
      },
      inline,
      onBlur(e) {
        api.setTouched();
        if (onBlur) onBlur(e);
      },
      setValue(e, v) {
        const { checked } = e.target;
        let newValue: unknown[] = modelValue || [];
        if (v === TOGGLE_ALL) {
          newValue = checked ? [...registered] : [];
        } else if (checked && !newValue.includes(v)) {
          newValue = [...newValue, v];
        } else if (!checked && newValue.includes(v)) {
          newValue = newValue.filter((x) => x !== v);
        }
        api.setValue(newValue);
        if (onChange) onChange(e, newValue as PathValue<V, M>);
      },
      register,
    }),
    [api, inline, model, modelValue, onBlur, onChange, register, registered],
  );
  const optionCheckboxes = React.useMemo(
    () => (
      <React.Fragment>
        {toggleAll && (
          <ToggleAllCheckbox>
            {typeof toggleAll === 'string' ? toggleAll : null}
          </ToggleAllCheckbox>
        )}
        {normalizeOptions(options).map(({ value, label, disabled }, idx) => (
          <Checkbox key={idx} value={value} disabled={disabled}>
            {label}
          </Checkbox>
        ))}
      </React.Fragment>
    ),
    [toggleAll, options],
  );

  return (
    <CheckboxGroupContext.Provider value={context}>
      {optionCheckboxes}
      {children}
    </CheckboxGroupContext.Provider>
  );
};
