// TODO this could possibly be generated automatically
export const app_list = [
  { id: 'kerbin', label: 'Maternity Neighborhood' },
  { id: 'dres', label: 'Quest eLabs' },
  { id: 'eve', label: 'Billing' },
  { id: 'kearth', label: 'EHR' },
  { id: 'duna', label: 'Care Guide' },
  { id: 'ike', label: 'Strong Start Surveys' },
  { id: 'moho', label: 'Surveys' },
  { id: 'jool_lite', label: 'Scheduler' },
];

export const apps = app_list.reduce((acc, app) => {
  acc[app.id] = app;
  return acc;
}, {} as { [k: string]: (typeof app_list)[number] });
