var { extend } = require('@maternity/mun-extend'),
    vg = require('@maternity/vertigo'),

    Invalid = require('./invalid').Invalid,
    base = require('./base'),
    Marker = base.Marker,
    to_typegraph = base.to_typegraph,
    dictify = base.dictify,
    undictify = base.undictify,
    traverse = base.traverse,

    Polymorph = exports.Polymorph = Marker.sub('Polymorph', function Polymorph(mapping) {
      var self = this;

      if (!(this instanceof Polymorph)) {
        self = Object.create(Polymorph.prototype);
        Polymorph.apply(self, arguments);
        return self;
      }

      self.names_by_type_key = {};
      Object.keys(mapping)
        .forEach(function(name) { self.add(name, mapping[name]); });
    });

Polymorph.prototype.add = function(name, proto) {
  if (typeof proto === 'function')
    proto = proto.prototype;

  var typeKey = base.ownTypeKeyOf(proto);

  this.names_by_type_key[typeKey] = name;
};

Polymorph.prototype.name_for_val = function(val) {
  var keys = base.typeKeysOf(val);

  for (var i=0; i < keys.length; i++) {
    if (keys[i] in this.names_by_type_key)
      return this.names_by_type_key[keys[i]];
  }

  throw new UnknownType(keys);
};

Polymorph.prototype.of = function(markers) {
  var children = Object.keys(markers).map(function(key) {
        return {key: key, node: to_typegraph.call(markers[key])};
      });

  return new vg.PlainGraphNode(this, children);
};

exports.UnknownType = UnknownType;
function UnknownType(keys) {
  // The keys are likely to be gibberish that changes with each run, so listing them may just be
  // antagonistic.
  this.message = 'Unknown type: '+keys;
  this.name = 'UnknownType';
  if (typeof Error.captureStackTrace === 'function')
    this.stack = Error.captureStackTrace(this, UnknownType);
  else
    this.stack = Error().stack;
}

UnknownType.prototype = extend(
  Error.prototype, {
    constructor: UnknownType,
  });


dictify.register(dictify_polymorph, Polymorph);
function dictify_polymorph(disp, value, options) {
  var name = disp.marker.name_for_val(value);

  return [name, disp.get_child(name).call(value, options)];
}

undictify.register(undictify_polymorph, Polymorph);
function undictify_polymorph(disp, value, options) {
  var name;

  if (typeof value === 'string')
    value = [value, {}];

  if (value == null || value.length !== 2)
    throw new Invalid('type_error');

  name = value[0];
  value = value[1];

  return disp.get_child(name).call(value, options);
}

traverse.register(traverse_polymorph, Polymorph);
function traverse_polymorph(disp, value, options) {
  var name = disp.marker.name_for_val(value),
      zipgraph = options?.zipgraph;

  if (zipgraph != null)
    options = extend(options, {zipgraph: zipgraph.get_child(name)});

  return disp.get_child(name).call(value, options);
}
