// TODO: Find a home for this.
import * as iter from '@maternity/mun-itertools';
import tv from '@maternity/travesty';

const create = tv.make_dispatcher();
export default create;

create.register(create_nothing, tv.Leaf);
create.register(create_nothing, tv.Optional);
create.register(create_nothing, tv.Polymorph);
function create_nothing(disp, options) {}

create.register(create_list, tv.List);
function create_list(disp, options) {
  return [];
}

create.register(create_tuple, tv.Tuple);
function create_tuple(disp, options) {
  var marker = disp.marker;

  return marker.ctor(marker.field_names.map(function(key, i) {
    return disp.get_child(key).call(options);
  }));
}

create.register(create_schema, tv.Schema);
function create_schema(disp, options) {
  var obj = {};

  iter.forEach(disp.edge_iter(), function create_schema_edge(edge) {
      obj[edge.key] = edge.node.call(options);
    });

  return obj;
}

create.register(create_empty_map, tv.StrMapping);
create.register(create_empty_map, tv.UniMapping);
function create_empty_map(disp, options) {
  return {};
}

create.register(create_schemaobj, tv.SchemaObj.marker);
function create_schemaobj(disp, options) {
  var marker = disp.marker,
      obj = Object.create(marker.target_proto),
      data = disp.super(tv.SchemaObj.marker).call(options);

  iter.forEach(disp.key_iter(), function create_schemaobj_key(key) {
      obj[key] = data[key];
    });

  return obj;
}

create.register(create_document, tv.Document.marker);
function create_document(disp, options) {
  // Only create the document if it isn't a leaf in the typegraph.
  if (disp.get_path(['uid'], null)) {
    var marker = disp.marker,
        ctor = marker.target_proto.constructor,
        detached = disp.super(tv.Document.marker).call(options),
        // detached is an instance, but the docset machinery needs to allocate uids and track objects
        doc = options.in_docset.create(ctor);

    doc.load(detached);
    return doc;
  }

}
