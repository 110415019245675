import {
  overflowStyle,
  useFitHeight,
} from '@maternity/mun-dropdown/useFitHeight';
import { ErrorBoundary } from '@maternity/mun-error-boundary';
import { Spinner } from '@maternity/mun-spinner';
import classNames from 'classnames';
import * as React from 'react';

import { ComboboxCtx } from './useComboboxCtx';

interface ComboboxMenuProps {
  /** Combobox context object */
  ctx: ComboboxCtx<any>;
  /** When true, max-height will be set on the menu to prevent overflow */
  fitHeight?: boolean;
  /** When true, include a suspense boundary configured with a loading
   * indicator and error boundary (similar to `MunSuspense`) so that the
   * `children` component(s) can use suspense-compatible data fetching */
  suspense?: boolean;
  /** Extra CSS classes to add to the `.dropdown-menu` element */
  className?: string;
  children: React.ReactNode;
}

/** Renders the menu element for a combobox. */
export const ComboboxMenu = ({
  ctx,
  fitHeight,
  suspense,
  className,
  children,
}: ComboboxMenuProps) => {
  const { isOpen, disabled, menuId, menuRef } = ctx;
  useFitHeight(fitHeight ? menuRef : undefined);

  if (!isOpen || disabled) return null;

  return (
    <ul
      className={classNames('dropdown-menu', className)}
      style={fitHeight ? overflowStyle : undefined}
      id={menuId}
      ref={menuRef}
      role="listbox"
    >
      {suspense ? (
        <React.Suspense
          fallback={
            <li>
              <Spinner />
            </li>
          }
        >
          <ErrorBoundary
            fallback={<li>{ErrorBoundary.defaultProps.fallback}</li>}
          >
            {children}
          </ErrorBoundary>
        </React.Suspense>
      ) : (
        children
      )}
    </ul>
  );
};
