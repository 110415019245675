var uuid = require('uuid/v4'),
    { extend, mixin } = require('@maternity/mun-extend'),
    tv = require('./travesty'),

    Invalid = require('./invalid').Invalid;


exports.Document = Document;
tv.SchemaObj.extend('tv.Document', Document, {
    field_types: {uid: tv.String(),
  }});
function Document(data) {
  if (!(this instanceof Document))
    return new Document(data);

  this.uid = data != null && data.uid != null ? data.uid : uuid();
  this._load(data);
}

Document.unloaded = function unloaded(uid) {
  if (uid == null)
    throw new Error('unloaded documents without uids will never amount to anything!');
  var self = Object.create(this.prototype);
  self.uid = uid;
  return self;
};

Document.extend = (function(base_extend) {
  return doc_extend;

  function doc_extend() {
    // Copy unloaded "ctormethod" when extending.
    return mixin(
      base_extend.apply(this, arguments), {
        extend: doc_extend,
        unloaded: this.unloaded,
      });
  }
})(Document.extend);

Object.defineProperty(Document.prototype,
    '$loaded', {enumerable: false, writable: true, value: false});

mixin(Document.prototype, {
    load: function load(data) {
      if (this.$loaded)
        throw new DoubleLoadError(this);
      this._load(data);
      return this;
    },

    _load: function _load(data) {
      var knownKeys = {},
          extraKeys = [];

      Object.defineProperty(this,
          '$loaded', {enumerable: false, writable: true, value: true});

      for (var key in this.constructor.field_types) {
        if (key !== 'uid')
          this[key] = data != null ? data[key] : undefined;

        knownKeys[key] = true;
      }

      if (data != null) {
        Object.keys(data)
          .forEach(function(key) {
            if (!knownKeys[key])
              extraKeys.push(key);
          });

          if (extraKeys.length > 0)
            throw new Invalid('unexpected_fields');
      }
    },
  });


exports.DoubleLoadError = DoubleLoadError;
function DoubleLoadError(doc) {
  this.name = 'DoubleLoadError';
  this.message = 'Trying to load a loaded document: '+(doc?.uid);
  if (typeof Error.captureStackTrace === 'function')
    Error.captureStackTrace(this, DoubleLoadError);
  else
    this.stack = Error().stack;
}
DoubleLoadError.prototype = extend(
  Error.prototype, {
    constructor: DoubleLoadError,
  });


tv.dictify.register(dictify_document, Document.marker);
function dictify_document(disp, doc, options) {
  var store = options?.doc_storage,
      result;

  // {doc_storage: null} is allowed, but it must be explicit
  if (typeof store === 'undefined')
    throw new Error('doc_storage must be specified');

  // Return a doc ref for untraversable documents
  if (!disp.get_path(['uid'], false))
    return doc.uid;

  if (!doc.$loaded)
    return doc.uid;

  if (store != null && doc.uid in store)
    return doc.uid;

  if (store != null)
    store[doc.uid] = null;

  result = disp.super(Document.marker).call(doc, options);

  return result;
}

tv.undictify.register(undictify_document, Document.marker);
function undictify_document(disp, value, options) {
  var uid,
      doctype,
      doc,
      data,
      docset = options?.in_docset;

  if (value == null || (typeof value !== 'string' && value.uid == null))
    throw new Invalid('type_error');

  // {in_docset: null} is allowed, but it must be explicit
  if (typeof docset === 'undefined')
    throw new Error('in_docset must be specified');

  uid = typeof value === 'string'
    ? value
    : value.uid;
  doctype = disp.marker.target_proto.constructor;
  doc = docset
    ? docset.get_or_create(doctype, uid)
    : doctype.unloaded(uid);

  if (typeof value !== 'string') {
    // Bypass ObjectMarker to use Schema methods
    data = disp.super(tv.ObjectMarker).call(value, options);

    // TODO: extra fields check

    doc.load(data);
  }

  return doc;
}

tv.traverse.register(traverse_document, Document.marker);
function traverse_document(disp, doc, options) {
  var superdisp = disp.super(Document.marker);

  if (!doc.$loaded)
    superdisp = superdisp.restrict(['uid']);

  return superdisp.call(doc, options);
}
