import * as React from 'react';

import { Label } from '../Label';
import { StaticControl } from '../StaticControl';

type LabelProps = React.ComponentProps<typeof Label>;
type StaticFieldProps = React.ComponentProps<typeof StaticControl> & {
  /** Label content. Can be text, JSX, or a function (see Label component). */
  label: NonNullable<LabelProps['children']>;
  /** Props to pass through to the Label component. */
  labelProps?: Omit<LabelProps, 'children'>;
};

/** Renders a static control (i.e. text) with a label. */
export const StaticField = ({
  label,
  labelProps,
  children,
  ...props
}: StaticFieldProps) => (
  <div className="form-group">
    <Label {...labelProps}>{label}</Label>
    <StaticControl {...props}>{children}</StaticControl>
  </div>
);
