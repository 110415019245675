import * as React from 'react';

import { useDebounceCallback } from './useDebounceCallback';

/**
 * Returns a debounced version of the input value- the return value will not
 * change until after `wait` milliseconds have elapsed since the last time the
 * input value changed.
 */
export const useDebounceValue = <T>(value: T, wait: number): T => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  const debouncedSetValue = useDebounceCallback(setDebouncedValue, wait);
  React.useEffect(() => debouncedSetValue(value), [debouncedSetValue, value]);
  return debouncedValue;
};
