var vg = require('@maternity/vertigo'),
    base = require('./base'),
    Wrapper = base.Wrapper,
    traverse = base.traverse,
    dictify = base.dictify,
    undictify = base.undictify,

    Optional = exports.Optional = Wrapper.sub('tv.Optional');


traverse.register(traverse_optional, Optional);
function traverse_optional(disp, value, options) {
  var zipgraph = options?.zipgraph,
      opt;

  if (value == null)
    return new vg.PlainGraphNode(zipgraph != null ? [null, zipgraph.value] : null);

  opt = disp.marker;

  return disp.for_marker(opt.marker).call(value, options);
}

dictify.register(dictify_optional, Optional);
undictify.register(dictify_optional, Optional);
function dictify_optional(disp, value, options) {
  if (value == null)
    return null;

  var opt = disp.marker;

  return disp.for_marker(opt.marker).call(value, options);
}
