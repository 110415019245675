import * as React from 'react';

type HtmlAnchorProps = React.ComponentProps<'a'>;

/**
 * Renders an anchor element.
 *
 * Sets rel="noopener noreferrer" on links with a target to prevent the
 * destination from changing `window.location`. See
 * https://mathiasbynens.github.io/rel-noopener/ for a more detailed
 * explanation and demo.
 */
export const Anchor = ({ rel, ...props }: HtmlAnchorProps) => (
  // eslint-disable-next-line react/forbid-elements
  <a rel={props.target ? `noopener noreferrer ${rel || ''}` : rel} {...props} />
);
