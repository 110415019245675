var iter = require('@maternity/mun-itertools'),
    vg = require('@maternity/vertigo'),
    tv = require('./travesty'),
    base = require('./base'),
    to_typegraph = base.to_typegraph;


var Schema = exports.Schema = base.Marker.sub('tv.Schema');
Schema.prototype.of = function(children) {
  var edges;

  if (Array.isArray(children)) {
    edges = children;

  } else if (children != null) {
    edges = [];
    for (var k in children) {
      if (children[k] != null)
        edges.push({key: k, node: to_typegraph.call(children[k])});
    }
  }

  return new vg.PlainGraphNode(this, edges);
};


base.traverse.register(traverse_schema, Schema);
function traverse_schema(disp, value, options) {
  var zipgraph = options?.zipgraph,
      suboptions = zipgraph != null ? Object.create(options) : options,
      edges = iter.map(disp.edge_iter(), function traverse_schema_edge(edge) {
        if (zipgraph != null)
          suboptions.zipgraph = zipgraph.get_child(edge.key);

        return {
          key: edge.key,
          node: edge.node.call(value[edge.key], suboptions),
        };
      }),
      v = zipgraph != null ? [value, zipgraph.value] : value;

  return new vg.PlainGraphNode(v, iter.toArray(edges));
}

base.dictify.register(dictify_schema, Schema);
function dictify_schema(disp, value, options) {
  var agg = new tv.InvalidAggregator();

  try {
    return iter.reduce(disp.edge_iter(), function dictify_schema_edge(acc, edge) {
        acc[edge.key] = agg.checking_sub(edge.key,
            edge.node.call.bind(edge.node, value[edge.key], options));
        return acc;
      }, {});

  } finally {
    agg.raise_if_any();
  }
}

base.undictify.register(undictify_schema, Schema);
function undictify_schema(disp, value, options) {
  var agg = new tv.InvalidAggregator();

  try {
    return iter.reduce(disp.edge_iter(), function undictify_schema_edge(acc, edge) {
        acc[edge.key] = agg.checking_sub(edge.key,
            edge.node.call.bind(edge.node, value[edge.key], options));
        return acc;
      }, {});

  } finally {
    agg.raise_if_any();
  }
}
