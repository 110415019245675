import { useMunSession } from '@maternity/mun-session';

import * as role_registry from './role_registry';

/** Returns true if the current user is the practice owner */
export const useIsPracticeOwner = (): boolean => {
  const { person, practice } = useMunSession();
  return !!(person && practice && person.uid === practice.owner.uid);
};

/** Returns true if the current user is a care provider */
export const useIsCareProvider = (): boolean => {
  const { person } = useMunSession();
  return !!person?.user_active && person.type === 'care_provider';
};

/** Returns true if the current user is a staff member */
export const useIsStaff = (): boolean => {
  const { person } = useMunSession();
  return !!person?.active && person.type !== 'non-member';
};

export const useIsPracticeManager = () => {
  const { person } = useMunSession();
  if (!person) return false;
  return role_registry.has_role('kerbin:practice_manager', person);
};

/** Returns true if the current user is a client */
export const useIsClient = (): boolean => !!useMunSession().client;

/** Returns true if client CSVs can be downloaded */
export const useCanDownloadClientCSV = (): boolean => {
  const { person, practice } = useMunSession();
  return (
    !!practice?.$loaded &&
    !!person?.$loaded &&
    (!practice.features.limit_client_csvs_to_owner || practice.owner === person)
  );
};
