import * as React from 'react';

import { Label } from '../Label';
import { StaticControl } from '../StaticControl';
import { withCols } from './utils';

type LabelProps = React.ComponentProps<typeof Label>;
type StaticFieldProps = React.ComponentProps<typeof StaticControl> & {
  /** Label content. Can be text, JSX, or a function (see Label component). */
  label: NonNullable<LabelProps['children']>;
  /** Props to pass through to the Label component. */
  labelProps?: Omit<LabelProps, 'children'>;
  /** Props to pass through to the div wrapper. */
  divProps?: React.ComponentProps<'div'>;
};

/** Renders a static control (i.e. text) with a label. */
export const StaticField = withCols(
  ({ label, labelProps, divProps, children, ...props }: StaticFieldProps) => (
    <div className="form-group">
      <Label {...labelProps}>{label}</Label>
      <div {...divProps}>
        <StaticControl {...props}>{children}</StaticControl>
      </div>
    </div>
  ),
);
