// TODO: inherits would extend function prototype and set constructor
// TODO: callable would return a method bound to an object with other methods attached to it

export function extend<T extends object, U extends object>(
  proto: T,
  ...mixins: U[]
): T & U {
  return Object.create(
    proto,
    mixins.reduce((d, props) => getOwnPropertyDescriptors(props, d), {}),
  );
}

export function mixin<T extends object, U extends object>(
  proto: T,
  ...mixins: U[]
): T & U {
  const props: PropertyDescriptorMap = mixins.reduce(
    (d, props_) => getOwnPropertyDescriptors(props_, d),
    {},
  );

  // In Safari, you can't define a new name for a function, so don't even try.
  if (typeof proto === 'function') {
    delete props.name;
  }

  return Object.defineProperties(proto as T & U, props);
}

export function attrChain<T, K extends keyof T>(name: K, val: T): Array<T[K]> {
  // Collect all values of an attribute through an object's prototype chain.
  const a = [];

  while (val != null) {
    if (Object.hasOwnProperty.call(val, name)) {
      a.push(val[name]);
    }
    val = Object.getPrototypeOf(val);
  }

  return a;
}

function getOwnPropertyDescriptors(
  props?: object,
  d?: any,
): PropertyDescriptorMap {
  // Get a mapping of name -> property descriptors suitable for use with Object.create() or
  // Object.defineProperties().
  if (props == null) {
    return d || {};
  }
  return Object.getOwnPropertyNames(props).reduce((d_, k) => {
    d_[k] = Object.getOwnPropertyDescriptor(props, k);
    return d_;
  }, d || {});
}
