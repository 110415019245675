import classNames from 'classnames';
import * as React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

/**
 * Renders a panel body. Not necessary for tables and list groups.
 */
export const PanelBody = ({ className, children }: Props) => (
  <div className={classNames('panel-body', className)}>{children}</div>
);
