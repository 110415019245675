import { CheckboxGroup } from '../CheckboxGroup';
import { DatePicker } from '../DatePicker';
import { generateId } from '../id';
import { EmailInput, PasswordInput, TextInput } from '../Input';
import { NumberInput } from '../NumberInput';
import { RadioGroup } from '../RadioGroup';
import { Select } from '../Select';
import { SingleCheckbox } from '../SingleCheckbox';
import { TextArea } from '../TextArea';
import { TimePicker } from '../TimePicker';
import { asField } from './asField';

/** Renders a text input with a label and error messages. */
export const TextField = asField(TextInput);

/** Renders a password input with a label and error messages. */
export const PasswordField = asField(PasswordInput);

/** Renders a number input with a label and error messages. */
export const NumberField = asField(NumberInput);

/** Renders a email input with a label and error messages. */
export const EmailField = asField(EmailInput);

/** Renders a text area with a label and error messages. */
export const TextAreaField = asField(TextArea);

/** Renders a group of radio buttons with a label and error messages. */
export const RadioGroupField = asField(RadioGroup);

/** Renders a single checkbox with a label and error messages. */
export const SingleCheckboxField = asField(SingleCheckbox);

/** Renders a group of checkboxes with a label and error messages. */
export const CheckboxGroupField = asField(CheckboxGroup);

/** Renders a select with a label and error messages. */
export const SelectField = asField(Select);

/** Renders a date picker with a label and error messages. */
// Override default id since date and time may use the same model
export const DateField = asField(DatePicker, (model) =>
  generateId(`${model}.date`),
);

/** Renders a time picker with a label and error messages. */
export const TimeField = asField(TimePicker, (model) =>
  generateId(`${model}.time`),
);
