import * as React from 'react';

import { Anchor } from './Anchor';

type Fragment =
  | { type: 'text'; text: string }
  | { type: 'link'; text: string; url: string };

// Link extraction was adapted from angular-sanitize's linky filter
const urlRegex =
  /((s?ftp|https?):\/\/|(www\.)|(mailto:)?[A-Za-z0-9._%+-]+@)\S*[^\s.;,(){}<>"\u201d\u2019]/i;
const mailtoRegex = /^mailto:/i;
const extractLinks = (text: string): Fragment[] => {
  const fragments: Fragment[] = [];
  let raw = text;
  let match;
  while ((match = raw.match(urlRegex))) {
    let url = match[0];
    // If didn't match ftp/http/mailto, need to add a protocol to the url
    if (!match[2] && !match[4]) {
      url = (match[3] ? 'http://' : 'mailto:') + url;
    }
    const prevText = raw.slice(0, match.index);
    if (prevText) fragments.push({ type: 'text', text: prevText });
    fragments.push({
      type: 'link',
      // Drop "mailto" prefix if present
      text: match[0].replace(mailtoRegex, ''),
      url,
    });
    raw = raw.slice(match.index! + match[0].length);
  }
  if (raw) fragments.push({ type: 'text', text: raw });
  return fragments;
};

interface LinkifyProps {
  /** Input text to check for links. */
  text: string;
  /** The `target` attribute to pass to links. Defaults to "_blank". */
  target?: string;
}

/**
 * Finds `http/https/www/ftp/sftp/mailto` links and email addresses in the
 * input text and turns them into clickable html links.
 */
export const Linkify = React.memo<LinkifyProps>(
  ({ text, target = '_blank' }) => (
    <React.Fragment>
      {extractLinks(text).map((fragment, idx) =>
        fragment.type === 'text' ? (
          fragment.text
        ) : (
          <Anchor key={idx} href={fragment.url} target={target}>
            {fragment.text}
          </Anchor>
        ),
      )}
    </React.Fragment>
  ),
);
