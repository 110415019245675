import _ from 'lodash';

import ngModule from '../kerbin-core-module';

ngModule

  .config(function($stateTransitionProvider) {
    $stateTransitionProvider
      .transition('*', 'top.*', {
        before: checkOriginalURL,
        between: checkChores,
      });

    /* @ngInject */
    function checkOriginalURL($from, $to, $transition, $state, munSession,
        kerbinChores) {
      var choreDesc = munSession.login_chores[0],
          chore = choreDesc && kerbinChores.chores[choreDesc.key],
          to = $to.$fullname.slice(6);

      // Goto intended state if allowed
      if (chore && chore.isAllowed(to))
        return;

      // Cancel transition if the state change is a result of changing the
      // location to the original URL
      try {
        if (kerbinChores.originalURL === $state.url(to, $to.$params.$all)) {
          $transition.cancel();
        }
      }
      catch(err) {}
    }

    /* @ngInject */
    function checkChores($from, $to, $transition, munSession, kerbinChores,
        $location, $state) {
      var choreDesc = munSession.login_chores[0],
          chore = choreDesc && kerbinChores.chores[choreDesc.key],
          to = $to.$fullname.slice(6);

      if (!choreDesc) {
        if (kerbinChores.choreInProgress)
          kerbinChores.reset();
        return;
      }

      if (!kerbinChores.chores[choreDesc.key])
        throw new Error("Unknown chore: "+choreDesc.key);

      if (to === chore.state)
        return;

      if (kerbinChores.originalURL) {
        $location.url(kerbinChores.originalURL).replace();
      } else {
        try {
          kerbinChores.originalURL = $state.url(to, $to.$params.$all);
        } catch {
          // If unable to calculate an URL (e.g. state not found), just store
          // the current URL
          kerbinChores.originalURL = $location.url();
        }
      }

      // Allow specific states
      if (chore.isAllowed(to))
        return;

      kerbinChores.choreInProgress = choreDesc;

      $transition.goto(chore.state, {chore: choreDesc});
    }

  })

  .provider('kerbinChores', function() {
    var chores = {},
        properties = {
          chores: chores,
          originalURL: null,
          choreInProgress: null,
        };

    this.register = register;
    this.$get = get;

    function register(name, chore) {
      chores[name] = new Chore(chore);
      return this;
    }

    function get() {
      return properties;
    }

    function Chore(args) {
        this.state = null;
        this.allowed_states = [];
        _.assign(this, args);
    }

    Chore.prototype.isAllowed = function(state) {
      return this.allowed_states.indexOf(state) !== -1;
    };

    Chore.prototype.reset = function(state) {
      this.originalURL = null;
      this.choreInProgress = null;
    };

  })

  ;
