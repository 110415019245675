// eslint-disable-next-line import/no-extraneous-dependencies
import angular from 'angular';

// TODO: Consider using separate functions for sync/async cases
type UnloadHandler<S extends boolean = boolean> = (
  sync?: S,
) => S extends true ? boolean : Promise<unknown>;

// Remove an item from an array (via mutation)
const remove = <T>(arr: T[], val: T): void => {
  const idx = arr.indexOf(val);
  if (idx !== -1) arr.splice(idx, 1);
};

/**
 * Manages an "unloadable scope" (e.g. window, view, modal) whose contents may
 * want to block unloading.
 */
export class UnloadManager {
  // TODO: Remove element args and tracking once not using angular
  args: Array<{ el: JQuery; handler: UnloadHandler }> = [];
  handlers: UnloadHandler[] = [];
  unsubscribeFns: Array<() => void> = [];
  el?: JQuery;

  constructor(readonly parent?: UnloadManager) {}

  /*
   * Bind the manager to an element that will be passed to the parent
   */
  setEl(el?: JQuery | HTMLElement) {
    const element = el && angular.element(el);
    const { parent } = this;
    if (parent) {
      // Re-register handlers with parent when `el` changes
      this.unsubscribeFns.forEach((deregister) => deregister());
      this.unsubscribeFns = this.args.map((args) =>
        parent.subscribe(element || args.el, args.handler),
      );
    }
    this.el = element;
  }

  /**
   * Register a handler that will be called when the scope (or ancestor scope)
   * is preparing to unload. Returns an unsubscribe function.
   */
  subscribe(el: JQuery, handler: UnloadHandler): () => void {
    const args = { el, handler };
    this.args.push(args);
    this.handlers.push(handler);

    const unsubscribeParent = this.parent
      ? this.parent.subscribe(this.el ? this.el : el, handler)
      : null;
    if (unsubscribeParent) this.unsubscribeFns.push(unsubscribeParent);

    return () => {
      remove(this.args, args);
      remove(this.handlers, handler);
      if (unsubscribeParent) {
        remove(this.unsubscribeFns, unsubscribeParent);
        unsubscribeParent();
      }
    };
  }

  destroy() {
    this.unsubscribeFns.forEach((deregister) => deregister());
  }

  /**
   * Synchronously check if it is safe to unload the manager's scope
   */
  checkSync(): boolean {
    return this.handlers.every((handler) => handler(true));
  }

  /**
   * Asynchronously check if it is safe to unload the manager's scope
   */
  checkAsync(): Promise<unknown> {
    return Promise.all(this.handlers.map((handler) => handler(false)));
  }
}

/**
 * Unload manager variant that is specific to our angular app
 */
export class AngularUnloadManager extends UnloadManager {
  nameHandlers: Record<string, UnloadHandler[]> = {};

  override subscribe(el: JQuery, handler: UnloadHandler) {
    const args = { el, handler };
    this.args.push(args);

    const ancestor = el.parents('[mun-modal], [jem-view]');
    let name: string;
    let unsubscribeModal = () => {};
    if (ancestor.attr('mun-modal') !== undefined) {
      const scope = ancestor.scope();
      name = scope.$modalId;
      unsubscribeModal = scope.$on(`${name}.hide.requested`, (evt, register) =>
        register(handler(false)),
      );
      this.unsubscribeFns.push(unsubscribeModal);
    } else {
      name = ancestor.attr('jem-view') as string;
    }

    this.handlers.push(handler);
    if (!this.nameHandlers[name]) this.nameHandlers[name] = [];
    this.nameHandlers[name].push(handler);

    let unsubscribeParent = () => {};
    if (this.parent) {
      unsubscribeParent = this.parent.subscribe(el, handler);
      this.unsubscribeFns.push(unsubscribeParent);
    }

    return () => {
      remove(this.args, args);
      remove(this.handlers, handler);
      remove(this.nameHandlers[name], handler);
      remove(this.unsubscribeFns, unsubscribeModal);
      unsubscribeModal();
      remove(this.unsubscribeFns, unsubscribeParent);
      unsubscribeParent();
    };
  }

  checkNameAsync(name: string): Promise<unknown> {
    const handlers = this.nameHandlers[name] || [];
    return Promise.all(handlers.map((handler) => handler(false)));
  }
}

export const attachToWindow = (
  manager: UnloadManager,
  message = 'You have unsaved changes.',
): (() => void) => {
  // TODO: warn if called multiple times without detaching?
  const beforeUnloadHandler = (evt: BeforeUnloadEvent) => {
    const allowUnload = manager.checkSync();

    if (!allowUnload) {
      evt.returnValue = message;
      return message;
    }
  };
  window.addEventListener('beforeunload', beforeUnloadHandler);

  return () => {
    window.removeEventListener('beforeunload', beforeUnloadHandler);
  };
};

// Default global unload manager
export const globalManager = new AngularUnloadManager();
