/*
travesty - not actually travesty

This oddly-named module defines several mun-doc features related to document
caching. It's named travesty because all the exposed providers are travesty-
related.

Useful objects:

munDocTV - a wrapper around various travesty tools. Look here for the app-wide
  defaults for .dictify, .copy, etc.
munDocCache - a global DocCache
munDocKeepalive - Scope-based keepalive

The first two are pretty self-explanatory.

munDocKeepalive is a callable that calls keepalive() with the global DocCache
and some stats tracking helpers. It also exposes several helper functions:

.forScope(scope, obj, restore) - invoke munDocKeepalive(obj, restore), but stop
  it and clean up when scope is destroyed
.updatingForScope(scope, restore) - returns a callable that can be used to start
  a .forScope keepalive on any object; subsequent calls to the callable will
  cancel the keepalive monitoring for previous calls. Basically this is like
  forScope except that you can change the target of the keepalive by calling it
  again.
.invalidation_monitor - the specific invalidation_monitor that we actually use

Typically the way you use this is:

1. In a config block, you set up any invalidation_monitor handlers for your
  endpoints. The listeners you set up in these handlers will ensure that your
  endpoint response is marked as invalidated whenever the server tells you that
  it will be.
2. In components that load data, you set up an updatingForScope on your scope
  and call it on the result every time you fetch new data; this ensures that
  every time the invalidation monitor detects that your endpoint response is
  stale, new data will immediately be fetched.
*/

import tv from '@maternity/travesty';

import DocCache from './doccache';
import copy from './copy';
import copy_or_create from './copy-or-create';
import { Counters } from './counters';
import create from './create';
import {
  EventSet,
  invalidation_monitor as base_invalidation_monitor,
} from './doccacheblaster';
import doccache_verify from './doccacheverify';
import keepalive_ from './keepalive';

import ngModule from './mun-doc-module';


ngModule

  .factory('munDocTV', function(munDocCache) {
    DocSet.prototype = tv.DocSet.prototype;
    function DocSet(options) {
      options = options || {};
      options.uidgen = options.uidgen || munDocCache.uidgen;
      tv.DocSet.call(this, options);
    }

    return {
      // TODO: extend as needed
      dictify: tv.dictify.sub(),
      undictify: tv.undictify.sub(),

      doccache_verify,
      copy,
      create,
      copy_or_create,
      DocSet,
    };
  })

  .factory('munDocKeepalive', function(munDocCache) {
    const invalidation_monitor = base_invalidation_monitor.sub();
    const stats = keepalive.stats = new (Counters.define({
          subCounters: {
            invalidationMonitors: new (Counters.define({
              counts: {
                started: 0,
                stopped: 0,
              },
              getTheseStats: function() {
                return ''+this.counts.stopped+'/'+this.counts.started+' monitors completed';
              },
            }))(),

            alive: new (Counters.define({
              counts: {
                started: 0,
                stopped: 0,
              },
              getTheseStats: function() {
                return ''+this.counts.stopped+'/'+this.counts.started+' keepalives completed';
              },
            }))(),
          }}))('stats');

    keepalive.forScope = keepaliveForScope;
    keepalive.updatingForScope = keepaliveUpdatingForScope;

    // This can be extended to add custom logic for monitoring specialized types.
    keepalive.invalidation_monitor = invalidation_monitor;
    // Make the invalidation monitor use munDocCache by default.
    invalidation_monitor.call = (function(orig) {
        return call;

        function call(obj, options) {
          options = options || {};
          options.doccache = options.doccache || munDocCache;
          options.stats_start = invalidationMonitorStatsStart;
          return orig.call(this, obj, options);
        }
      })(invalidation_monitor.call);

    // I'll just stick this here....
    invalidation_monitor.EventSet = EventSet;

    munDocCache.on('load', function(type, uid) {
        var doc = munDocCache.get(type, uid);

        invalidation_monitor.call(doc);
      });

    return keepalive;

    function keepalive(obj, restore) {
      return keepalive_(obj, restore, {
        doccache: munDocCache,
        stats_start: keepaliveStatsStart,
      });
    }

    function keepaliveForScope(scope, obj, restore) {
      var cancel = keepalive(obj, restore);

      scope.$on('$destroy', cancel);

      return obj;
    }

    function keepaliveUpdatingForScope(scope, restore) {
      var cancel;

      return updateKeepalive;

      function updateKeepalive(obj) {
        if (cancel) {
          // Cancel previous keepalive
          cancel();
        } else {
          // On first call, hook up destroy listener
          scope.$on('$destroy', function() {
            // Use closure to cancel last keepalive
            cancel();
          });
        }

        cancel = keepalive(obj, restore);

        return obj;
      }
    }

    function invalidationMonitorStatsStart(type, hint) {
      var d = stats.get('invalidationMonitors', type, hint),
          stopped;

      d.counts.started++;
      return stop;

      function stop() {
        if (stopped)
          return;
        stopped = true;
        d.counts.stopped++;
      }
    }

    function keepaliveStatsStart(type) {
      var d = stats.get('alive', type),
          stopped;

      d.counts.started++;
      return stop;

      function stop() {
        if (stopped)
          return;
        stopped = true;
        d.counts.stopped++;
      }
    }
  })

  .factory('munDocCache', function() {
    uidgen.counter = -100000;
    return new DocCache({uidgen: uidgen});

    function uidgen() {
      return String(--uidgen.counter);
    }
  })

  .config(function($provide) {
    $provide.decorator('munDocTV', ['$delegate', function($delegate) {
        $delegate.undictify.register(undictify_document, tv.Document.marker);
        return $delegate;

        function undictify_document(disp, value, options) {
          var type = disp.marker.target_proto.constructor,
              docset = options.in_docset,
              doccache = docset?.emit && docset,
              was_loaded;

          if (value != null && value.uid != null &&
              options && options.allow_updates &&
              docset.is_loaded(type, value.uid)) {
            // Reset the $loaded switch, don't worry about clean up it's all getting clobbered.
            docset.get(type, value.uid).$loaded = false;
            was_loaded = true;
          }

          // Now back to our regularly scheduled document undictification adventure programming.
          var doc = disp.parent($delegate.undictify).call(value, options);

          if (value.uid && doc.$loaded && doccache) {
            // HACK: The order here is reversed to work around a problem where an invalidation
            // monitor dispatched by the load event handler immediately cancels itself on seeing
            // the load+type+uid dispatched event.
            //
            // I think the fix is to use an event emitter that can dispatch a single event to these
            // different qualifiers while also avoiding dispatching to event handlers that were not
            // present at the time of the emit call.
            //
            // I also considered addressing this by having the invalidation monitors defer their
            // set up, but I'm pretty sure that would make the lizard people angry.

            doccache.emit(['load',type,doc.uid]);
            doccache.emit(['load',type], doc.uid);
            doccache.emit(['load'], type, doc.uid);

            var stats = doccache.stats.get(type),
                eventsStats = stats.get('events');

            eventsStats.counts.load++;

            if (!was_loaded) {
              var docsStats = stats.get('docs');
              docsStats.counts.unloaded--;
              docsStats.counts.loaded++;
            }
          }

          return doc;
        }
      }]);
  })

  ;
