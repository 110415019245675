import * as React from 'react';

import { Row } from '../grid';
import { TileContext, TileContextType } from './TileContext';

type TileContainerProps = TileContextType & { children: React.ReactNode };

/**
 * A container for `Tile`s that passes down the given sizes for different
 * breakpoints.
 */
export const TileContainer = ({
  xs,
  sm,
  md,
  lg,
  children,
}: TileContainerProps) => {
  const ctx = React.useMemo(() => ({ xs, sm, md, lg }), [xs, sm, md, lg]);

  return (
    <Row className="settings-nav">
      <TileContext.Provider value={ctx}>{children}</TileContext.Provider>
    </Row>
  );
};
