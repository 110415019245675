import { Overwrite } from '@maternity/mun-types';
import classNames from 'classnames';
import * as React from 'react';

type Props = Overwrite<
  React.ComponentProps<'label'>,
  {
    /* If the field is required, an indicator will be added to the label. */
    required?: boolean;
    /* By default, the "required" indicator will be appended to the children.
     * For alternative placement, `children` can be a function that accepts the
     * indicator markup.
     */
    children?:
      | React.ReactNode
      | ((required: React.ReactNode) => React.ReactNode);
  }
>;

/**
 * Renders a `<label>` element.
 */
export const Label = ({ children, className, required, ...props }: Props) => {
  const reqIndicator = required && <span className="required-field">*</span>;
  return (
    <label className={classNames(className, 'control-label')} {...props}>
      {typeof children === 'function' ? (
        children(reqIndicator)
      ) : (
        <React.Fragment>
          {children}
          {reqIndicator}
        </React.Fragment>
      )}
    </label>
  );
};
