/**
 * These components implement the Bootstrap 3 grid system as <Row> and <Col>
 * React components. https://getbootstrap.com/docs/3.4/css/#grid
 *
 * Example:
 * ```
 * <Row>
 *   <Col md={8}>.col-md-8</Col>
 *   <Col md={4}>.col-md-4</Col>
 * </Row>
 * ```
 *
 * Components are not provided for the `.container` and `.container-fluid`
 * classes because they should not be nested and generally only appear once on
 * the page.
 */
import classNames from 'classnames';
import * as React from 'react';
// TODO: Add explicit dependency on Bootstrap

type DivProps = React.ComponentProps<'div'>;

/**
 * A grid row that can contain columns.
 */
export const Row = ({ children, className, ...props }: DivProps) => (
  <div className={classNames('row', className)} {...props}>
    {children}
  </div>
);

type ColumnWidth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type OffsetWidth = 0 | ColumnWidth;
interface BreakpointConfig {
  xs?: ColumnWidth;
  xsOffset?: OffsetWidth;
  xsPull?: OffsetWidth;
  xsPush?: OffsetWidth;
  sm?: ColumnWidth;
  smOffset?: OffsetWidth;
  smPull?: OffsetWidth;
  smPush?: OffsetWidth;
  md?: ColumnWidth;
  mdOffset?: OffsetWidth;
  mdPull?: OffsetWidth;
  mdPush?: OffsetWidth;
  lg?: ColumnWidth;
  lgOffset?: OffsetWidth;
  lgPull?: OffsetWidth;
  lgPush?: OffsetWidth;
}

const buildBreakpointClasses = (
  bp: string,
  col?: ColumnWidth,
  offset?: OffsetWidth,
  pull?: OffsetWidth,
  push?: OffsetWidth,
): Array<string | null> => [
  col ? `col-${bp}-${col}` : null,
  offset ? `col-${bp}-offset-${offset}` : null,
  pull ? `col-${bp}-pull-${pull}` : null,
  push ? `col-${bp}-push-${push}` : null,
];

/**
 * A grid column; span, offset, push, and pull can be specified for each of the
 * breakpoints (xs, xm, md, and lg).
 */
export const Col = ({
  xs,
  xsOffset,
  xsPull,
  xsPush,
  sm,
  smOffset,
  smPull,
  smPush,
  md,
  mdOffset,
  mdPull,
  mdPush,
  lg,
  lgOffset,
  lgPull,
  lgPush,
  children,
  className,
  ...props
}: BreakpointConfig & DivProps) => {
  const xsClasses = buildBreakpointClasses('xs', xs, xsOffset, xsPull, xsPush);
  const smClasses = buildBreakpointClasses('sm', sm, smOffset, smPull, smPush);
  const mdClasses = buildBreakpointClasses('md', md, mdOffset, mdPull, mdPush);
  const lgClasses = buildBreakpointClasses('lg', lg, lgOffset, lgPull, lgPush);
  return (
    <div
      className={classNames(
        xsClasses,
        smClasses,
        mdClasses,
        lgClasses,
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
