import { injector } from '@maternity/ng-mun-linear';
import * as React from 'react';

import { Anchor } from './Anchor';
import { IScope, IState, SrefProps } from './types';

type HtmlAnchorProps = Omit<React.ComponentProps<'a'>, 'href'>;
type HtmlButtonProps = React.ComponentProps<'button'>;

type AnchorLinkProps = HtmlAnchorProps & SrefProps;
type ButtonLinkProps = HtmlButtonProps & SrefProps;
type LinkProps =
  | ({ tag?: 'a' } & AnchorLinkProps)
  | ({ tag: 'button' } & ButtonLinkProps);

const AnchorLink = ({ sref, params, ...anchorProps }: AnchorLinkProps) => {
  const $rootScope = injector.get('$rootScope') as IScope;
  const $state = injector.get('$state') as IState;
  const [href, setHref] = React.useState('#');

  // The effect will recalculate the url if sref or params change
  React.useEffect(() => {
    const updateHref = () => {
      try {
        setHref($state.url(sref, params));
      } catch (e) {
        // If the url can't be calculated, don't go anywhere
        // TODO: Log errors?
        setHref('#');
      }
    };
    // Calculate current url
    updateHref();
    // Subscribe to $state changes
    // (The type assertion is due to `useEffect` requiring the unsubscribe
    // function to return void.)
    return $rootScope.$on('$stateChangeSuccess', updateHref) as any;
  }, [$rootScope, $state, sref, params]);

  // Angular's $location service detects clicks on anchor elements within the
  // app's root element and handles navigation, so a click handler is not
  // necessary here.
  return <Anchor {...anchorProps} href={href} />;
};

const ButtonLink = ({
  sref,
  params,
  onClick,
  ...buttonProps
}: ButtonLinkProps) => {
  const $state = injector.get('$state') as IState;
  return (
    <button
      // This default type can be overriden by passing type as a prop
      type="button"
      {...buttonProps}
      onClick={(event) => {
        if (onClick) onClick(event);
        if (event.defaultPrevented) return;
        $state.goto(sref, params);
      }}
    />
  );
};

/**
 * Renders an anchor or button tag linking to a state in the angular app.
 *
 * Links to states without an url must use `tag="button"`.
 *
 * Example:
 * ```
 * <Link
 *   sref="top.episode"
 *   params={{ client: '123', episode: '456' }}
 *   className="btn btn-link"
 * >
 *   View episode
 * </Link>
 * ```
 */
export const Link = ({ tag = 'a', ...props }: LinkProps) => {
  const Component = tag === 'a' ? AnchorLink : ButtonLink;
  // Since `tag` is extracted as a separate variable, the type of `props`
  // doesn't get narrowed and a type assertion is required.
  return <Component {...(props as any)} />;
};
