import * as React from 'react';

import { FormApi, FormState } from './types';
import { useFormState } from './useFormState';
import { getIn } from './utils';
import { Validator } from './validation';

interface FieldConfig {
  /** Form API handle */
  form: FormApi<any>;
  /** Model path */
  model: string;
  /** Optional (memoized) list of validators */
  validators?: Validator[];
}

// The field API just partially applies some methods from the form API, but the
// type of `setValue` helps avoid type casts.
interface FieldApi<T> {
  /**
   * Set the value of the field. Generally called in `onChange` handlers.
   */
  setValue: (value: T | ((prev: T | undefined) => T)) => void;
  /**
   * Set the touched state of the field. Generally called in `onBlur` handlers.
   */
  setTouched: (touched?: boolean) => void;
}

// Use constant for default value to avoid breaking memoization
const NO_VALIDATORS: Validator[] = [];

/**
 * Registers a form field, returning the field's value and some helper methods.
 *
 * The type param should be used to specify the field value type. We forego
 * some type safety here and don't check that `form` and `model` are compatible
 * with `T` since field components should be verifying that. (We often can't
 * easily get Typescript to narrow the type down to the appropriate type, so we
 * just require field components to specify `T`.)
 */
export const useField = <T>({
  form,
  model,
  validators = NO_VALIDATORS,
}: FieldConfig): [T | undefined, FieldApi<T>] => {
  const selector = React.useCallback(
    (state: FormState<any>) => getIn(state.values, model),
    [model],
  );
  const value = useFormState(form, selector);

  const api = React.useMemo<FieldApi<T>>(
    () => ({
      setValue: (v) => form.setFieldValue(model, v),
      setTouched: (t) => form.setFieldTouched(model, t),
    }),
    [form, model],
  );

  React.useEffect(
    () =>
      form.registerField({
        model,
        validators,
      }),
    [form, model, validators],
  );

  return [value, api];
};
