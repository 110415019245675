import classNames from 'classnames';
import * as React from 'react';

interface Props {
  variant: 'success' | 'info' | 'warning' | 'danger';
  className?: string;
  children: React.ReactNode;
}

/**
 * Renders a static alert message.
 */
export const Alert = ({ variant, className, children }: Props) => (
  <div className={classNames(`alert alert-${variant}`, className)} role="alert">
    {children}
  </div>
);
