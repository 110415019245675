// Provide a pager state manager.
import routingModule from '@maternity/vendor-dotjem-routing';
import { Paginator } from '@maternity/mun-layout';
import { react2angular } from 'react2angular';

const ngModule = angular.module('mun-pager', [routingModule])
  // Pagination controls UI
  .component('munPaginator', react2angular(Paginator, ['pager']))

  ;

export default ngModule.name;
