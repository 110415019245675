import { DocsAsRefs, Path } from '@maternity/mun-types';
import classNames from 'classnames';
import * as React from 'react';

import {
  BaseFieldProps,
  EnforceModelType,
  EnforceRequired,
  FieldValues,
} from './types';
import { useField } from './useField';
import { requiredNonNullableValidator } from './validators';

interface OptionConfig<T> {
  label: string;
  value: T;
  disabled?: boolean;
}
type OptionsType<T> = Array<
  (T extends string ? string : never) | OptionConfig<T>
>;

const normalizeOptions = <T extends any>(
  options: OptionsType<T>,
): Array<OptionConfig<T>> =>
  options.map((opt) => {
    if (typeof opt === 'string') {
      return { label: opt, value: opt as T };
    }
    return opt;
  });

type SelectProps<V extends FieldValues, M extends Path<V>, T> = Omit<
  React.ComponentProps<'select'>,
  | 'form'
  | 'name'
  | 'value'
  | 'defaultValue'
  | 'required'
  | 'multiple'
  | 'children'
  | 'onChange'
> & {
  /** The list of options. When the value type includes strings, strings are
   * permitted, which will be used as both the label and value. Otherwise,
   * options may be specified as objects with the label, value, and optional
   * disabled state. */
  options: OptionsType<T>;
  /** Called when the value changes. The new value is passed as the second
   * argument. */
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>, value: T) => void;
} & BaseFieldProps<V, M> &
  // Convert any documents in `T` to references since the extra properties
  // would cause a type error when the model type is a document reference.
  EnforceModelType<V, M, DocsAsRefs<T>> &
  EnforceRequired<V, M>;

// Use constant for default value to avoid breaking memoization
const NO_VALIDATORS: any[] = [];

/**
 * Renders a `<select>` element drop down menu with the given options.
 *
 * Does not currently support multiple selection or `<optgroup>`.
 */
export const Select = <V extends FieldValues, M extends Path<V>, T = string>({
  form,
  model,
  validators = NO_VALIDATORS,
  required,
  options,
  className,
  onChange,
  onBlur,
  ...props
}: SelectProps<V, M, T>) => {
  validators = React.useMemo(
    () =>
      required ? [requiredNonNullableValidator, ...validators] : validators,
    [validators, required],
  );
  const [value, api] = useField<T>({ form, model, validators });
  const optionConfigs = React.useMemo(
    () => normalizeOptions(options),
    [options],
  );
  const valueIdx = optionConfigs.findIndex((c) => c.value === value);
  // Include a blank <option> if the model value isn't in the options
  const unknownOption = valueIdx === -1 ? <option key={-1} value={-1} /> : null;
  const optionEls = React.useMemo(
    () =>
      optionConfigs.map(({ label, disabled }, idx) => (
        <option key={idx} value={idx} disabled={disabled}>
          {label}
        </option>
      )),
    [optionConfigs],
  );

  return (
    <select
      className={classNames('form-control', className)}
      name={model}
      value={valueIdx}
      onChange={(e) => {
        const newValue = optionConfigs[e.target.value as any]?.value;
        api.setValue(newValue);
        if (onChange) onChange(e, newValue);
      }}
      onBlur={(e) => {
        api.setTouched();
        if (onBlur) onBlur(e);
      }}
      {...props}
    >
      {unknownOption}
      {optionEls}
    </select>
  );
};
