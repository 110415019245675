/**
 * mun-unload-detect v0.0.1
 *
 * Detects when a dotjem.routing jemView will be unloaded or refreshed
 * (possibly because the window is unloading), and allows the transition to be
 * cancelled.
 *
 */
import routingModule from '@maternity/vendor-dotjem-routing';
import { attachToWindow, globalManager } from './manager';

const ngModule = angular.module('mun-unload-detect', [routingModule])
  /**
   * unloadDetect service
   *
   * Config options:
   * - windowUnloadMessage: Optional message to display when asking the user
   *   to confirm window unload. Note: This is currently application-wide.
   *
   * Service methods:
   * - registerHandler(obj, handler): Registers a handler to call when the
   *   jemView specified by `obj` would unload or refresh. Returns a
   *   deregistration function. `obj` can be one of the following:
   *     1) an angular.element with a munModal ancestor (nearest will be used)
   *     2) an angular.element with a jemView ancestor (nearest will be used)
   *   The handler will be passed a `sync` flag as a parameter. In async
   *   operation, it should return a promise that resolves if/when the view can
   *   be unloaded (rejecting will cancel the state change). This may be used
   *   to e.g. display a modal asking the user to confirm the unload. In sync
   *   operation (occurs during window unload), it should return true if the
   *   view can be unloaded, and returning false will result in a confirmation
   *   dialog with the windowUnloadMessage.
   */
  .provider('unloadDetect', function($stateTransitionProvider) {
    this.$get = get;
    this.windowUnloadMessage = 'You have unsaved changes.';

    $stateTransitionProvider
      // Watch all state transitions in case a view we care about is unloading
      .onExit('*', {
        // Pending view information is only available at the between handler
        between: ['$from', '$to', '$transition', '$view', '$q', '$state', '$location',
          function($from, $to, $transition, $view, $q, $state, $location) {
            // TODO: Consider passing $to info to view handlers.
            const promises = Object.entries($view.pending())
              .filter(([name, data]) => data.action !== 'keep')
              .map(([name]) => globalManager.checkNameAsync(name));

            return $q.all(promises)
              .catch(function(reason) {
                // Reset the URL if the transition was cancelled
                // Note: This method leads to some extra entries in the history.
                //       See https://github.com/dotJEM/angular-routing/issues/14
                $location.url($state.url($from.$fullname, $from.$params.$all));
                return $q.reject(reason);
              });
          }
        ],
      })
      ;

    attachToWindow(globalManager, this.windowUnloadMessage);

    function get() {
      return {
        registerHandler: (el, handler) => globalManager.subscribe(el, handler),
      };
    }
  })

  ;

export default ngModule.name;
