import {
  globalManager,
  UnloadManager,
} from '@maternity/ng-mun-unload-detect/src/manager';
import * as React from 'react';

// TODO: Move stuff from ng-mun-unload-detect here once not using angular

const UnloadableContext = React.createContext<UnloadManager>(globalManager);

/**
 * Hook for accessing the closest unload manager
 */
export const useUnloadableContext = () => React.useContext(UnloadableContext);

export type UnloadableRefType = UnloadManager;

interface Props {
  // Ref to an element to use when registering handlers with the parent
  // manager. This should be set if the unloadable context may contain portals
  // so the angular manager will search the correct part of the DOM.
  // TODO: Should be able to drop this once not using angular
  elementRef?: React.RefObject<HTMLElement>;
  // Need explicit `children` type because of `forwardRef`
  children: React.ReactNode;
}

/**
 * Defines a new unloadable context.
 */
export const Unloadable = React.forwardRef<UnloadableRefType, Props>(
  ({ elementRef, children }, ref) => {
    // TODO: `parentContext` shouldn't change, but maybe assert that?
    const parentContext = useUnloadableContext();

    // Create a child manager on first render
    const managerRef = React.useRef<UnloadManager>(null as any);
    if (!managerRef.current) {
      managerRef.current = new UnloadManager(parentContext);
    }

    const manager = managerRef.current;

    // Copy element ref to manager
    const lastElRef = React.useRef<HTMLElement>();
    React.useEffect(() => {
      const el = elementRef?.current || undefined;
      if (el !== lastElRef.current) {
        lastElRef.current = el;
        manager.setEl(el);
      }
    });

    // Destroy the manager when unmounting
    React.useEffect(() => () => manager.destroy(), [manager]);

    // Expose the child manager via ref to allow the parent to access it
    React.useImperativeHandle(ref, () => manager, [manager]);

    return <UnloadableContext.Provider value={manager} children={children} />;
  },
);
