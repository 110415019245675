import _ from 'lodash';
import { mixin } from '@maternity/mun-extend';
import { react2angular } from 'react2angular';

import * as role_registry from '../role_registry';
import * as app_registry from '../app_registry';
import { Footer } from '../chrome';

import ngModule from './kerbin-core-module';

// Load the rest of this angular module
import './kerbin-core/chores';
import './kerbin-core/docs';
import './kerbin-core/http';
import './kerbin-core/logging';
import './kerbin-core/notfound';
import './kerbin-core/permissions';
import './kerbin-core/phones';
import './kerbin-core/sanitize';
import './kerbin-core/session';
import './kerbin-core/viewport';

import '../../css/style.less';
// Import the mun-crop styles here to prevent order conflicts when the CSS from
// all chunks is merged.
import '@maternity/ng-mun-crop/src/styles.less';

ngModule

  .config(function($compileProvider) {
    // This flag was added in 1.5.10, and will default to false in 1.6.
    $compileProvider.preAssignBindingsEnabled(false);
  })

  .value('role_registry', role_registry)
  .value('app_registry', app_registry)

  .config(function($locationProvider) {
    // Set hashPrefix to prevent angular from intercepting anchor links.
    $locationProvider.html5Mode(true).hashPrefix('!');
  })

  .config(function($provide) {
    // NOTE: This is gross, but the alternatives seemed to be rewriting or
    // replacing the routing library... -Keith
    // Apparently, the dotjem-routing library updates the "current" state,
    // which is used to determine URLs, when the transition pipeline has
    // finished. However, when view templates are defined as HTML strings,
    // those templates may be compiled during the transition, which can cause
    // broken links (that are not repaired after the transition or a digest
    // cycle). This hack seems to delay template loading enough for the
    // transition pipeline to complete.
    $provide.decorator('$template', function($delegate, $timeout) {
      return (...args) => $timeout(0).then(() => $delegate(...args));
    });
  })

  .config(function($routeProvider) {
    //$routeProvider.otherwise({state: 'top'});
  })

  .config(function($tooltipProvider) {
    // Tooltip positioning is sometimes incorrect inside relatively positioned
    // elements, so use <body> as the container. (e.g. a tooltip inside a
    // .pull-right inside a .col-*)
    // TODO: Fix that this breaks tooltips during resize?
    $tooltipProvider.defaults.container = 'body';
  })

  .config(function($alertProvider){

    angular.extend($alertProvider.defaults, {
      animation: 'am-fade',
      placement: 'top-right',
      title: '',
      content: '',
      type:'info',
      keyboard: true,
      show: true,
      container: '#alertContainer',
      duration: 4,
      dissmissable: true,
    });

  })

  .config(function($datepickerProvider) {
    angular.extend($datepickerProvider.defaults, {
      autoclose: true,
      dateType: 'string',
      dateFormat:'MM/dd/yyyy',
      modelDateFormat:'yyyy-MM-dd',
    });
  })
  .filter('mnStandardDate', function($filter){
      var dateFilter = $filter('date');
      return function(date) {
        return dateFilter(date, 'MM/dd/yyyy');
      };
  })
  .filter('mnStandardFullDate', function($filter){
      var dateFilter = $filter('date');
      return function(date) {
        return dateFilter(date, 'EEEE, dd MMM yyyy');
      };
  })
  .filter('mnStandardTime', function($filter){
      var dateFilter = $filter('date');
      return function(time) {
        return dateFilter(time, 'h:mm a');
      };
  })
  .filter('mnStandardDatetime', function($filter){
      var dateFilter = $filter('date');
      return function(datetime) {
        return dateFilter(datetime, 'MM/dd/yyyy h:mm a');
      };
  })
  .value('languages', {
    // This is a list of objects instead of a single object to guarantee order
    // (it could be replaced with an ES2015 Map though).
    options: [
      {label: 'English', value: 'en_US'},
      {label: 'Español', value: 'es_US'},
    ],
    displayValue: function(value) {
      var option = _.find(this.options, {value: value});
      return option
        ? option.label
        : '';
    }
  })
  // TODO: This isn't needed for authstrap, so move it somewhere else?
  .config(function(formSafetyProvider) {
    var modalOpen = false;
    formSafetyProvider.confirmHandler = ['munModal', '$q', function(munModal, $q) {
      var modal;

      if (modalOpen)
        // Another formSafety modal is already open; let it handle confirmation.
        return $q.when();

      modalOpen = true;

      modal = munModal({
        template: require('../../partials/common/form-safety-modal.jade'),
      });
      return modal.result.finally(function() { modalOpen = false; });
    }];
  })
  .run(function(munDocSchemas) {

    mixin(munDocSchemas['kerbin.Name'].prototype, {
      get $hasUniquePreferred() {
        return this.$first_last !== this.long_name;
      },
      get $first_last() {
        return concatNames(this.first_name, this.last_name);
      },
      get $first_middle_last() {
        return concatNames(this.first_name, this.middle_name, this.last_name);
      },
      get $last_first() {
        return _.filter([this.last_name, this.first_name]).join(', ');
      },
    });

    mixin(munDocSchemas['kerbin.Person'].prototype, {
      get activation_complete() {
        return this.activation_override || (this.acceptance && _.some(this.acceptance));
      },
      get is_staff() {
        return this.type !== 'non-member';
      },
      get is_active_staff() {
        return this.active && this.is_staff;
      },
      get user_active() {
        return this.active && this.activation_complete;
      },
    });

    function concatNames(){
      return _.filter(arguments).join(' ');
    }

    mixin(munDocSchemas['kerbin.Practice'].prototype, {
      get effective_features() {
        const features = Object.keys(this.features)
          // Ignore features that are not boolean
          .filter((k) => this.features[k] === true);
        return ['kerbin', ...this.apps, ...features];
      },
    });
  })

  .component('kerbinFooter', react2angular(Footer))

  ;

export default ngModule.name;
