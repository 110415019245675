import modalModule from '@maternity/vendor-angular-strap/modal';
import { getZIndex } from '@maternity/mun-modal/zIndex';

const ngModule = angular.module('mun-modal', [modalModule])
  .factory('munModal', function($controller, $injector, $modal, $q,
    $rootElement, $window) {
    /**
     * Wraps ngStrap.$modal to add some functionality from ui-bootstrap.$modal.
     * Note: Because the modal's lifecycle is tracked with a promise, the modal
     * is destroyed when hidden.
     *
     * Config options:
     * - controller: An optional controller for the modal. The modal object
     *   will be injected as `$modalInstance`.
     * - resolve: Optional map of dependencies to inject into the controller;
     *   it is equivalent to the `resolve` property for AngularJS routes.
     *
     * Modal properties:
     * - result: A promise that will be resolved or rejected when the modal is
     *   closed or dismissed, respectively.
     * - close(result): Close the modal, resolving the `result` promise.
     * - dismiss(reason): Dismiss the modal, rejecting the `result` promise.
     *
     * Modal scope:
     * - $close(result): Close the modal, resolving the `result` promise.
     * - $dismiss(reason): Dismiss the modal, rejecting the `result` promise.
     * - $modalId: A unique string identifying the modal.
     *
     * Events:
     * - {$modalId}.hide.requested: Broadcasted when hiding of the modal is
     *   requested. Hiding can be prevented synchronously by calling
     *   event.preventDefault(). Alternatively, event.promises can be set to an
     *   array of promises, where resolving or rejecting indicates hiding
     *   should be allowed or prevented, respectively.
     */
    var total_modals = 0;
    return modalFactory;

    function modalFactory(config) {
      if (config.container == null)
        config.container = $rootElement;
      // ngStrap.$modal has a `controller` option, but does not inject the
      // modal instance, so rename the option and handle controller
      // instantiation outside of $modal.
      config.munController = config.controller;
      delete config.controller;

      var deferred = $q.defer(),
          modal = $modal(config),
          scope = modal.$scope,
          origHide = modal.hide,
          modalId = modal.$options.prefixEvent + '.' + scope.$id,
          wasHidden = false,
          bodyElement = angular.element($window.document.body),
          locals;
      scope.$modalId = modalId;
      modal.close = scope.$close = modalClose;
      modal.dismiss = scope.$dismiss = modalDismiss;
      modal.hide = modalHide;
      modal.result = deferred.promise;
      // Increment the module-level total modals count and enable the
      // scrollfix style
      total_modals += 1;
      bodyElement.addClass("mun-modal-open");

      let zIndex = null;
      // Override z-indexes so angular and react modals layer properly
      // (this should really happen on the `.show.before` event, but the
      // backdrop element is inaccessable at that point)
      modal.$scope.$on(modal.$options.prefixEvent + '.show', () => {
        if (!zIndex) {
          zIndex = getZIndex();
          const dialog = modal.$element;
          if (modal.$options.backdrop) {
            // The backdrop element isn't exposed by angular-strap, so find the
            // correct one using the initial z-index
            const initialZIndex = +dialog.css('z-index') - 10;
            const backdrop = angular
              .element('.modal-backdrop')
              .filter((idx, el) => +el.style.zIndex === initialZIndex);
            backdrop.css({'z-index': zIndex.backdrop});

          }
          dialog.css({'z-index': zIndex.dialog});
        }
      });
      modal.$scope.$on(modal.$options.prefixEvent + '.hide', () => {
        if (zIndex) {
          zIndex.cleanup();
          zIndex = null;
        }
      });

      if (modal.$options.munController) {
        locals = {
          $scope: scope,
          $modalInstance: modal,
          // Make sure template(s) is loaded before instantiating controller
          $template: modal.$promise,
        };

        // Inject dependencies from `resolve`
        angular.forEach(modal.$options.resolve, function(value, key) {
          locals[key] = angular.isString(value)
            ? $injector.get(value)
            : $injector.invoke(value);
        });

        // Instantiate controller after dependency promises resolve
        modal.$promise = $q.all(locals)
          .then(function(locals) {
            $controller(modal.$options.munController, locals);
          })
          .catch(function(reason) {
            // Controller was not instantiated, so reject the result promise,
            // and destroy the modal.
            deferred.reject(reason);
            modal.destroy();
            wasHidden = true;
          })
          ;

      }

      // Reject the result promise if a template fails to load
      modal.$promise.catch(function(reason) {
        deferred.reject(reason);
      });

      return modal;

      function modalClose(result) {
        modal.hide(function() {
          deferred.resolve(result);
        });
      }

      function modalDismiss(result) {
        modal.hide(function() {
          deferred.reject(result);
        });
      }

      function modalHide(callback) {
        var event,
            promises = [];

        // Hide can only happen once
        if (wasHidden) return;

        // Broadcast an event allowing hide to be cancelled
        event = scope.$broadcast(modalId + '.hide.requested', register);

        // Handle synchronous cancel
        if (event.defaultPrevented) return;

        // Proceed with hide when promises resolve (or immediately if none)
        $q.all(promises)
          .then(function() {
            // Bail if hide occurred while waiting for promises
            if (wasHidden) return;

            wasHidden = true;
            scope.$on(modal.$options.prefixEvent + '.hide', onHide);
            origHide();
          })
          ;

        function register(promise) {
          promises.push(promise);
        }

        function onHide() {
          // Decrement the module-level total modals count and remove the
          // scrollfix style if there are none
          total_modals -= 1;
          if (!total_modals)
            bodyElement.removeClass("mun-modal-open");

          if (angular.isFunction(callback))
            callback();

          // Reject promise in case not already resolved (e.g. clicked backdrop)
          // TODO: Reject with a better reason
          deferred.reject('hide');

          // Remove modal elements
          // TODO: Make destroying a config option or separate service
          modal.destroy();
        }
      }
    }
  })

  .factory('munContentModal', function($injector, $bsCompiler, munModal) {
    /**
     * Wraps munModal to add the modalContent component within a boilerplate
     * bootstrap modal container. The provided template/templateUrl option is
     * compiled and added within the `modal-content` element.
     *
     * Config options: same as munModal
     *
     */
    return munContentModal;

    function munContentModal(config) {
      var $resolve = {}, // resolved values
          options = { // options to pass to $bsCompiler.compile
            template: config.template,
            templateUrl: config.templateUrl,
            controller: config.controller,
            controllerAs: config.controllerAs,
            resolve: {},
          },
          promise,
          modal;

      // Remove template and controller options from config and replace with
      // our modal-content wrapper
      delete config.template;
      delete config.templateUrl;
      delete config.controller;
      delete config.controllerAs;
      config.template = `
        <div class="modal" tabindex="-1" role="dialog" mun-modal>
          <div class="modal-dialog">
            <div class="modal-content">
              <modal-content resolve="$resolve" modal-instance="$modalInstance" />
            </div>
          </div>
        </div>
      `;
      config.controller = 'ModalCtrl';

      // Resolve values
      angular.forEach(config.resolve, function(value, key) {
        $resolve[key] = angular.isString(value)
          ? $injector.get(value)
          : $injector.invoke(value);
        options.resolve[key] = () => $resolve[key];
      });

      // Pass our resolved values to ModalCtrl
      config.resolve = {
        '$resolve': function() { return $resolve; },
      };

      // Build the modal
      modal = munModal(config);

      // Add the modal to resolved values for options.controller
      options.resolve.$modalInstance = () => modal;

      // Compile options and add it inside modal-content
      promise = $bsCompiler.compile(options);
      modal.$scope.$on(modal.$options.prefixEvent + '.show', function() {
        promise.then(function (data) {
          var scope = modal.$scope.$new();
          modal.$element.find('modal-content').append(data.element);
          data.link(scope);
        });
      });

      return modal;
    }
  })

  // Expose $modalInstance and $resolve on scope to bind to `modal-content`
  .controller('ModalCtrl', function($injector, $scope, $modalInstance, $resolve) {
    $scope.$modalInstance = $modalInstance;
    $scope.$resolve = $resolve;
  })

  .factory('munConfirm', /* @ngInject */
  function(munContentModal) {
    return munConfirm;

    function munConfirm(prompt, config) {
      if (typeof prompt === 'object' && prompt != null) {
        config = prompt;
        prompt = null;
      }
      if (config == null)
        config = {};
      if (typeof prompt === 'string')
        config.prompt = prompt;

      return munContentModal({
        template: `
          <modal-header title="confirm.title"></modal-header>
          <modal-body>
            <p class="text-pre-wrap">{{confirm.prompt}}</p>
          </modal-body>
          <modal-footer>
            <button type="button" ng-click="$dismiss()" class="btn btn-default">{{confirm.reject_text}}</button>
            <button type="button" ng-click="$close()" ng-class="confirm.accept_btn_type" class="btn">{{confirm.accept_text}}</button>
          </modal-footer>
        `,
        controller: 'ConfirmCtrl as confirm',
        keyboard: true,
        resolve: {
          munConfirmConfig: function() { return config; },
        },
        backdrop: 'static',
      }).result;
    }
  })

  .controller('ConfirmCtrl', function(munConfirmConfig) {
    var config = munConfirmConfig;

    this.title = config.title || 'Confirmation needed';
    this.prompt = config.prompt;
    this.accept_btn_type = config.accept_btn_type || 'btn-primary';
    this.accept_text = config.accept_text || 'OK';
    this.reject_text = config.reject_text || 'Cancel';
  })

  .component('modalContent', {
    controllerAs: '$modal',
    bindings: {
      'resolve': '<',
      'modalInstance': '<',
    },
    controller: function() {
      var self = this;

      self.$onInit = onInit;

      function onInit() {
        self.dismiss = self.modalInstance.dismiss;
        self.close = self.modalInstance.close;
      }
    }
  })

  .directive('modalHeader', function() {
    return {
      scope: {
        title: '<',
      },
      controllerAs: '$ctrl',
      bindToController: true,
      controller: function() {},
      require: {
        modalCtrl: '^modalContent'
      },
      transclude: true,
      template: `
        <div class="modal-header" mun-modal-transclude>
          <button class="close" type="button" ng-click="$modal.dismiss()">&times;</button>
          <h4 class="modal-title">{{$ctrl.title}}</h4>
        </div>
      `,
      link: modalContentChildLinkFn,
    };
  })

  .directive('modalBody', function() {
    return {
      scope: {},
      require: {
        modalCtrl: '^modalContent'
      },
      transclude: true,
      template: `<div class="modal-body" mun-modal-transclude></div>`,
      link: modalContentChildLinkFn,
    };
  })

  .directive('modalFooter', function() {
    return {
      scope: {},
      require: {
        modalCtrl: '^modalContent'
      },
      transclude: true,
      template: `
        <div class="modal-footer" mun-modal-transclude>
          <button class="btn btn-default" type="button" ng-click="$modal.close()"> Close</button>
        </div>
      `,
      link: modalContentChildLinkFn,
    };
  })

  ;

export default ngModule.name;

// Translude content and expose modal on scope
function modalContentChildLinkFn(scope, element, attr, ctrl, transclude) {
  // Expose modalCtrl on directive scope
  scope.$modal = ctrl.modalCtrl;
  transclude(function(clone, transcludedScope) {
    if (clone.length && notWhitespace(clone)) {
      element.find('[mun-modal-transclude]').html(clone);
      // Expose modalCtrl on transcluded scope
      transcludedScope.$modal = ctrl.modalCtrl;
    } else {
      transcludedScope.$destroy();
    }
  });
}

function notWhitespace(nodes) {
  for (var i = 0, ii = nodes.length; i < ii; i++) {
    var node = nodes[i];
    if (node.nodeType !== node.TEXT_NODE || node.nodeValue.trim()) {
      return true;
    }
  }
}
