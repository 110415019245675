import classNames from 'classnames';
import * as moment from 'moment';
import * as React from 'react';

import { TableView } from './TableView';
import { ViewProps } from './types';

const getWeeks = (d: moment.Moment) => {
  const firstDay = d.clone().startOf('month').startOf('week');
  const lastDay = d.clone().endOf('month').endOf('week');
  const totalDays = lastDay.diff(firstDay, 'days') + 1;

  const weeks = [];
  let week: moment.Moment[];
  const cursor = firstDay.clone();

  for (let i = 0; i < totalDays; i++) {
    if (i % 7 === 0) {
      week = [];
      weeks.push(week);
    }
    week!.push(cursor.clone());
    cursor.add(1, 'day');
  }
  return weeks;
};

export const DayView = ({
  dispatch,
  selectedDate,
  viewDate,
  dateInRange,
}: ViewProps) => {
  const today = moment();
  const weeks = getWeeks(viewDate);
  const title = viewDate.format('MMMM YYYY');
  return (
    <TableView
      onClickPrev={() =>
        dispatch({ type: 'shiftView', amount: -1, unit: 'month' })
      }
      onClickTitle={() => dispatch({ type: 'setView', viewMode: 'month' })}
      title={title}
      onClickNext={() =>
        dispatch({ type: 'shiftView', amount: 1, unit: 'month' })
      }
      extraHead={
        <tr>
          {moment.weekdaysShort().map((d) => (
            <th key={d} className="dow text-center">
              {d}
            </th>
          ))}
        </tr>
      }
      rows={weeks}
      renderCell={(d) => {
        const disabled = !dateInRange(d);
        const selected = selectedDate && d.isSame(selectedDate, 'day');
        const label = d.format('DD');
        return (
          <td className="text-center" key={label}>
            <button
              type="button"
              className={classNames('btn btn-default', {
                // selected
                'btn-primary': selected,
                // today
                'btn-info btn-today': !selected && d.isSame(today, 'day'),
              })}
              disabled={disabled}
              onClick={() => {
                if (disabled) return;
                dispatch({ type: 'selectDate', value: d });
              }}
              tabIndex={-1}
            >
              <span
                className={
                  d.isSame(viewDate, 'month') ? undefined : 'text-muted'
                }
              >
                {label}
              </span>
            </button>
          </td>
        );
      }}
    />
  );
};
