import classNames from 'classnames';
import * as React from 'react';

import { Action, Meridiem } from './types';

export interface TimePickerRowData {
  hour: {
    value: number;
    selected: boolean;
    disabled: boolean;
  };
  minute: {
    value: number;
    selected: boolean;
    disabled: boolean;
  };
  meridiem?: {
    value: Meridiem;
    selected: boolean;
  };
  isMid: boolean;
}
type TimePickerRowProps = TimePickerRowData & {
  dispatch: React.Dispatch<Action>;
};
export const TimePickerRow = ({
  hour,
  minute,
  meridiem,
  isMid,
  dispatch,
}: TimePickerRowProps) => (
  <tr>
    <td className="text-center">
      <button
        className={classNames('btn btn-default', {
          'btn-primary': hour.selected,
        })}
        type="button"
        tabIndex={-1}
        disabled={hour.disabled}
        onClick={() => {
          if (hour.disabled) return;
          dispatch({ type: 'select', unit: 'hour', value: hour.value });
        }}
      >
        <span>{hour.value === 0 ? 12 : hour.value}</span>
      </button>
    </td>
    <td>
      <span>{isMid && ':'}</span>
    </td>
    <td className="text-center">
      <button
        className={classNames('btn btn-default', {
          'btn-primary': minute.selected,
        })}
        type="button"
        tabIndex={-1}
        disabled={minute.disabled}
        onClick={() => {
          if (minute.disabled) return;
          dispatch({ type: 'select', unit: 'minute', value: minute.value });
        }}
      >
        <span>{`${minute.value}`.padStart(2, '0')}</span>
      </button>
    </td>
    <th>&nbsp;</th>
    <td>
      {meridiem && (
        <button
          className={classNames('btn btn-default', {
            'btn-primary': meridiem.selected,
          })}
          type="button"
          tabIndex={-1}
          onClick={() => {
            dispatch({
              type: 'select',
              unit: 'meridiem',
              value: meridiem.value,
            });
          }}
        >
          {meridiem.value.toUpperCase()}
        </button>
      )}
    </td>
  </tr>
);
