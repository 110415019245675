var iter = require('@maternity/mun-itertools'),
    vg = require('@maternity/vertigo'),
    tv = require('@maternity/travesty');


exports.SparseSchemaMapping = SparseSchemaMapping;
var SparseSchemaMapping = tv.SchemaMapping.sub('kerbin.SparseSchemaMapping');
SparseSchemaMapping.prototype.of = function(children) {
  var edges;

  if (Array.isArray(children)) {
    edges = children;
  } else if (children != null) {
    edges = [];
    for (var k in children) {
      if (children[k] != null)
        edges.push({key: k, node: tv.Optional.wrap(children[k])});
    }
  }

  return new vg.PlainGraphNode(this, edges);
};

tv.dictify.register(dictify_sparse_mapping, SparseSchemaMapping);
function dictify_sparse_mapping(disp, value, options) {
  var agg = new tv.InvalidAggregator(),
      result;

  try {
    result = iter.reduce(disp.edge_iter(), function dictify_schema_edge(acc, edge) {
        var v = value[edge.key];
        if (v != null)
          acc[edge.key] = agg.checking_sub(edge.key,
              edge.node.call.bind(edge.node, v, options));
        return acc;
      }, {});
  } finally {
    agg.raise_if_any();
  }

  if (disp.marker.extra_field_policy === 'save') {
    var known_keys = iter.reduce(disp.key_iter(), function(d, k) {
          d[k] = true;
          return d;
        }, {});

    Object.keys(value).forEach(function(k) {
      if (!known_keys[k])
        result[k] = value[k];
    });
  }

  return result;
}


exports.install_importer = install_importer;
function install_importer(importer) {
  importer.register(import_sparse_mapping, 'kerbin.SparseSchemaMapping');
}
function import_sparse_mapping(disp, d, options) {
  return SparseSchemaMapping(d.extra_field_policy).of(
    d.children && d.children.map(function(d) {
        return {key: d.name, node: disp.call(d, options)};
      }));

}
