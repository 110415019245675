import { Path } from '@maternity/mun-types';
import classNames from 'classnames';
import * as React from 'react';

import { BaseFieldProps, EnforceModelType, FieldValues } from './types';
import { useField } from './useField';
import { emailValidator, requiredStringValidator } from './validators';

type InputProps<V extends FieldValues, M extends Path<V>> = Omit<
  React.ComponentProps<'input'>,
  'form' | 'name' | 'value' | 'defaultValue' | 'children'
> &
  // Don't use EnforceRequired type since required=true also checks length > 0
  BaseFieldProps<V, M> &
  EnforceModelType<V, M, string>;

// Use constant for default value to avoid breaking memoization
const NO_VALIDATORS: any[] = [];

/**
 * An `<input>` element connected to the containing form.
 */
export const Input = <V extends FieldValues, M extends Path<V>>({
  form,
  model,
  validators = NO_VALIDATORS,
  required,
  className,
  onChange,
  onBlur,
  ...props
}: InputProps<V, M>) => {
  validators = React.useMemo(
    () => (required ? [requiredStringValidator, ...validators] : validators),
    [validators, required],
  );
  const [value, api] = useField<string>({ form, model, validators });

  return (
    <input
      className={classNames('form-control', className)}
      name={model}
      // Coerce null/undefined to the empty string
      value={value == null ? '' : value}
      onChange={(e) => {
        api.setValue(e.target.value);
        if (onChange) onChange(e);
      }}
      onBlur={(e) => {
        api.setTouched();
        if (onBlur) onBlur(e);
      }}
      {...props}
    />
  );
};

// Same as `InputProps` but disallow the `type` prop (duplicated definition
// because `Omit<InputProps<V, M>, 'type'>` seems to cause an error from the
// conditional types)
type SpecializedInputProps<V extends FieldValues, M extends Path<V>> = Omit<
  React.ComponentProps<'input'>,
  'form' | 'name' | 'value' | 'defaultValue' | 'children' | 'type'
> &
  BaseFieldProps<V, M> &
  EnforceModelType<V, M, string>;

/** Renders an `<input type="text" />` element. */
export const TextInput = <V extends FieldValues, M extends Path<V>>(
  props: SpecializedInputProps<V, M>,
) => <Input<V, M> type="text" {...props} />;

/** Renders an `<input type="password" />` element. */
export const PasswordInput = <V extends FieldValues, M extends Path<V>>(
  props: SpecializedInputProps<V, M>,
) => <Input<V, M> type="password" {...props} />;

/** Renders an `<input type="email" />` element with email validation. */
export const EmailInput = <V extends FieldValues, M extends Path<V>>({
  validators = NO_VALIDATORS,
  ...props
}: SpecializedInputProps<V, M>) => {
  validators = React.useMemo(
    () => [...validators, emailValidator],
    [validators],
  );

  return (
    <Input<V, M>
      type="email"
      spellCheck={false}
      validators={validators}
      // Apparently the destructuring is causing Typescript to incorrectly
      // error, so we need a cast here.
      {...(props as SpecializedInputProps<V, M>)}
    />
  );
};
