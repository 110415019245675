import { CheckboxGroup } from '../CheckboxGroup';
import { DatePicker } from '../DatePicker';
import { generateId } from '../id';
import { EmailInput, PasswordInput, TextInput } from '../Input';
import { NumberInput } from '../NumberInput';
import { RadioGroup } from '../RadioGroup';
import { Select } from '../Select';
import { SingleCheckbox } from '../SingleCheckbox';
import { TextArea } from '../TextArea';
import { TimePicker } from '../TimePicker';
import { asField } from '../vertical/asField';
import { withCols } from './utils';

/** Renders a text input with a label and error messages. */
export const TextField = withCols(asField(TextInput));

/** Renders a password input with a label and error messages. */
export const PasswordField = withCols(asField(PasswordInput));

/** Renders a number input with a label and error messages. */
export const NumberField = withCols(asField(NumberInput));

/** Renders a email input with a label and error messages. */
export const EmailField = withCols(asField(EmailInput));

/** Renders a text area with a label and error messages. */
export const TextAreaField = withCols(asField(TextArea));

/** Renders a group of radio buttons with a label and error messages. */
export const RadioGroupField = withCols(asField(RadioGroup));

/** Renders a single checkbox with a label and error messages. */
export const SingleCheckboxField = withCols(asField(SingleCheckbox));

/** Renders a group of checkboxes with a label and error messages. */
export const CheckboxGroupField = withCols(asField(CheckboxGroup));

/** Renders a select with a label and error messages. */
export const SelectField = withCols(asField(Select));

/** Renders a date picker with a label and error messages. */
// Override default id since date and time may use the same model
export const DateField = withCols(
  asField(DatePicker, (model) => generateId(`${model}.date`)),
);

/** Renders a time picker with a label and error messages. */
export const TimeField = withCols(
  asField(TimePicker, (model) => generateId(`${model}.time`)),
);
