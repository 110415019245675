import _ from 'lodash';
import * as React from 'react';

/**
 * Returns a throttled function that only invokes the given (memoized)
 * callback at most once every `wait` milliseconds. The callback is invoked
 * with the last arguments provided to the throttled function.
 */
// The return type of the callback is not propagated to the throttled function
// because the throttled function can return the result of the previous
// callback invocation, which might be unexpected, particularly with promises.
export const useThrottleCallback = <Args extends unknown[]>(
  callback: (...args: Args) => unknown,
  wait: number,
): ((...args: Args) => void) => {
  // TODO: Store callback in a ref so it doesn't need to be memoized?
  // TODO: Implement throttle instead of using lodash?
  const throttled = React.useMemo(
    () => _.throttle(callback, wait),
    [callback, wait],
  );
  // When component unmounts, don't invoke the function again
  React.useEffect(() => () => throttled.cancel(), [throttled]);

  return throttled;
};
