import { assertNever, Path } from '@maternity/mun-types';
import * as moment from 'moment';
import * as React from 'react';

import {
  BaseFieldProps,
  EnforceModelType,
  EnforceRequired,
  FieldValues,
} from '../types';
import { useField } from '../useField';
import { setDefaultErrorMessage } from '../validation';
import { PIN_DATE, ROWS } from './constants';
import { TimePickerTable } from './Table';
import { Action, ModelValue, PinnedMoment, State, Unit } from './types';
import { wrapHour, wrapMinute } from './utils';

/**
 * TODO: This time picker was created to match the behavior and markup/styles
 * of the angular-strap time picker for consistency throughout the product.
 * Once we are no longer using angular-strap, we should clean up the
 * markup/styles and improve the accessibility/UX.
 */

setDefaultErrorMessage('time', 'Please enter a valid time.');
// TODO: Reference the min/max values in the default messages?
setDefaultErrorMessage('timeMax', 'Please enter an earlier time.');
setDefaultErrorMessage('timeMin', 'Please enter a later time.');

// Returns a pinned copy of a `Date` or `Moment`.
const pinDate = (value: moment.Moment | Date) =>
  moment(value).set(PIN_DATE) as PinnedMoment;

interface ReducerCtx {
  modelType: 'string' | 'date';
  modelTimeFormat: string;
  hourStep: number;
  minuteStep: number;
}
const createReducer =
  ({
    modelType,
    modelTimeFormat,
    hourStep,
    minuteStep,
  }: ReducerCtx): React.Reducer<State, Action> =>
  (state, action) => {
    const toModel = (value: moment.Moment | undefined): ModelValue => {
      if (!value) return;
      if (modelType === 'string') return value.format(modelTimeFormat);
      // When model is a Date, copy the time to the last model value
      if (state.lastModelValue instanceof Date) {
        value = moment(state.lastModelValue).set({
          hour: value.hour(),
          minute: value.minute(),
        });
      }
      return value.toDate();
    };
    const toInputValue = (value: moment.Moment) =>
      value.format(TIME_FORMATS[0]);
    switch (action.type) {
      case 'close':
        return { ...state, open: false };
      case 'open':
        return { ...state, open: true };
      case 'setInputValue': {
        const inputValue = action.value;
        const parsed = moment(inputValue, TIME_FORMATS, true);
        let nextModelValue;
        if (parsed.isValid()) {
          const pinned = pinDate(parsed);
          nextModelValue = toModel(parsed);
          // When model is a Date, preserve object identity if value is unchanged
          if (modelType === 'date') {
            const lastModel = state.lastModelValue;
            const prev = lastModel && (lastModel as Date).getTime();
            const next = nextModelValue && (nextModelValue as Date).getTime();
            if (prev === next) nextModelValue = lastModel;
          }
          return {
            ...state,
            nextModelValue,
            inputValue,
            viewTime: pinned,
            selectedTime: pinned,
            focusedUnit: undefined,
          };
        }
        // Clear the model when the input is empty and the model type is string
        // (models of date type are generally shared with a date picker)
        nextModelValue =
          !inputValue && modelType === 'string'
            ? undefined
            : state.nextModelValue;
        return {
          ...state,
          nextModelValue,
          inputValue,
          selectedTime: undefined,
          focusedUnit: undefined,
        };
      }
      case 'select': {
        const newValue = moment(state.selectedTime || state.viewTime);
        if (action.unit === 'hour') {
          const meridiemOffset = newValue.hour() < 12 ? 0 : 12;
          newValue.hour(action.value + meridiemOffset);
        } else if (action.unit === 'minute') {
          newValue.minute(action.value);
        } else if (action.unit === 'meridiem') {
          const hour = newValue.hour() % 12;
          newValue.hour(hour + (action.value === 'am' ? 0 : 12));
        } else {
          assertNever(action.unit);
        }
        return {
          ...state,
          nextModelValue: toModel(newValue),
          inputValue: toInputValue(newValue),
          selectedTime: newValue as PinnedMoment,
          viewTime: newValue as PinnedMoment,
          focusedUnit: undefined,
          open: false,
        };
      }
      case 'shift': {
        const { unit, direction } = action;
        const sign = direction === 'up' ? -1 : 1;
        const viewTime = state.viewTime.clone() as PinnedMoment;
        if (unit === 'hour') {
          const newHour = wrapHour(viewTime.hour() + sign * hourStep * ROWS);
          viewTime.hour(newHour);
        } else if (unit === 'minute') {
          const newMinute = wrapMinute(
            viewTime.minute() + sign * minuteStep * ROWS,
          );
          viewTime.minute(newMinute);
        } else {
          assertNever(unit);
        }
        return { ...state, viewTime };
      }
      case 'arrowPressed': {
        const { direction } = action;
        let { focusedUnit } = state;
        if (direction === 'left') {
          if (focusedUnit === 'hour') focusedUnit = 'meridiem';
          else if (focusedUnit === 'meridiem') focusedUnit = 'minute';
          else focusedUnit = 'hour';
          return { ...state, focusedUnit };
        } else if (direction === 'right') {
          if (focusedUnit === 'hour') focusedUnit = 'minute';
          else if (focusedUnit === 'minute') focusedUnit = 'meridiem';
          else focusedUnit = 'hour';
          return { ...state, focusedUnit };
        }
        focusedUnit = focusedUnit || 'hour';
        const sign = direction === 'up' ? -1 : 1;
        const newValue = moment(state.selectedTime || state.viewTime);
        if (focusedUnit === 'hour') {
          const newHour = wrapHour(newValue.hour() + sign * hourStep);
          newValue.hour(newHour);
        } else if (focusedUnit === 'minute') {
          const newMinute = wrapMinute(newValue.minute() + sign * minuteStep);
          newValue.minute(newMinute);
        } else if (focusedUnit === 'meridiem') {
          const hour = newValue.hour();
          newValue.hour((hour % 12) + (hour < 12 ? 12 : 0));
        } else {
          assertNever(focusedUnit);
        }
        return {
          ...state,
          focusedUnit,
          nextModelValue: toModel(newValue),
          inputValue: toInputValue(newValue),
          selectedTime: newValue as PinnedMoment,
          viewTime: newValue as PinnedMoment,
        };
      }
      case 'modelChanged': {
        const { value } = action;
        // moment ignores the format string if given a Date object
        const parsed = moment(value, modelTimeFormat);
        const selectedTime = parsed.isValid() ? pinDate(parsed) : undefined;
        return {
          ...state,
          lastModelValue: value,
          nextModelValue: value,
          selectedTime,
          inputValue: selectedTime ? toInputValue(selectedTime) : '',
          viewTime: selectedTime || state.viewTime,
          focusedUnit: selectedTime ? state.focusedUnit : undefined,
        };
      }
      default:
        return assertNever(action);
    }
  };
const initState = (): State => ({
  lastModelValue: undefined,
  nextModelValue: undefined,
  inputValue: '',
  viewTime: moment({ ...PIN_DATE, hour: 0, minute: 0 }) as PinnedMoment,
  open: false,
});

type InputProps = Omit<
  React.ComponentProps<'input'>,
  'type' | 'className' | 'value' | 'onChange' | 'onClick' | 'onFocus' | 'onBlur'
>;
interface RenderInputArgs {
  value: string;
  focusedUnit?: Unit;
  open: boolean;
  dispatch: React.Dispatch<Action>;
  onBlur: React.FocusEventHandler;
  onKeyDown: React.KeyboardEventHandler;
  inputProps?: InputProps;
}

const DefaultInput = ({
  value,
  focusedUnit,
  open,
  dispatch,
  onBlur,
  onKeyDown,
  inputProps,
}: RenderInputArgs) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  // When a unit is focused by the keyboard, select the appropriate range of
  // text in the input.
  React.useEffect(() => {
    if (!focusedUnit) return; // Don't control cursor location
    const el = inputRef.current!;
    // TODO: This only parses 'h:mm A' format and uses magic numbers...
    const sepIndex = value.indexOf(':');
    if (sepIndex === -1) return; // Separator is missing (shouldn't happen)
    if (focusedUnit === 'hour') {
      el.selectionStart = 0;
      el.selectionEnd = sepIndex;
    } else if (focusedUnit === 'minute') {
      el.selectionStart = sepIndex + 1;
      el.selectionEnd = sepIndex + 3;
    } else if (focusedUnit === 'meridiem') {
      el.selectionStart = sepIndex + 4;
      el.selectionEnd = sepIndex + 6;
    } else {
      assertNever(focusedUnit);
    }
  }, [focusedUnit, value]);

  return (
    <input
      {...inputProps}
      ref={inputRef}
      type="text"
      className="form-control"
      value={value}
      onChange={(e) =>
        dispatch({ type: 'setInputValue', value: e.target.value })
      }
      onClick={(e) => {
        const isFocused = e.target === document.activeElement;
        if (isFocused && !open) dispatch({ type: 'open' });
      }}
      onFocus={() => dispatch({ type: 'open' })}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    />
  );
};

const defaultRenderInput = (props: RenderInputArgs) => (
  <DefaultInput {...props} />
);

type TimePickerProps<V extends FieldValues, M extends Path<V>> = (
  | ({
      /** The model can either be a string or a `Date` object. Defaults to date. */
      // A discriminated union is used for props that vary with `modelType`
      modelType: 'string';
      onChange?: (v: string | undefined) => void;
    } & EnforceModelType<V, M, string>)
  | ({
      modelType?: 'date';
      onChange?: (v: Date | undefined) => void;
    } & EnforceModelType<V, M, Date>)
) & {
  /** The format for the model when it is a string (see the moment docs).
   * Defaults to ISO 8601, which is expected by travesty. */
  modelTimeFormat?: string;
  /** Maximum allowed time  */
  maxTime?: moment.Moment | Date | 'now';
  /** Minimum allowed time */
  minTime?: moment.Moment | Date | 'now';
  /** Step size for hour options displayed (does not limit typed-in values).
   * Defaults to 1. */
  hourStep?: number;
  /** Step size for minute options displayed (does not limit typed-in values).
   * Defaults to 5. */
  minuteStep?: number;
  /** Function that renders the input field (or other toggle for the picker) */
  renderInput?: (args: RenderInputArgs) => React.ReactNode;
  /** Extra props to pass through to the input element */
  inputProps?: InputProps;
  onBlur?: () => void;
} & BaseFieldProps<V, M> &
  EnforceRequired<V, M>;

// Array of formats for parsing values typed in the input field. The first
// format is used for displaying valid times in the input field.
// TODO: Make this a prop rather than hard coding?
const TIME_FORMATS = ['h:mm A', 'h:mm a', 'h:mma'];
// Use constant for default value to avoid breaking memoization
const NO_VALIDATORS: any[] = [];

/**
 * Renders an input field (by default) that displays a time picker when
 * focused/clicked.
 *
 * It currently operates on dates in the local time zone. Also, the only format
 * currently supported is 12hr and minutes with AM/PM.
 */
// TODO: add special handling for mobile/touch like angular-strap?
export const TimePicker = <V extends FieldValues, M extends Path<V>>({
  form,
  model,
  modelType = 'date',
  modelTimeFormat = 'HH:mm:ss.SSS',
  validators = NO_VALIDATORS,
  required,
  maxTime,
  minTime,
  hourStep = 1,
  minuteStep = 5,
  renderInput = defaultRenderInput,
  inputProps,
  onChange,
  onBlur,
}: TimePickerProps<V, M>) => {
  const getPinnedMaxTime = React.useCallback(() => {
    if (!maxTime) return;
    const refTime = maxTime === 'now' ? moment() : maxTime;
    return pinDate(refTime);
  }, [maxTime]);
  const getPinnedMinTime = React.useCallback(() => {
    if (!minTime) return;
    const refTime = minTime === 'now' ? moment() : minTime;
    return pinDate(refTime);
  }, [minTime]);

  const [state, dispatch] = React.useReducer(
    createReducer({ modelType, modelTimeFormat, hourStep, minuteStep }),
    null,
    initState,
  );

  // Capture reference to last committed state to make callbacks stable
  // (this should not be read during render, only in event handlers)
  const latestState = React.useRef(state);
  React.useEffect(() => {
    latestState.current = state;
  });

  validators = React.useMemo(
    () => [
      (v: any) => {
        const { inputValue, selectedTime } = latestState.current;
        if (!inputValue) {
          if (required) return 'required';
          // Consider it an error when the input is empty, but the model isn't
          if (v != null) return 'time';
          return;
        }
        if (!selectedTime) return 'time';
        // TODO: Include min/max values in returned context?
        const pinnedMaxTime = getPinnedMaxTime();
        if (pinnedMaxTime && selectedTime.isAfter(pinnedMaxTime, 'minute')) {
          return 'timeMax';
        }
        const pinnedMinTime = getPinnedMinTime();
        if (pinnedMinTime && selectedTime.isBefore(pinnedMinTime, 'minute')) {
          return 'timeMin';
        }
      },
      ...validators,
    ],
    [validators, required, getPinnedMaxTime, getPinnedMinTime],
  );
  const [modelValue, api] = useField<string | Date | undefined>({
    form,
    model,
    validators,
  });

  // When the model value changes, update internal state
  if (modelValue !== state.lastModelValue) {
    dispatch({ type: 'modelChanged', value: modelValue });
  }

  React.useEffect(() => {
    // Push internal state changes back to the model when necessary
    if (modelValue !== state.nextModelValue) {
      api.setValue(state.nextModelValue);
      if (onChange) onChange(state.nextModelValue as any);
    }
  });

  const handleKeyDown = React.useCallback((e: React.KeyboardEvent) => {
    if (e.altKey || e.shiftKey) return;
    switch (e.key) {
      case 'Escape':
      case 'Enter':
        dispatch({ type: 'close' });
        break;
      case 'ArrowLeft': {
        dispatch({ type: 'arrowPressed', direction: 'left' });
        break;
      }
      case 'ArrowRight': {
        dispatch({ type: 'arrowPressed', direction: 'right' });
        break;
      }
      case 'ArrowUp': {
        dispatch({ type: 'arrowPressed', direction: 'up' });
        break;
      }
      case 'ArrowDown': {
        dispatch({ type: 'arrowPressed', direction: 'down' });
        break;
      }
      default:
        // Ignore all other keys
        return;
    }
    // Don't also move the cursor around the input
    e.preventDefault();
  }, []);
  const handleInputBlur = React.useCallback(() => {
    dispatch({ type: 'close' });
    api.setTouched();
    if (onBlur) onBlur();
  }, [api, onBlur]);
  const handleDropdownMouseDown = React.useCallback((e) => {
    // Prevent the input from blurring when clicking on the dropdown
    e.preventDefault();
  }, []);

  return (
    <React.Fragment>
      {renderInput({
        value: state.inputValue,
        focusedUnit: state.focusedUnit,
        open: state.open,
        dispatch,
        onBlur: handleInputBlur,
        onKeyDown: handleKeyDown,
        inputProps,
      })}
      {state.open && (
        <div
          className="dropdown open"
          onKeyDown={handleKeyDown}
          onMouseDown={handleDropdownMouseDown}
        >
          <div className="dropdown-menu timepicker">
            <TimePickerTable
              hourStep={hourStep}
              minuteStep={minuteStep}
              viewTime={state.viewTime}
              selectedTime={state.selectedTime}
              maxTime={getPinnedMaxTime()}
              minTime={getPinnedMinTime()}
              dispatch={dispatch}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
