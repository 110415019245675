import { filter } from '@maternity/mun-itertools';

import { GraphNode, KeyError } from './graph';

export class GraphWrapper<T> extends GraphNode<T> {
  constructor(readonly graph: GraphNode<T>, ...args: any[]) {
    super();
    // Emulate the python GraphWrapper __slots__ mechanism
    const sloots = (this.constructor as any).sloots || [];
    args.forEach((v, i) => ((this as any)[sloots[i]] = v));
  }

  get value(): T {
    return this.graph.value;
  }

  get_child(key: string): GraphNode<T> {
    const graph = this.graph.get_child(key);
    return new GraphWrapper(graph);
  }

  key_iter(): IterableIterator<string> {
    return this.graph.key_iter();
  }
}

export class ValueOverlay<T> extends GraphWrapper<T> {
  constructor(graph: GraphNode<T>, value: T) {
    super(graph);
    // Since GraphWrapper only defines a getter for `value`, normal property
    // assignment doesn't work here.
    Object.defineProperty(this, 'value', {
      value,
      enumerable: true,
      configurable: true,
    });
  }

  override get_child(key: string): GraphNode<T> {
    return this.graph.get_child(key);
  }
}

export class EdgeRestriction<T> extends GraphWrapper<T> {
  constructor(graph: GraphNode<T>, readonly edge_names: string[]) {
    super(graph);
  }

  override get_child(key: string): GraphNode<T> {
    if (this.edge_names.includes(key)) {
      return this.graph.get_child(key);
    }
    throw new KeyError([key]);
  }

  override key_iter(): IterableIterator<string> {
    return filter(this.graph.key_iter(), (k) => this.edge_names.includes(k));
  }
}
