// To create private instances, just use new env.constructor.

module.exports = exports = new Env();
function Env(base) {
  Object.defineProperty(this, '$expand', {value: expand});
  Object.defineProperty(this, '$template', {value: template});
  if (base != null) {
    Object.keys(base)
      .reduce(function(d, k) {
        d[k] = base[k];
        return d;
      }, this);
  }
  return this;
}

function expand(tpl, xtra) {
  // This seems a little backwards, but it makes the behavior consistent with template().  This
  // isn't an issue for now, we can revisit it that changes.
  var s = expandMaybe(tpl, this);
  if (xtra)
    s = expandMaybe(s, xtra);
  return s;
}

function template(tpl) {
  var s = expandMaybe(tpl, this);
  return expandMaybe.bind(this, s);
}

function expandMaybe(tpl, d) {
  // Previously we used underscore templates for this expansion, but all we're doing is
  // interpolating simple configuration strings, and I think the ability to do incremental
  // interpolation is useful.
  return tpl.replace(/\$(\w+)|\$\{(.*?)\}/g, function(zero, name, name_) {
      name = name || name_;
      if (typeof d[name] !== 'undefined')
        return d[name];
      return zero;
    });
}
