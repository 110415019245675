import * as iter from '@maternity/mun-itertools';
import tv from '@maternity/travesty';

const zip_errors_to = tv.make_dispatcher();
export default zip_errors_to;

zip_errors_to.register(zip_errors_to_marker, tv.Marker);
function zip_errors_to_marker(disp, data, error, options) {
  if (data == null)
    // What can we do?
    return;

  if (error == null)
    // Yay!
    return;

  if (error.value != null)
    try {
      data.$error = error.value;
    } catch (e) {
      // Swallow the TypeError that occurs when `data` is a primitive
    }

  iter.forEach(disp.edge_iter(), function(edge) {
      var subdisp = edge.node,
          subdata = data[edge.key],
          suberror = error.get_path([edge.key], null);

      if (suberror != null && suberror.value != null)
        data[edge.key+'$error'] = suberror.value;

      subdisp.call(subdata, suberror, options);
    });
}


zip_errors_to.register(zip_errors_to_list, tv.List);
function zip_errors_to_list(disp, data, error, options) {
  if (data == null)
    // What can we do?
    return;

  if (error == null)
    // Yay!
    return;

  if (error.value != null)
    data.$error = error.value;

  var subdisp = disp.get_child('sub');
  data.forEach(function(subdata, i) {
      var suberror = error.get_path([i], null);

      subdisp.call(subdata, suberror, options);
    });
}


// TODO: zip_errors_to.register(zip_errors_to_polymorph, tv.Polymorph);
// TODO: zip_errors_to.register(zip_errors_to_unimap, tv.UniMapping);
