var Invalid = require('./invalid').Invalid,
    base = require('./base'),

    DateTimeBase = exports.DateTimeBase = base.Leaf.sub('tv.DateTimeBase'),
    DateTime = exports.DateTime = DateTimeBase.sub('tv.DateTime'),
    Date_ = exports.Date = DateTimeBase.sub('tv.Date'),
    Time = exports.Time = DateTimeBase.sub('tv.Time'),
    TimeDelta = exports.TimeDelta = base.Leaf.sub('tv.TimeDelta');

DateTime.prototype.ctor = function(d) {
  if (typeof d === 'string')
    // Assume UTC if no timezone specified.
    d = Date.parse(/Z$|[-+]\d\d(:?\d\d)?$/.test(d) ? d : d+'Z');
  return new Date(d);
};
// FIXME: Strings are the least fucked up type for representing dates in javascript.
Date_.prototype.ctor = String;
Time.prototype.ctor = String;

base.dictify.register(dictify_datetime, DateTimeBase);
function dictify_datetime(disp, value) {
  if (value != null && typeof value.toISOString === 'function') {
    var result = value.toISOString();
    if (result[result.length-1] === 'Z')
      return result.substring(0, result.length-1);
    return result;
  }
  return String(value);
}

base.undictify.register(undictify_datetime, DateTimeBase);
function undictify_datetime(disp, value) {
  if (value == null)
    throw new Invalid('type_error');

  return disp.marker.ctor(value);
}


// JavaScript doesn't have a duration type so milliseconds it is because
// JavaScript also doesn't have microsecond precision. Alas.
TimeDelta.prototype.ctor = Number;

base.dictify.register(dictify_timedelta, TimeDelta);
function dictify_timedelta(disp, value) {
  var days = (value/86400e3)|0,
      seconds = ((value%86400e3)/1000)|0,
      microseconds = (((value%86400e3)%1000)*1000)|0;
  return [days, seconds, microseconds];
}

base.undictify.register(undictify_timedelta, TimeDelta);
function undictify_timedelta(disp, value) {
  var milliseconds;

  if (value == null)
    throw new Invalid('type_error');

  if (value.length !== 3)
    throw new Invalid('bad_len');

  // Days
  milliseconds = value[0]*86400e3;
  // Seconds
  milliseconds += value[1]*1000;
  // Microseconds
  milliseconds += value[2]*0.001;

  return disp.marker.ctor(milliseconds);
}
