import { Button, ButtonProps } from '@maternity/mun-layout';
import * as React from 'react';

// When the component (`C` type param) is `button`, disallow the `type` prop
// since its value will be automatically set to "submit".
type SubmitProps<C extends React.ElementType> = C extends 'button'
  ? Omit<ButtonProps<C>, 'type'>
  : ButtonProps<C>;

/**
 * Submit button for use in forms.
 *
 * The button text defaults to "Save" and the variant defaults to "primary",
 * but these can be overridden.
 */
export const Submit = <C extends React.ElementType = 'button'>({
  children,
  component = 'button',
  variant = 'primary',
  ...props
}: SubmitProps<C>) => {
  if (component === 'button') {
    (props as ButtonProps<'button'>).type = 'submit';
  }
  return (
    <Button component={component} variant={variant} {...props}>
      {React.Children.count(children) ? children : 'Save'}
    </Button>
  );
};
