import { Spinner } from '@maternity/mun-spinner';
import * as React from 'react';

import { ErrorBoundary } from './ErrorBoundary';

interface Props {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

/** Abstracts the boilerplate for Suspense. */
export const MunSuspense = ({ children, fallback = <Spinner /> }: Props) => (
  <React.Suspense fallback={fallback}>
    <ErrorBoundary>{children}</ErrorBoundary>
  </React.Suspense>
);
