import { Path } from '@maternity/mun-types';
import * as _ from 'lodash';
import * as React from 'react';

import { FieldValues, FormApi, FormState } from './types';
import { useFormState } from './useFormState';
import {
  ErrorMessageMap,
  getErrorMessage,
  ValidationErrorCtx,
} from './validation';

// TODO: Add an option to only show errors after an submission attempt rather
// than when touched?
// TODO: Should we track which models have been handled and render an error
// somewhere for models that are missing an ErrorMessage component?
// TODO: Do we need control over the order of error messages? (It is currently
// based on the error id.)
// TODO: Are there cases where multiple error ids with the same meaning will be
// present? If so, we probably need to change the dedupe logic.

interface Props<V extends FieldValues, M extends Path<V>> {
  /** Form API handle */
  form: FormApi<V>;
  /** A model path or an array of model paths (shared errors will be deduped) */
  model: M | M[];
  /** Mapping of error ids to messages (may be strings or functions) */
  errorMessages?: ErrorMessageMap;
  /** Classes to apply to the div wrapper around messages */
  className?: string;
}

/**
 * Renders the error messages for the given model(s).
 */
// Cast `React.memo` because the default type definition breaks Typescript's
// higher-order type inference.
export const ErrorMessage = (React.memo as <C>(c: C) => C)(
  <V extends FieldValues, M extends Path<V>>({
    form,
    model,
    errorMessages = {},
    className = 'form-errors text-danger',
  }: Props<V, M>) => {
    const selector = React.useMemo(() => {
      const modelArray = Array.isArray(model) ? model : [model];

      return (state: FormState<V>) => ({
        modelData: modelArray.map((m) => ({
          touched: state.touched[m],
          errors: state.errors[m],
          serverErrors: state.serverErrors[m],
        })),
        beforeSubmit: state.submitCount === 0,
      });
    }, [model]);
    const { modelData, beforeSubmit } = useFormState(form, selector, _.isEqual);

    // Collect client and server errors for all models but dedupe based on errId
    const seen: Record<string, React.ReactNode> = {};

    const processError = (err: ValidationErrorCtx) => {
      if (err.errId in seen) return;
      const message = getErrorMessage(err, errorMessages);
      seen[err.errId] = message ? <p key={err.errId}>{message}</p> : null;
    };

    modelData.forEach((data) => {
      // Before submission is attempted, only show messages for fields that have
      // been touched. After submission is attempted, show all messages.
      if (beforeSubmit && !data.touched) return;

      data.errors?.forEach(processError);
      data.serverErrors?.forEach(processError);
    });

    const messages: React.ReactNode[] = [];
    const unhandled: string[] = [];
    // Sort the errors by id so the order is stable with multiple models
    Object.keys(seen)
      .sort()
      .forEach((errId) => {
        const message = seen[errId];
        if (message) {
          messages.push(message);
        } else {
          unhandled.push(errId);
        }
      });

    if (unhandled.length) {
      messages.push(<p key="_unhandled">Unknown validation error</p>);
      // TODO: Log unhandled array to console and/or sentry?
    }

    if (!messages.length) return null;

    return <div className={className}>{messages}</div>;
  },
);
