import { injector } from '@maternity/ng-mun-linear';

// For now, just dispatch to the $alert angular service. We often show alerts
// right before a screen transition, so alerts cannot be managed by components
// within the screen getting unloaded. When we're ready to convert the whole
// component tree to react, we can add a component near the root that is
// responsible for managing alerts. (We may also want to consider using a word
// other than "alert" to describe this, e.g. "notification" or "toast".)

interface Options {
  type?: 'success' | 'info' | 'warning' | 'danger';
  title?: string;
  content?: string;
  template?: string;
  duration?: false | number;
  // TODO: Type the other $alert options if we need them...
}

interface AlertInstance {
  $scope: angular.IScope;
  // TODO: Type the other $alert instance properties if we need them...
}

/**
 * Show an alert message in the corner of the screen (handled by the angular
 * $alert service).
 */
export const flashAlert = (options: Options): AlertInstance => {
  const $alert = injector.get('$alert');
  return $alert(options);
};
