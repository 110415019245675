var iter = require('@maternity/mun-itertools'),
    { extend } = require('@maternity/mun-extend'),
    vg = require('@maternity/vertigo'),

    invalid = require('./invalid'),
    Invalid = invalid.Invalid,
    InvalidAggregator = invalid.InvalidAggregator,
    base = require('./base'),
    Schema = require('./schema').Schema,
    Marker = base.Marker,
    to_typegraph = base.to_typegraph,
    traverse = base.traverse,
    dictify = base.dictify,
    undictify = base.undictify;


var SchemaMapping = exports.SchemaMapping = Schema.sub(
      'tv.SchemaMapping', function SchemaMapping(extra_field_policy) {
        if (!(this instanceof SchemaMapping)) {
          // This never gets old!
          var self = Object.create(SchemaMapping.prototype);
          SchemaMapping.apply(self, arguments);
          return self;
        }
        this.extra_field_policy = extra_field_policy;
      });

dictify.register(dictify_mapping, SchemaMapping);
function dictify_mapping(disp, value, options) {
  var result = disp.super(SchemaMapping).call(value, options);

  if (disp.marker.extra_field_policy === 'save') {
    var known_keys = iter.reduce(disp.key_iter(), function(d, k) {
          d[k] = true;
          return d;
        }, {});

    Object.keys(value).forEach(function(k) {
      if (!known_keys[k])
        result[k] = value[k];
    });
  }

  return result;
}

undictify.register(undictify_mapping, SchemaMapping);
function undictify_mapping(disp, value, options) {
  var agg = new InvalidAggregator(),
      super_disp = disp.super(SchemaMapping),
      result = agg.checking(super_disp.call.bind(super_disp, value, options)),
      known_keys = disp.marker.extra_field_policy != null
        ? iter.reduce(disp.key_iter(), function(d, k) {
            d[k] = true;
            return d;
          }, {})
        : null;

  if (disp.marker.extra_field_policy === 'error') {
    Object.keys(value).forEach(function(k) {
      if (!known_keys[k])
        agg.own_error(new Invalid('unexpected_fields'));
    });

  } else if (disp.marker.extra_field_policy === 'save') {
    Object.keys(value).forEach(function(k) {
      if (!known_keys[k])
        result[k] = value[k];
    });
  }

  agg.raise_if_any();

  return result;
}


var StrMapping = exports.StrMapping = Marker.sub('tv.StrMapping');

StrMapping.prototype.of = function(sub) {
  return new vg.PlainGraphNode(this, [
      {key: 'sub', node: to_typegraph.call(sub)}]);
};

traverse.register(traverse_strmap, StrMapping);
function traverse_strmap(disp, value, options) {
  var zipgraph = options?.zipgraph,
      valgraph = disp.get_child('sub'),
      valsuboptions = zipgraph != null ? extend(options, {zipgraph: zipgraph.get_child('sub')}) : options,

      edges = Object.keys(value).reduce(function(acc, key, i) {
        acc.push({key: key, node: valgraph.call(value[key], valsuboptions)});
        return acc;
      }, []),
      v = zipgraph != null ? [value, zipgraph.value] : value;

      return new vg.PlainGraphNode(v, edges);
}

dictify.register(dictify_strmap, StrMapping);
function dictify_strmap(disp, value, options) {
  var valdisp = disp.get_child('sub');

  return Object.keys(value)
    .reduce(function(d, key) {
      d[key] = valdisp.call(value[key], options);
      return d;
    }, {});
}

undictify.register(undictify_strmap, StrMapping);
function undictify_strmap(disp, value, options) {
  var valdisp = disp.get_child('sub');

  // TODO: invalid

  return Object.keys(value)
    .reduce(function(d, key) {
      d[key] = valdisp.call(value[key], options);
      return d;
    }, {});
}


var UniMapping = exports.UniMapping = Marker.sub('tv.UniMapping');

UniMapping.prototype.of = function(key, val) {
  return new vg.PlainGraphNode(this, [
      {key: 'key', node: to_typegraph.call(key)},
      {key: 'val', node: to_typegraph.call(val)}]);
};

traverse.register(traverse_unimap, UniMapping);
function traverse_unimap(disp, value, options) {
  var zipgraph = options?.zipgraph,
      keygraph = disp.get_child('key'),
      valgraph = disp.get_child('val'),
      keysuboptions = zipgraph != null ? extend(options, {zipgraph: zipgraph.get_child('key')}) : options,
      valsuboptions = zipgraph != null ? extend(options, {zipgraph: zipgraph.get_child('val')}) : options,

      edges = Object.keys(value).reduce(function(acc, key, i) {
        acc.push({key: 'key_'+i, node: keygraph.call(key, keysuboptions)});
        acc.push({key: 'value_'+i, node: valgraph.call(value[key], valsuboptions)});
        return acc;
      }, []),
      v = zipgraph != null ? [value, zipgraph.value] : value;

      return new vg.PlainGraphNode(v, edges);
}

dictify.register(dictify_unimap, UniMapping);
function dictify_unimap(disp, value, options) {
  var keydisp = disp.get_child('key'),
      valdisp = disp.get_child('val');

  return Object.keys(value)
    .reduce(function(d, key) {
      d[keydisp.call(key, options)] = valdisp.call(value[key], options);
      return d;
    }, {});
}

undictify.register(undictify_unimap, UniMapping);
function undictify_unimap(disp, value, options) {
  var keydisp = disp.get_child('key'),
      valdisp = disp.get_child('val');

  // TODO: invalid

  return Object.keys(value)
    .reduce(function(d, key) {
      d[keydisp.call(key, options)] = valdisp.call(value[key], options);
      return d;
    }, {});
}
