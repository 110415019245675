import { extend } from '@maternity/mun-extend';

import ngModule from '../kerbin-core-module';

ngModule
  .directive('a', function() {
    /* Set rel="noopener noreferrer" on links with a target to prevent the
     * destination from changing window.location.
     *
     * See https://mathiasbynens.github.io/rel-noopener/ for a more detailed
     * explanation and demo.
     */
    return {
      restrict: 'E',
      link: function(scope, element, attrs) {
        if (attrs.target) {
          attrs.$set('rel', updateRel(attrs.rel));
        }
      },
    };
  })

  .config(function($provide) {
    // Make linkyFilter set rel="noopener noreferrer" on links with a target.
    $provide.decorator('linkyFilter', function($delegate) {
      return wrappedLinky;

      function wrappedLinky(text, target, attributes) {
        return $delegate(text, target, updateAttrs);

        function updateAttrs(url) {
          var attrs;

          if (angular.isFunction(attributes)) {
            attrs = attributes(url);
          } else {
            attrs = attributes || {};
          }

          if (target || attrs.target) {
            attrs = extend(attrs, { rel: updateRel(attrs.rel) });
          }

          return attrs;
        }
      }
    });
  })

  ;

function updateRel(rel) {
  rel = rel || '';
  return `noopener noreferrer ${rel}`;
}
