import { createRequiredContext } from '@maternity/mun-required-context';
import { Unloadable, UnloadableRefType } from '@maternity/mun-unload-detect';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { getZIndex } from './zIndex';

/**
 * Hook that returns z-index values for modal dialog and backdrop.
 */
const useZIndex = (isOpen: boolean) => {
  const [zIndex, setZIndex] = React.useState({ dialog: 0, backdrop: 0 });

  React.useLayoutEffect(() => {
    if (!isOpen) return;

    const { dialog, backdrop, cleanup } = getZIndex();

    setZIndex({ dialog, backdrop });

    return cleanup;
  }, [isOpen]);

  return zIndex;
};

let openModalCount = 0;
/**
 * Hook that adds a class to the body to hide its scrollbar when a modal is open
 */
const useApplyBodyClass = (isOpen: boolean) => {
  React.useLayoutEffect(() => {
    if (!isOpen) return;

    openModalCount += 1;
    // TODO: Drop the mun-modal-react-open class once not using angular-strap
    document.body.classList.add('modal-open', 'mun-modal-react-open');

    return () => {
      openModalCount -= 1;
      if (!openModalCount) {
        document.body.classList.remove('modal-open', 'mun-modal-react-open');
      }
    };
  }, [isOpen]);
};

export interface ModalContextType {
  /**
   * Show the modal
   */
  show(): void;
  /**
   * Request that the modal be hidden (may be interrupted)
   */
  hide(): void;
}
const ModalContext = createRequiredContext<ModalContextType>();
/**
 * Hook that returns the modal context object
 */
export const useModalContext = ModalContext.useContext;

interface ModalBackdropProps {
  container: Element;
  zIndex: number;
}
/**
 * Renders the backdrop for a modal in the given container.
 */
const ModalBackdrop = ({ container, zIndex }: ModalBackdropProps) =>
  ReactDOM.createPortal(
    <div className="modal-backdrop in" style={{ zIndex }} />,
    container,
  );

interface ModalDialogProps {
  container: Element;
  zIndex: number;
  onClick: React.MouseEventHandler;
  onKeyDown: React.KeyboardEventHandler;
  // Need explicit `children` type because of `forwardRef`
  children: React.ReactNode;
}
/**
 * Renders a modal dialog in the given container.
 */
const ModalDialog = React.forwardRef<HTMLDivElement, ModalDialogProps>(
  ({ container, zIndex, onClick, onKeyDown, children }, ref) =>
    ReactDOM.createPortal(
      <div
        className="modal"
        tabIndex={-1}
        role="dialog"
        style={{ zIndex, display: 'block' }}
        onClick={onClick}
        onKeyDown={onKeyDown}
        ref={ref}
      >
        <div className="modal-dialog">
          <div className="modal-content">{children}</div>
        </div>
      </div>,
      container,
    ),
);

interface ModalProps {
  /** If true, the escape key will close the modal (defaults to false) */
  keyboard?: boolean;
  /**
   * Backdrop mode (defaults to static)
   * - none: No backdrop
   * - static: Backdrop visible, but clicking it doesn't close the modal
   * - clickable: Backdrop visible, and clicking it closes the modal
   */
  backdrop?: 'none' | 'static' | 'clickable';
  /** Container where modal should be added to the DOM (defaults to body) */
  modalContainer?: Element;
  /** Container where backdrop should be added to the DOM (defaults to body) */
  backdropContainer?: Element;
  /** If true, the modal will start visible (defaults to false) */
  initialOpen?: boolean;
  /** Callback fired when the modal is shown */
  onShow?: () => void;
  /** Callback fired when the modal is hidden */
  onHide?: () => void;
  /** Need explicit `children` type because of `forwardRef` */
  children?: React.ReactNode;
}
/**
 * Manages rendering of a modal.
 *
 * The modal be opened and closed by calling the `show` and `hide` methods of
 * the object exposed as the component's ref.
 */
export const Modal = React.forwardRef<ModalContextType, ModalProps>(
  (
    {
      keyboard = false,
      backdrop = 'static',
      modalContainer = document.body,
      backdropContainer = document.body,
      initialOpen = false,
      onShow,
      onHide,
      children,
    },
    ref,
  ) => {
    // The "open" state needs to be managed internally rather than passed as a
    // prop due to unload detection.
    const [isOpen, setOpen] = React.useState(initialOpen);
    // Copy the state to a ref for use in context methods
    const isOpenRef = React.useRef(isOpen);
    React.useEffect(() => {
      isOpenRef.current = isOpen;
    }, [isOpen]);

    const zIndex = useZIndex(isOpen);

    const unloadableRef = React.useRef<UnloadableRefType | null>(null);
    // The modal uses a portal, so we render a dummy div to get the current
    // location in the DOM for use in the angular unload manager.
    // TODO: Remove this ref and associated div once not using angular
    const domLocationRef = React.useRef<HTMLDivElement>(null);

    const dialogRef = React.useRef<HTMLDivElement>(null);
    const focusRef = React.useRef<HTMLElement | null>(null);
    React.useLayoutEffect(() => {
      if (!isOpen) return;

      // Record the currently focused element
      focusRef.current = document.activeElement as HTMLElement | null;
      // Change focus to the modal dialog
      const dialogEl = dialogRef.current;
      if (dialogEl) dialogEl.focus();

      return () => {
        // Restore focus
        // TODO: improve handling of multiple modals? (closing modals in a
        // different order than opened may restore focus to the wrong place)
        const focusEl = focusRef.current;
        if (focusEl) focusEl.focus();
      };
    }, [isOpen]);

    useApplyBodyClass(isOpen);

    // TODO: when modal is open, set `aria-hidden: true` on rest of the app?
    // TODO: when modal is open, trap focus inside the modal?

    const contextValue: ModalContextType = React.useMemo(
      () => ({
        show() {
          if (isOpenRef.current) return;

          setOpen(true);

          if (onShow) onShow();
        },
        hide() {
          const unloadManager = unloadableRef.current;
          if (!isOpenRef.current || !unloadManager) return;
          unloadManager
            .checkAsync()
            .then(() => {
              setOpen(false);

              if (onHide) onHide();
            })
            // Avoid unhandled rejection error if `checkAsync` rejects
            .catch(() => {});
        },
      }),
      [onShow, onHide],
    );
    // Expose the context value via ref to allow the parent to open the modal
    React.useImperativeHandle(ref, () => contextValue, [contextValue]);

    const handleClick: React.MouseEventHandler = React.useCallback(
      (e) => {
        if (e.target !== e.currentTarget) return;
        if (backdrop === 'clickable') contextValue.hide();
      },
      [backdrop, contextValue],
    );
    const handleKeyDown: React.KeyboardEventHandler = React.useCallback(
      (e) => {
        if (!keyboard) return;
        if (e.key === 'Escape') contextValue.hide();
      },
      [keyboard, contextValue],
    );

    if (!isOpen) return null;

    return (
      <Unloadable ref={unloadableRef} elementRef={domLocationRef}>
        <span ref={domLocationRef} />
        <ModalContext.Provider value={contextValue}>
          {backdrop !== 'none' && (
            <ModalBackdrop
              container={backdropContainer}
              zIndex={zIndex.backdrop}
            />
          )}
          <ModalDialog
            container={modalContainer}
            zIndex={zIndex.dialog}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            children={children}
            ref={dialogRef}
          />
        </ModalContext.Provider>
      </Unloadable>
    );
  },
);

// TODO: Create a "modal manager" component to instantiate near the root of the
// component tree which can handle the lifecycle of "fire and forget" modals.
