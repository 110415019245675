/**
 * Styled button components (currently using Bootstrap 3 styles)
 */
import { Anchor, Link } from '@maternity/mun-router';
import classNames from 'classnames';
import * as React from 'react';

import { Variant } from './types';
// TODO: Add explicit dependency on Bootstrap

type Size = 'lg' | 'sm' | 'xs';
interface StyleProps {
  variant?: Variant | 'link';
  size?: Size; // omit for default size
  block?: boolean;
}

// Split on spaces and collect the parts in a union
type TokenizeClassName<S extends string> =
  S extends `${infer Prefix} ${infer Suffix}`
    ? Prefix | TokenizeClassName<Suffix>
    : S;
type BtnClasses = 'btn' | `btn-${Variant | 'link' | Size | 'block'}`;
// If the given className string contains `btn-*` classes that should be set
// through other props, return an error message instead of the string.
type CheckForBtnClasses<ClassName extends string> = Extract<
  TokenizeClassName<ClassName>,
  BtnClasses
> extends never
  ? ClassName
  : { error: 'Use props to set btn classes' };

export type ButtonProps<
  Component extends React.ElementType<{ className?: string }>,
  ClassName extends string = string,
> = Omit<React.ComponentProps<Component>, 'className'> &
  StyleProps & {
    component?: Component;
    className?: CheckForBtnClasses<ClassName>;
  };

/**
 * Renders a `<button>` tag by default, but the `component` prop allows another
 * component (or tag) to be used.
 *
 * For `<button>`, the `type` prop defaults to `button` instead of `submit`.
 */
export const Button = <
  Component extends React.ElementType = 'button',
  ClassName extends string = string,
>({
  component = 'button' as Component,
  variant = 'default',
  size,
  block,
  className,
  ...props
}: ButtonProps<Component, ClassName>) => {
  const classes = classNames(
    `btn btn-${variant}`,
    size && `btn-${size}`,
    block && 'btn-block',
    className,
  );
  if (component === 'button' && !props.type) {
    (props as React.ComponentProps<'button'>).type = 'button';
  }
  const Component = component;
  return <Component className={classes} {...(props as any)} />;
};

type AnchorButtonProps = StyleProps &
  React.ComponentProps<'a'> & {
    // Require a href
    href: string;
  };

/**
 * Renders an `<a>` tag as a styled button.
 *
 * TODO: Set `role="button"`?
 * TODO: Translate `disabled` attribute to class?
 */
export const AnchorButton = (props: AnchorButtonProps) => (
  <Button component={Anchor} {...props} />
);

type LinkButtonProps = StyleProps & React.ComponentProps<typeof Link>;

/**
 * Renders the Link component as a styled button.
 */
export const LinkButton = (props: LinkButtonProps) => (
  <Button component={Link} {...props} />
);

type OmniButtonProps =
  | AnchorButtonProps
  | LinkButtonProps
  // Disallow the `component` prop here
  | Omit<ButtonProps<'button'>, 'component'>;

/**
 * Infers which styled button component to render from the props given.
 */
export const OmniButton = (props: OmniButtonProps) => {
  if ('sref' in props) {
    return <LinkButton {...props} />;
  } else if ('href' in props) {
    return <AnchorButton {...props} />;
  }
  return <Button {...props} />;
};
