import { injector } from '@maternity/ng-mun-linear';
import * as React from 'react';

import { IScope, IState } from './types';

// TODO: Support non-string param values? (should probably use a type param)
type Params = {
  [k: string]: string | undefined;
} & {
  $search: {
    [k: string]: string | undefined;
  };
  $all: {
    [k: string]: unknown;
  };
};

/**
 * Returns the current state parameters (from path and query string).
 */
export const useParams = (): Params => {
  const $rootScope = injector.get('$rootScope') as IScope;
  const $state = injector.get('$state') as IState;
  const [params, setParams] = React.useState($state.params);

  React.useEffect(() => {
    const update = () => setParams($state.params);
    const changeOff = $rootScope.$on('$stateChangeSuccess', update);
    const updateOff = $rootScope.$on('$stateUpdate', update);

    return () => {
      changeOff();
      updateOff();
    };
  }, [$rootScope, $state]);

  return params;
};
