import { Anchor } from '@maternity/mun-router';
import * as React from 'react';

import { kerbin_Phone } from '../types';

interface Props {
  phone: kerbin_Phone;
}

export const PhoneDisplay = ({ phone }: Props) => (
  <div className="clearfix">
    {/* Floats are used to control line wrapping on narrow screens */}
    <div className="pull-left">
      <Anchor href={`tel:${phone.number}`}>{phone.number}</Anchor>
      {` (${phone.type})`}
    </div>
    {phone.voicemail_ok && (
      <div className="pull-left padding-left-5">- Can leave voicemail</div>
    )}
    {phone.note && (
      <div className="pull-left padding-left-5">- {phone.note}</div>
    )}
  </div>
);
