import { react2angular } from 'react2angular';

import { NotFound } from '../../errors/NotFound';
import ngModule from '../kerbin-core-module';

ngModule

  .provider('kerbinNotFound', function($stateProvider, $routeProvider) {
    var enabled = false;

    this.$get = get;
    this.enable = enable;

    // Configure $routeProvider and $stateProvider appropriately
    function enable() {
      enabled = true;
      $routeProvider.otherwise({state: 'top.notfound'});

      $stateProvider
        .state('top.notfound', {
          views: {
            main: {
              template: '<kerbin-not-found />',
            },
          },
        })
        ;
    }

    // Service itself doesn't do anything yet
    function get($log) {
      if (!enabled) {
        $log.error('kerbinNotFound was not enabled.');
        return;
      }
      return;
    }
  })

  .component('kerbinNotFound', react2angular(NotFound))

  .run(function($rootScope, $state) {
    $rootScope.$on('$stateChangeError', function(event, next, current, err) {
        if (err && err.status === 404)
          // A state local injectable was not found.
          $state.goto('top.notfound', {isRecord: true});

        else if (err && err.status === 403)
          // A state local injectable could not be accessed.
          $state.goto('top.notfound', {isRecord: true, status: 403});
      });
  })

  ;
