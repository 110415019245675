import * as React from 'react';

import { Variant } from '../types';
import { Panel } from './Panel';
import { PanelBody } from './PanelBody';
import { PanelHeading } from './PanelHeading';
import { PanelTitle } from './PanelTitle';

interface Props {
  /**
   * Title of the panel (usually a string, but allows any JSX)
   */
  title: React.ReactNode;
  /**
   * Optional buttons (or other JSX) to add to the heading.
   */
  headerButtons?: React.ReactNode;
  /**
   * Optional body content to wrap in `<PanelBody>`. Other content (e.g.
   * tables) should be passed via `children`.
   */
  body?: React.ReactNode;
  /**
   * Bootstrap variant, defaults to `default`.
   */
  variant?: Variant;
  /**
   * Optional CSS classes to add to the `<Panel>`.
   */
  className?: string;
  /**
   * If the panel should be expanded (`true`) or collapsed (`false`);
   * defaults to `true`. This will only set the initial state, unless `open` is
   * fully controlled with `onToggle`.
   */
  open?: boolean;
  /**
   * Fired when the panel is expanded/collapsed.
   */
  onToggle?: (e: Event) => void;
  children?: React.ReactNode;
}

/**
 * Renders a panel that can be collapsed or expanded by clicking on the panel
 * heading (using `<details>`/`<summary>`).
 */
export const CollapsiblePanel = ({
  title,
  headerButtons,
  body,
  variant = 'default',
  className,
  open = true,
  onToggle,
  children,
}: Props) => (
  <Panel
    component="details"
    variant={variant}
    className={className}
    open={open}
    onToggle={onToggle}
  >
    <PanelHeading component="summary">
      {/* TODO: This is consistent with the `collapsible-panel-heading` jade
      mixin, but we should consider adjusting the styles so this markup can
      be more logical... */}
      <h4>
        <i className="fa fa-fw fa-caret-right hidden-open" />
        <i className="fa fa-fw fa-caret-down hidden-closed" />
        <PanelTitle component="span">{title}</PanelTitle>
        {headerButtons}
      </h4>
    </PanelHeading>
    {body != null && <PanelBody>{body}</PanelBody>}
    {children}
  </Panel>
);
