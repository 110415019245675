import * as React from 'react';
import * as ReactDOM from 'react-dom';

/**
 * Render some JSX in the DOM that might resolve the returned promise.
 *
 * This can be useful when an event should render a modal that may return a
 * result.
 */
export const promisifyRender = <T>(
  /** A render function that is passed `resolve` and `cleanup` functions which
   * should return JSX. */
  render: (
    resolve: (value: T | PromiseLike<T>) => void,
    cleanup: () => void,
  ) => React.ReactElement,
  /** Optional element where the rendered content should be inserted as a child.
   * Defaults to `document.body`. */
  root = document.body,
): Promise<T> => {
  const element = root.appendChild(document.createElement('div'));
  const cleanup = () => {
    ReactDOM.unmountComponentAtNode(element);
    root.removeChild(element);
  };
  return new Promise((resolve) => {
    ReactDOM.render(render(resolve, cleanup), element);
  });
};
