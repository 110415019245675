import { DeepPartialReadonly, Path, PathValue } from '@maternity/mun-types';
import * as React from 'react';

import { FieldValues, FormApi, FormState } from './types';
import { getIn } from './utils';

const UNSET = Symbol();
const DONE = Symbol();
type Sentinels = typeof UNSET | typeof DONE;

/**
 * This hook (dynamically) calculates a default value for a field based on
 * other data in the form. If the user edits the field, or the field has an
 * initial value that differs from the calculated value, recalculation stops.
 *
 * Default values that do not depend on other form data should be passed as
 * initial values to the form rather than using this hook.
 *
 * If the value is an object, the calculation function should preserve object
 * identity across calls.
 */
export const useDefaultValue = <V extends FieldValues, M extends Path<V>>(
  /** Form API handle */
  form: FormApi<V>,
  /** Model path where the value should be inserted */
  model: M,
  /** Function that calculates the default value from the form data */
  calculate: (values: DeepPartialReadonly<V>) => PathValue<V, M>,
) => {
  // This component might not need to rerender when the computed value changes,
  // so use a ref instead of state.
  const lastCalc = React.useRef<PathValue<V, M> | Sentinels>(UNSET);

  const checkForUpdates = React.useCallback(
    (state: FormState<V>) => {
      if (lastCalc.current === DONE) return;

      // Typescript can't tell that `model` is compatible with
      // `values`/`defaultValues` due to the deep partial/readonly wrappers, so
      // the `getIn` calls use `any`.
      const value = getIn<any, any>(state.values, model);
      const defaultValue = getIn<any, any>(state.defaultValues, model);

      // When the value is changed by something other than this hook (e.g. user
      // input), stop deriving values.
      if (value !== defaultValue && value !== lastCalc.current) {
        lastCalc.current = DONE;
        return;
      }

      const result = calculate(state.values);
      if (value !== result) {
        // On the first render, if the value is not nully and differs from the
        // derived value, stop deriving values.
        if (lastCalc.current === UNSET && value != null) {
          lastCalc.current = DONE;
          return;
        }
        form.setFieldValue(model, result);
      }
      lastCalc.current = result;
    },
    [form, model, calculate],
  );

  React.useEffect(() => {
    // Calculate after first render (avoids set state during render)
    checkForUpdates(form.getFormState());

    // Watch for future form updates
    return form.subscribe(checkForUpdates);
  }, [form, checkForUpdates]);
};
