/**
 * mun-form-status v0.0.1
 *
 * Adds the `munSubmit` directive for form elements, which is a replacement
 * for `ngSubmit`. This directive adds two properties to the form's controller,
 * `$activated` indicating the form submission has been attempted, and
 * `$submitting` indicating the form is currently submitting. The `mun-submit`
 * attribute should be passed the function to evaluate when submitting. It will
 * not submit the form if it is invalid.
 *
 */

const ngModule = angular.module('mun-form-status', [])
  .directive('munSubmit', function($parse, $q, $log) {
    return {
      require: 'form',
      link: function(scope, element, attr, formCtrl) {
        var fn = $parse(attr.munSubmit);

        formCtrl.$activated = false;
        formCtrl.$submitting = false;
        element.on('submit', function(event) {
          if (formCtrl.$submitting) {
            // TODO: Add more info to aid debugging
            $log.info('Attempted duplicate submit of a form');
            return;
          }
          scope.$apply(function() {
            formCtrl.$activated = true;
            if (formCtrl.$clearServerValidity) {
              formCtrl.$clearServerValidity();
            }
            if (formCtrl.$valid) {
              formCtrl.$submitting = true;
              formCtrl.$whenSubmit = $q.when()
                .then(fn.bind(this, scope, {$event: event}))
                .then(function() {
                  formCtrl.$setPristine();
                })
                .finally(function() {
                  formCtrl.$submitting = false;
                  delete formCtrl.$whenSubmit;
                })
                ;
            }
          });
        });
      }
    };
  })
  // propagate properties to nested ngForm controllers
  .directive('ngForm', function() {
    return {
      restrict: 'EAC',
      require: 'form',
      link: function(scope, element, attr, formCtrl) {
        var parentCtrl = element.parent().controller('form');

        if (parentCtrl) {
          Object.defineProperties(formCtrl, {
            $activated: { get: function() { return parentCtrl.$activated; } },
            $submitting: { get: function() { return parentCtrl.$submitting; } },
          });
        }
      }
    };
  })
  ;

export default ngModule.name;
