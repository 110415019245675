interface ScrollIntoViewIfNeededMethod {
  // This is a non-standard (WebKit-specific) method, so it is not included in
  // Typescript's DOM types.
  scrollIntoViewIfNeeded?: (centerIfNeeded?: boolean) => void;
}

/** Scroll to the given element if it not already visible. */
export const scrollToElement = (el: Element & ScrollIntoViewIfNeededMethod) => {
  // Unfortunately, Safari doesn't support the options object form of
  // scrollIntoView, so use scrollIntoViewIfNeeded for Webkit browsers.
  if (el.scrollIntoViewIfNeeded) {
    el.scrollIntoViewIfNeeded(false);
  } else {
    el.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }
};
