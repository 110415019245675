import * as React from 'react';

/**
 * Returns a function that can be called to determine if the component is
 * currently mounted.
 *
 * This is often useful in asynchronous callbacks to avoid state updates on
 * unmounted components.
 */
export const useIsMounted = (): (() => boolean) => {
  const mountedRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return React.useCallback(() => mountedRef.current, []);
};
