import { useIsMounted } from '@maternity/mun-cantrips';
import { DocsAsRefs, Path, PathValue } from '@maternity/mun-types';
import * as React from 'react';

import {
  EnforceModelType,
  EnforceRequired,
  FieldValues,
  FormApi,
  FormState,
} from '../types';
import { useField } from '../useField';
import { getIn } from '../utils';
import { requiredNonNullableValidator } from '../validators';

type SetState<T> = (value: T | ((prev: T) => T)) => void;

/** The combobox context type returned by `useComboboxCtx`. */
export interface ComboboxCtx<T> {
  /** Form API handle */
  form: FormApi<any>;
  /** Model path */
  model: string;
  /** Called to convert the model value to a string for the input */
  valueToString: (value: T | undefined) => string;
  /** Called when the input value changes */
  onInputChange?: (inputValue: string) => void;
  /** Called when an option is selected */
  onSelect?: (value: T | undefined) => void;
  /** When true, disables the input and interactions */
  disabled?: boolean;
  /** If true, enable required validation */
  required?: boolean;
  /** The current selection (model value) */
  selection: T | undefined;
  /** Sets the selection (model value) and invokes `onSelect` */
  setSelection: (value?: T) => void;
  /** Sets the touched state of the model */
  setTouched: (touched?: boolean) => void;
  /** Indicates if the menu is open */
  isOpen: boolean;
  /** Sets the open state of the menu */
  setIsOpen: SetState<boolean>;
  /** The value of the input element. This should be debounced/throttled if
   * used to fetch options from the server. */
  inputValue: string;
  /** Sets the value of the input element. Does not invoke `onInputChange`. */
  setInputValue: SetState<string>;
  /** Reference to the input element */
  inputRef: React.RefObject<HTMLInputElement>;
  /** Id of the menu element */
  menuId: string;
  /** Reference to the menu element */
  menuRef: React.RefObject<HTMLUListElement>;
}

type ComboboxConfig<
  V extends FieldValues,
  M extends Path<V>,
  T = PathValue<V, M>,
> = {
  /** Form API handle */
  form: FormApi<V>;
  /** Model path */
  model: M;
} & Pick<
  ComboboxCtx<T>,
  'valueToString' | 'onInputChange' | 'onSelect' | 'disabled'
> &
  EnforceModelType<V, M, DocsAsRefs<T>> &
  EnforceRequired<V, M>;

/**
 * This hook returns a combobox context object that should be passed to the
 * combobox components.
 *
 * The context object is passed via props instead of React's Context to allow
 * option values to be type checked. The option type can be inferred from the
 * model path in the form or specified explicitly for cases where the form
 * type and/or model path are dynamic.
 *
 * Rather than registering options and tracking which option is focused via
 * React state, the combobox components currently manage the focused option
 * with DOM operations targeting the `.focused` CSS class.
 */
export const useComboboxCtx = <
  V extends FieldValues,
  M extends Path<V>,
  T = PathValue<V, M>,
>({
  form,
  model,
  onInputChange,
  onSelect,
  valueToString,
  disabled,
  required,
}: ComboboxConfig<V, M, T>): ComboboxCtx<T> => {
  const isMounted = useIsMounted();
  const [isOpen, setIsOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const inputRef = React.useRef<HTMLInputElement>(null);
  const menuRef = React.useRef<HTMLUListElement>(null);
  // TODO: Replace with `useId` once on React 18
  const menuId = React.useMemo(
    () => `combobox_menu_${model.replace(/\.|\[|\]/g, '__')}`,
    [model],
  );

  const validators = React.useMemo(
    () => (required ? [requiredNonNullableValidator] : []),
    [required],
  );
  const [selection, { setValue, setTouched }] = useField<PathValue<V, M>>({
    form,
    model,
    validators,
  });
  const setSelection = React.useCallback(
    (v) => {
      setValue(v);
      onSelect?.(v);
    },
    [setValue, onSelect],
  );

  // Set the input value based on the model value
  React.useEffect(() => {
    const updateInput = (formState: FormState<V>) => {
      if (!isMounted()) return;
      const modelValue = getIn<any, any>(formState.values, model);
      setInputValue(valueToString(modelValue));
    };

    updateInput(form.getFormState());

    return form.subscribe(updateInput);
  }, [isMounted, form, model, valueToString]);

  return React.useMemo(
    () => ({
      form,
      model,
      onInputChange,
      onSelect,
      valueToString,
      disabled,
      required,
      selection,
      setSelection,
      setTouched,
      isOpen,
      setIsOpen,
      inputValue,
      setInputValue,
      inputRef,
      menuId,
      menuRef,
    }),
    [
      form,
      model,
      onInputChange,
      onSelect,
      valueToString,
      disabled,
      required,
      selection,
      setSelection,
      setTouched,
      isOpen,
      setIsOpen,
      inputValue,
      setInputValue,
      menuId,
    ],
  );
};
