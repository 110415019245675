import { OmniButton } from '@maternity/mun-layout';
import * as React from 'react';

import { useBypassFormSafety } from './Form';

type Props = React.ComponentProps<typeof OmniButton>;

/**
 * Cancel button for use in forms which skips the form safety check.
 *
 * The button text defaults to "Cancel" and the variant defaults to "link",
 * but these can be overridden.
 */
export const Cancel = ({ children, onClick, ...props }: Props) => {
  const bypassFormSafety = useBypassFormSafety();
  const handleClick = (event: React.MouseEvent<any>) => {
    bypassFormSafety();
    return onClick && onClick(event);
  };
  // Force links to use <button> tags to avoid interference from angular
  // (angular's click handler for <a> tags triggers the state change before the
  // react click handler can bypass the form safety check)
  // TODO: Remove this once not using angular
  if ('sref' in props && props.tag == null) {
    props.tag = 'button' as any;
  }

  return (
    <OmniButton variant="link" onClick={handleClick} {...(props as any)}>
      {React.Children.count(children) ? children : 'Cancel'}
    </OmniButton>
  );
};
