import _ from 'lodash';
import * as React from 'react';

/**
 * Returns a debounced function that delays invoking the given (memoized)
 * callback until after `wait` milliseconds have elapsed since the last time
 * the debounced function was invoked. The callback is invoked with the last
 * arguments provided to the debounced function.
 */
// The return type of the callback is not propagated to the debounced function
// because the debounced function returns the result of the previous callback
// invocation, which might be unexpected, particularly with promises.
export const useDebounceCallback = <Args extends unknown[]>(
  callback: (...args: Args) => unknown,
  wait: number,
): ((...args: Args) => void) => {
  // TODO: Store callback in a ref so it doesn't need to be memoized?
  // TODO: Implement debounce instead of using lodash?
  const debounced = React.useMemo(
    () => _.debounce(callback, wait),
    [callback, wait],
  );
  // When component unmounts, don't invoke the function again
  React.useEffect(() => () => debounced.cancel(), [debounced]);

  return debounced;
};
