/*
io - helpers for configuring endpoints.

This module exposes a single angular service, munDocIO. This service provides a
slew of helpers for defining endpoints with sensible configuration.

These configuration functions all take an endpoint descriptor as their first
argument, but if you pass in a string instead, they will use munDocEndpoints to
find the endpoint data.

They also a take an options object, which defaults to munDocIO.defaultIOOptions;
you can modify this to customize how urls are built, how serialization works,
etc., but in general you shouldn't have to.

The option object also includes the method (GET, POST, etc.), but you usually
don't need to specify that either because the various configuration functions
all have sensible defaults.


## munDocIO.mkBaseIO

This is the simplest case. You give it an endpoint spec, and it returns a BaseIO
function that you can call to invoke that endpoint. It takes care of
serialization and deserialization based on the typegraphs provided in the
endpoint spec. The return value of BaseIO is a promise that will resolve to the
parsed response from the endpoint.

The response will be a response object as returned by angular's $http function.

## munDocIO.mkAccessIO

This extends mkBaseIO and adds caching. If you invoke an AccessIO and it would
fetch a URL that it's fetched before, it'll use the previously returned value
unless doccache_verify indicates the cached response is stale.

Additionally, an AccessIO will add .$refresh to resp.data; this is just a
callback that re-invokes the AccessIO with the same arguments.

Finally, the promise returned by an AccessIO will have a .forScope(scope)
method. AccessIO(args).forScope(scope) is shorthand for calling
munDocKeepalive.forScope on (scope, resp.data, resp.data.$refresh). In other
words, it sets up a scope-level keepalive for the response using the
aforementioned $refresh function.


## munDocIO.mkDocLister, munDocIO.mkDocLoader

In the future these may do something special; right now they're just aliases for
mkAccessIO.


## munDocIO.mkActionIO

This is just a BaseIO with the method defaulting to POST.


## munDocIO.mkDocSaver

A DocSaver is an ActionIO with an additional .prep() method. This method
constructs an object to use as the basis for an editing task. Typical usage when
creating an object is something like:

var new_obj;
io.prep().then(function(item){
  new_obj = item;
  <edit new_obj through various workflows>
  io(new_obj);
});

You can also pass a source object to io.prep(); the returned value will inherit
the properties of the source object. Thus, when editing an object, you'd do:

var edit_obj;
io.prep(original_object).then(function(item){
  edit_obj = item;
  <edit edit_obj through various workflows>
  io(edit_obj);
});

The intermediate object edit_obj exists outside of the document cache, and thus
can be modified without changing the cached copy of the document.


Additionally, on 400/403 responses with trees of invalid markers in the
response, a DocSaver will zip those errors to its object, so that you can
display those errors to the user.

TODO better document how exactly errors are zipped to objects.


## munDocIO.mkDocDeleter

This is just a DocSaver with the method defaulting to DELETE.

*/

import _ from 'lodash';
import { extend, mixin } from '@maternity/mun-extend';
import * as vg from '@maternity/vertigo';
import tv from '@maternity/travesty';

import zip_errors_to from './zip-errors-to';

import ngModule from './mun-doc-module';


ngModule

  .factory('munDocIO', function($http, $q, munDocEndpoints, munDocSchemas, munDocTV, munDocCache,
      munDocKeepalive) {
    var prepDispatcher = munDocTV.copy_or_create.sub(),
        defaultIOOptions = {
          method: 'GET',
          buildUrl: nontrivialBuildUrl,
          dictify: basicDictify,
          undictify: cachingUndictify,
          prep: {
            dispatcher: prepDispatcher,
            preprocessTypegraph: preprocessPrepTypegraph,
          },
          get hasRequestBody() { return !/^(?:GET|HEAD)$/i.test(this.method); },
        },
        invalidation_monitor = munDocKeepalive.invalidation_monitor;

    prepDispatcher.register(prepDocument, tv.Document.marker);
    function prepDocument(disp, value, options) {
      if (!disp.get_path(['uid'], null)) {
        // The field is a document reference, so copy the source by reference.
        return disp.for_marker(tv.Leaf()).call(value, options);
      }
      return disp.super(tv.Document.marker).call(value, options);
    }

    return {
      defaultIOOptions: defaultIOOptions,
      mkBaseIO: mkEndpointIO,
      mkAccessIO: mkAccessIO,
      mkActionIO: mkActionIO,
      mkDocLister: mkAccessIO,
      mkDocLoader: mkAccessIO,
      mkDocSaver: mkDocSaver,
      mkDocDeleter: mkDocDeleter,
    };

    function mkAccessIO(ep, options) {
      options = options || {};
      var baseIO = mkEndpointIO(ep, options),
          spec = (typeof ep === 'string' ? munDocEndpoints[ep] : ep)[
            (options.method || 'GET').toUpperCase()],
          responseTG = munDocSchemas[spec.message_out];

      if (options.cache !== false)
        io.cache = _.isObject(options.cache) ? options.cache : {};
      mixin(io, baseIO);
      io._withResponse = wrapWithResponse(io._withResponse);

      return io;

      function io() {
        var retry = io.bind.apply(io, [this].concat([].slice.call(arguments))),
            url = io.cache && io._buildUrl.apply(this, arguments),
            cached = io.cache?.[url];

        if (cached) {
          return mixin(cached
            .then(
              function(resp) {
                if (io.cache[url] !== cached)
                  return retry();
                D('Verifying cached response');
                if (responseTG && !munDocTV.doccache_verify.call(responseTG, resp.data)) {
                  D('Cache is stale');
                  delete io.cache[url];
                  return retry();
                }
                //D('Cache is OK');
                return resp;
              }), {
                forScope: forScope});
        }

        //D('Requesting');

        return loadResponse(url, baseIO.apply(this, arguments), retry);

        function D() {}
        //function D() {
        //  console.log.apply(console,
        //      [options.method+' '+args.url].concat([].slice.call(arguments, 0)));
        //}
      }

      function forScope(scope) {
        // Automatically refresh the response data when it is unloaded, until the scope is
        // destroyed.
        // This will work for loaders because the doccache ensures documents are updated in
        // place.  For queries the same documents will also be updated, but the matching result
        // set may be different.
        return this
          .then(function(resp) {
            var restore = options?.restore || resp.data.$refresh,
                cancel = munDocKeepalive(resp.data, restore);
            scope.$on('$destroy', cancel);
            return resp;
          });
      }

      function loadResponse(url, resp, retry) {
        resp = resp
          .then(
            function(resp) {
              mixin(resp.data, {
                  get $refresh() { return refresh; },
                });

              return resp;

              function refresh() {
                return retry()
                  .then(function(resp) { return resp.data; });
              }
            });

        if (io.cache)
          io.cache[url] = resp;

        resp.forScope = forScope;

        return resp;
      }

      function wrapWithResponse(baseWithResponse) {
        return withResponse;

        function withResponse(/* urlArgs..., data?, resp */) {
          // screw scanning backwards for the last non-null argument
          var resp = baseWithResponse.apply(this, arguments),
              args = [].slice.call(arguments, 0, -1),
              retry = io.bind.apply(io, [null].concat(args)),
              url = io._buildUrl.apply(this, args);

          return loadResponse(url, resp, retry);
        }
      }
    }

    function mkActionIO(ep, options) {
      options = options || {};
      options.method = options.method || 'POST';
      return mkEndpointIO(ep, options);
    }

    function mkDocSaver(ep, options) {
      /**
      * The `io.prep()` method is used to create an instance of the endpoint's
      * message_in typegraph. The prep method takes a single optional argument,
      * a source object to copy data from when constructing the instance.
      *
      * The default behavior will accept sparse source objects, and will copy
      * objects by reference at document reference fields (leaf/untraversed
      * documents).
      *
      * Options:
      * The options for prep can specified when the io is instantiated by
      * including an object as the `prep` property of the io's options, or
      * using the `with(options)` method of the `prep` method (e.g.
      * `io.prep.with({...})(sourceObj)`).
      * - docset: DocSet to use for any Documents.
      * - keepFields: List of field path strings ('/' separated) to keep from
      *   the source object when creating the instance.
      * - preprocessTypegraph: A hook for preprocessing the message_in
      *   typegraph (do not mutate the input typegraph).
      * - dispatcher: A dispatcher for creating an instance from the typegraph
      *   and source object.
      *
      * NOTE: Fields in referenced documents and fields that are kept on the
      * instance with keepFields should not be mutated, as they are copied by
      * reference.
      */

      options = options || {};
      var actionIO = mkActionIO(ep, options),
          spec = (typeof ep === 'string' ? munDocEndpoints[ep] : ep)[
            (options.method || 'POST').toUpperCase()],
          tg = munDocSchemas[spec.message_in];

      io.prep = prepWithOptions.call({}, extend(defaultIOOptions.prep, options.prep));

      return io;

      function io() {
        var data;

        for (var dataPos = arguments.length-1; dataPos >= 0; dataPos--) {
          if ((data = arguments[dataPos]) != null)
            break;
        }

        return actionIO.apply(this, arguments)
          .catch(
            function(e) {
              var invalidGraph;

              if (e != null &&
                  (e.status === 400 || e.status === 403) &&
                  e.data.invalid != null)
                invalidGraph = vg.from_dict(e.data.invalid);

              else if (e instanceof tv.Invalid)
                invalidGraph = e.as_graph();

              if (tg && data && invalidGraph)
                zip_errors_to.call(tg, data, invalidGraph);

              return $q.reject(e);
            });
      }

      function prepWithOptions(options) {
        options = options || {};
        if (this.options)
          options = extend(this.options, options);

        prep.options = options;
        prep.with = prepWithOptions;
        return prep;

        function prep(source) {
          return $q.when(source)
            .then(function(source) {
              var docset = options.docset || new munDocTV.DocSet(),
                  processedTG = options.preprocessTypegraph(tg, options);

              return options.dispatcher.call(processedTG, source, {in_docset: docset});
            });
        }
      }
    }

    function mkDocDeleter(ep, options) {
      options = options || {};
      options.method = options.method || 'DELETE';
      return mkDocSaver(ep, options);
    }

    function mkEndpointIO(ep, options) {
      /**
       * ep: Endpoint name or definition
       * options:
       * - method: Http method to use
       * - buildUrl: Function that builds the url from the rule and data
       * - dictify: Dictifier or function to prepare request data
       * - undictify: Undictifier or function to process response data
       * - hasRequestBody: Whether data is passed in the request body
       * ? extraConfig: Extra config for $http
       */
      var origEp = ep;
      options = extend(defaultIOOptions, options);
      options.dictify = maybeAdaptGraphDispatcher(options.dictify);
      options.undictify = maybeAdaptGraphDispatcher(options.undictify);

      if (typeof ep === 'string')
        ep = munDocEndpoints[ep];
      if (!ep)
        throw Error("Missing endpoints. " + origEp + " is invalid.");

      var spec = ep[options.method.toUpperCase()],
          requestTG = munDocSchemas[spec.message_in],
          responseTG = munDocSchemas[spec.message_out];

      io._withResponse = withResponse;
      io._buildUrl = buildUrl;

      return io;

      function io(/* urlArgs..., data? */) {
        /**
         * Make the request, following the spec. Has zero or more optional url building parameters,
         * followed by a data parameter if a request body is needed.
         *
         * data properties:
         * - urlArgs...: Args used in construction the request uri and query string
         * - data: Optional object to dictify using the message in schema
         */

        var args = processArgs(arguments);

        return loadResponse($http({
            method: options.method,
            url: args.url,
            data: requestTG && options.dictify(requestTG, args.data),
          }));
      }

      function processArgs(args) {
        // Returns {url, data}

        var urlArgs,
            data,
            url;

        if (options.hasRequestBody) {
          for (var dataPos = args.length-1; dataPos >= 0; dataPos--) {
            if ((data = args[dataPos]) != null)
              break;
          }

          if (dataPos < 0)
            throw new Error('Data required to use endpoint: '+spec.endpoint);

          urlArgs = [].slice.call(args, 0, dataPos);

        } else {
          urlArgs = [].slice.call(args, 0);
        }

        url = options.buildUrl(spec, urlArgs, data);

        return {
          url: url,
          data: data,
        };
      }

      function buildUrl(/* urlArgs..., data? */) {
        return processArgs(arguments).url;
      }

      function withResponse(/* urlArgs..., data?, resp */) {
        // This interface is provided for the account session endpoint which we
        // want to initialize with a response from the login endpoint, or a
        // prior response from the same endpoint at application boot time.  It
        // shouldn't be relied on too heavily.
        var resp;

        for (var respPos = arguments.length-1; respPos >= 0; respPos--) {
          if ((resp = arguments[respPos]) != null)
            break;
        }

        if (respPos < 0)
          throw new Error('withResponse requires a response object');

        if (typeof resp.then !== 'function')
          resp = $q.when(resp);

        return loadResponse(resp);
      }

      function containsChangeSet(tg) {
        var node = tv.to_typegraph.call(tg).get_path(['changes'], null),
            marker = node && tv.unwrap.call(node.value);
        return marker instanceof munDocSchemas['kerbin.ChangeSet'].marker;
      }

      function loadResponse(resp) {
        return resp
          .then(function(resp) {
            if (responseTG && containsChangeSet(responseTG)) {
              var changes = resp.data.changes;

              if (changes?.created) {
                changes.created.forEach(function(ev) {
                    var type = munDocSchemas[ev.type];
                    if (type != null)
                      munDocCache.created(type, ev.uid);
                  });
              }

              if (changes?.updated) {
                changes.updated.forEach(function(ev) {
                    var type = munDocSchemas[ev.type];
                    if (type != null)
                      munDocCache.updated(type, ev.uid);
                  });
              }

              if (changes?.deleted) {
                changes.deleted.forEach(function(ev) {
                    var type = munDocSchemas[ev.type];
                    if (type != null)
                      munDocCache.deleted(type, ev.uid);
                  });
              }
            }

            return resp;
          })
          .then(function(resp) {
            if (responseTG && resp.status === 200 &&
                !(resp.data instanceof tv.to_typegraph.call(responseTG).value.target_proto.constructor))
              resp.data = options.undictify(responseTG, resp.data);

            // TODO: 201? 204? 206?

            return resp;
          })
          .catch(function(resp) {
            // TODO: 400
            return $q.reject(resp);
          });
      }
    }

    //function trivialBuildUrl(ep, args, data) {
    //  var url = ep.rule,
    //      qs = buildQueryString(args[0]);

    //  if (qs)
    //    url += '?'+qs;

    //  return url;
    //}

    function nontrivialBuildUrl(spec, args, data) {
      var qs, url, argI = 0;

      url = spec.rule.replace(/<(\w+)>/g, function(_, name) {
          var arg = argI < args.length
                ? args[argI++]
                : null;

          if (arg != null)
            return typeof arg === 'object'
              ? arg.uid
              : arg;

          return data.uid;
        });

      if (args[argI] != null)
        qs = buildQueryString(args[argI]);

      if (qs)
        url += '?'+qs;

      return url;
    }

    function buildQueryString(pairs) {
      if (pairs == null)
        return '';

      // TODO: mun-url needs some query string manipulation routines
      if (!Array.isArray(pairs))
        pairs = Object.keys(pairs)
          .map(function(k) { return {key: k, value: pairs[k]}; });

      return pairs
        .map(function(d) { return encodePair(d.key, d.value); })
        .join('&');

      function encodePair(k, v) {
        return encodeURIComponent(k)+'='+encodeURIComponent(v);
      }
    }

    function basicDictify(tg, obj) {
      return tg
        ? munDocTV.dictify.call(tg, obj, {doc_storage: {}})
        : obj;
    }

    function cachingUndictify(tg, data) {
      var options = {
            in_docset: munDocCache,
            allow_updates: true,
          },
          msg = munDocTV.undictify.call(tg, data, options);

      // Documents in the message already have invalidation_monitor applied, but the message can
      // be invalidation_monitor'd.
      invalidation_monitor.call(msg);

      return msg;
    }

    function maybeAdaptGraphDispatcher(disp_or_fn) {
      if (typeof disp_or_fn === 'function')
        return disp_or_fn;

      if (disp_or_fn && typeof disp_or_fn.call === 'function')
        return disp_or_fn.call.bind(disp_or_fn);
    }

    function preprocessPrepTypegraph(tg, options) {
      options = options || {};
      if (!options.keepFields)
        return tg;

      var keepGraph = options.keepFields.reduce(function(root, f) {
            var path = f.split('/').filter(_.identity),
                target = root;
            path.forEach(function(bit) {
              if (!target.get_path([bit], null))
                target.set_edge(bit, new vg.PlainGraphNode());
              target = target.get_path([bit]);
            });
            target.value = tv.Leaf();
            return root;
          }, new vg.PlainGraphNode()),
          graphs = [tv.to_typegraph.call(tg), keepGraph];

      // This is basically vertigo.overlay(*graphs, merge_fn='union') in python
      return vg.map(vg.zip(graphs, 'union'), function(vals) {
          // Use first defined value
          return _.find(vals, function(v) { return typeof v !== 'undefined'; });
        });
    }
  })

  ;
