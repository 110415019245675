/**
 * mun-form-safety v0.0.2
 *
 * Provides a mechanism to warn the user when leaving a form with unsaved changes.
 *
 */
import munUnloadDetectModule from '@maternity/ng-mun-unload-detect';

const ngModule = angular.module('mun-form-safety', [munUnloadDetectModule])
  /**
   * `formSafetyProvider` allows configuration of the handler used to confirm
   * that the user wishes to discard their changes to a form. The provider's
   * `confirmHandler` property should be set to an annotated function that
   * returns a promise, resolving if the user allows the form to unload,
   * and rejecting otherwise.
   *
   * Example:
   * .config(function(formSafetyProvider) {
   *   formSafetyProvider.confirmHandler = handler;
   *
   *   handler.$inject = ['$q', '$timeout'];
   *   function handler($q, $timeout) {
   *     var deferred = $q.defer();
   *     $timeout(function() {
   *       if (userConfirmsFormUnload()) {
   *         deferred.resolve();
   *       } else {
   *         deferred.reject();
   *       }
   *     }, 0);
   *     return deferred.promise;
   *   }
   * })
   */
  .provider('formSafety', function() {
    var self = this;

    this.confirmHandler = function() {};
    this.$get = get;

    get.$inject = ['$injector'];
    function get($injector) {
      return {
        confirmHandler: function() {
          return $injector.invoke(self.confirmHandler);
        }
      };
    }
  })
  /**
   * Add the `formSafety` attribute to a form to  trigger the configured
   * confirmation handler (or display a confirmation dialog if the window is
   * unloading) if the form is dirty. The confirmation can be suppressed by
   * calling the `$safeToUnload` method of the form controller or insuring the
   * form is pristine.
   */
  .directive('formSafety', function($q, unloadDetect, formSafety, globalBypassFormSafety) {
    return {
      require: 'form',
      link: function(scope, element, attr, formCtrl) {
        var markedSafe = false,
            removeHandler;

        formCtrl.$safeToUnload = function() { markedSafe = true; };

        removeHandler = unloadDetect.registerHandler(element, function(sync) {
          if (sync) {
            if (formCtrl.$submitting) {
              // If submit is still running, it is not safe to unload synchronously.
              return false;
            } else {
              return isSafe();
            }
          }
          // Wait for submit to finish, if necessary.
          return (formCtrl.$whenSubmit || $q.reject())
            .catch(function() {
              if (isSafe())
                // Form is safe, so resolve the promise
                return true;
              // Run the confirmation handler
              return formSafety.confirmHandler();
            });
        });

        scope.$on('$destroy', function() {
          removeHandler();
        });

        function isSafe() {
          return markedSafe || !formCtrl.$dirty || globalBypassFormSafety.safe;
        }
      },
    };
  })
  /**
   * Add the `bypassFormSafety` attribute to suppress confirmation when the
   * element is clicked. This prevents an extra step when e.g. the user clicks
   * a "cancel" button. Note: Once bypassed, the form is always considered safe
   * to unload.
   */
  .directive('bypassFormSafety', function() {
    return {
      require: '^form',
      priority: -1, // run this click handler before ng-click
      link: function(scope, element, attr) {
        var formCtrl = element
              .closest('[form-safety]')
              .controller('form');
        element.on('click', function(event) {
          formCtrl.$safeToUnload();
        });
      },
    };
  })
  // A service providing a way to suppress confirmation for all forms.
  // TODO: Remove mun-server-error-handler's dependency on this so it can be
  //       removed.
  .factory('globalBypassFormSafety', function() {
    var self = {
      safe: false,
      bypass: function() {
        self.safe = true;
      },
      reset: function() {
        self.safe = false;
      }
    };
    return self;
  })
  // When the expression passed to the `formSetDirty` attribute becomes truthy,
  // the form will be set to the dirty state.
  .directive('formSetDirty', () => ({
    require: {
      formCtrl: 'form',
    },
    bindToController: true,
    controller: /* @ngInject */ function($attrs, $scope) {
      $scope.$watch($attrs.formSetDirty, (curr, prev) => {
        if (curr && !prev) this.formCtrl.$setDirty();
      });
    },
  }))
  ;

export default ngModule.name;
