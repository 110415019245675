var { extend } = require('@maternity/mun-extend'),
    vg = require('@maternity/vertigo'),

    invalid = require('./invalid'),
    Invalid = invalid.Invalid,
    InvalidAggregator = invalid.InvalidAggregator,
    base = require('./base'),
    Marker = base.Marker,
    to_typegraph = base.to_typegraph,
    traverse = base.traverse,
    dictify = base.dictify,
    undictify = base.undictify;


var List = exports.List = Marker.sub('tv.List');
List.prototype.of = function(sub) {
  return new vg.PlainGraphNode(this, [{key: 'sub', node: to_typegraph.call(sub)}]);
};

traverse.register(traverse_list, List);
function traverse_list(disp, value, options) {
  var sub = disp.get_child('sub'),
      zipgraph = options?.zipgraph,
      subzip = zipgraph && zipgraph.get_child('sub'),
      subopts = zipgraph != null ? extend(options, {zipgraph: subzip}) : options,
      edges = value.map(function(v, i) {
        return {key: i, node: sub.call(v, subopts)};
      }),
      v = zipgraph != null ? [value, zipgraph.value] : value;

  return new vg.PlainGraphNode(v, edges);
}

dictify.register(dictify_list, List);
function dictify_list(disp, value, options) {
  var sub = disp.get_child('sub');

  return value.map(function(v) { return sub.call(v, options); });
}

undictify.register(undictify_list, List);
function undictify_list(disp, value, options) {
  if (!Array.isArray(value))
    throw new Invalid('type_error');

  var agg = new InvalidAggregator(),
      sub = disp.get_child('sub');

  try {
    return value.map(function(v, i) {
        return agg.checking_sub(i, sub.call.bind(sub, v, options));
      });

  } finally {
    agg.raise_if_any();
  }
}
