import { useEffect, useRef } from 'react';

/**
 * Execute the effect `fn` no more than one time.  Setting the optional argument
 * `ready` to false will cause the effect to be deferred.
 */

export function useEffectOnce(fn: () => void, ready: boolean = true) {
  interface OnceState {
    activated: boolean;
    ready: boolean;
  }
  const state = useRef<OnceState>({ activated: false, ready: false });
  state.current.ready = ready;
  useEffect(() => {
    if (!state.current.ready) return;
    if (state.current.activated) return;
    state.current.activated = true;
    fn();
  });
}
