import classNames from 'classnames';
import * as React from 'react';

type Props<C extends React.ElementType> = React.ComponentProps<C> extends {
  className?: string;
}
  ? React.ComponentProps<C> & { component?: C }
  : never;

/**
 * Renders a panel heading.
 *
 * The `component` prop controls what element or component is rendered
 * (defaults to a `div` element). Any other props are passed through.
 */
export const PanelHeading = <C extends React.ElementType = 'div'>({
  component = 'div',
  className,
  ...props
}: Props<C>) => {
  const Component = component;
  return (
    <Component className={classNames('panel-heading', className)} {...props} />
  );
};
