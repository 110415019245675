import * as React from 'react';

import { FormApi, FormState } from './types';
import { getIn } from './utils';

const UNSET = Symbol();

/**
 * Use this hook when a single model value maps to multiple inputs or requires
 * a transformation to be compatible with an input component.
 *
 * Given a model path and from/to model functions, it returns a view value path
 * that can be used as a model with input components.
 *
 * This hook must be called from a component inside a `<Form>`.
 *
 * If this hook is called multiple times for the same model, the calls should
 * use the `suffix` parameter to avoid collisions.
 */
export const useViewValue = <ModelValue, ViewValue>(
  form: FormApi<any>,
  model: string,
  fromModel: (modelValue: ModelValue) => ViewValue,
  toModel: (viewValue: ViewValue) => ModelValue,
  suffix: string = '',
) => {
  const lastModelValue = React.useRef<ModelValue | typeof UNSET>(UNSET);
  const lastViewValue = React.useRef<ViewValue | typeof UNSET>(UNSET);
  const viewValuePath = React.useMemo(() => {
    // Replace dot and bracket characters to avoid nesting and support view
    // values of ancestor and leaf paths at the same time.
    const key = model.replace(/\.|\[|\]/g, '__');
    // Travesty will generally omit unknown keys when dictifying, and angular's
    // json replacer drops keys starting with "$$" for cases like tv.Passthrough.
    // TODO: We may need to revisit this when we stop using angular...
    return `$$viewValue.${key}${suffix}`;
  }, [model, suffix]);

  React.useEffect(() => {
    const checkForUpdates = (state: FormState<any>) => {
      const modelValue = getIn(state.values, model);
      // If model value changed since last commit or the form was reset,
      // update the view value
      if (
        modelValue !== lastModelValue.current ||
        state.values === state.defaultValues
      ) {
        const newViewValue = fromModel(modelValue);
        lastModelValue.current = modelValue;
        lastViewValue.current = newViewValue;
        // Don't consider the form dirty from deriving the view value
        form.setFieldValue(viewValuePath, newViewValue, { shouldDirty: false });
        return;
      }
      const viewValue = getIn(state.values, viewValuePath);
      // If view value changed since last commit, update the model value
      if (viewValue !== lastViewValue.current) {
        const newModelValue = toModel(viewValue);
        lastModelValue.current = newModelValue;
        lastViewValue.current = viewValue;
        form.setFieldValue(model, newModelValue);
      }
    };

    checkForUpdates(form.getFormState());

    return form.subscribe(checkForUpdates);
  }, [form, model, fromModel, toModel, viewValuePath]);

  return viewValuePath;
};
