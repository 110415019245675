// TODO: Find a home for this.
import * as iter from '@maternity/mun-itertools';
import _ from 'lodash';
import tv from '@maternity/travesty';

const copy = tv.make_dispatcher();
export default copy;

copy.register(copy_leaf, tv.Leaf);
function copy_leaf(disp, value, options) {
  return value;
}

copy.register(copy_optional, tv.Optional);
function copy_optional(disp, value, options) {
  if (value == null)
    return null;

  var opt = disp.marker;

  return disp.for_marker(opt.marker).call(value, options);
}

copy.register(copy_passthrough, tv.Passthrough);
function copy_passthrough(disp, value, options) {
  return _.cloneDeep(value);
}

copy.register(copy_list, tv.List);
function copy_list(disp, value, options) {
  var subdisp = disp.get_child('sub');
  return value.map(function copy_list_sub(v) { return subdisp.call(v, options); });
}

copy.register(copy_tuple, tv.Tuple);
function copy_tuple(disp, value, options) {
  var marker = disp.marker;

  return marker.ctor(marker.field_names.map(function(key, i) {
    return disp.get_child(key).call(value[i], options);
  }));
}

copy.register(copy_schema, tv.Schema);
function copy_schema(disp, value, options) {
  var obj = {};

  iter.forEach(disp.edge_iter(), function copy_schema_edge(edge) {
      obj[edge.key] = edge.node.call(value[edge.key], options);
    });

  return obj;
}

copy.register(copy_strmap, tv.StrMapping);
function copy_strmap(disp, value, options) {
  var valdisp = disp.get_child('sub');

  return Object.keys(value)
    .reduce(function(d, key) {
      d[key] = valdisp.call(value[key], options);
      return d;
    }, {});
}

copy.register(copy_unimap, tv.UniMapping);
function copy_unimap(disp, value, options) {
  var obj = {},
      keydisp = disp.get_child('key'),
      valdisp = disp.get_child('val');

  Object.keys(value)
    .forEach(function(key) {
      var val = value[key];
      obj[keydisp.call(key, options)] = valdisp.call(val, options);
    });

  return obj;
}

copy.register(copy_schemaobj, tv.SchemaObj.marker);
function copy_schemaobj(disp, value, options) {
  var marker = disp.marker,
      obj = Object.create(marker.target_proto),
      data = disp.super(tv.SchemaObj.marker).call(value, options);

  iter.forEach(disp.key_iter(), function copy_schemaobj_key(key) {
      obj[key] = data[key];
    });

  return obj;
}

copy.register(copy_document, tv.Document.marker);
function copy_document(disp, source, options) {
  var marker = disp.marker,
      ctor = marker.target_proto.constructor,
      // detached is an instance, but the docset machinery needs to allocate uids and track objects
      detached = disp.super(tv.Document.marker).call(source, options),
      dest = options.in_docset.get_or_create(ctor, source.uid);

  // dest.load() uses field_types, so we need to skip the load if this is a leaf in the typegraph.
  if (disp.get_path(['uid'], null))
    dest.load(detached);

  return dest;
}

copy.register(copy_polymorph, tv.Polymorph);
function copy_polymorph(disp, source, options) {
  var name = disp.marker.name_for_val(source);

  return disp.get_child(name).call(source, options);
}
