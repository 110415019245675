var { mixin } = require('@maternity/mun-extend'),
    vg = require('@maternity/vertigo'),
    tv = require('@maternity/travesty'),
    _ = require('lodash');


// Timeline
exports.TimelineMarker = TimelineMarker;
var TimelineMarker = tv.ObjectMarker.sub('kerbin.Timeline');

TimelineMarker.prototype.of = function(timestamp, value) {
  if (timestamp == null)
    timestamp = tv.DateTime();

  var event = tv.Tuple(['timestamp', 'value']).of([timestamp, value]);

  return new vg.PlainGraphNode(this, [
      {key: 'initial', node: value},
      {key: 'events', node: tv.List().of(event)}]);
};

exports.Timeline = Timeline;
function Timeline() {
  this.initial = undefined;
  this.events = [];
}

mixin(Timeline.prototype, {
    get latest() {
      return this.events.length > 0
        ? this.events[this.events.length-1][1]
        : this.initial;
    },
    get latest_time() {
      return this.events.length > 0
        ? this.events[this.events.length-1][0]
        : null;
    },
    insert: function(value, timestamp) {
      var events = this.events,
          idx;
      // TODO: Support alternative timestamp schemas and clocks
      if (timestamp == null)
        timestamp = new Date();
      idx = _.sortedLastIndexBy(events, {timestamp: timestamp}, 'timestamp');
      events.splice(idx, 0, [timestamp, value]);
    },
    valAtTime: valAtTime,
  });

TimelineMarker.prototype.target_proto = Timeline.prototype;

function valAtTime(timestamp) {
  var events = this.events,
      idx = _.sortedIndexBy(events, {timestamp: timestamp}, 'timestamp');
  return idx === 0 ? this.initial : events[idx - 1].value;
}

exports.install_importer = install_importer;
function install_importer(importer) {
  importer.register(import_timeline, 'kerbin.Timeline');
}
function import_timeline(disp, d, options) {
  var timestamp = disp.call(d.timestamp, options),
      value = disp.call(d.value, options);

  return TimelineMarker().of(timestamp, value);
}
