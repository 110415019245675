/**
 * mun-form-errors v0.0.1
 *
 * This angular.js module provides an easy way to display angular's model
 * validation errors. For displaying server-generated errors, see the
 * mun-form-server-errors module.
 *
 * As angular's ngModel and form/ngForm directives already handle running
 * validation and aggregating errors, this module adds a single directive.  The
 * `errorsHere` directive searches up the DOM tree for the nearest `form` or
 * `ngForm`, which will have aggregated validation errors from all `ngModel`s
 * contained in the form, and injects the form controller into a new scope as
 * `formCtrl`. The form controller's `$error` object has validation tokens
 * (error code) for keys, and values are either false, if valid, or an array of
 * ngModelControllers. Thus, if the value is truthy it indicates an error. It
 * is usually helpful to include extra ngForm directives to limit the number of
 * ngModels that are aggregated.
 *
 * Example:
 * <form>
 *  <div errors-here>
 *    <!-- $error has all errors in the top level form -->
 *    <p ng-if="$error.required">Missing {{$error.required.length}} required
 *        fields</p>
 *  </div>
 *  <div ng-form>
 *    <label>First</label>
 *    <input type="text" required ng-maxlength="20" ng-model="person.first">
 *    <div errors-here>
 *      <!-- $error limited to `person.first` due to ng-form -->
 *      <!-- in-place declaration of error display -->
 *      <p ng-if="$error.required">First name is required</p>
 *      <p ng-if="$error.maxlength">Must be 20 characters or less</p>
 *    </div>
 *  </div>
 *  <div ng-form>
 *    <label>Last</label>
 *    <input type="text" required ng-maxlength="20" ng-model="person.last">
 *    <div errors-here ng-include="'/template.html'">
 *    </div>
 * </form>
 * <script type="text/ng-template" id="/template.html">
 *  <p ng-if="$error.required">This field is required</p>
 *  <p ng-if="$error.maxlength">Input too long</p>
 * </script>
 *
 */

const ngModule = angular.module('mun-form-errors', [])
  .directive('errorsHere', function() {
    return {
      require: '^form',
      scope: true,
      priority: 500,
      link: function(scope, element, attr, formCtrl) {
        scope.formCtrl = formCtrl;
      }
    };
  })
  ;
  // TODO allow "errorsHere" directly on ng-model element for complex controls?
  // TODO add "error-for" directive to replace "ng-if='$error.key'"?

export default ngModule.name;
