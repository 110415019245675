import moment from 'moment';

// Either a Date object (for timestamps) or an ISO8601 date string
type DateInput = Date | string;

export function gestationalAge(
  estimate?: DateInput,
  now?: DateInput,
): string | undefined {
  if (estimate == null) {
    return;
  }
  // Calculate LMP with Naegele's rule
  const estimate_lmp = moment(estimate).subtract(280, 'days');
  // Determine days since LMP
  let days = moment(now).startOf('day').diff(estimate_lmp, 'days');
  // If the difference is negative, use zero instead
  days = Math.max(days, 0);
  // eslint-disable-next-line no-bitwise
  return `${(days / 7) | 0}.${days % 7}`;
}

export function postpartumAge(
  actual?: DateInput,
  now?: DateInput,
): string | undefined {
  if (actual == null) {
    return;
  }
  // Determine days since actual
  let days = moment(now).startOf('day').diff(moment(actual), 'days');
  // If the difference is negative, use zero instead
  days = Math.max(days, 0);
  // eslint-disable-next-line no-bitwise
  return `+${(days / 7) | 0}.${days % 7}`;
}

export function inBounds(
  end_date?: DateInput | null,
  end_type?: string | null,
  estimate?: DateInput | null,
  now?: DateInput,
): boolean {
  // Check if the given dates correspond to a gestational age within the bounds
  // [0,50) || [+0,+12).
  let weeks = -1;

  const now_ = moment(now).startOf('day');

  if (end_date) {
    // Reject when end type is unknown
    if (end_type === 'unknown') {
      return false;
    }
    weeks = now_.diff(moment(end_date), 'weeks', true);
    // Reject greater than or equal to 12 wks postpartum
    if (weeks >= 12) return false;
  } else if (estimate) {
    // Calculate LMP with Naegele's rule
    const estimate_lmp = moment(estimate).subtract(280, 'days');
    weeks = now_.diff(estimate_lmp, 'weeks', true);
    // Reject greater than or equal to 50 wks prenatal
    if (weeks >= 50) return false;
  }
  // Reject negative (end/estimate too far in the future)
  if (weeks < 0) {
    return false;
  }

  return true;
}

export function gestationalAgeDisplay(value: string): string {
  // TODO: Add bounds & type checks?
  if (!value) {
    return 'Well-woman';
  } else if (value === '0') {
    return 'Start of care';
  } else if (value === '-1') {
    return 'Pre-conception';
  } else if (value.slice(0, 1) === '+') {
    return `Postpartum ${value.slice(1)} weeks`;
  } else {
    return `Prenatal ${value} weeks`;
  }
}

export function sortKey(ga: string) {
  if (!ga) {
    return -Infinity;
  }

  return ga[0] === '+' ? +ga + 100 : +ga;
}

export function gestationalAgeAt(
  atDate: DateInput,
  eddEstimate?: DateInput,
  endDate?: DateInput,
  endType?: string,
): string | undefined {
  if (!inBounds(endDate, endType, eddEstimate, atDate)) return;
  if (endDate && moment(atDate) >= moment(endDate)) {
    return postpartumAge(endDate, atDate);
  }
  return gestationalAge(eddEstimate, atDate);
}

const isoDay = 'YYYY-MM-DD';

/** Estimate delivery date from last menstrual date */
export const estimateFromLmp = (lastMenstrualDate: DateInput) =>
  moment(lastMenstrualDate).add(280, 'days').format(isoDay);

/** Estimate delivery date from conception date */
export const estimateFromConception = (conceptionDate: DateInput) =>
  moment(conceptionDate)
    .add(280 - 14, 'days')
    .format(isoDay);
