import env from '@maternity/mun-env';
import { extend } from '@maternity/mun-extend';
import { react2angular } from 'react2angular';

import { UserMenu } from '../../chrome/UserMenu';
import ngModule from '../kerbin-core-module';

ngModule

  .factory('accountSessionIO', function(munDocIO, $injector) {
    var accountSessionIO = {
          session: munDocIO.mkAccessIO('account_session_load', {
            undictify: stashingCachingUndictify
          }),
          login: munDocIO.mkActionIO('login', {
            undictify: stashingCachingUndictify
          }),
          logout: munDocIO.mkActionIO('logout', {
            hasRequestBody: false,
          }),
          resetTimeout: munDocIO.mkActionIO('reset_timeout', {
            hasRequestBody: false,
          }),
        };

    // Preload account session data if it was provided.
    if ($injector.has('MUN_ACCOUNT_SESSION')) {
      var rawSession = $injector.get('MUN_ACCOUNT_SESSION');

      accountSessionIO.session._withResponse(
          {status: 200, data: rawSession});
    }

    return accountSessionIO;

    // TODO: Make this dashing
    function stashingCachingUndictify(tg, raw) {
      var data = munDocIO.defaultIOOptions.undictify(tg, raw);
      data.item.$raw = raw;
      return data;
    }
  })

  .factory('profileSessionIO', function(munDocIO, $injector) {
    var profileSessionIO = {
          session: munDocIO.mkAccessIO('profile_session_load', {
            buildUrl: simplisticBuildUrl,
            undictify: stashingCachingUndictify}),
        };

    // Preload profile session data if it was provided.
    if ($injector.has('MUN_PROFILE_SESSION')) {
      var rawSession = $injector.get('MUN_PROFILE_SESSION');

      profileSessionIO.session._withResponse(
          rawSession.item.person, rawSession.item.mode,
          {status: 200, data: rawSession});
    }

    function simplisticBuildUrl(spec, args) {
      var person = args[0],
          mode = args[1];

      var rule = spec.rule.replace(/<(\w+)>/g, function(param, name) {
        if (name === 'session_person_uid')
          return person.uid;
        if (name === 'session_person_mode')
          return mode;

        return param;
      });
      return rule;
    }

    return profileSessionIO;

    // TODO: Make this dashing
    function stashingCachingUndictify(tg, raw) {
      var data = munDocIO.defaultIOOptions.undictify(tg, raw);
      data.item.$raw = raw;
      return data;
    }
  })

  .config(function($injector, munSessionProvider) {
    var initSession = $injector.has('MUN_SESSION')
          ? $injector.get('MUN_SESSION')
          : null;

    munSessionProvider.configure(initSession, KerbinSession);

    /* @ngInject */
    function KerbinSession($rootElement, munDocIO, munDocSchemas, munDocTV,
        munDocCache, munDocKeepalive, $injector, $window, accountSessionIO,
        profileSessionIO, $q, $alert, globalBypassFormSafety, $location) {
      var profile,
          isSudoer,
          cancelAutorefresh,
          reloading,
          warningAlert;

      $rootElement.on('$destroy', cleanup);

      return {
        login: login,
        logout: logout,
        refresh: refresh,
        import: import_,
        timeoutWarning: timeoutWarning,
        forceReload: forceReload,
      };

      function refresh() {
        return (profile
          ? $q.when()
          : accountSessionIO.session()
            .then(function(resp) {
              var accountSession = resp.data.item;
              isSudoer = accountSession.is_sudoer;
              profile = selectProfile(accountSession);
            }))
          .then(function() {
            // If no profile selected, simulate 401
            if (!profile) return $q.reject({status: 401});

            return profileSessionIO.session(profile.person, profile.mode)
              .then(function(resp) {
                autorefresh(resp.data);
                return resp;
              });
          });
      }

      function login(username, password) {
        var payload = {username: username, password: password};
        return accountSessionIO.login(payload)
          .then(function(resp) {
            // The user may use a specific email address to login to a specific profile, so we need
            // to remove the previous selection to avoid overriding that.
            clearCurrentProfile();
            // Reload the page to ensure the user gets the correct version of
            // the client-side app (i.e. matches the build cookie set by the
            // login endpoint). Without this, the user may have a stale copy of
            // the client-side app (e.g. they left a tab open) but send
            // requests to the latest backend, which can cause errors.
            // TODO: Only reload if actually stale to avoid redundant requests?
            location.reload();
            // Return a promise that will never resolved to avoid running the
            // mun-session machinery that expects to receive a response object.
            return $q.defer().promise;
          });
      }

      function selectProfile(accountSession) {
        var storageKey = 'currentProfile',
            storageProfile,
            profiles = accountSession.profiles,
            selection = accountSession.select_profile || accountSession.default_profile,
            match;

        if (!profiles.length) {
          // Bail if there are no profiles (usually a kerbol account)
          return;
        }

        try {
          storageProfile = JSON.parse($window.sessionStorage.getItem(storageKey) || null);
        } catch (e) {
          // Yay, garbage!
        }

        if (storageProfile)
          // First, try the profile from sesion storage
          match = profiles.filter(selector(storageProfile.person))[0];
        if (!match && selection.person)
          // Next, try the select_profile or default_profile
          match = profiles.filter(selector(selection.person))[0];
        if (!match)
          // If nothing else worked, just use the first profile
          match = profiles[0];

        return match;

        function selector(person) {
          var uid = person.uid || person;
          return function(profile) {
            return profile.person.uid === uid;
          };
        }
      }

      function clearCurrentProfile() {
        var storageKey = 'currentProfile';

        $window.sessionStorage.removeItem(storageKey);
      }

      function logout() {
        // Redirect to login screen, unless user is sudoer
        var redirectUrl = isSudoer ? env.KERBOL_HOME : getLoginPrefix();
        return accountSessionIO.logout()
          .finally(() => forceReload(redirectUrl));
      }

      function import_(data) {
        accountSessionIO.session._withResponse({status: 200, data: data});
        return refresh();
      }

      function autorefresh(msg) {
        if (cancelAutorefresh)
          cancelAutorefresh();
        munDocKeepalive.invalidation_monitor.call(msg);
        cancelAutorefresh = munDocKeepalive(msg, function() {
            // TODO: Refactor or defactor mun-session so pushing updates is less awkward.
            var munSession = $injector.get('munSession');
            munSession.$refresh();
          });
        return msg;
      }

      function cleanup() {
        if (cancelAutorefresh)
          cancelAutorefresh();
      }

      function timeoutWarning(cookie, setting) {
        if (setting) {
          if (warningAlert)
            // Warning was reset
            warningAlert.hide();
          // else initial set
          return;
        }
        if (warningAlert) {
          // Redisplay existing warning
          warningAlert.show();
        } else {
          // Display a warning alert about session timeout
          warningAlert = $alert({
            template: require('../../../partials/common/timeout-alert.jade'),
            duration: false,
          });
        }
      }

      function getLoginPrefix() {
        // Need $injector.get() due to circular dependency
        return $injector.get('customLoginPrefix').get();
      }

      function forceReload(redirectUrl) {
        // Bail if a reload is already in progress
        if (reloading) return;

        if (!redirectUrl) {
          // Preserve the url, but maybe add a custom login prefix
          redirectUrl = getLoginPrefix() + $location.url().slice(1);
        }

        // Prevent form safety from interrupting the reload
        globalBypassFormSafety.bypass();
        // Load the redirect url
        $window.location.assign(redirectUrl);
        reloading = true;
      }
    }
  })

  .config(function($provide) {
    /* @ngInject */
    $provide.decorator('munDocKeepalive', function($delegate, munDocEndpoints, munDocSchemas) {
        // Note: The session isn't really a document, so we need some glue to connect the dots.
        var invalidation_monitor = $delegate.invalidation_monitor;

        invalidation_monitor.register(im_at_session,
          munDocEndpoints.profile_session_load.GET.message_out);
        invalidation_monitor.register(im_at_session,
          // Also used by login
          munDocEndpoints.account_session_load.GET.message_out);
        function im_at_session(disp, msg, options) {
          var doccache = options.doccache,
              events = new invalidation_monitor.EventSet('session_monitor');

          disp.super(invalidation_monitor).call(msg, options);

          events.obj(doccache)
            .on(['updated','<kerbin.Session>'], invalidate_session)
            .on(['deleted','<kerbin.Session>'], invalidate_session);

          events.obj(msg)
            .cancelOn('invalid');

          if (options.stats_start)
            events.onCancel(options.stats_start(msg.constructor, 'session'));

          function invalidate_session() {
            msg.$invalidate();
          }
        }

        return $delegate;
      });
  })

  .run(function(munDocIO, munSession) {
    munDocIO.defaultIOOptions.buildUrl = wrapBuildUrl(munDocIO.defaultIOOptions.buildUrl);

    function wrapBuildUrl(baseBuildUrl) {
      return buildUrl;

      function buildUrl(spec, args, data) {
        return baseBuildUrl(extend(spec, {
            rule: spec.rule.replace(/<(\w+)>/g, function(param, name) {
              if (name === 'session_person_uid')
                return munSession.person ? munSession.person.uid : param;
              if (name === 'session_person_mode')
                return munSession.mode || param;

              return param;
            }),
          }), args, data);
      }
    }
  })

  .component('kerbinUserMenu', react2angular(UserMenu))

  .controller('TimeoutWarningCtrl', function($scope, accountSessionIO) {
    var submitting = false;
    $scope.resetTimeout = resetTimeout;

    function resetTimeout(hide) {
      if (submitting) return;
      submitting = true;
      accountSessionIO.resetTimeout()
        .then(hide)
        .finally(function() {
          submitting = false;
        });
    }
  })

  .run(function(munSession, $rootScope, $state, $location) {
    munSession.$whenAuthenticated
      .then(function() {
        // Strip URL path prefix for custom login screen if present
        $location.url($location.url().replace(/^\/p\/[^\/]+\//, '/'));

        $rootScope.$on('munSession.reset', function(e) {
            $state.goto('logout');
            e.preventDefault();
          });
      });
  })

  .factory('customLoginPrefix', function(munSession, $window) {
    return {
      get: get,
    };

    function get() {
      // Returns the custom login prefix with base, or just the base URI
      var base = $window.document.baseURI,
          slug = munSession?.practice?.features?.brand?.slug;

      if (slug)
        return base + 'p/' + slug + '/';
      return base;
    }
  })

  .config(function($stateProvider) {
    $stateProvider

      .state('logout', {
        views: {
          page: { template: {
            html: '',
          } },
        },
        onEnter: {
          after:
            /* @ngInject */
            function(munSession) {
              munSession.$logout();
            },
        },
      })

      ;
  })

  ;
