import * as moment from 'moment';
import * as React from 'react';

import { ROWS } from './constants';
import { TimePickerRow, TimePickerRowData } from './Row';
import { Action, PinnedMoment } from './types';
import { wrapHour, wrapMinute } from './utils';

interface TimePickerTableProps {
  hourStep: number;
  minuteStep: number;
  viewTime: moment.Moment;
  selectedTime?: moment.Moment;
  maxTime?: PinnedMoment;
  minTime?: PinnedMoment;
  dispatch: React.Dispatch<Action>;
}

export const TimePickerTable = ({
  hourStep,
  minuteStep,
  viewTime,
  selectedTime,
  maxTime,
  minTime,
  dispatch,
}: TimePickerTableProps) => {
  const viewHour = viewTime.hour();
  const viewMinute = viewTime.minute();
  const selectedHour = selectedTime && selectedTime.hour();
  const selectedMinute = selectedTime && selectedTime.minute();
  const pmSelected = selectedHour != null && selectedHour >= 12;
  const rows: TimePickerRowData[] = [];
  const midIndex = Math.floor(ROWS / 2);
  for (let i = 0; i < ROWS; i++) {
    const offset = midIndex - i;
    const hour = wrapHour(viewHour - offset * hourStep) % 12;
    const hourMoment = viewTime.clone().hour(hour + (pmSelected ? 12 : 0));
    const minute = wrapMinute(viewMinute - offset * minuteStep);
    const minuteMoment = viewTime.clone().minute(minute);
    let meridiem;
    if ((pmSelected && offset === -1) || (!pmSelected && offset === 0)) {
      meridiem = {
        value: 'am' as const,
        selected: selectedHour != null && selectedHour < 12,
      };
    } else if ((pmSelected && offset === 0) || (!pmSelected && offset === 1)) {
      meridiem = {
        value: 'pm' as const,
        selected: pmSelected,
      };
    }
    rows.push({
      hour: {
        value: hour,
        selected: hour === (selectedHour && selectedHour % 12),
        disabled:
          (!!maxTime && hourMoment.isAfter(maxTime, 'minute')) ||
          (!!minTime && hourMoment.isBefore(minTime, 'minute')),
      },
      minute: {
        value: minute,
        selected: minute === selectedMinute,
        disabled:
          (!!maxTime && minuteMoment.isAfter(maxTime, 'minute')) ||
          (!!minTime && minuteMoment.isBefore(minTime, 'minute')),
      },
      meridiem,
      isMid: offset === 0,
    });
  }
  return (
    <table>
      <thead>
        <tr className="text-center">
          <th>
            <button
              className="btn btn-default pull-left"
              type="button"
              tabIndex={-1}
              onClick={() =>
                dispatch({
                  type: 'shift',
                  unit: 'hour',
                  direction: 'up',
                })
              }
            >
              <i className="fa fa-chevron-up" />
            </button>
          </th>
          <th>&nbsp;</th>
          <th>
            <button
              className="btn btn-default pull-left"
              type="button"
              tabIndex={-1}
              onClick={() =>
                dispatch({
                  type: 'shift',
                  unit: 'minute',
                  direction: 'up',
                })
              }
            >
              <i className="fa fa-chevron-up" />
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, idx) => (
          <TimePickerRow key={idx} dispatch={dispatch} {...row} />
        ))}
      </tbody>
      <tfoot>
        <tr className="text-center">
          <th>
            <button
              className="btn btn-default pull-left"
              type="button"
              tabIndex={-1}
              onClick={() =>
                dispatch({
                  type: 'shift',
                  unit: 'hour',
                  direction: 'down',
                })
              }
            >
              <i className="fa fa-chevron-down" />
            </button>
          </th>
          <th />
          <th>
            <button
              className="btn btn-default pull-left"
              type="button"
              tabIndex={-1}
              onClick={() =>
                dispatch({
                  type: 'shift',
                  unit: 'minute',
                  direction: 'down',
                })
              }
            >
              <i className="fa fa-chevron-down" />
            </button>
          </th>
        </tr>
      </tfoot>
    </table>
  );
};
