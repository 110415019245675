import * as React from 'react';

/**
 * Executes `callback` every animation frame, when `isEnabled` is `true`.
 *
 * The callback gets scheduled for the next animation frame. If the callback
 * needs to run during React's commit phase, use `useEffect`/`useLayoutEffect`.
 */
export const useRafLoop = (callback: () => void, isEnabled = true) => {
  React.useEffect(() => {
    if (!isEnabled) return;

    let requestId = 0;

    const tick = () => {
      callback();
      requestId = requestAnimationFrame(tick);
    };

    requestId = requestAnimationFrame(tick);

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [callback, isEnabled]);
};
