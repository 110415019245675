/// <reference types="angular" />

// Proxy for an angular injector
class Injector {
  _injector?: angular.auto.IInjectorService;

  _set_injector(inj: angular.auto.IInjectorService) {
    this._injector = inj;
  }

  get(name: string): any {
    if (!this._injector) {
      throw new Error('No injector set');
    }
    return this._injector.get(name);
  }

  has(name: string): boolean {
    if (!this._injector) {
      throw new Error('No injector set');
    }
    return this._injector.has(name);
  }
}

// Global singleton proxy to most-recent injector
export const injector = new Injector();
