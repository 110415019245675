import classNames from 'classnames';
import * as React from 'react';

import { Form as BaseForm, FormProps } from '../Form';
import { FieldValues } from '../types';

/**
 * Renders a `<form>` element with horizontal layout and a `fieldset` to
 * disable all inputs while the form is submitting.
 */
export const Form = <V extends FieldValues>({
  className,
  ...props
}: FormProps<V>) => (
  <BaseForm className={classNames('form-horizontal', className)} {...props} />
);
