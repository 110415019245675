import ngModule from '../kerbin-core-module';

ngModule
  // TODO: Add caching or memoization?
  // TODO: Check permissions on state transitions?
  //       Keith: I'm punting on this because 1) server permission checks and
  //       link hiding cover most use cases, and 2) the following issues:
  //       - Need to display something to the user
  //       - Redircting to the $from state would fail if coming from $root
  //       - Need munSession to be populated (issue on initial page load)

  /**
   * kerbinPermissions
   *
   * Provider methods:
   * - register(name, checkFn): Registers the `name` permission, where
   *   `checkFn` can be used to check if the user has the permission. The
   *   `checkFn` will be injected, with `this` set to the `context` of the
   *   permission check. The `checkFn` should return truthy if the check
   *   succeeds.
   * - stateHas(name): Returns an injectable version of `has` with `name`
   *   bound, suitable for `checkPermission` in state definitions.
   * - stateAll(names): Returns an injectable version of `all` with `names`
   *   bound.
   *
   * Service methods:
   * - has(name, context): Checks permission `name`, given an optional
   *   `context`.
   * - all(names, context): Checks that all permissions in the `names` array
   *   apply.
   */
  .provider('kerbinPermissions', function() {
    var permissions = {};

    this.register = register;
    this.stateHas = stateHas;
    this.stateAll = stateAll;
    this.$get = get;

    function register(name, checkFn) {
      permissions[name] = checkFn;
      return this;
    }

    function stateHas(name) {
      // TODO: Improve context support.
      injectableHas.$inject = ['kerbinPermissions'];
      function injectableHas(kerbinPermissions) {
        return kerbinPermissions.has(name, this);
      }

      return injectableHas;
    }

    function stateAll(names) {
      // TODO: Improve context support.
      injectableAll.$inject = ['kerbinPermissions'];
      function injectableAll(kerbinPermissions) {
        return kerbinPermissions.all(names, this);
      }

      return injectableAll;
    }

    get.$inject = ['$injector'];
    function get($injector) {
      return {
        has: has,
        all: all,
      };

      function has(name, context, auxiliaryContext) {
        // Can't have permissions that aren't registered
        return name in permissions &&
          !!$injector.invoke(permissions[name], context, {
            auxiliaryContext: auxiliaryContext,
          });
      }

      function all(names, context, auxiliaryContext) {
        // TODO: Assert names is actually an array?
        names = names || [];

        return names.every(function(name) {
            return has(name, context, auxiliaryContext);
          });
      }
    }
  })

  // TODO:Determine if these filters should be replaced by exposing `has` and
  // `all` on the $rootScope (e.g. as `hasPermission`).
  /**
   * The hasPermission filter synchronously checks the permission indicated by
   * the input expression, and optionally takes context as an argument.
   */
  .filter('hasPermission', function(kerbinPermissions) {
    return hasPermission;

    function hasPermission(name, context, auxiliaryContext) {
      return kerbinPermissions.has(name, context, auxiliaryContext);
    }
  })

  /**
   * The hasPermissions filter synchronously checks the array of permissions
   * indicated by the input expression, and optionally takes context as an
   * argument.
   */
  .filter('hasPermissions', function(kerbinPermissions) {
    return hasPermissions;

    function hasPermissions(names, context, auxiliaryContext) {
      return kerbinPermissions.all(names, context, auxiliaryContext);
    }
  })

  .config(function(kerbinPermissionsProvider) {
    // Configure default permissions
    kerbinPermissionsProvider
      //.register('require_person', person)
      .register('practice_owner', practiceOwner)
      .register('not_practice_owner', notPracticeOwner)
      .register('care_provider', careProvider)
      .register('staff', staff)
      .register('client', client)
      ;

    practiceOwner.$inject = ['munSession'];
    function practiceOwner(munSession) {
      return munSession.person &&
        munSession.practice &&
        munSession.person === munSession.practice.owner;
    }

    notPracticeOwner.$inject = ['munSession'];
    function notPracticeOwner(munSession) {
      return munSession.person &&
        munSession.practice &&
        munSession.person !== munSession.practice.owner;
    }

    staff.$inject = ['munSession'];
    function staff(munSession) {
      return munSession.person?.active &&
        munSession.person.type !== 'non-member';
    }

    client.$inject = ['munSession'];
    function client(munSession) {
      return !!munSession.client;
    }

    careProvider.$inject = ['munSession'];
    function careProvider(munSession) {
      return munSession.person?.user_active &&
        munSession.person.type === 'care_provider';
    }

  })

  /**
   * Notes from Kai & Keith's discussion 2014-08-20:
   * - Hide links (sref) to states that the user doesn't have permission to
   *   access.
   * - Since we can get permission data on endpoints in the route export, we
   *   should try to use it. However, using endpoint-level data to limit state
   *   transitions is complicated...
   * - Object-level permissions will require the object to be loaded. Maybe
   *   there is a way to tie permission checks into the state `resovlve`
   *   functionality? Or maybe request caching will prevent duplicate
   *   requests?
   */

  ;
