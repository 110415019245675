import './mun-spinner.less';

const ngModule = angular.module('mun-spinner', [])

  .directive('munSpinner', function() {
    return {
      restrict: 'A',
      bindToController: {
        waitFor: '<munSpinner',
      },
      controllerAs: '$$nonsense',
      controller: function($element, $q) {
        const CLS_LOADING = 'mun-loading';
        const CLS_LOADED = 'mun-loaded';
        const CLS_UNLOADED = 'mun-unloaded';
        const CLS_ERROR = 'mun-load-error';

        Object.assign(this, {$onChanges, setState});

        function setState(...states) {
          for (let state of states) {
            if (state === CLS_LOADED || state === CLS_LOADING || state === CLS_UNLOADED) {
              $element.removeClass(`${CLS_LOADING} ${CLS_LOADED} ${CLS_UNLOADED}`);
            }
            $element.addClass(state);
            if (state === CLS_LOADED) {
              $element.removeClass(CLS_ERROR);
            }
          }
        }

        function $onChanges() {
          let waitFor = this.waitFor;

          if (waitFor && typeof waitFor.then === 'function') {
            this.setState(CLS_LOADING);

            waitFor
              .then(
                () => {
                  // Check if the value of the expression changed while waiting
                  if (waitFor === this.waitFor)
                    this.setState(CLS_LOADED);
                },
                () => {
                  // Check if the value of the expression changed while waiting
                  if (waitFor === this.waitFor)
                    this.setState(CLS_UNLOADED, CLS_ERROR);
                });
          } else {
            this.setState(waitFor ? CLS_LOADED : CLS_UNLOADED);
          }
        }
      },
    };
  })

  .component('munSpinner', {
    // Displays a loading spinner until the value of the `waitFor` expression
    // resolves, then the transcluded content is displayed.
    //
    // The expression may evaluate to a truthy value or a promise. If the
    // result of the expression changes, the spinner will be redisplayed until
    // it resolves again.
    // TODO: Handle promise rejections
    bindings: {
      waitFor: '<',
    },
    controller: function($q) {
      this.$onChanges = onChanges;

      function onChanges() {
        let waitFor = this.waitFor;

        this.loaded = false;

        if (waitFor) {
          $q.when(waitFor)
            .then(() => {
              // Check if the value of the expression changed while waiting
              if (waitFor === this.waitFor)
                this.loaded = true;
            });
        }
      }
    },
    transclude: true,
    template: `
      <div ng-if="!$ctrl.loaded" class="mun-loading"></div>
      <ng-transclude ng-if="$ctrl.loaded"></ng-transclude>
    `,
  })

  ;

export default ngModule.name;
