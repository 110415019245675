var { attrChain, extend, mixin } = require('@maternity/mun-extend'),
    vg = require('@maternity/vertigo'),
    dispatcher = require('./dispatcher'),
    Dispatcher = dispatcher.Dispatcher,
    NoDispatchError = dispatcher.NoDispatchError,
    dispatchgraph = require('./dispatchgraph'),
    DynamicDispatchGraph = dispatchgraph.DynamicDispatchGraph,
    ValueOverlay = dispatchgraph.ValueOverlay;


exports.Marker = Marker;
function Marker() {
  if (!(this instanceof Marker))
    return new Marker();
}
mixin(Marker.prototype, {
    toString: function() { return '<'+typeKeysOf(this)[0]+'>'; },
  });
addTypeKey(Marker.prototype, 'tv.Marker');
Marker.sub = function(name, ctor) {
  var super_ = this;

  ctor = ctor || SubMarker;

  ctor.prototype = extend(super_.prototype, {
     constructor: ctor,
    });

  ctor.sub = super_.sub;

  addTypeKey(ctor.prototype, name);

  return ctor;

  function SubMarker() {
    if (!(this instanceof SubMarker)) {
      var self = Object.create(SubMarker.prototype);
      SubMarker.apply(self, arguments);
      return self;
    }
    super_.apply(this, arguments);
  }
};

var Leaf = exports.Leaf = Marker.sub('tv.Leaf');


/* Type keys are labels used to identify objects, this allows something like the type based
 * dispatch used in the python travesty.
 *
 * Type keys are stored on the non-enumerable property $tvType.
 * Multiple labels can be added to an object.
 * Type keys are agregated across the prototype chain.
 * Type keys are generated for null, undefined, boolean, number, string, and Array.
 */

exports.typeKeysOf = typeKeysOf;
function typeKeysOf(val) {
  // Collect all type keys for an object.
  if (val == null)
    return ['null'];

  else if (typeof val !== 'object')
    return [typeof val];

  else if (Array.isArray(val))
    // What if the array instance has type keys?
    // TODO: if needed
    return ['Array'];

  return attrChain('$tvType', val)
    .reduce(function(acc, a) { return acc.concat(a); }, []);
}

exports.ownTypeKeyOf = ownTypeKeyOf;
function ownTypeKeyOf(val) {
  // Return the first type key on *this* object, ignoring the prototype chain.  If this object has
  // no type key of it's own, one is randomly generated.
  if (!Object.hasOwnProperty.call(val, '$tvType'))
    // TODO: consider incorporating val.constructor.name in gibberish?  This should aid debugging.
    addTypeKey(val, gibberish());

  return val.$tvType[0];

  function gibberish() {
    return String(Array(16))
      .replace(/,/g, function() { return String.fromCharCode(0x61+Math.random()*26|0); });
  }
}

exports.addTypeKey = addTypeKey;
function addTypeKey(val, name) {
  // Non-enumerable properties are less intrusive
  if (!Object.hasOwnProperty.call(val, '$tvType'))
    Object.defineProperty(val, '$tvType', {value: []});

  val.$tvType.push(name);
}

exports.hasTypeKey = hasTypeKey;
function hasTypeKey(val, name) {
  if (Array.isArray(name))
    return name.some(hasTypeKey.bind(this, val));
  return typeKeysOf(val).indexOf(name) !== -1;
}

var to_typegraph = exports.to_typegraph = new Dispatcher(typeKeysOf);

addTypeKey(vg.GraphNode.prototype, 'vg.GraphNode');

to_typegraph.register(graphnode_to_typegraph, 'vg.GraphNode');
function graphnode_to_typegraph(d, node) {
  // py assumes the node's value is a marker, but I think this is a useful assertion
  if (hasTypeKey(node.value, 'tv.Marker'))
    return node;
  throw new NoDispatchError(typeKeysOf(node));
}

// markers are their own marker
to_typegraph.register(marker_to_typegraph, 'tv.Marker');
function marker_to_typegraph(d, marker) {
  return new vg.PlainGraphNode(marker);
}

to_typegraph.register(type_to_typegraph, 'function');
function type_to_typegraph(d, obj) {
  if (obj.typegraph != null)
    return obj.typegraph;
  throw new NoDispatchError(typeKeysOf(obj));
}

function GraphDispatcher(parents) {
  Dispatcher.call(this, parents);
}

exports.GraphDispatcher = GraphDispatcher;
GraphDispatcher.prototype = extend(
    Dispatcher.prototype, {
      constructor: GraphDispatcher,

      sub: function(parents) {
        parents = parents
          ? [this].concat(parents)
          : [this];

        return new this.constructor(parents);
      },

      _to_keys: function(val) {
        return typeKeysOf(val);
      },

      call: function(graph) {
        var args = [].slice.call(arguments, 1);
        graph = this._mk_graph(graph);

        return graph.call.apply(graph, args);
      },

      _mk_graph: function(graph) {
        return new DynamicDispatchGraph(to_typegraph.call(graph), this);
      },

      register: function(fn, key) {
        // enable dictify.register(dictify_foo, FooMarker)
        if (typeof key === 'function')
          key = ownTypeKeyOf(key.prototype);

        Dispatcher.prototype.register.call(this, fn, key);
      },
    });


var Wrapper = exports.Wrapper = Marker.sub('tv.Wrapper', function Wrapper(marker) {
      if (!(this instanceof Wrapper)) {
        var self = Object.create(Wrapper.prototype);
        Wrapper.apply(self, arguments);
        return self;
      }

      this.marker = marker;
    });

Wrapper.wrap = function(t) {
  var typegraph = to_typegraph.call(t),
      args = [typegraph.value].concat([].slice.call(arguments, 1)),
      wrapper = Object.create(this.prototype);

  this.apply(wrapper, args);

  return new ValueOverlay(typegraph, wrapper);
};

Wrapper.sub = (function wrapper_sub(marker_sub) {
  return function sub() {
    var SubWrapper = marker_sub.apply(this, arguments);
    SubWrapper.wrap = this.wrap;
    SubWrapper.sub = wrapper_sub(SubWrapper.sub);
    return SubWrapper;
  };
})(Wrapper.sub);

Wrapper.prototype.toString = function toString() {
  return Marker.prototype.toString.call(this).replace(/(?=>$)/, '('+String(this.marker).slice(1, -1)+')');
};


var unwrap = exports.unwrap = new Dispatcher(typeKeysOf);


unwrap.register(return_the_marker, 'tv.Marker');
function return_the_marker(disp, marker) {
  return marker;
}

unwrap.register(return_the_inner_marker, 'tv.Wrapper');
function return_the_inner_marker(disp, marker) {
  return marker.marker;
}

var base_dispatch = exports.base_dispatch = new GraphDispatcher();

base_dispatch.register(pass_through_wrapper, Wrapper);
function pass_through_wrapper(d) {
  var args = [].slice.call(arguments, 1),
      subdisp = d.for_marker(d.marker.marker);

  return subdisp.call.apply(subdisp, args);
}

exports.make_dispatcher = make_dispatcher;
function make_dispatcher(parents) {
  return base_dispatch.sub(parents);
}


var traverse = exports.traverse = make_dispatcher();

traverse.register(traverse_object, Leaf);
function traverse_object(d, val, options) {
  var zipgraph = options?.zipgraph;

  if (zipgraph != null)
    return new vg.PlainGraphNode([val, zipgraph.value]);
  return new vg.PlainGraphNode(val);
}


exports.dictify = make_dispatcher();
exports.undictify = make_dispatcher();
