import { Dropdown } from '@maternity/mun-dropdown';
import { MunSuspense } from '@maternity/mun-error-boundary';
import { Anchor, goto, Link, useMunDocLoader } from '@maternity/mun-router';
import { useMunSession } from '@maternity/mun-session';
import * as React from 'react';

import { Avatar } from '../Avatar';
import { useIsStaff } from '../permissions';
import { kerbin_ProfileStub } from '../types';
import { SetDefaultProfileModal } from './SetDefaultProfileModal';

interface ContentProps {
  onChangeDefault: () => void;
}

const UserMenuContent = ({ onChangeDefault }: ContentProps) => {
  const { person, practice } = useMunSession();
  const isStaff = useIsStaff();
  const accountSession = useMunDocLoader('accountSessionIO.session').read()
    .item;
  // This shouldn't happen, but it narrows the type for typescript
  if (!person || !practice) return null;

  const switchProfile = (profile: kerbin_ProfileStub) => {
    const profileUid = profile.person.uid;
    if (profileUid === person.uid) return;
    // TODO: Colocate the code for storing and loading the current profile
    sessionStorage.setItem(
      'currentProfile',
      JSON.stringify({ person: profileUid }),
    );
    location.reload();
  };
  const profiles = accountSession.profiles.filter(
    (profile) => profile.person.uid !== person.uid,
  );
  const { default_profile, is_sudoer } = accountSession;
  const currentIsDefault = person.uid === default_profile?.person.uid;

  return (
    <React.Fragment>
      <li>
        <div className="dropdown-header">
          <i className={`fa ${currentIsDefault ? 'fa-thumb-tack' : ''}`} />{' '}
          {practice.name}
        </div>
      </li>
      {isStaff && (
        <li>
          <Link sref="top.settings.profile">
            <i className="fa fa-user" /> My Profile
          </Link>
        </li>
      )}
      {profiles.length > 0 && (
        <React.Fragment>
          <li className="divider" />
          <li className="dropdown-header">
            <strong>SWITCH TO</strong>
          </li>
        </React.Fragment>
      )}
      {profiles.map((profile) => (
        <li key={profile.person.uid}>
          {profile.mode === 'client' ? (
            <Anchor
              href="#"
              onClick={() => switchProfile(profile)}
              title="Switch to this client profile"
            >
              <i className="fa fa-user" /> {profile.practice.name}
              {isStaff && <span> (Client)</span>}
            </Anchor>
          ) : (
            <Anchor
              href="#"
              onClick={() => switchProfile(profile)}
              title="Switch to this user profile"
            >
              <i className="fa fa-home" /> {profile.practice.name}
              {!isStaff && <span> (User)</span>}
            </Anchor>
          )}
        </li>
      ))}
      {profiles.length > 0 && (
        <li>
          <Anchor href="#" onClick={onChangeDefault}>
            <i className="fa" /> Change Default
          </Anchor>
        </li>
      )}
      <li className="divider" />
      <li>
        <Link sref="top.changepassword">
          <i className="fa fa-key" /> Change Password
        </Link>
      </li>
      <li>
        <Anchor
          href="https://maternityneighborhood.helpscoutdocs.com/"
          target="_blank"
        >
          <i className="fa fa-question" /> Help
        </Anchor>
      </li>
      <li>
        {/* The logout state doesn't have an URL, so can't use `<Link>` */}
        <Anchor href="#" onClick={() => goto('logout')}>
          <i
            className={`fa fa-sign-out${
              is_sudoer ? ' fa-flip-horizontal' : ''
            }`}
          />{' '}
          Sign Out
        </Anchor>
      </li>
    </React.Fragment>
  );
};

export const UserMenu = () => {
  const { person } = useMunSession();
  const [modalOpen, setModalOpen] = React.useState(false);
  if (!person) return null;

  return (
    <ul className="nav navbar-nav navbar-right mn-nav-user">
      <Dropdown
        tag="li"
        renderToggle={(props) => (
          <Anchor href="#" {...props}>
            <Avatar person={person} />
            <span className="mn-name"> {person.name.latest!.long_name} </span>
            <i className="fa fa-caret-down" />
          </Anchor>
        )}
        fitHeight={true}
      >
        <MunSuspense>
          <UserMenuContent onChangeDefault={() => setModalOpen(true)} />
        </MunSuspense>
      </Dropdown>
      {modalOpen && (
        <SetDefaultProfileModal onHide={() => setModalOpen(false)} />
      )}
    </ul>
  );
};
