var iter = require('@maternity/mun-itertools'),
    { extend, mixin } = require('@maternity/mun-extend'),

    base = require('./base'),
    objectmarker = require('./objectmarker'),
    ObjectMarker = objectmarker.ObjectMarker;


exports.SchemaObj = SchemaObj;
function SchemaObj(data) {
  var field_types = this.constructor.field_types;
  // Python impl allows non field_type attrs in data, is this needed?
  // Note that because field_types are inherited, it's important to use for in here.
  for (var key in field_types) {
    if (field_types[key] == null)
      continue;
    this[key] = data ? data[key] : undefined;
  }
}
SchemaObj.field_types = {};
SchemaObj.marker = ObjectMarker.sub('tv.SchemaObjMarker');
base.addTypeKey(SchemaObj.prototype, 'tv.SchemaObj');

SchemaObj.extend = function(name, ctor, options) {
  // Type.extend(name, [ctor,] {field_types})
  var super_ = this;

  if (!options) {
    options = ctor;
    ctor = null;
  }
  ctor = ctor || SubSchemaObj;

  options.field_types = extend({}, this.field_types, options.field_types);
  mixin(ctor, options);

  ctor.extend = this.extend;

  if (ctor.marker) {
    // re-extending.  In this case a stub schemaobj is being defined in place.
    // We can update markers and typegraphs in place also.
    //console.log('extending '+name+' from '+this.marker.prototype);
    ctor.marker.prototype = this.marker.sub(name+'Marker').prototype;
    ctor.typegraph.value = new ctor.marker();
    iter.forEach(ctor.typegraph.value.of(options.field_types).edge_iter(), function(edge) {
        ctor.typegraph.set_edge(edge.key, edge.node);
      });

  } else {
    ctor.marker = this.marker.sub(name);
    ctor.typegraph = new ctor.marker().of(options.field_types);
  }

  ctor.prototype = extend(
    this.prototype, {
      constructor: ctor,
    });
  base.addTypeKey(ctor.prototype, name);

  ctor.marker.prototype.target_proto = ctor.prototype;
  ctor.toString = toString;

  return ctor;

  function SubSchemaObj(data) {
    if (!(this instanceof SubSchemaObj)) {
      var self = Object.create(SubSchemaObj.prototype);
      SubSchemaObj.apply(self, arguments);
      return self;
    }

    super_.apply(this, arguments);
  }

  function toString() {
    return '<'+base.ownTypeKeyOf(this.prototype)+'>';
  }
};

base.to_typegraph.register(obj_to_typegraph, 'tv.SchemaObj');
function obj_to_typegraph(disp, obj) {
  return obj.constructor.typegraph;
}
