import { createRequiredContext } from '@maternity/mun-required-context';
import { Path, PathValue } from '@maternity/mun-types';
import classNames from 'classnames';
import * as React from 'react';

import { BaseFieldProps, EnforceRequired, FieldValues } from './types';
import { useField } from './useField';
import { requiredNonNullableValidator } from './validators';

// TODO: Add typechecking of options and <Radio> values? Would probably
// require passing around a handle via props similar to useForm...

interface RadioGroupContextType {
  model: string;
  modelValue: unknown;
  setValue: (e: React.ChangeEvent<any>, value: unknown) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
  allDisabled: boolean;
  inline: boolean;
}

const RadioGroupContext = createRequiredContext<RadioGroupContextType>();

interface RadioProps {
  /** The value associated with the radio. */
  value: unknown;
  /** If the radio should be disabled. */
  disabled?: boolean;
  /** If true, don't wrap the radio in a label (no styles). */
  // TODO: Eliminate the need for this...
  bare?: boolean;
  /** The label for the radio. */
  children: React.ReactNode;
}

/**
 * A single radio button input, which must be used inside a `<RadioGroup>`.
 *
 * A label should be provided via `children`.
 */
export const Radio = ({ value, disabled, bare, children }: RadioProps) => {
  const { model, modelValue, setValue, onBlur, allDisabled, inline } =
    RadioGroupContext.useContext();

  const body = (
    <React.Fragment>
      <input
        type="radio"
        name={model}
        onBlur={onBlur}
        onChange={(e) => setValue(e, value)}
        checked={value === modelValue}
        disabled={allDisabled || disabled}
      />
      {children}
    </React.Fragment>
  );
  if (bare) return body;
  if (inline) {
    return (
      <label className={classNames('radio-inline', disabled && 'disabled')}>
        {body}
      </label>
    );
  }
  return (
    <div className={classNames('radio', disabled && 'disabled')}>
      <label>{body}</label>
    </div>
  );
};

interface OptionConfig {
  label: React.ReactNode;
  value: unknown;
  disabled?: boolean;
}
type OptionsType = Array<string | OptionConfig>;

const normalizeOptions = (options: OptionsType): OptionConfig[] =>
  options.map((opt) => {
    if (typeof opt === 'string') {
      return { label: opt, value: opt };
    }
    return opt;
  });

type RadioGroupProps<V extends FieldValues, M extends Path<V>> = {
  /** A list of options. When the value type includes strings, strings are
   * permitted, which will be used as both the label and value. Otherwise,
   * options may be specified as objects with the label, value, and optional
   * disabled state. */
  options?: OptionsType;
  /** If true, all radios will be disabled. */
  allDisabled?: boolean;
  /** If true, the radios will be inline. */
  inline?: boolean;
  /** Called when the value changes. The new value is passed as the second
   * argument.  */
  onChange?: (e: React.ChangeEvent<any>, value: PathValue<V, M>) => void;
  /** Called when a checkbox is blurred. */
  onBlur?: (e: React.FocusEvent<any>) => void;
  /** May contain additional `<Radio>` options. */
  children?: React.ReactNode;
} & BaseFieldProps<V, M> &
  EnforceRequired<V, M>;

// Use constant for default values to avoid breaking memoization
const EMPTY_ARRAY: any[] = [];

/**
 * A group of radio items.
 *
 * Options may be given via the `options` prop or rendering `<Radio>`
 * components as children (depth doesn't matter). If both are given, the
 * options from the `options` prop appear first.
 */
export const RadioGroup = <V extends FieldValues, M extends Path<V>>({
  form,
  model,
  validators = EMPTY_ARRAY,
  required,
  options = EMPTY_ARRAY,
  allDisabled = false,
  inline = false,
  onChange,
  onBlur,
  children,
}: RadioGroupProps<V, M>) => {
  validators = React.useMemo(
    () =>
      required ? [requiredNonNullableValidator, ...validators] : validators,
    [validators, required],
  );
  const [modelValue, api] = useField<unknown>({ form, model, validators });
  const context: RadioGroupContextType = React.useMemo(
    () => ({
      model,
      modelValue,
      allDisabled,
      inline,
      setValue(e, v) {
        // Bail out if somehow triggered for an unchecked input
        if (!e.target.checked) return;
        api.setValue(v);
        if (onChange) onChange(e, v as PathValue<V, M>);
      },
      onBlur(e) {
        api.setTouched();
        if (onBlur) onBlur(e);
      },
    }),
    [api, model, modelValue, allDisabled, inline, onChange, onBlur],
  );
  const optionRadios = React.useMemo(
    () =>
      normalizeOptions(options).map(({ value, label, disabled }, idx) => (
        <Radio key={idx} value={value} disabled={disabled}>
          {label}
        </Radio>
      )),
    [options],
  );

  return (
    <RadioGroupContext.Provider value={context}>
      {optionRadios}
      {children}
    </RadioGroupContext.Provider>
  );
};
