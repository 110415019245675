var { extend } = require('@maternity/mun-extend');


exports.BaseDispatcher = BaseDispatcher;
function BaseDispatcher() {}

BaseDispatcher.prototype = {
  dispatch: function(val) {
    var keys = this._to_keys(val),
        key,
        mapping,
        i, j;

    for (i=0; i < keys.length; i++) {
      key = keys[i];
      for (j=0; j < this.dispatch_mro.length; j++) {
        mapping = this.dispatch_mro[j].mapping;
        if (mapping[key] != null)
          return mapping[key];
      }
    }

    for (j=0; j < this.dispatch_mro.length; j++) {
      if (this.dispatch_mro[j].default_ != null)
        return this.dispatch_mro[j].default_;
    }
  },

  call: function(val) {
    var fn = this.dispatch(val),
        args;

    if (fn == null)
      throw new NoDispatchError('No handler defined for any of '+String(this._to_keys(val)));

    args = [].slice.call(arguments);
    args.unshift(this);

    return fn.apply(null, args);
  },
};


exports.Dispatcher = Dispatcher;
function Dispatcher(mapping, keyfn, parents) {
  //BaseDispatcher.call(this);

  if (typeof arguments[0] === 'function') {
    parents = arguments[1];
    keyfn = arguments[0];
    mapping = undefined;

  } else if (Array.isArray(arguments[0])) {
    parents = arguments[0];
    keyfn = undefined;
    mapping = undefined;
  }

  this.mapping = mapping || {};

  this.keyfn = keyfn;

  this.dispatch_mro = mro_merge(
      [[this]].concat(
          (parents||[])
            .map(function(d) { return d.dispatch_mro; })));
}

Dispatcher.prototype = extend(
    BaseDispatcher.prototype, {
      constructor: Dispatcher,

      _to_keys: function(val) {
        var keys = this.keyfn(val);
        return Array.isArray(keys)
          ? keys
          : [keys];
      },

      set_default: function(fn) {
        this.default_ = fn;
      },

      register: function(fn, key) {
        var mapping = this.mapping;
        if (Array.isArray(key))
          key.forEach(this.register.bind(this, fn));
        else
          mapping[key] = fn;
      },

      sub: function() {
        return new Dispatcher(this.keyfn, [this]);
      },

      super: function(ctor) {
        return new DispatchSuper(ctor, this);
      },
    });


exports.DispatchSuper = DispatchSuper;
function DispatchSuper(above, disp) {
  this.disp = disp;
  this.dispatch_mro = disp.dispatch_mro.slice(disp.dispatch_mro.indexOf(above)+1);
}

DispatchSuper.prototype = extend(
    BaseDispatcher.prototype, {
      constructor: DispatchSuper,

      _to_keys: function(val) {
        return this.disp._to_keys(val);
      },

      call: function(val) {
        var fn = this.dispatch(val),
            args;

        if (fn == null)
          throw new NoDispatchError('No handler defined for any of '+String(this._to_keys(val)));

        args = [].slice.call(arguments);
        args.unshift(this.disp);

        return fn.apply(null, args);
      },
    });

// This implementation merges depth first as python does, rather than breadth first which the
// travesty docs describe.
function mro_merge(lists) {
  var nexts = [],
      merged = [];

  lists = lists.map(function(l) { return l.slice(); });
  while (true) {
    for (var i=0; i < lists.length; i++) {
      if (lists[i].length > 0)
        break;
    }

    if (lists[i] == null)
      break;

    findPreceding(lists[i][0]);
    while (nexts.length > 0)
      merged.push(nexts.pop());
  }

  return merged;

  function findPreceding(n) {
    var idx;

    idx = nexts.indexOf(n);
    if (idx !== -1)
      throw new Error('Cannot merge lists: '
        +nexts.slice(idx).concat(n).join(' -> '));
    nexts.push(n);

    for (var i=0; i < lists.length; i++) {
      do {
        idx = lists[i].indexOf(n);

        if (idx === 0)
          lists[i].splice(idx, 1);

        else if (idx > 0)
          findPreceding(lists[i][idx-1]);
      } while (idx > 0);
    }
  }
}


exports.NoDispatchError = NoDispatchError;
function NoDispatchError(message) {
  this.message = message;
  this.name = 'NoDispatchError';
  if (Error.captureStackTrace != null)
    Error.captureStackTrace(this, NoDispatchError);
  else
    this.stack = Error().stack;
}

NoDispatchError.prototype = extend(
  Error.prototype, {
    constructor: NoDispatchError,
  });
