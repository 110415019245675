var base = require('./base');


base.Wrapper.sub('tv.Validated', Validated);
exports.Validated = Validated;
function Validated(marker, vdors) {
  if (!(this instanceof Validated)) {
    var self = Object.create(Validated.prototype);
    Validated.apply(self, arguments);
    return self;
  }

  base.Wrapper.apply(this, arguments);
  this.vdors = vdors;
}
