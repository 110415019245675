import * as React from 'react';

interface Props {
  children: React.ReactNode;
}

/**
 * Wrapper for aligning content with the inputs in a horizontal form. This is
 * useful for buttons, single checkboxes, text, etc.
 */
export const OffsetFormGroup = ({ children }: Props) => (
  <div className="form-group">
    <div className="col-sm-9 col-sm-offset-3">{children}</div>
  </div>
);
