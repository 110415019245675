import * as React from 'react';

interface Props {
  children: React.ReactNode;
}

/**
 * Renders a panel footer.
 *
 * Takes no props.
 */
export const PanelFooter = ({ children }: Props) => (
  <div className="panel-footer">{children}</div>
);
