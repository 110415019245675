/* Test if the browser is supported.
 *
 * Checks for the following features:
 * - media queries
 * - canvas
 * - File api
 * - accessors (get/set) in object literals
 */
Modernizr.addTest('supportedbrowser', function() {
  return Modernizr.mq('only all')
    && Modernizr.canvas
    && Modernizr.filereader
    && Modernizr.es5syntax
    ;
});

