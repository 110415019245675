import { Button, ButtonProps } from '@maternity/mun-layout';
import * as React from 'react';

import { ComboboxCtx } from './useComboboxCtx';

type ComboboxToggleProps = Omit<
  ButtonProps<'button'>,
  'disabled' | 'component'
> & {
  /** Combobox context object */
  ctx: ComboboxCtx<any>;
};

/** Renders a button for toggling the open state of the combobox menu. */
export const ComboboxToggle = ({
  ctx,
  onClick,
  ...rest
}: ComboboxToggleProps) => {
  const { isOpen, setIsOpen, disabled, menuId } = ctx;

  return (
    <Button
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        setIsOpen((o) => !o);
        onClick?.(e);
        // TODO: Automatically focus input element?
      }}
      disabled={disabled}
      className="dropdown-toggle"
      aria-controls={menuId}
      aria-haspopup="listbox"
      aria-expanded={isOpen}
      {...rest}
    />
  );
};
