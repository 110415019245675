import * as React from 'react';

import { Label } from '../Label';

type LabelProps = React.ComponentProps<typeof Label>;
interface Props {
  /** Label content. Can be text, JSX, or a function (see Label component). */
  label: NonNullable<LabelProps['children']>;
  /** Props to pass through to the Label component. */
  labelProps?: Omit<LabelProps, 'children' | 'required' | 'htmlFor'>;
  /** Simplifies passing required to the Label component. */
  required?: boolean;
  /** Simplifies passing htmlFor to the Label component. */
  htmlFor?: string;
  /** Props to pass through to the div wrapper. */
  divProps?: React.ComponentProps<'div'>;
  children: React.ReactNode;
}

/** Renders the layout for a field with a label. */
export const Field = ({
  required,
  htmlFor,
  label,
  labelProps = {},
  divProps = {},
  children,
}: Props) => (
  <div className="form-group">
    <Label htmlFor={htmlFor} required={required} {...labelProps}>
      {label}
    </Label>
    <div {...divProps}>{children}</div>
  </div>
);
