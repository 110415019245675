/**
 * mun-cantrips
 *
 * A collection of functions that don't seem to belong anywhere else
 *
 */
import _ from 'lodash';
import tsort from '@maternity/vendor-tsort';

const ngModule = angular.module('mun-cantrips', [])

  .filter('shorten', function() {
    return function(text, length, atback) {
      var options = {};
      if (atback === undefined)
        atback = true;
      if (length === undefined)
        length = 15;
      options.length = length;
      if (atback)
        return _.truncate(text, options);
      return reverseString(_.truncate(reverseString(text), options));
    };

    function reverseString(string) {
      return string.split('').reverse().join('');
    }
  })

  .filter("whenNone", function() {
    // Replace undefined/null with val
    return function(string, val) {
      if (string==null)
        return val;
      return string;
    };
  })

  .filter("upperFirst", function() {
    // Capitalize the first character of a string
    return _.upperFirst;
  })

  .filter("isEmpty", function() {
    // Return true iff _.isEmpty(value)
    return _.isEmpty;
  })

  .filter("itemsetAsArray", function() {
    // Convert an ItemSet to an array
    // An ItemSet is a dict where the keys are all e.g. item0, item1, etc. This
    // flattens that into an array of objects and also gives each object a .id
    // attribute that stores its index in the ItemSet. Thus, if myset =
    // {item0:{x:1}, item3:{x:2}}, then myset | itemsetAsArray will be
    // [{x:1, id:'item0'}, {x:2, id:'item3'}]
    return function(dict) {
      return _.map(dict, function(problem, id) {
        return _.assignIn(problem, {id:id});
        // Cloning them would make sense, but somehow this confuses angular:
        //return _.assignIn(_.clone(problem), {$key: id});
      });
    };
  })

  .factory('beforeAfterSort', function() {
    /**
     * tsort wrapper to sort before/after an array of values
     *
     * `add` arguments:
     * - key: Value to sort
     * - before: Optional value or array of values to sort key before
     * - after: Optional value or array of values to sort key after
     */
    //
    return beforeAfterSort;

    function beforeAfterSort() {
      var graph= tsort();
      return {
        add: add.bind(graph),
        sort: graph.sort.bind(graph),
      };
    }

    function add(key, before, after) {
      var graph = this;
      if (before) {
        if (Array.isArray(before)) {
          before.forEach(function(val){
            graph.add(key, val);
          });
        } else {
          graph.add(key, before);
        }
      } else {
        graph.add(key);
      }

      if (after) {
        if (Array.isArray(after)) {
          after.forEach(function(val){
            graph.add(val, key);
          });
        } else {
          graph.add(after, key);
        }
      } else {
        graph.add(key);
      }
    }
  })

  ;

export default ngModule.name;
