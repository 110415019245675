import { MunSuspense } from '@maternity/mun-error-boundary';
import {
  Cancel,
  Form,
  RadioGroupField,
  Submit,
  useForm,
} from '@maternity/mun-forms/horizontal';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHideButton,
  ModalTitle,
} from '@maternity/mun-modal';
import { useMunDocLoader } from '@maternity/mun-router';
import { injector } from '@maternity/ng-mun-linear';
import * as React from 'react';

import { kerbin_ProfileStub } from '../types';

interface SetProfileFieldValues {
  profile: kerbin_ProfileStub;
}

const SetDefaultProfileModalContent = ({ onHide }: Props) => {
  const accountSession = useMunDocLoader('accountSessionIO.session').read()
    .item;
  const handleSubmit = React.useCallback(
    (data: SetProfileFieldValues) => {
      const munDocIO = injector.get('munDocIO');
      const io = munDocIO.mkDocSaver('set_default_profile');
      return io(data.profile).then(() => onHide?.());
    },
    [onHide],
  );
  const defaultUid = accountSession.default_profile?.person.uid;
  const defaultProfile = defaultUid
    ? accountSession.profiles.find((p) => p.person.uid === defaultUid)
    : undefined;
  const options = accountSession.profiles.map((profile) => ({
    value: profile,
    label: `${profile.practice.name} (${
      profile.mode === 'client' ? 'Client' : 'User'
    })`,
  }));
  const form = useForm<SetProfileFieldValues>({
    defaultValues: { profile: defaultProfile },
  });

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <ModalBody>
        <RadioGroupField
          form={form}
          label="Default Practice"
          model="profile"
          options={options}
          required={true}
        />
      </ModalBody>
      <ModalFooter>
        <Submit>Save changes</Submit>
        <ModalHideButton component={Cancel} />
      </ModalFooter>
    </Form>
  );
};

interface Props {
  onHide?: () => void;
}

export const SetDefaultProfileModal = ({ onHide }: Props) => (
  <Modal initialOpen={true} onHide={onHide}>
    <ModalHeader>
      <ModalTitle>Set default practice</ModalTitle>
    </ModalHeader>
    <MunSuspense>
      <SetDefaultProfileModalContent onHide={onHide} />
    </MunSuspense>
  </Modal>
);
