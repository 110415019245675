import classNames from 'classnames';
import * as React from 'react';

type StaticControlProps = React.ComponentProps<'div'> & {
  // Allow these to be passed in, but they are ignored
  form?: unknown;
  model?: unknown;
  validators?: unknown;
  required?: unknown;
};

/**
 * Renders text in place of an input within a form.
 */
export const StaticControl = ({
  className,
  // Drop these props
  form,
  model,
  validators,
  required,
  ...props
}: StaticControlProps) => (
  <div className={classNames('form-control-static', className)} {...props} />
);
