/*
counter.js - for counting things.

The only export is the Counters type, which is useful for counting things and
printing them in debug statements:
Basic usage:
> c = new Counters('some_name');
> c.counts.thing1 = 5;
> c.counts.thing2 = 14;
> c.getStats()
"[some_name: {"thing1":5,"thing2":14}]"

Counters also nest automatically via the .get() method:

> c.get('foo').counts.thing1=2
> c.get('foo','bar','baz').counts.thing1=3
> c.getStats()
"[some_name: {"thing1":5,"thing2":14}
  [foo: {"thing1":2}
    [bar:
      [baz: {"thing1":3}]]]]"

You can get just the local stats (ignoring children) with .getTheseStats():

> c.getTheseStats()
"{"thing1":5,"thing2":14}"

You can also use e.g. `c.getStats(/foo/)` to select only stats that match the
given regex somewhere in the path.

For example, in our current setup, merely logging in gets you a lot of stats:

> keepalive.stats.getStats()
"[stats:
  [invalidationMonitors:
    [kerbin.item(kerbin.AccountSessionStub<>):
      [message: 0/1 monitors completed]
      [session: 0/1 monitors completed]]
    [kerbin.item(kerbin.ProfileSessionWithIntercomData<>):
      [message: 0/2 monitors completed]
      [session: 0/2 monitors completed]]
    [kerbin.GiantCSVTimestamps:
      [message: 0/1 monitors completed]]
    [kerbin.ClientStub:
      [document: 0/6 monitors completed]
      [clientstub: 0/6 monitors completed]]
    [kerbin.ClientIndexMessage:
      [message: 0/1 monitors completed]
      [clients_index: 0/1 monitors completed]]]
  [alive:
    [kerbin.item(kerbin.ProfileSessionWithIntercomData<>): 0/1 keepalives completed]
    [kerbin.item(kerbin.AccountSessionStub<>): 0/1 keepalives completed]
    [mun-doc.SearchResults: 0/1 keepalives completed]]]""

>keepalive.stats.getStats(/CSV/)
[stats:
  [invalidationMonitors:
    [kerbin.GiantCSVTimestamps:
      [message: 0/1 monitors completed]]]]


Stringifying a Counters is the same as calling .getStats():

> c.toString()
"[some_name: {"thing1":5,"thing2":14}
  [foo: {"thing1":2}
    [bar:
      [baz: {"thing1":3}]]]]"

Finally, you can use the static method Counters.define to create subclasses with
sensible defaults and more expressive printed values:

> MyCounter = Counters.define({
      subCounters: {
        foo: new (Counters.define({
          counts: {
            happy: 0,
            sad: 0,
            get total() {
              return this.happy+this.sad;
            },
          },
          getTheseStats: function() {
            return ''+this.counts.happy+'/'+this.counts.total+' happy';
          },
        }))(),
        bar: new (Counters.define({
          counts: {
            potatoes: 0,
            carrots: 0,
          },
        }))(),
      },
    });

> c = new MyCounter('rootname');
> c.get('foo').counts.happy++;
> c.get('foo').counts.sad++;
> c.get('bar').counts.potatoes = 9;
> c.getStats();
"[rootname:
  [foo: 1/2 happy]
  [bar: {"potatoes":9}]]"
*/

import { extend, mixin } from '@maternity/mun-extend';
import tv from '@maternity/travesty';

export function Counters(name) {
  this.name = name;
  this.counts = this.counts
    ? Object.create(this.counts)
    : {};
  this.subCounters = this.subCounters
    ? Object.create(this.subCounters)
    : {};
}

mixin(
  Counters.prototype, {
    get: countersGet,
    getStats: countersGetStats,
    getTheseStats: function() { return JSON.stringify(this.counts); },
    toString: function() { return this.getStats(); },
  });

Counters.define = defineCounter;

function defineCounter(proto, ctor) {
  var base = this;

  if (ctor == null)
    ctor = SubCounters;

  ctor.prototype = extend(
    base.prototype,
    proto, {
      constructor: ctor,
    });

  return ctor;

  function SubCounters() {
    base.apply(this, arguments);
  }
}

function countersGet(name) {
  // Get a sub-counter for a given name. Also accepts travesty types, and
  // anything else that works with tv.base.ownTypeKeyOf. If a subcounter doesn't
  // exist on this counter, one will be created, inheriting from the one on this
  // object's prototype if present. This also accepts additional arguments, in
  // which case it's shorthand for calling this method repeatedly - e.g.
  // events.get('foo', 'bar', 'baz') is short for
  // events.get('foo').get('bar').get('baz')
  var key = typeof name === 'string'
        ? name
        : tv.base.ownTypeKeyOf(name.prototype),
      sub = Object.hasOwnProperty.call(this.subCounters, key)
        ? this.subCounters[key]
        : this.subCounters[key] = new (this.subCounters[key] || this).constructor(key),
      args = [].slice.call(arguments, 1);

  return args[0] != null
    ? sub.get.apply(sub, args)
    : sub;
}

function countersGetStats(pattern) {
  var s,
      subs = '';

  if (pattern && pattern.test(this.name))
    pattern = null;

  if (Object.keys(this.counts).length && !pattern)
    s = this.getTheseStats();

  for (var k in this.subCounters) {
    var sub = this.subCounters[k],
        subStats = sub.getStats(pattern);

    if (subStats)
      subs += '\n'+subStats.replace(/^/mg, '  ');
  }

  if (s || subs)
    return '['
        +this.name+':'
        +(s ? ' '+s : '')
        +subs
      +']';
}
