import { setDefaultErrorMessage } from './validation';

/** Check that the value is not null/undefined. */
export const requiredNonNullableValidator = (value: unknown) => {
  if (value == null) return 'required';
};
/** Check that the value is a non-empty string. */
export const requiredStringValidator = (value: unknown) => {
  if (typeof value !== 'string' || value === '') return 'required';
};
setDefaultErrorMessage('required', 'This field is required.');
// Server-side validation
setDefaultErrorMessage('empty', 'This field is required.');

// This regex was taken from the angular.js source
// TODO: Should the travesty email validator regex instead?
const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
/** Check that the value is a valid email address (or nully). */
export const emailValidator = (value: unknown) => {
  if (value == null) return;
  if (typeof value !== 'string' || !EMAIL_REGEXP.test(value)) {
    return 'email';
  }
};
const invalidEmailMessage = 'Enter a valid email address.';
setDefaultErrorMessage('email', invalidEmailMessage);
// Server-side validation
setDefaultErrorMessage('invalid_email/no_at', invalidEmailMessage);
setDefaultErrorMessage('invalid_email/bad_user', invalidEmailMessage);
setDefaultErrorMessage('invalid_email/bad_domain', invalidEmailMessage);

/**
 * Check that the value matches the given regex, if the value is not nully. The
 * value is coerced to a string to support numbers and custom objects.
 */
export const patternValidator = (pattern: RegExp) => (value: unknown) => {
  if (value == null) return;
  if (!pattern.test(`${value}`)) {
    return 'pattern';
  }
};

/** Check that a string value is not longer than the given length. */
export const maxLengthValidator = (len: number) => (value: unknown) => {
  if (value == null) return;
  if (typeof value !== 'string' || value.length > len) {
    return 'maxlength';
  }
};

/** Check that a string value is not shorter than the given length. */
export const minLengthValidator = (len: number) => (value: unknown) => {
  if (value == null) return;
  if (typeof value !== 'string' || value.length < len) {
    return 'minlength';
  }
};

/** Check that a numeric value is not greater than the given limit. */
export const maxValidator = (max: number) => (value: unknown) => {
  if (value == null) return;
  if (typeof value !== 'number' || value > max) {
    return 'max';
  }
};

/** Check that a numeric value is not less than the given limit. */
export const minValidator = (min: number) => (value: unknown) => {
  if (value == null) return;
  if (typeof value !== 'number' || value < min) {
    return 'min';
  }
};
