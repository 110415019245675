import { createRequiredContext } from '@maternity/mun-required-context';
import { useUnloadableContext } from '@maternity/mun-unload-detect';
import {
  MunFormSafetyService,
  MunGlobalBypassFormSafetyService,
} from '@maternity/ng-mun-form-safety';
import { injector } from '@maternity/ng-mun-linear';
import angular from 'angular';
import * as React from 'react';

import { FieldValues, FormApi, FormState } from './types';
import { useFormState } from './useFormState';

// Context for passing down the bypass form safety function
const BypassFormSafetyContext = createRequiredContext<() => void>(
  'Form safety bypass cannot be used outside a form',
);

const useFormSafety = (blockUnloadWhenDirty: boolean, form: FormApi<any>) => {
  const unloadManager = useUnloadableContext();
  // We need a mutable value, but don't need to re-render when it changes, so
  // we use a ref here.
  const isBypassedRef = React.useRef(false);
  const bypassFormSafety = React.useCallback(() => {
    isBypassedRef.current = true;
  }, []);

  React.useEffect(() => {
    if (!blockUnloadWhenDirty) return;

    if (!form.formRef.current) throw new Error('Form ref not set');

    const globalBypass = injector.get(
      'globalBypassFormSafety',
    ) as MunGlobalBypassFormSafetyService;

    const isSafe = () =>
      isBypassedRef.current ||
      !form.getFormState().isDirty ||
      globalBypass.safe;

    const angularEl = angular.element(form.formRef.current as HTMLElement);
    return unloadManager.subscribe(angularEl, (sync) => {
      if (sync) {
        return form.getFormState().isSubmitting
          ? false // Not safe to unload while the form is submitting
          : isSafe();
      }

      const formSafety = injector.get('formSafety') as MunFormSafetyService;

      // Wait for submission to finish, if necessary
      const whenSubmitDone =
        form.getFormState().whenSubmitDone || Promise.reject();
      // If submission fails, check that the form is safe or invoke the
      // confirmation handler.
      return whenSubmitDone.catch<any>(
        () => isSafe() || formSafety.confirmHandler(),
      );
    });
  }, [blockUnloadWhenDirty, form, unloadManager]);

  return bypassFormSafety;
};

type FormElProps = React.ComponentProps<'form'>;
export type FormProps<V extends FieldValues> = Omit<FormElProps, 'onSubmit'> & {
  /** Form API handle */
  form: FormApi<V>;
  /** A submit handler function. Should be memoized to avoid unnecessary
   * rerenders. */
  onSubmit?: (values: V) => Promise<unknown>;
  /** If true (the default), block unloads when the form is dirty */
  blockUnloadWhenDirty?: boolean;
};

/**
 * Renders a `<form>` element and a `fieldset` to disable all inputs while the
 * form is submitting.
 */
export const Form = <V extends FieldValues>({
  form,
  onSubmit,
  onReset,
  blockUnloadWhenDirty = true,
  children,
  ...formProps
}: FormProps<V>) => {
  // TODO: store onSubmit/onReset in refs so they don't need to be memoized?
  const handleSubmit = React.useCallback<NonNullable<FormElProps['onSubmit']>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (onSubmit) form.handleSubmit(onSubmit);
    },
    [form, onSubmit],
  );
  const handleReset = React.useCallback<NonNullable<FormElProps['onReset']>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      form.resetForm();
      // TODO: call onReset first and don't resetForm if default prevented?
      onReset?.(e);
    },
    [form, onReset],
  );
  const bypassFormSafety = useFormSafety(blockUnloadWhenDirty, form);
  const selector = React.useCallback(
    (state: FormState<V>) => state.isSubmitting,
    [],
  );
  const isSubmitting = useFormState(form, selector);

  return (
    <form
      noValidate={true}
      onReset={handleReset}
      onSubmit={handleSubmit}
      ref={form.formRef}
      {...formProps}
    >
      <fieldset disabled={isSubmitting}>
        <BypassFormSafetyContext.Provider value={bypassFormSafety}>
          {children}
        </BypassFormSafetyContext.Provider>
      </fieldset>
    </form>
  );
};

/**
 * Returns the bypass form safety function for the current form.
 */
export const useBypassFormSafety = BypassFormSafetyContext.useContext;
