var vg = require('@maternity/vertigo'),

    Invalid = require('./invalid').Invalid,
    base = require('./base'),
    Marker = base.Marker,
    to_typegraph = base.to_typegraph,
    traverse = base.traverse,
    dictify = base.dictify,
    undictify = base.undictify,

    Tuple = exports.Tuple = Marker.sub('tv.Tuple', function Tuple(nfields, field_names) {
      if (!(this instanceof Tuple)) {
        var self = Object.create(Tuple.prototype);
        Tuple.apply(self, arguments);
        return self;
      }

      if (field_names == null && Array.isArray(nfields)) {
        field_names = arguments[0];
        nfields = field_names.length;

      } else if (field_names == null) {
        field_names = [];
        for (var i=0; i < nfields; i++)
          field_names.push(i.toString());
      }

      this.field_names = field_names;
      this.nfields = nfields;

      // provide some named accessors in prototype
      field_names.forEach(function(name, i) {
        if (+name !== i)
          Object.defineProperty(ctor.prototype, name, {get: function() { return this[i]; }});
      });
      this.ctor = ctor;

      function ctor(d) {
        if (!(this instanceof ctor))
          return new ctor(d);
        // This gets some basic index and length properties.  We could inherit from array too, but
        // I'm not sure if we want to do array stuff with tuples.
        [].splice.apply(this, [0, 0].concat(d));
      }
    });


Tuple.prototype.of = function(types) {
  var edges = this.field_names.map(function(k, i) {
        var typegraph = Array.isArray(types)
              ? to_typegraph.call(types[i])
              : to_typegraph.call(types[k]);

        return {key: k, node: typegraph};
      });

  return new vg.PlainGraphNode(this, edges);
};

traverse.register(traverse_tuple, Tuple);
function traverse_tuple(disp, value, options) {
  var marker = disp.marker,
      zipgraph = options?.zipgraph,
      suboptions = zipgraph != null ? Object.create(options) : options,

      edges = marker.field_names.map(function(key, i) {
        if (zipgraph != null)
          suboptions.zipgraph = zipgraph.get_child(key);

        return {key: key, node: disp.get_child(key).call(value[i], suboptions)};
      }),

      v = zipgraph != null ? [value, zipgraph.value] : value;

  return new vg.PlainGraphNode(v, edges);
}

dictify.register(dictify_tuple, Tuple);
function dictify_tuple(disp, value, options) {
  var marker = disp.marker;

  return marker.field_names.map(function(key, i) {
    return disp.get_child(key).call(value[i], options);
  });
}

undictify.register(undictify_tuple, Tuple);
function undictify_tuple(disp, value, options) {
  var marker = disp.marker;

  if (value == null)
    throw new Invalid('type_error');

  if (value.length !== marker.nfields)
    throw new Invalid('bad_len');

  return marker.ctor(marker.field_names.map(function(key, i) {
    return disp.get_child(key).call(value[i], options);
  }));
}
