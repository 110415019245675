import _ from 'lodash';
import munCantripsModule from '@maternity/ng-mun-cantrips';
import routingModule from '@maternity/vendor-dotjem-routing';

const ngModule = angular.module('mun-nav', [
    munCantripsModule,
    routingModule,
  ])

  .factory('navigation', function() {
    var sections = {},
        // We don't need to calculate reachable navs with every digest cycle.
        cache = {},
        self = {
          refresh: refresh,
          addNavItem: addNavItem,
          sections: {},
        };

    return self;

    /* Add a navigation item to section.  Properties of nav include:
     * - label
     * - href
     * - active
     * - templateUrl
     * - extra
     */
    function addNavItem(section, nav) {
      if (sections[section] == null)
        addSection(section);

      sections[section].push(nav);
    }

    function addSection(name) {
      sections[name] = [];
      Object.defineProperty(self.sections, name, {
          enumerable: true,
          get: sectionResolver(name),
        });
    }

    function sectionResolver(name) {
      return function() {
          if (cache[name] == null && sections[name]) {
            cache[name] = sections[name].reduce(function(acc, nav) {
                if (nav.href != null && nav.hasPerm)
                  acc.push(nav);
                return acc;
              }, []);
          }
          return cache[name];
        };
    }

    function refresh() {
      cache = {};
    }
  })

  .controller('MunNavigationCtrl', function(navigation, $routeParams) {
    var self = Object.create(navigation.sections);
    self.$params = $routeParams;

    return self;
  })

  .run(function($state, navigation, $rootScope, $injector, beforeAfterSort) {

    /* Object: convert states that have nav segments looking like this:
     *
     * [
     *   {
     *     fullname: top.provider.patients,
     *     nav: {
     *       main: {
     *         label: Patients
     *       },
     *     },
     *   },
     *   {
     *     fullname: top.provider.patients.new,
     *     nav: {
     *       patients: {
     *         label: 'Add a Patient',
     *       },
     *     },
     *   },
     *   {
     *     fullname: top.provider.surveys,
     *     nav: {
     *       main: {
     *         label: Surveys,
     *         after: top.provider.patients,
     *       },
     *     },
     *   },
     * ]
     *
     * to this:
     * {
     *   main: {
     *     top.provider.patients: {...},
     *     top.provider.surveys: {...},
     *   },
     *   patients: {
     *     top.provider.patients.new: {...},
     *   },
     * }
     *
     * Then apply tsort for this:
     *
     * {
     *   main: [
     *     { name: top.provider.patients, nav: {...} },
     *     { name: top.provider.surveys, nav: {...} },
     *   ],
     *   patients: [
     *     {name: top.provider.patients.new, nav: {...} },
     *   ],
     * }
     */

    var ROOTNAME = '$root',
        allStates = collect($state.root),
        navMetas = _.reject(allStates, {fullname: ROOTNAME})
          .map(function(state) {
            // We have to use $state.lookup to get the nav data.  I don't know why there are
            // multiple data types for state.

            return _.map($state.lookup(state.fullname).nav || {}, function(nav, section) {
                return {
                    state: state.fullname.slice(ROOTNAME.length+1),
                    section: section,
                    nav: nav};
              });
          })
          .reduce(function(a, b) { return a.concat(b); });

    _.forEach(
        _.groupBy(navMetas, 'section'),
        function(navMetas, navSection) {
          _.transform(
              navMetas,
              function(g, navMeta) {
                var nav = navMeta.nav,
                    navState = navMeta.state;
                g.add(navState, nav.before, nav.after);
              }, beforeAfterSort())
            .sort()
            .forEach(function(navState) {
              var state, nav;

              // meant to catch errors when looking up states in apps not loaded.
              try {
                state = $state.lookup(navState);
              } catch (err) {
                return;
              }

              nav = state.nav[navSection];

              if (!nav) {
                throw Error('State does not have the navSection configured properly. Ensure "before" declarations are being accurately registered.');
              }

              navigation.addNavItem(navSection, {
                  label: nav.label,
                  templateUrl: nav.templateUrl,
                  extra: nav.extra,
                  get href() {
                    try {
                      return $state.url(navState);
                    } catch (e) {
                      // States with parameters, will take them from the current context.  If a
                      // parameter is not available then url generation blows up.  It would be
                      // nice if it had a way to signal this that could be differentiated from
                      // any other programming error.
                    }
                  },
                  get active() {
                    return $state.isActive(navState);
                  },
                  get current() {
                    return $state.is(navState);
                  },
                  get hasPerm() {
                    if (!state.checkPermission) return true;

                    // TODO: Check that state parameters are taken from
                    // current contxt
                    return $injector.invoke(state.checkPermission, state);
                  },
                });

            });
        });

    $rootScope.$on('$stateChangeSuccess', function() {
        navigation.refresh();
      });

    function collect(state) {
      if (!state.children)
        return [state];
      return Object.keys(state.children)
          .reduce(function(states, name) {
              return states.concat(collect(state.children[name]));
            }, [state]);
    }
  })

  ;

export default ngModule.name;
