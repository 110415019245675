import alertModule from '@maternity/vendor-angular-strap/alert';
import animateModule from 'angular-animate';
import datepickerModule from '@maternity/vendor-angular-strap/datepicker';
import dropdownModule from '@maternity/vendor-angular-ui-bootstrap/dropdown';
import munCantripsModule from '@maternity/ng-mun-cantrips';
import munDocModule from '@maternity/ng-mun-doc';
import munFormErrorsModule from '@maternity/ng-mun-form-errors';
import munFormSafetyModule from '@maternity/ng-mun-form-safety';
import munFormServerErrorsModule from '@maternity/ng-mun-form-server-errors';
import munFormStatusModule from '@maternity/ng-mun-form-status';
import munGAModule from '@maternity/ng-mun-gestational-age';
import munLinearModule from '@maternity/ng-mun-linear';
import munModalModule from '@maternity/ng-mun-modal';
import munNavModule from '@maternity/ng-mun-nav';
import munPagerModule from '@maternity/ng-mun-pager';
import munSessionModule from '@maternity/ng-mun-session';
import munSpinnerModule from '@maternity/ng-mun-spinner';
import routingModule from '@maternity/vendor-dotjem-routing';
import sanitizeModule from 'angular-sanitize';
import tooltipModule from '@maternity/vendor-angular-strap/tooltip';

// Be careful when removing dependencies here since some are used in react
// components through the injector
export default angular.module('kerbin-core', [
  alertModule,
  animateModule,
  datepickerModule,
  dropdownModule,
  munCantripsModule,
  munDocModule,
  munFormErrorsModule,
  munFormSafetyModule,
  munFormServerErrorsModule,
  munFormStatusModule,
  munGAModule,
  munLinearModule,
  munModalModule,
  munNavModule,
  munPagerModule,
  munSessionModule,
  munSpinnerModule,
  routingModule,
  sanitizeModule,
  tooltipModule,
]);
