var { extend, mixin } = require('@maternity/mun-extend');


exports.Query = Query;
function Query(fn, data) {
  this.data = data || {
    filters: [],
    sort: [],
    slice: {},
  };
  this.fn = fn;
}

mixin(
  Query.prototype, {
    extend: function(data) {
      return new this.constructor(this.fn, extend(this.data, data));
    },

    filter: function(name, query) {
      var filters = this.data.filters.concat({name: name, query: query});
      return this.extend({filters: filters});
    },

    sort: function(specs) {
      if (specs == null)
        specs = [];

      else if (!Array.isArray(specs))
        specs = [specs];

      return this.extend({sort: specs});
    },

    slice: function(start, stop) {
      var slice = this.data.slice;
      if (slice.start === +slice.start) {
        if (start === +start)
          start = slice.start + start;
        if (stop === +stop)
          stop = slice.start + stop;
      }

      if (slice.stop === +slice.stop && stop > slice.stop)
        stop = slice.stop;

      return this.extend({slice: {start: start, stop: stop}});
    },

    resolve: function() {
      return this.fn(this.data);
    },
  });
