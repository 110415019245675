import { Path, PathValue } from '@maternity/mun-types';
import * as _ from 'lodash';

// TODO: move to mun-cantrips?

// Some helpers for working with objects and paths. These wrap lodash functions
// to take advantage of its path parsing that is more robust that just splitting
// on "." (which is needed for mun-schema-form's mnqs.PastPregsSummary).
// However, the `Path`/`PathValue` types don't actually support bracket syntax.

/**
 * Get the value at the path in the object.
 */
export const getIn = _.get as <V, P extends Path<V>>(
  obj: V,
  path: P,
) => PathValue<V, P>;

/**
 * Immutably set the value at the path in the object.
 */
export const setIn = <V extends object, P extends Path<V>>(
  obj: V,
  path: P,
  value: PathValue<V, P>,
): V =>
  _.setWith<V>(_.clone(obj), path, value, (v) => {
    // This function is called with the current value in `obj` for each segment
    // of the path. If that value is not nully, then clone it. Otherwise,
    // implicitly return `undefined` so `setWith` will create an object or
    // array. We need the conditional because `_.clone(null)` returns `null`,
    // which would prevent creation of objects/arrays when `obj` has a `null`
    // before the end of the path.
    if (v != null) {
      return _.clone(v);
    }
  });
