import _ from 'lodash';
import Emitter from 'component-emitter';
import { mixin } from '@maternity/mun-extend';
import search from '@maternity/mun-search';
import tv from '@maternity/travesty';

import ngModule from './mun-doc-module';

const SearchResults = tv.SchemaObj.extend('mun-doc.SearchResults', {});


mixin(
  SearchResults.prototype,
  Emitter.prototype, {
    $invalidate: function searchresults_invalidate(base) {
      // If base is provided, then base. invalidation will be forwarded.
      if (this.$invalid)
        return;

      if (base && !base.$invalid) {
        base.once('invalid', this.$invalidate.bind(this, null));

      } else {
        this.$invalid = true;
        this.emit('invalid');
      }
    },
  });


ngModule

  .factory('munDocSearch', function(munDocCache) {
    return {
      mkDocIndex: mkDocIndex,

      SortKeyDef: search.SortKeyDef,
      TemplateSortKeyDef: search.TemplateSortKeyDef,
      NumericSortKeyDef: search.NumericSortKeyDef,

      BaseIndexDef: search.BaseIndexDef,
      StringIndexDef: search.StringIndexDef,
      WordIndexDef: search.WordIndexDef,
    };

    function mkDocIndex(io) {
      var def = new DocIndexDef(wrapperIO);

      return def;

      function wrapperIO() {
        // Fixup the SearchResults type just in time
        var resp = io.apply(this, arguments);

        if (!def.SearchResults.typegraph.contains('items'))
          resp = resp
            .then(function(resp) {
              var respTG = tv.to_typegraph.call(resp.data),
                  itemsTG = respTG.get_child('items'),
                  metaTG = respTG.get_path(['meta'], null);
              if (!def.SearchResults.typegraph.contains('items'))
                def.SearchResults.typegraph.add_edge('items', itemsTG);
              if (metaTG && !def.SearchResults.typegraph.contains('meta'))
                def.SearchResults.typegraph.add_edge('meta', metaTG);
              return resp;
            });

        return resp;
      }
    }
  })

  ;


function DocIndexDef(io) {
  this.sortKeys = {};
  this.indexes = {};
  this.io = io;
  // TODO: Initialize typegraph up front.
  // Using the same name prevents custom invalidation monitors.  Do we need that?
  this.SearchResults = SearchResults.extend(
    'mun-doc.SearchResults',
    SubSearchResults, {
      field_types: {},
    });
  function SubSearchResults(items) {
    SearchResults.call(this);
    this.items = items;
  }
}

mixin(
  DocIndexDef.prototype, {
    addSortKey: function addSortKey(name, keydef) {
      this.sortKeys[name] = keydef;
      return this;
    },

    addIndex: function addIndex(name, indexdef) {
      this.indexes[name] = indexdef;
      return this;
    },

    create: function createIndex() {
      return new DocIndex(this);
    },
  });


function DocIndex(def) {
  this.def = def;

  this.reset();
}

mixin(
  DocIndex.prototype, {
    add: function addDoc(doc) {
      var name;

      for (name in this.def.sortKeys)
        this.def.sortKeys[name].add(this.sortKeys[name], doc.uid, doc);

      for (name in this.def.indexes)
        this.def.indexes[name].add(this.indexes[name], doc.uid, doc);

      this.uids[doc.uid] = null;
    },

    remove: function remove(removeUids) {
      var name,
          uids = this.uids;

      for (name in this.sortKeys)
        this.sortKeys[name].remove(removeUids);

      for (name in this.indexes)
        this.indexes[name].remove(removeUids);

      removeUids.forEach(function(uid) { delete uids[uid]; });
    },

    reset: function reset() {
      var name;

      this.sortKeys = {};
      for (name in this.def.sortKeys)
        this.sortKeys[name] = this.def.sortKeys[name].create();

      this.indexes = {};
      for (name in this.def.indexes)
        this.indexes[name] = this.def.indexes[name].create();

      this.uids = {};
    },

    get query() {
      var def = this.def,
          uids = this.uids,
          sortKeys = this.sortKeys,
          indexes = this.indexes,
          self = this;

      // TODO: customize query handle to do more than filter/sort/slice
      return new search.Query(query);

      function query(args) {
        var localFilters = args.filters.filter(function(d) { return indexes[d.name]; }),
            remoteFilters = args.filters.filter(function(d) { return !indexes[d.name]; }),
            localSorts = _.filter(args.sort, isLocalSortKey),
            remoteSorts = _.reject(args.sort, isLocalSortKey),
            queryArgs = [];

        remoteFilters.forEach(function(d) {
          if (Array.isArray(d.query))
            d.query.forEach(function(v) {
              queryArgs.push({key: d.name, value: v});
            });
          else
            queryArgs.push({key: d.name, value: d.query});
        });

        remoteSorts.forEach(function(d) {
          queryArgs.push({key: 'sort', value: d});
        });

        return def.io(queryArgs)

          .then(function loadResponse(resp) {
            var items = resp.data.items;

            // Make sure all items are in the index
            items.forEach(function(item) {
                if (item.uid in uids)
                  self.remove([item.uid]);
                self.add(item);
              });

            return {
              msg: resp.data,
              // TODO: avoid creating itemMap if items elements are documents which can be
              // retrieved from the cache
              itemMap: _.keyBy(items, 'uid'),
            };
          })

          .then(function processResults(data) {
            var itemUids = _.map(data.msg.items, 'uid'),
                filteredUids = localFilters
                  .reduce(function(uids, d) {
                    var matches = def.indexes[d.name].match(indexes[d.name], d.query);
                    return _.intersection(uids, matches);
                  }, itemUids),
                results;

            if (localSorts.length)
              filteredUids.sort(getCmp(localSorts));

            results = new def.SearchResults(
                filteredUids
                  .map(function(uid) { return data.itemMap[uid]; }));

            // Only set meta if no local filters
            if (localFilters.length === 0)
              results.meta = data.msg.meta;

            // This is bypassing the invalidation_monitor, which prevents custom invalidation.
            // That's ok for now, and it will probably change.
            results.$invalidate(data.msg);

            return results;
          });
      }

      function isLocalSortKey(spec) {
        if (spec[0] === '-')
          return Boolean(sortKeys[spec.slice(1)]);
        return Boolean(sortKeys[spec]);
      }

      function getCmp(specs) {
        var cmps = specs.map(function(spec) {
              var reversed = spec[0] === '-',
                  name = reversed
                    ? spec.slice(1)
                    : spec,
                  sortKey = sortKeys[name];

              return reversed
                ? sortKey.rcmp
                : sortKey.cmp;
            });

        if (specs.length === 0)
          return null;

        if (specs.length === 1)
          return cmps[0];

        return mcmp;

        function mcmp(a, b) {
          var status;

          for (var i=0; i < cmps.length; i++) {
            if ((status = cmps[i](a, b)) !== 0)
              return status;
          }

          return 0;
        }
      }
    },
  });
