// These numbers were derived from the bootstrap defaults, then the base was
// shifted by 5 so angular-strap backdrops would have unique z-indexes.
const BASE_Z_INDEX = 1025;
const DIALOG_Z_OFFSET = 10;
const LAYER_Z_OFFSET = 20;
const stack = [BASE_Z_INDEX];

/**
 * Function that returns the z-index values for a modal dialog and backdrop.
 * Maintains a global stack of z-indexes so the latest rendered modal will
 * appear on top.
 */
export const getZIndex = () => {
  const zIndex = stack[0] + LAYER_Z_OFFSET;
  stack.unshift(zIndex);

  return {
    backdrop: zIndex,
    dialog: zIndex + DIALOG_Z_OFFSET,
    cleanup: () => {
      const idx = stack.indexOf(zIndex);
      if (idx !== -1) stack.splice(idx, 1);
    },
  };
};
