var { extend } = require('@maternity/mun-extend'),
    iter = require('@maternity/mun-itertools'),

    invalid = require('./invalid'),
    Invalid = invalid.Invalid,
    InvalidAggregator = invalid.InvalidAggregator,
    base = require('./base'),
    undictify = base.undictify,
    traverse = base.traverse,
    schema = require('./schema'),
    Schema = schema.Schema,

    ObjectMarker = exports.ObjectMarker = Schema.sub('tv.ObjectMarker', function ObjectMarker(target_proto, factory) {
      if (!(this instanceof ObjectMarker)) {
        var self = Object.create(ObjectMarker.prototype);
        ObjectMarker.apply(self, arguments);
        return self;
      }

      // .target_proto may have been set by SchemaObj.extend()

      // If we have a fixed target_proto (on this prototype), assume a single argument is factory.
      if (this.target_proto != null && factory == null) {
        factory = target_proto;
        target_proto = undefined;
      }

      if (typeof target_proto === 'function')
        target_proto = target_proto.prototype;

      if (target_proto != null)
        this.target_proto = target_proto;
      if (factory != null)
        this.factory = factory;
    });

// no dictify_object, dictify_schema suffices in js

undictify.register(undictify_object, ObjectMarker);
function undictify_object(disp, value, options) {
  var agg = new InvalidAggregator(),
      marker = disp.marker,
      super_disp = disp.super(ObjectMarker),
      result = agg.checking(super_disp.call.bind(super_disp, value, options)),
      known_keys = iter.reduce(disp.key_iter(), function(d, k) {
          d[k] = true;
          return d;
        }, {});

  Object.keys(value).forEach(function(k) {
    if (!known_keys[k])
      agg.own_error(new Invalid('unexpected_fields'));
  });

  agg.raise_if_any();

  if (marker.factory != null)
    return marker.factory(result);
  return extend(marker.target_proto, result);
}

traverse.register(traverse_object, ObjectMarker);
function traverse_object(disp, value, options) {
  var zipgraph = options?.zipgraph,
      graph = disp.super(ObjectMarker).call(value, options);

  // Keep object instance as node value
  graph.value = zipgraph != null
    ? [value, zipgraph.value]
    : value;

  return graph;
}
