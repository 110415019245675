import cookiesModule from 'angular-cookies';
import env from '@maternity/mun-env';

const ngModule = angular.module('mun-session', [
    cookiesModule,
  ])

  .provider('munSession', function() {
    // TODO: remove knowledge of http and rubedo from $login and $refresh.

    var initial,
        Options;

    this.$get = get;
    this.configure = configure;

    function configure(initial_, Options_) {
      /* munSessionProvider.configure(function($http) {
       *   return {
       *     login: function(username, password) { ... },
       *     refresh: function() { ... },
       *     logout: function() { ... },
       *     import: function(data) { ... },
       *     timeoutWarning: function(cookie, setting) { ... },
       *     forceReload: function() { ... },
       *   };
       * });
       */
      initial = initial_;
      Options = Options_;
    }

    function get($injector, $rootScope, $route, $q, $cookies, cookieWatcher) {
      var options = $injector.instantiate(Options),
          self = Object.create({
            $refresh: refresh,
            $login: login,
            $logout: logout,
            get $whenReady() { return whenReady; },
            get $ready() { return ready; },
            get $whenAuthenticated() { return whenAuthenticated; },
            get $authenticated() { return authenticated; },
          }),
          // Initialize as ready, for initial != null case.
          ready = true,
          whenReady = $q.when(),
          authenticated,
          whenAuthenticatedDeferred = $q.defer(),
          whenAuthenticated = whenAuthenticatedDeferred.promise,
          SESSION_COOKIE = env.$expand('${SESSION_COOKIE_NAME}'),
          TIMEOUT_COOKIE = env.$expand('${SESSION_TIMEOUT_COOKIE}'),
          TIMEOUT_WARNING_COOKIE = env.$expand('${SESSION_TIMEOUT_WARNING_COOKIE}'),
          COOKIE_WATCH_INTERVAL = 2000;

      if (initial != null)
        $q.when(options.import(initial))
          .then(function(data) { return init(data.item); });

      else
        // don't have initial data, so get from server
        refresh();

      return self;

      function init(data) {
        var reload = false;
        Object.keys(self).forEach(function(k) { delete self[k]; });

        if (data == null) {
          if (authenticated) {
            authenticated = false;
            reload = true;
            whenAuthenticatedDeferred.resolve((whenAuthenticatedDeferred = $q.defer()).promise);
            whenAuthenticated = whenAuthenticatedDeferred.promise;
          }
          if ($rootScope.$broadcast('munSession.reset').defaultPrevented)
            reload = false;

        } else {
          // Clear old data and then do a shallow copy.  Don't get prototype methods.
          Object.keys(data).forEach(function(k) { self[k] = data[k]; });
          if (!authenticated) {
            authenticated = true;
            whenAuthenticatedDeferred.resolve();
          }
          startSessionWatcher();
          startTimeoutWatcher();
          if (options.timeoutWarning)
            startTimeoutWarningWatcher();
        }

        if ($rootScope.$broadcast('munSession.refresh', self).defaultPrevented)
          reload = false;

        if (reload)
          $route.reload();
      }

      function refresh() {
        ready = false;
        return whenReady = qTry(options.refresh)
          .then(function(response) {
            var data = response.data;
            ready = true;
            init(data.item);
          })
          .catch(function(response) {
            ready = true;
            if (response.status === 401)
              init();
            return $q.reject(response);
          });
      }

      function login(username, password) {
        return qTry(options.login, username, password)
          .then(function(response) {
            var data = response.data;
            init(data.item);
          });
      }

      function logout() {
        return qTry(options.logout)
          .then(
            function(response) {
              init();
            },
            function(response) {
              // if 401, server session already expired, otherwise clear cookie
              if (response.status !== 401) {
                $cookies.remove(SESSION_COOKIE);
              }
              init();
              return response.status === 401
                ? response
                : $q.reject(response);
            }
          );
      }

      function qTry(fn) {
        var args = [].slice.call(arguments, 1);
        return $q.when().then(function() { return fn.apply(this, args); });
      }

      function startSessionWatcher() {
        cookieWatcher(SESSION_COOKIE, COOKIE_WATCH_INTERVAL, function() {
          // The refresh machinery doesn't reboot if the account changes (i.e
          // user logged into a different account in another tab), so just
          // reload the page.
          options.forceReload();
        });
      }

      /* How session timeout works:
       * The server sets a cookie that expires at the same time as the session.
       * When this cookie expires, the browser evicts it, and the cookie
       * watcher observes a change in value, which triggers logout. The session
       * cookie does not have an expiration set, so it will expire at the end
       * of the browser session. Thus, the session will end when the timeout
       * occurs or the user closes their browser (assuming they don't use some
       * "restore from previous session" functionality).
       *
       *
       * Other implementations that were considered:
       *  - Set a "reset timeout" header in web service responses
       *  - Put the expiration datetime in the cookie value, possibly parsing
       *    the date header to fix clock skew
       */
      function startTimeoutWatcher() {
        cookieWatcher(TIMEOUT_COOKIE, COOKIE_WATCH_INTERVAL, function() {
          // Clear the session cookie and reload (effectively, force logout).
          // Don't hit the logout endpoint to prevent hanging if it times out.
          // Explictly clear session cookie to prevent unitentionally extending
          // the sesion. This also preserves the url.
          $cookies.remove(SESSION_COOKIE);
          options.forceReload();
        });
      }

      function startTimeoutWarningWatcher() {
        cookieWatcher(TIMEOUT_WARNING_COOKIE, COOKIE_WATCH_INTERVAL,
          function(cookie, setting) {
            // Restart watcher
            startTimeoutWarningWatcher();
            options.timeoutWarning(cookie, setting);
          }, true);
      }
    }
  })

  .factory('cookieWatcher', function($cookies, $rootElement, $interval) {
    var watching = {};

    return watcher;

    function watcher(cookieName, interval, callback, callOnSet) {
      // NOTE: The callback is not invoked inside a digest cycle, so it may
      // need to use $scope.$apply to notify angular.
      var cookie, itimer;

      if (watching[cookieName]) return;

      watching[cookieName] = true;
      $rootElement.on('$destroy', stop);

      schedule();

      function checkCookie() {
          var cookieNow = $cookies.get(cookieName);
          if (cookieNow === cookie) {
            schedule();
          } else if (cookie === undefined) {
            schedule();
            cookie = cookieNow;
            if (callOnSet)
              callback(cookieNow, true);
          } else {
            // stop is called first so the callback can restart the watcher
            stop();
            callback(cookieNow);
          }
      }

      function schedule() {
        // An $interval that only runs once is used instead of $timeout because
        // chained timeouts prevent protractor from detecting that the page has
        // finished loading.
        itimer = $interval(checkCookie, interval, 1 /* count */, false);
      }

      function stop() {
        if (!watching[cookieName])
          return;
        delete watching[cookieName];
        $interval.cancel(itimer);
        $rootElement.off('$destroy', stop);
      }
    }
  })

  ;

export default ngModule.name;
