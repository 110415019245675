import { useForceUpdate } from '@maternity/mun-cantrips';
import { DeepPartialReadonly, Path } from '@maternity/mun-types';
import * as React from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import {
  FieldInfo,
  FieldValues,
  FormApi,
  FormState,
  FormStateListener,
} from './types';
import { getIn, setIn } from './utils';
import {
  convertInvalid,
  isErrorResponse,
  normalizeError,
  ValidationError,
  ValidationErrorCtx,
} from './validation';

/** Config options for the `useForm` hook. */
interface FormConfig<V extends FieldValues> {
  /**
   * Initial values of the fields. Changing this prop after the component has
   * mounted has no effect. Use `reset()` to update or set the `key` prop on
   * the component to force a new component instance.
   */
  defaultValues?: DeepPartialReadonly<V>;
  /**
   * Optional function to validate the form's values that should return a flat
   * mapping of model paths to a list of validation errors. The result will be
   * merged with any errors from field-level validation. Changing this function
   * does not cause validation to run.
   */
  validate?: (
    values: DeepPartialReadonly<V>,
  ) => Partial<Record<Path<V>, ValidationError[]>> | undefined;
  /**
   * Process server errors. Disable if endpoint is not Travesty-based.
   */
  handleServerErrors?: boolean;

  // TODO: Add option for controlling when validation runs (e.g. blur/change)?
  // TODO: Add options for initial state (e.g. touched, errors, etc.)?
  // TODO: Add option to disable focusing errors?
}

const initFormState = <V extends FieldValues>(
  defaultValues: DeepPartialReadonly<V>,
): FormState<V> => ({
  values: defaultValues,
  defaultValues,
  touched: {},
  errors: {},
  serverErrors: {},
  isDirty: false,
  isValid: true,
  isSubmitting: false,
  submitCount: 0,
});

/** Returns a function to queue a callback to run as an effect */
const useQueueEffect = () => {
  const forceUpdate = useForceUpdate();
  // Use a ref because we don't want to rerender when resetting the value
  const callbackRef = React.useRef<() => void>();

  // Every render check if there is a callback to run
  React.useEffect(() => {
    if (callbackRef.current) {
      const cb = callbackRef.current;
      callbackRef.current = undefined;
      cb();
    }
  });

  return React.useCallback(
    (cb: () => void) => {
      callbackRef.current = cb;
      // Trigger a rerender to run the effect
      forceUpdate();
    },
    [forceUpdate],
  );
};

const useFocusError = (formRef: React.RefObject<HTMLFormElement>) => {
  const queueEffect = useQueueEffect();

  // We query the DOM to find errors, since field registration order may differ
  // from DOM order. We need to wait for the DOM to actually get updated, so
  // focusing happens in an effect that runs every render.
  return React.useCallback(
    () =>
      queueEffect(() => {
        // Try to find the first error message block and get its parent. We
        // mainly want the user to see the error, so we don't bother trying to
        // focus the corresponding input/control.
        const el =
          formRef.current?.querySelector('.form-errors')?.parentElement;
        if (!el) return;
        // Try to focus the element
        el.focus();
        if (el === document.activeElement) return;
        // Focusing failed, so add the tabindex attribute and try again
        el.tabIndex = -1;
        el.focus();
        // Clean up the attribute to avoid matching `:focus` CSS rules
        el.removeAttribute('tabIndex');
      }),
    [queueEffect, formRef],
  );
};

/**
 * This hook manages the state of a form. It returns a form API handle that
 * should be passed to other form-related hooks and components (e.g. <Form>).
 *
 * The type parameter `V` indicates the type of field values in the form,
 * generally the schema type of the corresponding endpoint.
 */
export const useForm = <V extends FieldValues>({
  defaultValues = {} as DeepPartialReadonly<V>,
  validate,
  handleServerErrors = true,
}: FormConfig<V> = {}): FormApi<V> => {
  // Store form state in a ref so we have control over rerenders
  const formStateRef = React.useRef<FormState<V>>(null as any);
  // Initialize state on first render
  if (!formStateRef.current) {
    formStateRef.current = initFormState(defaultValues);
  }
  // Store the validate function in a ref so that we don't need to create a new
  // form API handle object if the function changes
  const validateRef = React.useRef(validate);
  React.useEffect(() => {
    validateRef.current = validate;
  });
  // We use an array for fields to allow multiple fields for the same model.
  // The fields and listeners arrays are marked readonly so we don't need to
  // worry about them mutating while iterating.
  const fieldsRef = React.useRef<readonly FieldInfo[]>([]);
  const listenersRef = React.useRef<ReadonlyArray<FormStateListener<V>>>([]);
  const formRef = React.useRef<HTMLFormElement>(null);
  const focusError = useFocusError(formRef);
  const queueEffect = useQueueEffect();

  const api = React.useMemo(() => {
    let notifyState: 'idle' | 'running' | 'queued' = 'idle';
    // Notify listeners of the new form state. The listeners are run
    // synchronously with a consistent state. If the listeners cause another
    // state update, the `notify` call will be queued to avoid interleaving
    // listener calls with different states.
    const notify = () => {
      // When called while running, queue another call for later
      if (notifyState !== 'idle') {
        notifyState = 'queued';
        return;
      }

      notifyState = 'running';
      // Allow react to batch state updates/rerenders triggered by listeners
      // into a single render pass. Even though the function is marked
      // "unstable", it is used by major libraries like Redux. This should be
      // obsolete in React 18.
      unstable_batchedUpdates(() => {
        // Dereference state here so all listeners see the same value
        const state = formStateRef.current;
        for (const listener of listenersRef.current) {
          listener(state);
        }
      });
      // Typescript narrows the type from the assignment above, but the value
      // may have changed, so we need a cast here.
      const isQueued = (notifyState as any) === 'queued';
      notifyState = 'idle';
      if (isQueued) {
        // Execute the queued call. If we get an error from blowing the stack
        // here, it will probably be due to buggy listeners that never settle
        // to a stable state.
        notify();
      }
    };

    const batch: FormApi<V>['batch'] = (callback) => {
      // When `notify` is running, batching is already handled by the queuing
      // mechanism, so just execute the callback.
      if (notifyState !== 'idle') {
        callback();
        return;
      }
      // Pretend that `notify` is running, so that any calls to it just queue
      // a call for later instead of starting to execute listeners.
      notifyState = 'running';
      callback();
      // Typescript narrows the type from the assignment above, but the value
      // may have changed, so we need a cast here.
      const isQueued = (notifyState as any) === 'queued';
      notifyState = 'idle';
      if (isQueued) {
        notify();
      }
    };

    // Run validators and collect any errors. Callers should call `notify`.
    const runValidation = () => {
      const errors = {} as Record<string, ValidationErrorCtx[]>;
      // Run the top-level validation function
      const topLevelErrors: Record<string, ValidationError[] | undefined> =
        validateRef.current?.(formStateRef.current.values) ?? {};
      for (const [model, fieldErrors] of Object.entries(topLevelErrors)) {
        if (!fieldErrors?.length) continue;
        errors[model] = fieldErrors.map((error) => normalizeError(error));
      }
      // Run the validators for all fields
      for (const { model, validators } of fieldsRef.current) {
        // Check if the key already exists in order to support multiple fields
        // for the same model and merge with top-level validation errors
        const fieldErrors = errors[model] ?? [];
        const value = getIn(formStateRef.current.values, model as any);
        for (const validator of validators) {
          const error = validator(value);
          if (error != null) fieldErrors.push(normalizeError(error));
        }
        if (fieldErrors.length) {
          errors[model] = fieldErrors;
        }
      }

      formStateRef.current = {
        ...formStateRef.current,
        errors,
        isValid: Object.keys(errors).length === 0,
      };
    };

    const registerField: FormApi<V>['registerField'] = (config) => {
      // TODO: throw if config already registered?
      fieldsRef.current = [...fieldsRef.current, config];
      // Queue validation in case validators have changed
      // TODO: Allow skipping validation on initial render?
      queueEffect(() => {
        runValidation();
        notify();
      });
      return () => {
        fieldsRef.current = fieldsRef.current.filter((c) => c !== config);
      };
    };

    const setFieldValue: FormApi<V>['setFieldValue'] = (
      model,
      value,
      { shouldDirty = true } = {},
    ) => {
      const prevState = formStateRef.current;
      // Typescript can't tell that `model` and `value` are compatible with
      // `values` due to the deep partial/readonly wrapper, so the
      // `getIn`/`setIn` calls use `any`.
      const prevValue = getIn<any, any>(prevState.values, model);
      if (typeof value === 'function') {
        value = (value as any)(prevValue);
      }
      formStateRef.current = {
        ...prevState,
        values: setIn<any, any>(prevState.values, model, value),
        // Once any value has changed, consider the form dirty until reset.
        // TODO: Allow undoing changes to make the form clean again? (i.e. deep
        // compare defaultValues and values)
        isDirty: shouldDirty
          ? prevState.isDirty || prevValue !== value
          : prevState.isDirty,
      };
      // TODO: Only run validation for model and parent paths?
      runValidation();
      notify();
    };

    const setFieldTouched: FormApi<V>['setFieldTouched'] = (
      model,
      touched = true,
    ) => {
      const prevState = formStateRef.current;
      if (prevState.touched[model] === touched) return;
      formStateRef.current = {
        ...prevState,
        touched: {
          ...prevState.touched,
          [model]: touched,
        },
      };
      // We validate on change (`setFieldValue`), so it would be redundant to
      // validate on blur.
      notify();
    };

    const handleSubmit: FormApi<V>['handleSubmit'] = (handler) => {
      formStateRef.current = {
        ...formStateRef.current,
        isSubmitting: true,
        submitCount: formStateRef.current.submitCount + 1,
      };
      runValidation();
      if (!formStateRef.current.isValid) {
        formStateRef.current = {
          ...formStateRef.current,
          isSubmitting: false,
        };
        notify();
        focusError();
        return;
      } else {
        notify();
      }
      const { values } = formStateRef.current;

      // Call the handler
      const handlerPromise = handler(values as V);

      // Since validation passed, assume all required fields have values, so it
      // should be safe to cast as `V`.

      const whenSubmitDone = handlerPromise
        .then((result) => {
          // If this is the latest submit attempt, reset the form so it is not
          // dirty and to clear any stale server errors
          if (formStateRef.current.whenSubmitDone === whenSubmitDone) {
            resetForm(values);
          }
          return result;
        })
        .catch((err: unknown) => {
          // If this is the latest submit attempt and the result was an error
          // response, process, store, and focus the server validation errors
          if (
            handleServerErrors &&
            formStateRef.current.whenSubmitDone === whenSubmitDone &&
            isErrorResponse(err)
          ) {
            formStateRef.current = {
              ...formStateRef.current,
              serverErrors: convertInvalid(err.data.invalid || {}),
            };
            focusError();
          }
          return Promise.reject(err);
        })
        .finally(() => {
          // If this is the latest submit attempt, clear the submitting state
          // and notify listeners
          if (formStateRef.current.whenSubmitDone === whenSubmitDone) {
            formStateRef.current = {
              ...formStateRef.current,
              isSubmitting: false,
              whenSubmitDone: undefined,
            };
            notify();
          }
        });
      formStateRef.current = {
        ...formStateRef.current,
        whenSubmitDone,
      };
      notify();
    };

    const resetForm: FormApi<V>['resetForm'] = (
      newDefaultValues = formStateRef.current.defaultValues,
    ) => {
      formStateRef.current = initFormState(newDefaultValues);
      notify();
    };

    const getFormState: FormApi<V>['getFormState'] = () => formStateRef.current;

    const subscribe: FormApi<V>['subscribe'] = (listener) => {
      listenersRef.current = [...listenersRef.current, listener];
      return () => {
        listenersRef.current = listenersRef.current.filter(
          (l) => l !== listener,
        );
      };
    };

    return {
      registerField,
      setFieldValue,
      setFieldTouched,
      getFormState,
      subscribe,
      handleSubmit,
      resetForm,
      formRef,
      batch,
    };
  }, [focusError, queueEffect, handleServerErrors]);

  // Expose the API as the hook's debug value so it can be accessed in the
  // console through the react devtools.
  React.useDebugValue(api);
  return api;
};
