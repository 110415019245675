import classNames from 'classnames';
import * as React from 'react';

import { useModalContext } from './Modal';

interface Props {
  className?: string;
  /** If the "close" button should be displayed (defaults to true) */
  showCloseButton?: boolean;
  children?: React.ReactNode;
}

/**
 * Renders a modal header. Shows a close button by default.
 */
export const ModalHeader = ({
  children,
  className,
  showCloseButton = true,
}: Props) => {
  const modalContext = useModalContext();

  return (
    <div className={classNames('modal-header', className)}>
      {showCloseButton && (
        <button
          type="button"
          className="close"
          onClick={modalContext.hide}
          aria-label="Close"
        >
          <span aria-hidden={true}>&times;</span>
        </button>
      )}
      {children}
    </div>
  );
};
