import {
  useDebounceCallback,
  useThrottleCallback,
} from '@maternity/mun-cantrips';
import type { IState } from '@maternity/mun-router/types';
import type { QueryArgs } from '@maternity/mun-router/useMunDocLoader';
import type {
  DeepPartialReadonly,
  KeysOfType,
  Path,
} from '@maternity/mun-types';
import { injector } from '@maternity/ng-mun-linear';
import type { ILocationService, IScope } from 'angular';
import _ from 'lodash';
import React from 'react';

import type { FormApi, FormState } from './types';
import { useForm } from './useForm';
import type { ValidationError } from './validation';

// TODO: Allow (and coerce) more value types (e.g. number, boolean)?
type SearchParams = Record<string, string | string[]>;

/**
 * A wrapper around `useForm` for search forms, this hook synchronizes search
 * parameters between the URL query string and the form state.
 *
 * The history entry is replaced to avoid creating an excessive number of
 * entries. Updates to the URL are throttled to avoid potentially triggering
 * an error in Safari from >100 changes in 30s.
 *
 * If fetching data for the search params with suspense, it should be done in
 * a child component to avoid clobbering this hook's state.
 */
export const useSearchForm = <V extends SearchParams>({
  defaultValues,
  validate,
  urlThrottleMs = 500,
}: {
  /**
   * Defines the full set of search parameter keys and their initial values.
   * Any keys that may be repeated in the query string should have an array for
   * the initial value. Changing this prop after the component has mounted has
   * no effect. Use `reset()` to update (though this won't merge parameters
   * from the URL) or set the `key` prop on the component to force a new
   * component instance.
   */
  defaultValues: V;
  /**
   * Optional (memoized) function to validate the form's values that should
   * return a flat mapping of model paths to a list of validation errors. The
   * result will be merged with any errors from field-level validation.
   * Changing this function does not cause validation to run.
   */
  validate?: (
    values: DeepPartialReadonly<V>,
  ) => Partial<Record<Path<V>, ValidationError[]>> | undefined;
  /** Milliseconds to throttle URL updates, defaults to 500 */
  urlThrottleMs?: number;
}): FormApi<V> => {
  // TODO: Replace angular stuff with browser APIs, probably parsing/building
  // query strings with `URLSearchParams` (may need to polyfill)
  const $location = injector.get('$location') as ILocationService;
  const $rootScope = injector.get('$rootScope') as IScope;
  const $state = injector.get('$state') as IState;
  const form = useForm({
    // TODO: Typescript isn't allowing `V` to be assigned to
    // `DeepPartialReadonly<V>`, which should be valid...
    defaultValues: defaultValues as DeepPartialReadonly<V>,
    validate,
  });
  // Store the last params in a ref so the URL is only updated when necessary
  const lastParamsRef = React.useRef<DeepPartialReadonly<V>>();
  // On first render, update form state for current URL search params. This is
  // done by updating the fields instead of changing the `defaultValues` passed
  // to `useForm` so that resetting the form returns to the defaults instead of
  // the initial URL params.
  if (!lastParamsRef.current) {
    form.batch(() => {
      for (const [key, initialValue] of Object.entries(defaultValues)) {
        const value = $state.params.$search[key];
        if (value == null) continue;
        form.setFieldValue(
          key as Path<V>,
          Array.isArray(initialValue) && !Array.isArray(value)
            ? [value]
            : value,
        );
      }
    });
    // Any view values won't be initialized yet, so can skip destructuring
    lastParamsRef.current = form.getFormState().values;
  }
  const urlReplace = React.useCallback(
    // Ignore any view values
    ({ values: { $$viewValue, ...newParams } }: FormState<V>) => {
      if (!_.isEqual(newParams, lastParamsRef.current)) {
        // Need casts due to destructuring removing `$$viewValue`
        lastParamsRef.current = newParams as unknown as DeepPartialReadonly<V>;
        $location.search(newParams).replace();
        // Schedule a digest for angular to apply the location change
        $rootScope.$applyAsync();
      }
    },
    [$rootScope, $location],
  );
  const throttledUrlReplace = useThrottleCallback(urlReplace, urlThrottleMs);
  React.useEffect(
    () => form.subscribe(throttledUrlReplace),
    [form, throttledUrlReplace],
  );
  // TODO: Ideally, any pending URL updates would be flushed before navigating
  // to a new path. However, this isn't straightfoward with the events
  // available from angular and the browser...

  return form;
};

const defaultPrepareArgs = ({
  $$viewValue,
  ...params
}: SearchParams): QueryArgs => {
  const args = [];
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      for (const v of value) {
        args.push({ key, value: v });
      }
    } else {
      args.push({ key, value });
    }
  }
  return args;
};
const unsetQueryKey = Symbol();

/**
 * Converts the search params in a form to query args for use in data fetching,
 * throttling the return value to avoid excessive requests. Additionally, this
 * can optionally debounce the value of a search query text input. If fetching
 * data with suspense, it should be done in a child component to avoid
 * clobbering this hook's state.
 */
// TODO: Expose a way to skip the debounce timeout (i.e. `.flush()`) that can
// called in response to events like blur or enter/return keypress?
export const useSearchQueryArgs = <V extends SearchParams>({
  form,
  prepareArgs = defaultPrepareArgs,
  throttleMs = 500,
  // The default is a symbol guaranteed to not exist in the form values
  queryKey = unsetQueryKey as any,
  queryDebounceMs = 250,
}: {
  /** (Search) Form API handle */
  form: FormApi<V>;
  /**
   * A function to prepare query args from the form state. The default
   * function will produce args for all fields in the form (except view values)
   * and any array values will be converted to a series of key/value pairs.
   */
  prepareArgs?: (values: V) => QueryArgs;
  /** Milliseconds to throttle return value updates, defaults to 500 */
  throttleMs?: number;
  /**
   * Optional field name correpsonding to a search query text input that should
   * be debounced.
   */
  queryKey?: KeysOfType<V, string>;
  /** Milliseconds to debounce the search query text value, defaults to 250. */
  queryDebounceMs?: number;
}): QueryArgs => {
  // Store previous form values in a ref for diffing
  const prevValuesRef = React.useRef<DeepPartialReadonly<V>>();
  if (!prevValuesRef.current) {
    prevValuesRef.current = form.getFormState().values;
  }
  const [args, setArgs] = React.useState<QueryArgs>(() =>
    prepareArgs(form.getFormState().values as V),
  );
  const updateArgs = React.useCallback(
    // Get the form state instead of passing it to ensure it isn't stale
    () => setArgs(prepareArgs(form.getFormState().values as V)),
    [prepareArgs, form],
  );
  const throttledUpdate = useThrottleCallback(updateArgs, throttleMs);
  const debouncedThrottledUpdate = useDebounceCallback(
    throttledUpdate,
    queryDebounceMs,
  );
  React.useEffect(() => {
    const checkForUpdates = ({ values }: FormState<V>) => {
      // Split up the current and previous values into the query (if a key was
      // given) and the rest of the params, discarding any view values.
      const { $$viewValue: _vv, [queryKey]: query, ...rest } = values;
      const {
        $$viewValue: _pvv,
        [queryKey]: prevQuery,
        ...prevRest
      } = prevValuesRef.current!;

      if (!_.isEqual(rest, prevRest)) {
        throttledUpdate();
      } else if (query !== prevQuery) {
        debouncedThrottledUpdate();
      }

      prevValuesRef.current = values;
    };

    // Check for any updates between render and effect handler
    checkForUpdates(form.getFormState());

    return form.subscribe(checkForUpdates);
  }, [form, queryKey, throttledUpdate, debouncedThrottledUpdate]);

  return args;
};
