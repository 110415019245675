/*
doccacheblaster - tools for managing a DocCache.

# EventSet

The EventSet type is useful for setting up listeners that will later be removed.
You can attach event listeners to Emitters through an EventSet, and then later
call .cancel() on the EventSet to remove all the listeners created through it.

 You create one just with a name (for debugging, mainly):
> events = new EventSet(name)

And then you can register handlers on objects with
> events.obj(<some Emitter>).on(key, function)

events.obj(foo).on(key, fn) is just like foo.on(key, fn) except that the events
object will remember the registration.

You can register a function to be called when the EventSet is cancelled via
> events.onCancel(fn)

and cancel it yourself via
> events.cancel()

The helper method
> events.obj(<some Emitter>).cancelOn(key)
is just shorthand for
> events.obj(<some Emitter>).on(key, function() {events.cancel()})


# invalidation_monitor

The invalidation_monitor travesty dispatcher is called on responses sent by the
server, and sets up any invalidation hooks needed for that response. Typical
usage is to register a callback for some endpoint's type that sets up an
EventSet that will invalidate the response when certain things change:

// Register the callback for the return type of this endpoint
invalidation_monitor.register(handle_some_endpoint,
  munDocEndpoints.some_endpoint.GET.message_out);
function handle_some_endpoint(disp, msg, options) {
  // Get the doccache and an EventSet
  var doccache = options.doccache,
      events = new EventSet('some_endpoint');

  // Propagate over the rest of this object
  disp.super(invalidation_monitor).call(msg, options);

  // Register listeners to invalidate the response whenever SomeType instances
  // are changed
  function invalidate_msg() { msg.$invalidate(); }
  events.obj(doccache)
    .on(['created', SomeType], invalidate_msg)
    .on(['deleted', SomeType], invalidate_msg)
    .on(['updated', SomeType], invalidate_msg);

  // Clean up event listeners when the response is invalidated
  events.obj(msg)
    .cancelOn('invalid');

  // See below
  if (options.stats_start)
    events.onCancel(options.stats_start(msg.constructor, 'some_endpoint'));

}

By convention, the invalidation monitor functions can also take an option called
stats_start, which is used to indicate than object has started being tracked by
the caching system; it returns a function that should be called when the object
is no longer tracked. This leads to the last two lines of boilerplate above - if
stats_start is provided, then call it and save the result to be invoked when the
watcher for this response is canceled.

Note that the invalidation monitor will detect documents within a SchemaMessage
already, so if you simply have a message that contains some documents, the
message will be invalidated if any of those documents are.

There are two main cases where you will need to include custom invalidation
monitoring.

Firstly, if your endpoint returns data that's derived from some set of
documents, but those documents aren't actually part of the response, then you'll
need to set up the hooks that invalidate your endpoint when any of those
documents change.

Secondly, if you have an endpoint that lists documents, the returned message
will automatically be invalidated if any of the listed documents are, but you'll
need to set up custom invalidation to respond to the creation of new documents.

Note that the invalidation monitor assumes that the objects it's being called on
are valid when it's called - they should already have been checked by e.g.
doccache_verify.

Note also that the hooks set up by the invalidation monitor will generally only
run once, and then deregister themselves - once the object is invalid, it no
longer needs to be monitored, and if it is ever freshened something will call
invalidation_monitor anew.


# message_invalidation_monitor, base_invalidation_monitor

These two are exported but in general shouldn't be messed with unless you really
know what you're doing.
*/

import { extend, mixin } from '@maternity/mun-extend';
import * as iter from '@maternity/mun-itertools';
import tv from '@maternity/travesty';

export const base_invalidation_monitor = tv.make_dispatcher();

// The indirect invalidation monitor is responsible for monitoring events on
// other documents that might invalidate this document/message/snowglobe.
export const invalidation_monitor =
  new tv.dispatcher.Dispatcher(tv.base.typeKeysOf);

// The message invalidation monitor is called by the indirect invalidation
// monitor to monitor in aggregate the component documents of a message.
export const message_invalidation_monitor = base_invalidation_monitor.sub();


export function EventSet(name) {
  this._cancelFns = [];
  this.name = name;

  // Bound method is too convenient
  this.cancel = this.cancel.bind(this);
  // For debugging
  this.cancel.eventset = this.name;
}
mixin(
  EventSet.prototype, {
    obj: function eventset_obj(obj) {
      var self = this,
          handle = {
            on: eventset_obj_on,
            cancelOn: eventset_obj_cancelOn,
          };

      return handle;

      function eventset_obj_on(key, fn) {
        if (this._canceled)
          throw new Error('canceled already');
        obj.on(key, fn);
        self.onCancel(cancel);
        return this;

        function cancel() {
          obj.off(key, fn);
        }
      }

      function eventset_obj_cancelOn(key) {
        return this.on(key, self.cancel);
      }
    },

    onCancel: function eventset_onCancel(fn) {
      this._cancelFns.push(fn);
    },

    cancel: function eventset_cancel() {
      if (this._canceled)
        return;
      this._canceled = true;
      this._cancelFns.forEach(function(fn) { fn(); });
    },
  });

invalidation_monitor.register(im_at_document, 'tv.Document');
function im_at_document(disp, doc, options) {
  var type = doc.constructor,
      baseType = getBase(type),
      doccache = options.doccache,
      events = new EventSet('document_monitor');

  if (baseType) {
    events.obj(doccache)
      .on(['updated',baseType,doc.uid], invalidate_document_monitor)
      .on(['deleted',baseType,doc.uid], invalidate_document_monitor)
      // Monitoring starts whenever a document is loaded (or reloaded).
      .cancelOn(['unload',type,doc.uid])
      .cancelOn(['load',type,doc.uid]);

    if (options.stats_start)
      events.onCancel(options.stats_start(type, 'document'));
  }


  function invalidate_document_monitor() {
    doccache._unload(type, doc.uid);
  }

  function getBase(type) {
    // Assume the type after tv.Document is the base, we'll need better hints if this is untrue.
    var proto = type.prototype,
        base = type;

    while (proto.constructor !== tv.Document) {
      base = proto.constructor;
      proto = Object.getPrototypeOf(proto);
    }

    return base !== type
      ? base
      : null;
  }
}

invalidation_monitor.register(im_at_message, 'tv_chasm.SchemaMessage');
invalidation_monitor.register(im_at_message, 'mun-doc.SearchResults');
function im_at_message(disp, msg, options) {
  var cancelMsg = message_invalidation_monitor.call(msg, msg, extend(options, {
          notify: notify_message_invalid})),
      events = new EventSet('message_monitor');

  events.obj(msg)
    .cancelOn('invalid');

  if (cancelMsg)
    events.onCancel(cancelMsg);

  if (options.stats_start)
    events.onCancel(options.stats_start(msg.constructor, 'message'));

  function notify_message_invalid() {
    msg.$invalidate();
  }
}


base_invalidation_monitor.register(bim_at_leaf, tv.Leaf);
function bim_at_leaf(disp, value, options) { }

base_invalidation_monitor.register(bim_at_optional, tv.Optional);
function bim_at_optional(disp, value, options) {
  if (value == null)
    return;
  return disp.for_marker(disp.marker.marker).call(value, options);
}

base_invalidation_monitor.register(bim_at_schema, tv.Schema);
function bim_at_schema(disp, value, options) {
  return iter.reduce(disp.edge_iter(),
    function bim_at_schema_edge(cancelPrev, edge) {
      var cancel = edge.node.call(value[edge.key], options);
      return chainFns(cancelPrev, cancel);
    }, null);
}

base_invalidation_monitor.register(bim_at_list, tv.List);
function bim_at_list(disp, value, options) {
  var subdisp = disp.get_child('sub');

  return value.reduce(function(cancelPrev, v, i) {
      var cancel = subdisp.call(v, options);
      return chainFns(cancelPrev, cancel);
    }, null);
}

base_invalidation_monitor.register(bim_at_tuple, tv.Tuple);
function bim_at_tuple(disp, value, options) {
  var marker = disp.marker;

  return marker.field_names.reduce(function(cancelPrev, key, i) {
    var cancel = disp.get_child(key).call(value[i], options);
    return chainFns(cancelPrev, cancel);
  }, null);
}

base_invalidation_monitor.register(bim_at_polymorph, tv.Polymorph);
function bim_at_polymorph(disp, value, options) {
  var name = disp.marker.name_for_val(value);

  return disp.get_child(name).call(value, options);
}


message_invalidation_monitor.register(mim_at_document, tv.Document.marker);
function mim_at_document(disp, doc, options) {
  // Leafs can be unloaded, we don't care about that.
  if (disp.get_path(['uid'], null) == null)
    return;

  var doccache = options.doccache,
      notify = options.notify,
      cancelBase = disp.super(tv.Document.marker).call(doc, options);

  // If a message's component document is unloaded, the message is invalid.
  // Nothing else matters.
  doccache.on(['unload',doc.constructor,doc.uid], invalidate_message_document_monitor);
  // If a message's component document is refreshed, the message is ... still valid I guess?
  //doccache.on(['load',doc.constructor,doc.uid], cancel_message_document_monitor);

  return cancel_message_document_monitor;

  function invalidate_message_document_monitor() {
    notify();
    cancel_message_document_monitor();
  }

  function cancel_message_document_monitor() {
    doccache.off(['unload',doc.constructor,doc.uid], invalidate_message_document_monitor);
    //doccache.off(['load',doc.constructor,doc.uid], cancel_message_document_monitor);
    if (cancelBase)
      cancelBase();
  }
}

base_invalidation_monitor.register(bim_at_strmapping, tv.StrMapping);
function bim_at_strmapping(disp, value, options) {
  var subdisp = disp.get_child('sub');

  return Object.keys(value)
    .reduce(function(cancelPrev, key) {
      var cancel = subdisp.call(value[key], options);
      return chainFns(cancelPrev, cancel);
    }, null);
}

function chainFns(a, b) {
  return a && b
    ? chain
    : a || b;

  function chain() {
    a.apply(this, arguments);
    b.apply(this, arguments);
  }
}

base_invalidation_monitor.register(bim_at_passthrough, tv.Passthrough);
function bim_at_passthrough(disp, value, options) { }
