var { extend, mixin } = require('@maternity/mun-extend'),
    vg = require('@maternity/vertigo'),
    tv = require('./travesty'),
    Dispatcher = tv.dispatcher.Dispatcher,

    importer = exports.importer = new Dispatcher(
        function(d) { return d != null ? d.type : 'null'; });


// visibility
importer.register(import_null, 'null');
function import_null(disp, d) {
  return null;
}


// Primitives
importer.register(import_boolean, 'tv.Boolean');
function import_boolean(disp, d) {
  return new vg.PlainGraphNode(tv.Boolean());
}

importer.register(import_number, 'tv.Number');
function import_number(disp, d) {
  return new vg.PlainGraphNode(tv.Number());
}

importer.register(import_int, 'tv.Int');
function import_int(disp, d) {
  return new vg.PlainGraphNode(tv.Int());
}

importer.register(import_string, 'tv.String');
importer.register(import_string, 'tv.Bytes');
importer.register(import_string, 'tv.Decimal');
function import_string(disp, d) {
  return new vg.PlainGraphNode(tv.String());
}

// I'm not sure what we'll use passthrough for, so I'm leaving it commented out for now.
importer.register(import_passthrough, 'tv.Passthrough');
function import_passthrough(disp, d, options) {
  return new vg.PlainGraphNode(tv.Passthrough());
}

// Dates and time
importer.register(import_datetime, 'tv.DateTime');
function import_datetime(disp, d) {
  return new vg.PlainGraphNode(tv.DateTime());
}

importer.register(import_date, 'tv.Date');
function import_date(disp, d) {
  return new vg.PlainGraphNode(tv.Date());
}

importer.register(import_time, 'tv.Time');
function import_time(disp, d) {
  return new vg.PlainGraphNode(tv.Time());
}

importer.register(import_timedelta, 'tv.TimeDelta');
function import_timedelta(disp, d) {
  return new vg.PlainGraphNode(tv.TimeDelta());
}


// Collections
importer.register(import_tuple, 'tv.Tuple');
function import_tuple(disp, d, options) {
  return tv.Tuple(d.nfields, d.field_names)
    .of(d.children.map(function(d) { return disp.call(d, options); }));
}

importer.register(import_schema, 'tv.SchemaMapping');
function import_schema(disp, d, options) {
  return tv.SchemaMapping(d.extra_field_policy).of(
      d.children && d.children.map(function(d) { return {key: d.name, node: disp.call(d, options)}; }));
}

importer.register(import_unimapping, 'tv.UniMapping');
function import_unimapping(disp, d, options) {
  var key = d.children[0],
      val = d.children[1];

  if (key && key.name === 'val') {
    key = d.children[1];
    val = d.children[0];
  }

  if (!key || key.name !== 'key' || !val || val.name !== 'val')
    throw new Error('bad unimapping marker data');

  return tv.UniMapping().of(disp.call(key, options), disp.call(val, options));
}

importer.register(import_strmapping, 'tv.StrMapping');
function import_strmapping(disp, d, options) {
  var sub = d.children[0];

  if (!sub || sub.name !== 'sub')
    throw new Error('bad strmapping marker data');

  return tv.StrMapping().of(disp.call(sub, options));
}

importer.register(import_list, 'tv.List');
function import_list(disp, d, options) {
  var sub = d.children[0];

  if (!sub || sub.name !== 'sub')
    throw new Error('bad list marker data');

  return tv.List().of(disp.call(sub, options));
}


// Custom types
importer.register(import_schemaobj, 'tv.SchemaObj');
importer.register(import_schemaobj, 'tv.Document');
function import_schemaobj(disp, d, options) {
  if (d.typedef)
    return define_schemaobj(disp, d, options);

  // This must be a mistake or something
  throw new Error('Markers for SchemaObj/Document bases aren\'t useful');
}

function make_lazy_schemaobj(name) {
  tv.SchemaObj.extend(name, LazySchemaObj, {field_types: {}});
  LazySchemaObj.typegraph = new LazyTypeRefNode(LazySchemaObj, name);
  return LazySchemaObj;

  function LazySchemaObj() {
    // This requires the finalizer to do something like this:
    // ctor.prototype = base.extend(name, {field_types: field_types}).prototype;
    return LazySchemaObj.prototype.constructor.apply(this, arguments);
  }
}

function define_schemaobj(disp, d, options) {
  var name = d.typedef,
      field_types = Object.keys(d.field_types)
        .reduce(function(acc, key) {
          acc[key] = disp.call(d.field_types[key], options);
          return acc;
        }, {}),
      ctor = options.schemas[name] = options.schemas[name] || make_lazy_schemaobj(name),
      base,
      real;

  if (d.type === 'tv.SchemaObj')
    base = tv.SchemaObj;

  else if (d.type === 'tv.Document')
    base = tv.Document;

  else
    base = options.schemas[d.type];


  real = base.extend(name, extend({}, d, {field_types: field_types}));

  // TODO: Refactor so this isn't necessary in SchemaObj.extend() and here
  ctor.prototype = real.prototype;
  ctor.marker.prototype = real.marker.prototype;
  ctor.field_types = real.field_types;
  ctor.extend = real.extend;
  if (real.unloaded)
    ctor.unloaded = real.unloaded;
  if (real.traverse_docs)
    ctor.traverse_docs = real.traverse_docs;

  return ctor;
}

importer.set_default(import_custom);
function import_custom(disp, d, options) {
  if (d.typedef)
    return define_schemaobj(disp, d, options);

  // NOTE: These are all SchemaObj/Document

  var type = options.schemas[d.type] = options.schemas[d.type] || make_lazy_schemaobj(d.type),
      typegraph;

  if (d.children) {
    // This marker is wrong, but we need something in the graph until finalization has completed.
    typegraph = new vg.PlainGraphNode(type.marker());
    d.children.forEach(function(d) { typegraph.set_edge(d.name, disp.call(d, options)); });

  } else {
    // The default typegraph is updated in place when finalization happens.  We can use it as is.
    typegraph = type.typegraph;
  }

  return typegraph;
}


// Polymorph
importer.register(import_polymorph, 'tv.Polymorph');
function import_polymorph(disp, d, options) {
  var polymorph = tv.Polymorph({}),
      typegraph = polymorph.of({});

  Object.keys(d.mapping)
    .forEach(function(key) {
      var subtypegraph = disp.call(d.mapping[key], options),
          proto = proto_for.call(subtypegraph),
          node = proto.constructor.typegraph || subtypegraph;

      // This may be a stub prototype, but the own type key should be the same.
      polymorph.add(key, proto);
      typegraph.set_edge(key, node);
    });

  return typegraph;
}


var proto_for = exports.proto_for = tv.make_dispatcher();

proto_for.register(proto_for_object, tv.ObjectMarker);
function proto_for_object(disp) {
  return disp.marker.target_proto;
}

proto_for.register(proto_for_leaf, tv.Leaf);
function proto_for_leaf(disp) {
  return disp.marker.constructor.prototype;
}


// Wrappers
function import_wrapper(wrapper_type, disp, d, options) {
  var marker_graph = disp.call(d.marker, options),
      wrapper = wrapper_type.wrap(marker_graph);

  mixin(
    wrapper.value, {
      get marker() { return marker_graph.value; },
    });

  return wrapper;
}

importer.register(import_optional, 'tv.Optional');
function import_optional(disp, d, options) {
  return import_wrapper(tv.Optional, disp, d, options);
}

importer.register(import_validated, 'tv.Validated');
function import_validated(disp, d, options) {
  return import_wrapper(tv.Validated, disp, d, options);
}


function LazyTypeRefNode(ctor, name) {
  this.ctor = ctor;
  this.name = name;
}
LazyTypeRefNode.prototype = extend(
  vg.wrapper.GraphWrapper.prototype, {
    constructor: LazyTypeRefNode,

    get graph() {
      if (this.ctor.prototype.constructor === this.ctor)
        return new CanaryGraphNode(new this.ctor.marker(), this.name);
      return this.ctor.prototype.constructor.typegraph;
    },
    get_child: function lazytyperef_get_child(key) {
      return this.graph.get_child(key);
    },
  });


// While the typegraph is incomplete references to undefined types use this graph node type.  If
// anything tries to walk the graph then it's probably a bug.  That bug might result on a stack
// overflow, or it might pass silently leading to weird behavior later.  Later sucks.  Fail now.
function CanaryGraphNode(value, name) {
  this.value = value;
  this.name = name;
}
CanaryGraphNode.prototype = extend(
  vg.GraphNode.prototype, {
    constructor: CanaryGraphNode,
    key_iter: function() { throw "Incomplete type: "+this.name; },
    get_child: function() { throw "Incomplete type: "+this.name; },
  });
