// Load dependencies
import 'angular-strap/dist/modules/compiler';
import 'angular-strap/dist/modules/dimensions';

// Load module
import 'angular-strap/dist/modules/tooltip';

// Load template
import tooltipTpl from 'angular-strap/src/tooltip/tooltip.tpl.html';

// Expose the module name
const moduleName = 'mgcrea.ngStrap.tooltip';
export default moduleName;

// Override template defaults
angular.module(moduleName)
  .config(function($tooltipProvider) {
    $tooltipProvider.defaults.template = tooltipTpl;
    $tooltipProvider.defaults.templateUrl = null;
  });
