import classNames from 'classnames';
import * as React from 'react';

type BaseCheckboxProps = Omit<React.ComponentProps<'input'>, 'type'> & {
  inline?: boolean;
  containerClassName?: string;
};

/**
 * Encapsulates the markup/styles for a single checkbox input with a label, but
 * does not hook it up to the form. Supports stacked or inline layouts.
 */
export const BaseCheckbox = ({
  inline = false,
  containerClassName,
  disabled,
  children,
  ...props
}: BaseCheckboxProps) => {
  const body = (
    <React.Fragment>
      <input type="checkbox" disabled={disabled} {...props} />
      {children}
    </React.Fragment>
  );
  if (inline) {
    return (
      <label
        className={classNames(
          'checkbox-inline',
          disabled && 'disabled',
          containerClassName,
        )}
      >
        {body}
      </label>
    );
  }
  return (
    <div
      className={classNames(
        'checkbox',
        disabled && 'disabled',
        containerClassName,
      )}
    >
      <label>{body}</label>
    </div>
  );
};
