import { Anchor, Link } from '@maternity/mun-router';
import { SrefProps } from '@maternity/mun-router/types';
import * as React from 'react';

import { Col } from '../grid';
import { TileContext } from './TileContext';

type TileProps = {
  /** Optional CSS class for an icon */
  iconClass?: string;
  children: React.ReactNode;
} & (
  | {
      href: string;
    }
  | {
      onClick?: () => void;
    }
  | SrefProps
);

/**
 * Renders a square tile that gets its size from the parent `TileContainer`.
 *
 * Pass one of a sref, a href, or an onClick handler.
 */
export const Tile = ({ iconClass, children, ...linkProps }: TileProps) => {
  const colSizes = TileContext.useContext();
  const content = (
    <div className="setting-wrapper">
      <div className="setting">
        {iconClass && <i className={iconClass} />}
        <div className="margin-top-5">{children}</div>
      </div>
    </div>
  );

  return (
    <Col className="nav" {...colSizes}>
      <li>
        {'sref' in linkProps ? (
          <Link {...linkProps}>{content}</Link>
        ) : (
          <Anchor {...linkProps}>{content}</Anchor>
        )}
      </li>
    </Col>
  );
};
