import * as React from 'react';

interface Props {
  children: React.ReactNode;
}

/**
 * Visually masks its content until clicked.
 */
export const Masked = ({ children }: Props) => {
  const [mask, setMask] = React.useState(true);

  return (
    <span
      className={mask ? 'input-mask' : ''}
      onClick={() => setMask((m) => !m)}
    >
      {children}
    </span>
  );
};
