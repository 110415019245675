// private sentinel, do not release
const NOPE = Symbol();

export function iter<T>(x?: StrictCoerceable<T>): EnhancedIter<T> {
  return enhance(x ? coerce(x) : empty());
}

// eslint-disable-next-line require-yield
export function* empty(): IterableIterator<any> {
  return;
}

type ReduceFn<T, U> = (acc: U, val: T, idx: undefined, iter: Iterator<T>) => U;

// Case without initial value
export function reduce<T>(i: IterableIterator<T>, fn: ReduceFn<T, T>): T;
// Case with initial value
export function reduce<T, U>(
  i: IterableIterator<T>,
  fn: ReduceFn<T, U>,
  a: U,
): U;
export function reduce<T, U>(
  i: IterableIterator<T>,
  fn: ReduceFn<T, U>,
  a: U | typeof NOPE = NOPE,
): U {
  const first = i.next();
  let acc: U;

  if (a === NOPE) {
    if (first.done) {
      throw new TypeError('Reduce of empty iterator with no initial value');
    }
    acc = first.value as any as U;
  } else {
    if (first.done) return a;
    acc = fn(a, first.value, undefined, i);
  }

  for (const value of i) {
    acc = fn(acc, value, undefined, i);
  }

  return acc;
}

export function* map<T, U>(
  i: IterableIterator<T>,
  fn: (val: T) => U,
  self?: any,
): IterableIterator<U> {
  for (const value of i) {
    yield fn.call(self, value);
  }
}

// Case where fn is a type predecate that can narrow T to U
export function filter<T, U extends T>(
  i: IterableIterator<T>,
  fn: (val: T) => val is U,
  self?: any,
): IterableIterator<U>;
// Case where fn just returns a boolean
export function filter<T>(
  i: IterableIterator<T>,
  fn: (val: T) => boolean,
  self?: any,
): IterableIterator<T>;
export function* filter<T>(
  i: IterableIterator<T>,
  fn: (val: T) => any,
  self?: any,
): IterableIterator<T> {
  for (const value of i) {
    if (fn.call(self, value)) {
      yield value;
    }
  }
}

export function forEach<T>(
  i: IterableIterator<T>,
  fn: (val: T) => any,
  self?: any,
): void {
  for (const value of i) {
    fn.call(self, value);
  }
}

// Similar to `any`, but excludes `void` (requires functions to return)
// eslint-disable-next-line @typescript-eslint/ban-types
type NonVoid = {} | null | undefined;

export function some<T>(
  i: IterableIterator<T>,
  fn: (val: T) => NonVoid,
  self?: any,
): boolean {
  for (const value of i) {
    if (fn.call(self, value)) {
      return true;
    }
  }

  return false;
}

export function every<T>(
  i: IterableIterator<T>,
  fn: (val: T) => NonVoid,
  self?: any,
): boolean {
  for (const value of i) {
    if (!fn.call(self, value)) {
      return false;
    }
  }

  return true;
}

export function* concat<T>(
  ...iters: Array<Coerceable<T>>
): IterableIterator<T> {
  for (const it of iters) {
    const i = coerce(it, true);
    for (const value of i) {
      yield value;
    }
  }
}

const isIterLike = <T>(x: any): x is IterableIterator<T> =>
  x && typeof x.next === 'function';
const isArrayLike = <T>(x: any): x is ArrayLike<T> =>
  x && typeof x.length === 'number';

type StrictCoerceable<T> = IterableIterator<T> | T[];
type Coerceable<T> = StrictCoerceable<T> | T;

// Case where wrapOthers is falsy (x must be an iterator or array)
function coerce<T>(
  x: StrictCoerceable<T>,
  wrapOthers?: false,
): IterableIterator<T>;
// Case where wrapOthers is true
function coerce<T>(x: Coerceable<T>, wrapOthers: true): IterableIterator<T>;
function coerce<T>(
  x: Coerceable<T>,
  wrapOthers?: boolean,
): IterableIterator<T> {
  if (isIterLike(x)) return x;
  if (isArrayLike(x)) return fromArray(x);
  if (wrapOthers) return fromArray([x]);
  throw new Error('Not iterish');
}

export function toArray<T>(i: IterableIterator<T>): T[] {
  return reduce(
    i,
    (a, x) => {
      a.push(x);
      return a;
    },
    [] as T[],
  );
}

export function* fromArray<T>(arr: T[]): IterableIterator<T> {
  yield* arr;
}

export function* zip<T>(...iters: Array<Iterator<T>>): IterableIterator<T[]> {
  while (true) {
    const results = iters.map((i) => i.next());

    if (results.some((d) => d.done)) {
      return;
    }

    yield results.map((d) => d.value);
  }
}

export function join(i: IterableIterator<string>, sep: string = ','): string {
  return reduce(i, (acc, s) => (acc ? acc + sep + s : s), '');
}

export function enhance<T>(i: Iterator<T>): EnhancedIter<T> {
  return new EnhancedIter(i);
}

class EnhancedIter<T> {
  constructor(readonly i: Iterator<T>) {}

  next(): IteratorResult<T> {
    return this.i.next();
  }

  [Symbol.iterator]() {
    return this;
  }

  map<U>(fn: (val: T) => U, self?: any): EnhancedIter<U> {
    return new EnhancedIter(map(this, fn, self));
  }

  // Case where fn is a type predecate that can narrow T to U
  filter<U extends T>(fn: (val: T) => val is U, self?: any): EnhancedIter<U>;
  // Case where fn just returns a boolean
  filter(fn: (val: T) => boolean, self?: any): EnhancedIter<T>;
  filter(fn: (val: T) => boolean, self?: any): EnhancedIter<T> {
    return new EnhancedIter(filter(this, fn, self));
  }

  concat(...iters: Array<Coerceable<T>>): EnhancedIter<T> {
    return new EnhancedIter(concat(this, ...iters));
  }

  // Case without initial value
  reduce(fn: ReduceFn<T, T>): T;
  // Case with initial value
  reduce<U>(fn: ReduceFn<T, U>, a: U): U;
  reduce<U>(fn: ReduceFn<T, U>, a?: U): U {
    return reduce(this, fn, a as any);
  }

  forEach(fn: (val: T) => any, self?: any): void {
    return forEach(this, fn, self);
  }

  some(fn: (val: T) => NonVoid, self?: any): boolean {
    return some(this, fn, self);
  }

  every(fn: (val: T) => NonVoid, self?: any): boolean {
    return every(this, fn, self);
  }

  toArray(): T[] {
    return toArray(this);
  }

  join(sep?: string): string {
    // TODO: Add runtime check that this is an Iterator<string>?
    return join(this as any, sep);
  }
}
