import { Path } from '@maternity/mun-types';
import classNames from 'classnames';
import * as React from 'react';

import {
  BaseFieldProps,
  EnforceModelType,
  EnforceRequired,
  FieldValues,
} from './types';
import { useField } from './useField';
import { setDefaultErrorMessage } from './validation';
import { maxValidator, minValidator } from './validators';

const stringToFloat = (v: string) =>
  v === ''
    ? null
    : /^\s*(-|\+)?(\d+|(\d*(\.\d*)))([eE][+-]?\d+)?\s*$/.test(v)
    ? parseFloat(v)
    : NaN;
const stringToInt = (v: string) =>
  v === '' ? null : /^\s*(-|\+)?(\d+)\s*$/.test(v) ? parseInt(v, 10) : NaN;

const modelToString = (m: number | null | undefined) =>
  m == null || isNaN(m) || !isFinite(m) ? '' : m.toString();

const checkLimit = (
  value: number,
  limit: number | string | undefined,
  validator: typeof maxValidator | typeof minValidator,
) => {
  const coerced = typeof limit === 'string' ? parseFloat(limit) : limit;
  if (coerced == null || isNaN(coerced)) {
    return;
  }
  return validator(coerced)(value);
};

setDefaultErrorMessage('numeric', 'Please enter a number.');

type NumberInputProps<V extends FieldValues, M extends Path<V>> = Omit<
  React.ComponentProps<'input'>,
  | 'form'
  | 'name'
  | 'value'
  | 'defaultValue'
  | 'required'
  | 'children'
  | 'min'
  | 'max'
> & {
  /** When set to `integer`, only integers are considered valid. When set to
   * `float` (default), any number is considered valid. */
  mode?: 'float' | 'integer';
  /** Optional maximum value. */
  max?: number | string;
  /** Optional minimum value. */
  min?: number | string;
} & BaseFieldProps<V, M> &
  EnforceModelType<V, M, number> &
  EnforceRequired<V, M>;

// Use constant for default value to avoid breaking memoization
const NO_VALIDATORS: any[] = [];

/**
 * An input with number validation.
 *
 * `NaN` and `Infinity` are treated as invalid values.
 *
 * There is no default error message for min and max validation.
 *
 * We use `type=text` as default with validation because of issues with `type=number`:
 * - native validation interferes with change events when the value is invalid
 * - in some browsers, `type=number` responds to mousewheel events
 * - `type=number` doesn't respect the `size` attribute
 * So be aware of these issues when using `type=number`.
 */
export const NumberInput = <V extends FieldValues, M extends Path<V>>({
  form,
  model,
  mode = 'float',
  type = 'text',
  max,
  min,
  validators = NO_VALIDATORS,
  // Give mobile browsers a hint about which keyboard to show
  inputMode = 'numeric',
  required,
  className,
  onChange,
  onBlur,
  ...props
}: NumberInputProps<V, M>) => {
  validators = React.useMemo(
    () => [
      (v: any) => {
        if (required && v == null) return 'required';
        if (v != null && (typeof v !== 'number' || isNaN(v) || !isFinite(v))) {
          return 'numeric';
        }
        const isMax = checkLimit(v, max, maxValidator);
        if (isMax) return isMax;
        const isMin = checkLimit(v, min, minValidator);
        if (isMin) return isMin;
      },
      ...validators,
    ],
    [validators, required, max, min],
  );
  const [modelValue, api] = useField<number | null>({
    form,
    model,
    validators,
  });
  // Store the input value in local state to allow it to diverge slightly from
  // the model value while the user is editing (e.g. "1." parses to "1").
  const [viewValue, setViewValue] = React.useState('');
  const parse = mode === 'float' ? stringToFloat : stringToInt;
  // When the model value changes, update local state
  if (!Object.is(modelValue, parse(viewValue))) {
    const modelString = modelToString(modelValue);
    // Also compare the strings since the mapping is not one-to-one
    if (modelString !== viewValue) setViewValue(modelString);
  }

  return (
    <input
      className={classNames('form-control', className)}
      type={type}
      name={model}
      value={viewValue}
      onChange={(e) => {
        const { value } = e.target;
        setViewValue(value);
        const parsed = parse(value);
        // Update model value if necessary
        if (!Object.is(modelValue, parsed)) {
          api.setValue(parsed);
        }
        if (onChange) onChange(e);
      }}
      onBlur={(e) => {
        api.setTouched();
        if (onBlur) onBlur(e);
      }}
      inputMode={inputMode}
      {...props}
    />
  );
};
