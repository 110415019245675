import { Button } from '@maternity/mun-layout';
import * as React from 'react';

import { useModalContext } from './Modal';

type GenericMouseEvent = React.MouseEvent<any, any>;
type Props<C extends React.ElementType> = React.ComponentProps<C> extends {
  onClick?: (e: GenericMouseEvent) => void;
}
  ? Omit<React.ComponentProps<C>, 'onClick'> & {
      /** Component with an `onClick` prop to render (defaults to <Button>) */
      component?: C;
      /**
       * Optional click handler. If the `onClick` handler returns a promise,
       * the modal will be hidden when the promise resolves. If the `onClick`
       * handler calls `preventDefault` on the event, the modal will not be
       * hidden.
       * */
      onClick?: (e: GenericMouseEvent) => void | Promise<unknown>;
    }
  : never;

/**
 * Renders a button that hides the modal when clicked.
 */
export const ModalHideButton = <
  C extends React.ElementType = typeof Button<'button'>,
>({
  component = Button as any,
  onClick,
  ...props
}: Props<C>) => {
  const Component = component;
  const modalContext = useModalContext();

  return (
    <Component
      onClick={(e: GenericMouseEvent) => {
        if (onClick) {
          const result = onClick(e);
          if (result && result.then) {
            result.then(modalContext.hide);
            return;
          }
          if (e.defaultPrevented) return;
        }
        modalContext.hide();
      }}
      {...(props as any)}
    />
  );
};
