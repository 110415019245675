import classNames from 'classnames';
import * as React from 'react';

export const addClass = <P extends { className?: string }>(
  { className, ...rest }: P,
  name: string,
) => ({
  className: classNames(className, name),
  ...rest,
});

/** A HOC to add column css classes to the wrapped component. */
export const withCols = <C extends (props: any) => React.ReactElement | null>(
  Component: C,
) =>
  (({ labelProps = {}, divProps = {}, ...rest }) =>
    Component({
      labelProps: addClass(labelProps, 'col-sm-3'),
      divProps: addClass(divProps, 'col-sm-9'),
      ...rest,
    })) as C;
