import tv from '@maternity/travesty';

import copy from './copy';
import create from './create';

const copy_or_create = tv.make_dispatcher();
export default copy_or_create;

/**
 * The `copy_or_create` dispatcher will copy the given source value, creating
 * empty objects for any missing fields.
 *
 * Call as `copy_or_create.call(typegraph, source, options)``
 *
 * Arguments:
 * - typegraph: The typegraph to traverse.
 * - source: The source value to copy from or `undefined`.
 * - options: An object with the following properties:
 *   * in_docset: A DocSet to use for any created documents.
 */

copy_or_create.register(copy_or_create_marker, tv.Marker);
function copy_or_create_marker(disp, value, options) {
  if (value == null) {
    // No source value, so initialze subgraph with create.
    return create.call(disp.marker_graph(), options);
  } else {
    // Copy the source value, but return to this dispatcher for children.
    var copy_disp = new tv.dispatchgraph.DispatchOverlay(disp, null, copy);
    return copy_disp.call(value, options);
  }
}

// Don't automatically unwrap optionals.
copy_or_create.register(copy_or_create_optional, tv.Optional);
function copy_or_create_optional(disp, value, options) {
  if (value == null)
    return null;

  return disp.for_marker(disp.marker.marker).call(value, options);
}
