// Load dependencies
import 'angular-strap/dist/modules/compiler';
import 'angular-strap/dist/modules/dimensions';

// Load module
import 'angular-strap/dist/modules/modal';

// Load template
import modalTpl from 'angular-strap/src/modal/modal.tpl.html';

// Expose the module name
const moduleName = 'mgcrea.ngStrap.modal';
export default moduleName;

// Override template defaults
angular.module(moduleName)
  .config(function($modalProvider) {
    $modalProvider.defaults.template = modalTpl;
    $modalProvider.defaults.templateUrl = null;
  });
