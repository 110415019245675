import classNames from 'classnames';
import * as React from 'react';

import { ModalHideButton } from './ModalHideButton';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

/**
 * Renders a modal footer. Shows a close button if no children are given.
 */
export const ModalFooter = ({ children, className }: Props) => (
  <div className={classNames('modal-footer', className)}>
    {React.Children.count(children) ? (
      children
    ) : (
      <ModalHideButton>Close</ModalHideButton>
    )}
  </div>
);
