var { extend, mixin } = require('@maternity/mun-extend'),
    tv = require('./travesty'),
    undictify = tv.undictify,
    ownTypeKeyOf = tv.base.ownTypeKeyOf,
    DoubleLoadError = tv.document.DoubleLoadError;


exports.DocSet = DocSet;
function DocSet(options) {
  this.map = {};
  this.uidgen = options?.uidgen || require('uuid/v4');
}

mixin(DocSet.prototype, {
    _key: function(type, uid) {
      return ownTypeKeyOf(type.prototype)+'/'+uid;
    },

    // TODO: add, update?

    is_loaded: function(type, uid) {
      var key = this._key(type, uid);

      return key in this.map && this.map[key].$loaded;
    },

    get: function(type, uid) {
      var key = this._key(type, uid);

      return this.map[key];
    },

    create: function(type, uid) {
      if (uid == null)
        uid = this.uidgen();

      var key = this._key(type, uid);

      if (key in this.map)
        throw new Error('Duplicate key '+key);

      return (this.map[key] = type.unloaded(uid));
    },

    get_or_create: function(type, uid) {
      var key = this._key(type, uid);

      return key in this.map
        ? this.map[key]
        : this.map[key] = this.create(type, uid);
    },

    load: function(type, data, options) {
      var key = this._key(type, data.uid);

      if (this.is_loaded(type, data.uid))
        throw new DoubleLoadError(this.map[key]);

      options = options ? extend(options) : {};
      options.in_docset = this;

      return undictify(type, data, options);
    },
  });
