var _ = require('lodash'),
    { extend, mixin } = require('@maternity/mun-extend');


exports.BaseIndexDef = BaseIndexDef;
function BaseIndexDef() {
  this._extractors = [];
  this._cleaners = [];
}

mixin(
  BaseIndexDef.prototype, {
    extractor: function addExtractor(fn) {
      this._extractors.push(fn);
      return this;
    },

    cleaner: function setCleaner(fn) {
      this._cleaners.push(fn);
      return this;
    },

    resetCleaners: function resetCleaners() {
      this._cleaners = [];
      return this;
    },

    _clean: function _clean(field) {
      return this._cleaners.reduce(function(field, fn) { return fn(field); }, field);
    },

    compare: function setCompare(fn) {
      this._compare = fn;
      return this;
    },

    create: function createIndex() {
      return new SortedIndex(this._compare);
    },

    add: function add(index, key, data) {
      var self = this,
          added = {};

      this._extractors
        .forEach(function(fn) {
          //fn(data, addField);
          // TODO: multiple values at a time
          addField(fn(data));
        });

      function addField(field) {
        if (field == null || field === '')
          return;

        field = self._clean(field);

        if (field in added)
          return;

        added[field] = null;

        // NOTE: It may look backwards, but the token is the key in index.add.
        index.add(field, key);
      }
    },

    match: function match(index, query) {
      // Match items that exactly match the cleaned query
      var idx = _.indexOf(index.sorted, this._clean(query));

      if (idx === -1)
        return [];
      return index.keymap[index.sorted[idx]];
    },
  });


exports.StringIndexDef = StringIndexDef;
function StringIndexDef() {
  BaseIndexDef.apply(this, arguments);
}

StringIndexDef.prototype = extend(
  BaseIndexDef.prototype, {
    constructor: StringIndexDef,

    match: function match(index, query) {
      // Match items that start with the cleaned query
      return this.startsWith(index, this._clean(query));
    },

    startsWith: function startsWith(index, term) {
      var sorted = index.sorted,
          startPos = _.sortedIndex(sorted, term),
          matches = [];

      for (var endPos = startPos;
            endPos < sorted.length && sorted[endPos].slice(0, term.length) === term;
            endPos++)
        /* nothing */;

      for (var pos = startPos; pos < endPos; pos++)
        matches = matches.concat(index.keymap[index.sorted[pos]]);

      return _.uniq(matches);
    },
  });


exports.WordIndexDef = WordIndexDef;
function WordIndexDef() {
  StringIndexDef.apply(this, arguments);

  this.tokenizer(splitWords);
  this.cleaner(lowercase);

  function splitWords(s) { return s.split(/\W+/); }
  function lowercase(s) { return s.toLowerCase(); }
}

WordIndexDef.prototype = extend(
  StringIndexDef.prototype, {
    constructor: WordIndexDef,

    tokenizer: function setTokenizer(fn) {
      this._tokenizer = fn;
      return this;
    },

    add: function add(index, key, data) {
      var self = this,
          added = {};

      this._extractors
        .forEach(function(fn) {
          //fn(data, addField);
          // TODO: multiple values at a time
          addField(fn(data));
        });

      function addField(field) {
        if (field == null || field === '')
          return;

        field = self._clean(field);

        self._tokenizer(field)
          .forEach(function(token) {
            if (token in added)
              return;

            added[token] = null;

            // NOTE: It may look backwards, but the token is the key in index.add.
            index.add(token, key);
          });
      }
    },

    match: function match(index, query) {
      // Match items containing all terms in the cleaned and tokenized query
      var self = this,
          terms = this._tokenizer(this._clean(query));

      return _.intersection.apply(this, terms.map(function(term) {
        return self.startsWith(index, term);
      }));
    },
  });


// This is the component that actually holds data
function SortedIndex(cmp) {
  this.keymap = {};
  // TODO: add a switch to enable or disable the pkeymap
  this._pkeymap = {};
  this._cmp = cmp;
}

mixin(
  SortedIndex.prototype, {
    add: function add(key, pkey) {
      if (!this.keymap[key])
        this.keymap[key] = [];
      this.keymap[key].push(pkey);

      if (this._pkeymap) {
        // Track the keys that a pkey matches
        // This is likely not worth the cost, so it can be disabled by not defining the pkeymap.
        if (!this._pkeymap[pkey])
          this._pkeymap[pkey] = [];
        this._pkeymap[pkey].push(key);
      }

      this._sorted = null;
    },

    remove: function remove(pkeys) {
      if (!this._pkeymap)
        throw new Error('no pkeymap, no remove()');

      if (!Array.isArray(pkeys))
        pkeys = [pkeys];

      var keymap = this.keymap,
          pkeymap = this._pkeymap;

      pkeys.forEach(function(pkey) {
          if (!pkeymap[pkey])
            return;
          pkeymap[pkey].forEach(function(key) {
              keymap[key].splice(keymap[key].indexOf(pkey), 1);
              if (keymap[key].length === 0)
                delete keymap[key];
            });
          delete pkeymap[pkey];
        });

      this._sorted = null;
    },

    get sorted() {
      if (!this._sorted)
        this._sorted = Object.keys(this.keymap).sort(this._cmp);
      return this._sorted;
    },
  });

