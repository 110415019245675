import env from '@maternity/mun-env';
import { extend } from '@maternity/mun-extend';

import routeDefs from '../../sterling-routes';
import schemaDefs from '../../sterling-schemas';
import sparse_mapping from '../../sparse_mapping';
import timeline from '../../timeline';

import ngModule from '../kerbin-core-module';

ngModule

  .config(function(munDocEndpointsProvider, munDocSchemasProvider) {
    var endpointDefs = {};

    Object.keys(routeDefs).forEach(function(ep) {
      var epData = routeDefs[ep],
          methods = endpointDefs[ep] = {};

      Object.keys(epData).forEach(function(method) {
        var methodData = epData[method];

        methods[method] = extend(methodData, {
          endpoint: ep,
          rule: env.STERLING_SERVICE_LOCATION+methodData.rule,
        });
      });
    });

    munDocEndpointsProvider.register(endpointDefs);

    sparse_mapping.install_importer(munDocSchemasProvider.importer);
    timeline.install_importer(munDocSchemasProvider.importer);

    schemaDefs.forEach(munDocSchemasProvider.load);

    // Add stub for kerbin.FileReference so doccache events fire
    munDocSchemasProvider.load({
      typedef: 'kerbin.FileReference',
      type: 'tv.Document',
      field_types: {},
    });
  })

  .run(function(munDocEndpoints, munDocSchemas, munDocKeepalive) {
    var invalidation_monitor = munDocKeepalive.invalidation_monitor,

        //ClientStub = munDocSchemas['kerbin.ClientStub'],
        Client = munDocSchemas['kerbin.Client'],
        Person = munDocSchemas['kerbin.Person'],
        Episode = munDocSchemas['kerbin.Episode'],
        EpisodeRoles = munDocSchemas['kerbin.EpisodeRoles'],
        File = munDocSchemas['kerbin.File'],
        FileReference = munDocSchemas['kerbin.FileReference'],
        Export = munDocSchemas['kerbin.Export'],
        //PracticeItem = munDocSchemas['kerbin.PracticeItem'],
        Location = munDocSchemas['kerbin.Location'],
        Message = munDocSchemas['kerbin.Message'],
        Signature = munDocSchemas['kerbin.Signature'];

    invalidation_monitor.register(im_at_clientstub, 'kerbin.ClientStub');
    function im_at_clientstub(disp, doc, options) {
      var doccache = options.doccache,
          person = doc.person,
          latest_episode = doc.latest_episode,
          events = new invalidation_monitor.EventSet('clientstub_monitor');

      disp.super(invalidation_monitor).call(doc, options);

      events.obj(doccache)
        .on(['updated',Person,person.uid], invalidate_clientstub)
        .on(['deleted',Person,person.uid], invalidate_clientstub)
        .on(['updated',Episode,latest_episode.uid], invalidate_clientstub)
        .on(['deleted',Episode,latest_episode.uid], invalidate_clientstub)
        // This is correct, but as a practical matter we can just monitor the items(ClientStub) message.
        //.on(['updated',Episode])
        //.on(['created',Episode])
        .cancelOn(['unload',doc.constructor,doc.uid])
        .cancelOn(['load',doc.constructor,doc.uid]);

      if (options.stats_start)
        events.onCancel(options.stats_start(doc.constructor, 'clientstub'));

      function invalidate_clientstub() {
        doccache._unload(doc.constructor, doc.uid);
      }
    }

    invalidation_monitor.register(im_at_clients_index_msg,
      munDocEndpoints.clients_index.GET.message_out);
    function im_at_clients_index_msg(disp, msg, options) {
      var doccache = options.doccache,
          events = new invalidation_monitor.EventSet('client_index_monitor');

      disp.super(invalidation_monitor).call(msg, options);

      events.obj(doccache)
        .on(['created',Client], invalidate_clients_index)
        .on(['updated',Client], invalidate_clients_index)
        .on(['created',Episode], invalidate_clients_index)
        .on(['updated',Episode], invalidate_clients_index)
        .on(['created', EpisodeRoles], invalidate_clients_index)
        .on(['deleted', EpisodeRoles], invalidate_clients_index);

      events.obj(msg)
        .cancelOn('invalid');

      if (options.stats_start)
        events.onCancel(options.stats_start(msg.constructor, 'clients_index'));

      function invalidate_clients_index() {
        msg.$invalidate();
      }
    }

    invalidation_monitor.register(im_at_file_list,
      // Also used with file_add, file_person_index, and file_practice_index
      munDocEndpoints.file_episode_index.GET.message_out);
    function im_at_file_list(disp, msg, options) {
      var doccache = options.doccache,
          events = new invalidation_monitor.EventSet('file_list_monitor');

      disp.super(invalidation_monitor).call(msg, options);

      events.obj(doccache)
        .on(['created', File], invalidate_file_list)
        .on(['updated', File], invalidate_file_list)
        .on(['created', Export], invalidate_file_list);

      events.obj(msg)
        .cancelOn('invalid');

      if (options.stats_start)
        events.onCancel(options.stats_start(msg.constructor, 'file_list'));

      function invalidate_file_list() {
        msg.$invalidate();
      }
    }

    invalidation_monitor.register(
      (disp, msg, options) => {
        const invalidate = () => msg.$invalidate();
        const events = new invalidation_monitor.EventSet('file_references_monitor');

        disp.super(invalidation_monitor).call(msg, options);

        events.obj(options.doccache)
          .on(['created', FileReference], invalidate)
          .on(['updated', FileReference], invalidate)
          .on(['deleted', FileReference], invalidate);

        events.obj(msg)
          .cancelOn('invalid');

        if (options.stats_start)
          events.onCancel(options.stats_start(msg.constructor, 'file_references'));
      },
      munDocEndpoints.file_references.GET.message_out,
    );


    invalidation_monitor.register(im_at_practiceitem, 'kerbin.PracticeItem');
    function im_at_practiceitem(disp, doc, options) {
      var doccache = options.doccache,
          events = new invalidation_monitor.EventSet('practiceitem_monitor');

      disp.super(invalidation_monitor).call(doc, options);

      events.obj(doccache)
        .on(['created',Person], invalidate_practiceitem)
        .on(['created',Location], invalidate_practiceitem)
        .cancelOn(['unload',doc.constructor,doc.uid])
        .cancelOn(['load',doc.constructor,doc.uid]);

      if (options.stats_start)
        events.onCancel(options.stats_start(doc.constructor, 'practiceitem'));

      function invalidate_practiceitem() {
        doccache._unload(doc.constructor, doc.uid);
      }
    }

    invalidation_monitor.register(im_at_person_list_msg,
      munDocEndpoints.practice_members_index.GET.message_out);
    function im_at_person_list_msg(disp, msg, options) {
      var doccache = options.doccache,
          events = new invalidation_monitor.EventSet('person_list_monitor');

      disp.super(invalidation_monitor).call(msg, options);

      events.obj(doccache)
        .on(['created',Person], invalidate_person_list);

      events.obj(msg)
        .cancelOn('invalid');

      if (options.stats_start)
        events.onCancel(options.stats_start(msg.constructor, 'person_list'));

      function invalidate_person_list() {
        msg.$invalidate();
      }
    }

    invalidation_monitor.register(im_at_location_list_msg,
      munDocEndpoints.practice_locations_index.GET.message_out);
    function im_at_location_list_msg(disp, msg, options) {
      var doccache = options.doccache,
          events = new invalidation_monitor.EventSet('location_list_monitor');

      disp.super(invalidation_monitor).call(msg, options);

      events.obj(doccache)
        .on(['created',Location], invalidate_location_list);

      events.obj(msg)
        .cancelOn('invalid');

      if (options.stats_start)
        events.onCancel(options.stats_start(msg.constructor, 'location_list'));

      function invalidate_location_list() {
        msg.$invalidate();
      }
    }

    invalidation_monitor.register(im_at_message_list_msg,
        munDocEndpoints.library_resource_discussions.GET.message_out);
    invalidation_monitor.register(im_at_message_list_msg,
        // Also used with messages_practice_index
        munDocEndpoints.messages_episode_index.GET.message_out);
    function im_at_message_list_msg(disp, msg, options) {
      var doccache = options.doccache,
          events = new invalidation_monitor.EventSet('message_list_monitor');

      disp.super(invalidation_monitor).call(msg, options);

      events.obj(doccache)
        .on(['created',Message], invalidate_message_list)
        .on(['updated',Message], invalidate_message_list)
        .on(['created', Signature], invalidate_message_list)
        .on(['deleted', Signature], invalidate_message_list)
        .on(['updated', Signature], invalidate_message_list);

      events.obj(msg)
        .cancelOn('invalid');

      if (options.stats_start)
        events.onCancel(options.stats_start(msg.constructor, 'message_list'));

      function invalidate_message_list() {
        msg.$invalidate();
      }
    }

    invalidation_monitor.register(im_at_message_msg,
      munDocEndpoints.message_load.GET.message_out);
    function im_at_message_msg(disp, msg, options) {
      var doccache = options.doccache,
          events = new invalidation_monitor.EventSet('message_monitor');

      disp.super(invalidation_monitor).call(msg, options);

      events.obj(doccache)
        .on(['created', Message], invalidate_message)
        .on(['updated', Message], invalidate_message)
        .on(['updated', Signature], invalidate_message)
        .on(['deleted', Signature], invalidate_message)
        .on(['created', Signature], invalidate_message);

      events.obj(msg)
        .cancelOn('invalid');

      if (options.stats_start)
        events.onCancel(options.stats_start(msg.constructor, 'message'));

      function invalidate_message() {
        msg.$invalidate();
      }
    }

    invalidation_monitor.register(im_at_pending_info_msg,
      munDocEndpoints.pending_info_load.GET.message_out);
    function im_at_pending_info_msg(disp, msg, options) {
      var doccache = options.doccache,
          events = new invalidation_monitor.EventSet('pending_info_load_monitor');

      disp.super(invalidation_monitor).call(msg, options);

      events.obj(doccache)
        .on(['updated', Person], invalidate_pending_info_load);

      events.obj(msg)
        .cancelOn('invalid');

      if (options.stats_start)
        events.onCancel(options.stats_start(msg.constructor, 'pending_info_load'));

      function invalidate_pending_info_load() {
        msg.$invalidate();
      }
    }

    invalidation_monitor.register(im_at_care_team_msg,
      munDocEndpoints.episode_care_team_index.GET.message_out);
    function im_at_care_team_msg(disp, msg, options) {
      var doccache = options.doccache,
          events = new invalidation_monitor.EventSet(
            'episode_care_team_index_monitor');

      disp.super(invalidation_monitor).call(msg, options);

      events.obj(doccache)
        .on(['created', EpisodeRoles], invalidate);

      events.obj(msg)
        .cancelOn('invalid');

      if (options.stats_start)
        events.onCancel(options.stats_start(
          msg.constructor, 'episode_care_team_index'));

      function invalidate() {
        msg.$invalidate();
      }
    }

  })

  ;
