var _ = require('lodash'),
    { extend, mixin } = require('@maternity/mun-extend');

function defaultCmp(a, b) {
  return a < b ? -1 : a > b ? 1 : 0;
}

exports.SortKeyDef = SortKeyDef;
function SortKeyDef(fn, cmp) {
  this._extract = fn;
  this._cmp = cmp || defaultCmp;
}

mixin(
  SortKeyDef.prototype, {
    _nullsFirst: false,
    _reverse: false,

    nullsFirst: function() { return extend(this, {_nullsFirst: true}); },
    nullsLast: function() { return extend(this, {_nullsFirst: false}); },
    asc: function() { return extend(this, {_reverse: false}); },
    desc: function() { return extend(this, {_reverse: true}); },

    get cmp() {
      var cmp = this._cmp;

      return this._reverse
        ? rcmp
        : cmp;

      function rcmp(a, b) { return cmp(b, a); }
    },

    get: function get(obj) {
      var key = this._extract(obj);

      if (key != null)
        return key;

      return this._nullsFirst
        ? -Infinity
        : Infinity;
    },

    create: function create() {
      return new SortKey(this.cmp);
    },

    add: function add(index, key, obj) {
      index.add(this.get(obj), key);
    },
  });


exports.TemplateSortKeyDef = TemplateSortKeyDef;
function TemplateSortKeyDef(tpl) {
  SortKeyDef.call(this, _.template(tpl));
}

TemplateSortKeyDef.prototype = extend(
  SortKeyDef.prototype, {
    constructor: TemplateSortKeyDef,
  });

function numericCmp(a, b) {
  return defaultCmp(+a, +b);
}

exports.NumericSortKeyDef = NumericSortKeyDef;
function NumericSortKeyDef(fn) {
  SortKeyDef.call(this, fn, numericCmp);
}

NumericSortKeyDef.prototype = extend(
  SortKeyDef.prototype, {
    constructor: NumericSortKeyDef,
  });


function SortKey(cmp) {
  this._cmp = cmp;
  this._keymap = {};
}

mixin(
  SortKey.prototype, {
    add: function add(key, pkey) {
      var keymap = this._keymap;
      if (!keymap[key])
        keymap[key] = [];
      keymap[key].push(pkey);
      this._index = null;
    },

    remove: function(pkeys) {
      if (!Array.isArray(pkeys))
        pkeys = [pkeys];

      var index = this.index,
          keymap = this._keymap;

      pkeys.forEach(function(pkey) {
          if (index[pkey] == null)
            return;
          var key = index[pkey].key,
              pkeys = keymap[key];
          pkeys.splice(pkeys.indexOf(pkey), 1);
          if (pkeys.length === 0)
            delete keymap[key];
        });

      this._index = null;
    },

    get index() {
      // Generate a map of the index of each pkey
      if (!this._index) {
        var index = this._index = {},
            keymap = this._keymap;

        Object.keys(keymap).sort(this._cmp)
          .forEach(function(key, i) {
            keymap[key].forEach(function(pkey) { index[pkey] = {key: key, pos: i}; });
          });
      }
      return this._index;
    },

    get cmp() {
      // Generate a cmp function for the current index
      var index = this.index;
      return function cmp(a, b) {
        var apos = index[a].pos,
            bpos = index[b].pos;
        if (apos < bpos)
          return -1;
        if (apos > bpos)
          return 1;
        return 0;
      };
    },

    get rcmp() {
      var cmp = this.cmp;
      return function rcmp(a, b) { return cmp(b, a); };
    },
  });
