import { Path } from '@maternity/mun-types';
import * as React from 'react';

import { BaseCheckbox } from './BaseCheckbox';
import { BaseFieldProps, EnforceModelType, FieldValues } from './types';
import { useField } from './useField';

type SingleCheckboxProps<V extends FieldValues, M extends Path<V>, T> = Omit<
  React.ComponentProps<typeof BaseCheckbox>,
  'form' | 'name' | 'value' | 'checked' | 'defaultChecked'
> &
  // Don't use EnforceRequired type since required=true only permits trueValue
  BaseFieldProps<V, M> &
  EnforceModelType<V, M, T> & {
    /** The value to use when checked. Defaults to `true`. */
    trueValue?: T;
    /** The value to use when unchecked. Defaults to `false`. */
    falseValue?: T;
  };

// Use constant for default value to avoid breaking memoization
const NO_VALIDATORS: any[] = [];

/**
 * A single checkbox input with a label.
 *
 * By default, the value will be `true` when checked and `false` when
 * unchecked, but these can be customized with the `trueValue`and `falseValue`
 * props.
 */
export const SingleCheckbox = <
  V extends FieldValues,
  M extends Path<V>,
  T = boolean,
>({
  form,
  model,
  validators = NO_VALIDATORS,
  required,
  onChange,
  onBlur,
  trueValue = true as unknown as T,
  falseValue = false as unknown as T,
  ...props
}: SingleCheckboxProps<V, M, T>) => {
  validators = React.useMemo(
    () =>
      required
        ? [
            (v: any) => {
              if (v !== trueValue) return 'required';
            },
            ...validators,
          ]
        : validators,
    [validators, required, trueValue],
  );
  const [value, api] = useField<T>({ form, model, validators });
  const handleChange: typeof onChange = React.useCallback(
    (e) => {
      api.setValue(e.target.checked ? trueValue : falseValue);
      if (onChange) onChange(e);
    },
    [api, trueValue, falseValue, onChange],
  );
  const handleBlur: typeof onBlur = React.useCallback(
    (e) => {
      api.setTouched();
      if (onBlur) onBlur(e);
    },
    [api, onBlur],
  );

  return (
    <BaseCheckbox
      name={model}
      checked={value === trueValue}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />
  );
};
