import { kerbin_Practice } from '@maternity/kerbin/app/js/types';

import { useMunSession } from './useMunSession';

// TODO: Document how to code split features? (React.lazy/webpack-chunk-name)

type EffectiveFeatures = kerbin_Practice['effective_features'][0];

/** Returns true if the practice has the given feature enabled. */
export const useFeatureEnabled = (feature: EffectiveFeatures): boolean => {
  const session = useMunSession();
  return !!session.practice?.effective_features.includes(feature);
};
