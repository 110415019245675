/*
keepalive - a helper to let you know when objects die.

keepalive(object, restore, options) will call `restore` whenever `object` is
invalidated in the cache. `restore` should be a function that will result in a
fresh value of `object` (otherwise you won't actually keep the object alive).

The return value is a cancel function that you should call when you no longer
care about keeping this object alive.

Typically a component or service will use this to indicate that it's currently
displaying a particular document or endpoint response, so that the value will
automatically be reloaded when another endpoint response indicates that it's no
longer valid. The component will call the returned cancel() function as part of
its own teardown handling.

*/

import tv from '@maternity/travesty';

import doccache_verify from './doccacheverify';
import { EventSet } from './doccacheblaster';

const keepalive_watch = new tv.dispatcher.Dispatcher(tv.base.typeKeysOf);


keepalive_watch.register(keepalive_document, 'tv.Document');
function keepalive_document(disp, doc, notify, options) {
  var doccache = options.doccache,
      events = new EventSet('keepalive_document');

  events.obj(doccache)
    .on(['unload',doc.constructor.prototype,doc.uid], notify);

  if (options.stats_start)
    events.onCancel(options.stats_start(doc.constructor));

  return events.cancel;
}


keepalive_watch.register(keepalive_message, 'tv_chasm.SchemaMessage');
function keepalive_message(disp, msg, notify, options) {
  var events = new EventSet('keepalive_message');

  events.obj(msg)
    .on('invalid', notify);

  if (options.stats_start)
    events.onCancel(options.stats_start(msg.constructor));

  return events.cancel;
}


keepalive_watch.register(keepalive_searchresults, 'mun-doc.SearchResults');
function keepalive_searchresults(disp, results, notify, options) {
  var events = new EventSet('keepalive_searchresultls');

  events.obj(results)
    .on('invalid', notify);

  if (options.stats_start)
    events.onCancel(options.stats_start(results.constructor));

  return events.cancel;
}


//var keepaliveCounter = 0;
export default function keepalive(obj, restoreCb, options) {
  // keepalive(obj, restore, options);
  //
  // restoreCb should restore or refresh the response data, and resolve to the updated or restored
  // message.
  var //keepaliveID = ++keepaliveCounter,
      cancelWatch,
      invalidated,
      canceled;

  D('init');
  if (obj.then)
    obj.then(start);
  else
    start(obj);

  return cancel;

  function start(obj_) {
    if (!obj_ || canceled)
      return;

    D('starting');
    obj = obj_;

    if (!doccache_verify.call(obj, obj)) {
      invalidate();
      return;
    }

    invalidated = false;
    cancelWatch = keepalive_watch.call(obj, invalidate, options);
  }

  function invalidate() {
    if (invalidated)
      return;

    D('invalidated');
    invalidated = true;

    if (cancelWatch)
      cancelWatch();
    cancelWatch = null;

    var msg = restoreCb();
    if (msg)
      msg.then(start);
    else
      cancel();
  }

  function cancel() {
    D('canceled');
    canceled = true;

    if (cancelWatch)
      cancelWatch();
  }

  function D() {
    //console.log.apply(console, ['keepalive #'+keepaliveID+' '+obj.constructor].concat([].slice.call(arguments)));
  }
}
