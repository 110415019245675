/*
doccacheverify - helper for checking if an object is invalid.

The exported module is a travesty dispatcher; run on an object, it tells you if
that object is invalid. Here "invalid" means in the sense of cache validation,
not in the sense of tv.validate - a "valid" object is an object that's fully
loaded and no part of it has been invalidated.

An object is invalid if it contains an unloaded document OR if it contains a
SchemMessage with $invalid=True.

This is the synchronous version of the invalidation monitor in
doccacheblaster.js - doccacheverify answers the question "Is this document
stale?", while the monitor performs "Notify me when this object becomes stale".


Note that in our SchemaMessage typegraphs we omit all nodes below a Document
node when we don't intend to send a loaded document as part of this message.
doccacheverify understands this, and won't consider these documents to be part
of the SchemaMessage itself; thus, they can be unloaded or stale without
invalidating the parent SchemaMessage.
*/

import * as iter from '@maternity/mun-itertools';
import tv from '@maternity/travesty';

import { SchemaMessage } from './schemamessage';

const doccache_verify = tv.make_dispatcher();
export default doccache_verify;

doccache_verify.register(doccache_verify_at_leaf, tv.Leaf);
function doccache_verify_at_leaf(disp, value) {
  return true;
}

doccache_verify.register(doccache_verify_at_schema, tv.Schema);
function doccache_verify_at_schema(disp, value) {
  return iter.every(disp.edge_iter(), function doccache_verify_at_schema_edge(edge) {
      return edge.node.call(value[edge.key]);
    });
}

doccache_verify.register(doccache_verify_at_list, tv.List);
function doccache_verify_at_list(disp, value) {
  var subdisp = disp.get_child('sub');

  return value.every(function(subvalue) {
    return subdisp.call(subvalue);
  });
}

doccache_verify.register(doccache_verify_at_tuple, tv.Tuple);
function doccache_verify_at_tuple(disp, value) {
  var marker = disp.marker;

  return marker.field_names.every(function(key, i) {
    return disp.get_child(key).call(value[i]);
  });
}

doccache_verify.register(doccache_verify_at_document, tv.Document.marker);
function doccache_verify_at_document(disp, doc) {
  // Assume it's not null because Optional will stop us and if it's not optional
  // it should never have gotten into the cache.

  if (disp.get_path(['uid'], null) == null)
    return true;

  if (!doc.$loaded)
    return false;

  return disp.super(tv.Document.marker).call(doc);
}

doccache_verify.register(doccache_verify_at_optional, tv.Optional);
function doccache_verify_at_optional(disp, value) {
  if (value == null)
    return true;

  return disp.super(tv.Optional).call(value);
}

doccache_verify.register(doccache_verify_at_message, SchemaMessage.marker);
function doccache_verify_at_message(disp, msg) {
  if (msg.$invalid)
    return false;

  return disp.super(SchemaMessage.marker).call(msg);
}

doccache_verify.register(doccache_verify_at_polymorph, tv.Polymorph);
function doccache_verify_at_polymorph(disp, value) {
  var name = disp.marker.name_for_val(value);

  return disp.get_child(name).call(value);
}

doccache_verify.register(doccache_verify_at_strmapping, tv.StrMapping);
function doccache_verify_at_strmapping(disp, value) {
  var subdisp = disp.get_child('sub');

  return Object.keys(value).every(function(key) {
      return subdisp.call(value[key]);
    });
}

// TODO: unimap

doccache_verify.register(doccache_verify_at_passthrough, tv.Passthrough);
function doccache_verify_at_passthrough(disp, value) {
  return true;
}
