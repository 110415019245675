import { ButtonProps } from '@maternity/mun-layout';
import { Path } from '@maternity/mun-types';
import * as React from 'react';

import { FieldValues, FormApi } from './types';
import { useField } from './useField';
import { Validator } from './validation';

// TODO: Conditionally type model based on `multiple` prop?
type ModelType = File | File[];
// We handle file uploads separately from form data submission, so file inputs
// don't map to a path in the `FieldValues` type. We produce a compiler error
// if the model path exists in the `FieldValues` type to avoid confusing
// browser and kerbin `File` objects.
type EnforceVirtualModel<
  V extends FieldValues,
  M extends string,
> = M extends Path<V>
  ? { _error: 'file input must use a virtual model path' }
  : {}; // eslint-disable-line @typescript-eslint/ban-types
type FileInputProps<V extends FieldValues, M extends string> = Omit<
  React.ComponentProps<'input'>,
  | 'form'
  | 'name'
  | 'type'
  | 'value'
  | 'defaultValue'
  | 'required'
  | 'children'
  | 'onChange'
> & {
  /** Form API handle */
  form: FormApi<V>;
  /** Model path */
  model: M;
  /** Optional (memoized) list of validators */
  validators?: Validator[];
  /** If true, enable required validation */
  required?: boolean;
  /** If true, the input is reset when a file is chosen. This allows the user
   * to reselect the same file where the file is processed immediately. */
  resetAfterChange?: boolean;
  /** Also pass the model value to `onChange` callbacks */
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: ModelType,
  ) => void;
} & EnforceVirtualModel<V, M>;

// Use constant for default value to avoid breaking memoization
const NO_VALIDATORS: any[] = [];

/**
 * Renders an unstyled `<input type="file" />`.
 *
 * Generally, the model value is either a `File` object or undefined. If
 * `multiple=true`, the model value is set to an array of `File` objects.
 */
export const FileInput = <V extends FieldValues, M extends string>({
  form,
  model,
  validators = NO_VALIDATORS,
  resetAfterChange = false,
  required,
  multiple,
  onChange,
  // Default to an empty string so the `accept` attribute is always emitted to
  // work around an issue with Safari 14
  accept = '',
  ...props
}: FileInputProps<V, M>) => {
  validators = React.useMemo(
    () =>
      required
        ? [
            (v: any) => {
              if ((multiple && !v?.length) || !v) return 'required';
            },
            ...validators,
          ]
        : validators,
    [validators, required, multiple],
  );
  const [, api] = useField<ModelType>({ form, model, validators });
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (!files) return;
      // Coerce the `FileList` to an array or single `File`
      const value = multiple ? [].slice.call(files) : files[0];
      api.setValue(value);
      if (resetAfterChange) {
        // Clear the file selection
        e.target.value = '';
      }
      if (onChange) onChange(e, value);
    },
    [multiple, resetAfterChange, onChange, api],
  );

  return (
    <input
      type="file"
      name={model}
      onBlur={() => api.setTouched()}
      onChange={handleChange}
      multiple={multiple}
      accept={accept}
      {...props}
    />
  );
};

type FileInputButtonProps<
  V extends FieldValues,
  M extends string,
> = FileInputProps<V, M> & {
  /** Button variant style. Defaults to `default`. */
  variant?: ButtonProps<'label'>['variant'];
  /** Visible content of the "button". */
  children: React.ReactNode;
};

/**
 * Renders a label that looks like a button and triggers a hidden file input
 * (since file inputs have limited styling options).
 */
export const FileInputButton = <V extends FieldValues, M extends string>({
  variant = 'default',
  children,
  ...props
}: FileInputButtonProps<V, M>) => {
  const labelRef = React.useRef<HTMLLabelElement>(null);

  return (
    <label className={`btn btn-${variant}`} role="button" ref={labelRef}>
      {children}
      <FileInput<V, M>
        // Apparently the destructuring is causing Typescript to incorrectly
        // error, so a we need a cast here.
        {...(props as any)}
        className="hidden-file-input"
        onFocus={() => {
          labelRef.current?.classList.add('focus');
        }}
        onBlur={() => {
          labelRef.current?.classList.remove('focus');
        }}
      />
    </label>
  );
};
