import * as React from 'react';

interface TableViewProps<T> {
  onClickPrev: React.MouseEventHandler<HTMLButtonElement>;
  onClickTitle: React.MouseEventHandler<HTMLButtonElement>;
  onClickNext: React.MouseEventHandler<HTMLButtonElement>;
  title: React.ReactNode;
  // optional extra markup to include in the <thead>
  extraHead?: React.ReactNode;
  // 2d array of data; should have at least 3 columns
  rows: T[][];
  // render function for table data cells that is passed a value from `rows`
  renderCell: (v: T) => React.ReactNode;
}

export const TableView = <T extends any>({
  onClickPrev,
  onClickTitle,
  onClickNext,
  title,
  extraHead,
  rows,
  renderCell,
}: TableViewProps<T>) => (
  <table>
    <thead>
      <tr className="text-center">
        <th>
          <button
            type="button"
            className="btn btn-default pull-left"
            onClick={onClickPrev}
            tabIndex={-1}
          >
            <i className="fa fa-chevron-left" />
          </button>
        </th>
        <th colSpan={rows[0].length - 2}>
          <button
            type="button"
            className="btn btn-default btn-block text-strong"
            onClick={onClickTitle}
            tabIndex={-1}
          >
            <strong>{title}</strong>
          </button>
        </th>
        <th>
          <button
            type="button"
            className="btn btn-default pull-right"
            onClick={onClickNext}
            tabIndex={-1}
          >
            <i className="fa fa-chevron-right" />
          </button>
        </th>
      </tr>
      {extraHead}
    </thead>
    <tbody>
      {rows.map((row, idx) => (
        <tr key={idx}>{row.map(renderCell)}</tr>
      ))}
    </tbody>
  </table>
);
