import * as React from 'react';

// TODO: Stop using inline styles and bootstrap classes
// TODO: More explicit dependency on Fontawesome
export const Spinner = () => (
  <div
    className="text-center text-muted"
    style={{ margin: 'auto', padding: '2em' }}
  >
    <i className="fa fa-spinner fa-spin fa-3x" />
  </div>
);
