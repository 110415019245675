import { createRequiredContext } from '@maternity/mun-required-context';

import { type Col } from '../grid';

export type TileContextType = Pick<
  React.ComponentProps<typeof Col>,
  'xs' | 'sm' | 'md' | 'lg'
>;

export const TileContext = createRequiredContext<TileContextType>();
