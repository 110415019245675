import {
  Col,
  Panel,
  PanelBody,
  PanelHeading,
  PanelTitle,
  Row,
} from '@maternity/mun-layout';
import { Anchor, Link, useParams } from '@maternity/mun-router';
import * as React from 'react';

export const NotFound = () => {
  const { message, status } = useParams();
  // Coerce status since it is often a number instead of a string
  const isForbidden = `${status}` === '403';

  return (
    <Row className="margin-top-20">
      <Col sm={6} smOffset={3}>
        <Panel>
          <PanelHeading>
            <PanelTitle component="h1">
              We're sorry,{' '}
              {isForbidden
                ? "it seems you're not authorized to view this page."
                : "the page you're looking for isn't here."}
            </PanelTitle>
          </PanelHeading>
          <PanelBody>
            {message && <p>{message}</p>}
            <p>
              {isForbidden
                ? 'If you believe this message is in error, please '
                : 'Please '}
              head back to the <Link sref="top.dashboard">Dashboard</Link> and
              try navigating here again.
            </p>
            <p>
              If the problem persists, please{' '}
              <Anchor
                href="https://maternityneighborhood.com/help"
                target="_blank"
              >
                contact support
              </Anchor>
              .
            </p>
          </PanelBody>
        </Panel>
      </Col>
    </Row>
  );
};
