import env from '@maternity/mun-env';
import { Anchor } from '@maternity/mun-router';
import { useMunSession } from '@maternity/mun-session';
import * as React from 'react';

import mnLogoUrl from '../../images/brands/maternity-neighborhood-logo.png';
import { kerbin_Practice } from '../types';

const gitInfo = {
  commitDate: process.env.GIT_COMMIT_DATE,
  version: process.env.GIT_VERSION,
  // Note: This is just here for reference by devs (not used in the component)
  hash: process.env.GIT_HASH,
};

export const Footer = () => {
  const { practice } = useMunSession();

  // If no practice (i.e. unauthenticated), use the brand in the env (if any)
  const brand = practice
    ? practice.features.brand
    : (env.CUSTOM_BRAND as kerbin_Practice['features']['brand'] | undefined);

  const year = new Date().getUTCFullYear();

  return (
    <footer className="text-muted">
      {brand?.logo_url ? (
        <div className="custom-logo">
          <img className="img-responsive center-block" src={brand.logo_url} />
        </div>
      ) : (
        <Anchor
          href="https://maternityneighborhood.com?from=kerbin"
          target="_blank"
        >
          <img className="img-responsive center-block" src={mnLogoUrl} />
        </Anchor>
      )}
      <div className="smerfline">
        ©{year}, Maternity Neighborhood &nbsp;&nbsp; | &nbsp;&nbsp;
        <span title={gitInfo.version}>Version {gitInfo.commitDate}</span>
      </div>
    </footer>
  );
};
