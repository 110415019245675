/**
 * Assert the argument has type `never`. This is useful for exhaustiveness
 * checking (require all variants of a discriminated union to be handled).
 *
 * Example:
 * ```
 * type Shape =
 *   | { type: 'square'; s: number }
 *   | { type: 'rectangle'; h: number; w: number };
 * const area = (shape: Shape): number => {
 *   switch (shape.type) {
 *     case 'square':
 *       return shape.s * shape.s;
 *     case 'rectangle':
 *       return shape.h * shape.w;
 *     default:
 *       // Adding a variant to Shape will cause a compiler error here
 *       return assertNever(shape);
 *   }
 * };
 * ```
 */
export const assertNever = (v: never): never => {
  throw new Error(`Unexpected value: ${v}`);
};
